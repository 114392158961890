export default {
    "Account": [
      {
        "Data Fields": "resourceType",
        "Type": "Account",
        
        "Required": "True",
        "Description": "This is a Account resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Unique identifier used to reference the account.  Might or might not be intended for human use (e.g. credit card number)."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Indicates whether the account is presently used/usable or not."
      },
      {
        "Data Fields": "type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Categorizes the account for reporting and searching purposes."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Name used for the account when displaying it to humans in reports, etc."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Identifies the entity which incurs the expenses. While the immediate recipients of services or goods might be entities related to the subject, the expenses were ultimately incurred by the subject of the Account."
      },
      {
        "Data Fields": "servicePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The date range of services associated with this account."
      },
      {
        "Data Fields": "coverage",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The party(s) that are responsible for covering the payment of this account, and what order should they be applied to the account."
      },
      {
        "Data Fields": "coverage.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "coverage.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "coverage.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "coverage.priority",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "The priority of the coverage in the context of this account."
      },
      {
        "Data Fields": "owner",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Indicates the service area, hospital, department, etc. with responsibility for managing the Account."
      },
      {
        "Data Fields": "description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Provides additional information about what the account tracks and how it is used."
      },
      {
        "Data Fields": "guarantor",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The parties responsible for balancing the account if other payment options fall short."
      },
      {
        "Data Fields": "guarantor.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "guarantor.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "guarantor.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "guarantor.party",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The entity who is responsible."
      },
      {
        "Data Fields": "guarantor.onHold",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "A guarantor may be placed on credit hold or otherwise have their role temporarily suspended."
      },
      {
        "Data Fields": "guarantor.period",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The timeframe during which the guarantor accepts responsibility for the account."
      },
      {
        "Data Fields": "partOf",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Reference to a parent Account."
      }
    ],
    "ActivityDefinition": [
      {
        "Data Fields": "resourceType",
        "Type": "ActivityDefinition",
        
        
        "Required": "True",
        "Description": "This is a ActivityDefinition resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "url",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "An absolute URI that is used to identify this activity definition when it is referenced in a specification, model, design or an instance; also called its canonical identifier. This SHOULD be globally unique and SHOULD be a literal address at which at which an authoritative instance of this activity definition is (or will be) published. This URL can be the target of a canonical reference. It SHALL remain the same when the activity definition is stored on different servers."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A formal identifier that is used to identify this activity definition when it is represented in other formats, or referenced in a specification, model, design or an instance."
      },
      {
        "Data Fields": "version",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The identifier that is used to identify this version of the activity definition when it is referenced in a specification, model, design or instance. This is an arbitrary value managed by the activity definition author and is not expected to be globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if a managed version is not available. There is also no expectation that versions can be placed in a lexicographical sequence. To provide a version consistent with the Decision Support Service specification, use the format Major.Minor.Revision (e.g. 1.0.0). For more information on versioning knowledge assets, refer to the Decision Support Service specification. Note that a version is required for non-experimental active assets."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A natural language name identifying the activity definition. This name should be usable as an identifier for the module by machine processing applications such as code generation."
      },
      {
        "Data Fields": "title",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A short, descriptive, user-friendly title for the activity definition."
      },
      {
        "Data Fields": "subtitle",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "An explanatory or alternate title for the activity definition giving additional information about its content."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The status of this activity definition. Enables tracking the life-cycle of the content."
      },
      {
        "Data Fields": "experimental",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "A Boolean value to indicate that this activity definition is authored for testing purposes (or education/evaluation/marketing) and is not intended to be used for genuine usage."
      },
      {
        "Data Fields": "subjectCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code or group definition that describes the intended subject of the activity being defined."
      },
      {
        "Data Fields": "subjectReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A code or group definition that describes the intended subject of the activity being defined."
      },
      {
        "Data Fields": "date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date  (and optionally time) when the activity definition was published. The date must change when the business version changes and it must change if the status code changes. In addition, it should change when the substantive content of the activity definition changes."
      },
      {
        "Data Fields": "publisher",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the organization or individual that published the activity definition."
      },
      {
        "Data Fields": "contact",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "Contact details to assist a user in finding and communicating with the publisher."
      },
      {
        "Data Fields": "description",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A free text natural language description of the activity definition from a consumer's perspective."
      },
      {
        "Data Fields": "useContext",
        "Type": "UsageContext",
        
        
        "Required": "False",
        "Description": "The content was developed with a focus and intent of supporting the contexts that are listed. These contexts may be general categories (gender, age, ...) or may be references to specific programs (insurance plans, studies, ...) and may be used to assist with indexing and searching for appropriate activity definition instances."
      },
      {
        "Data Fields": "jurisdiction",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A legal or geographic region in which the activity definition is intended to be used."
      },
      {
        "Data Fields": "purpose",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Explanation of why this activity definition is needed and why it has been designed as it has."
      },
      {
        "Data Fields": "usage",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A detailed description of how the activity definition is used from a clinical perspective."
      },
      {
        "Data Fields": "copyright",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A copyright statement relating to the activity definition and/or its contents. Copyright statements are generally legal restrictions on the use and publishing of the activity definition."
      },
      {
        "Data Fields": "approvalDate",
        "Type": "date",
        
        
        "Required": "False",
        "Description": "The date on which the resource content was approved by the publisher. Approval happens once when the content is officially approved for usage."
      },
      {
        "Data Fields": "lastReviewDate",
        "Type": "date",
        
        
        "Required": "False",
        "Description": "The date on which the resource content was last reviewed. Review happens periodically after approval but does not change the original approval date."
      },
      {
        "Data Fields": "effectivePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The period during which the activity definition content was or is planned to be in active use."
      },
      {
        "Data Fields": "topic",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Descriptive topics related to the content of the activity. Topics provide a high-level categorization of the activity that can be useful for filtering and searching."
      },
      {
        "Data Fields": "author",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individiual or organization primarily involved in the creation and maintenance of the content."
      },
      {
        "Data Fields": "editor",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individual or organization primarily responsible for internal coherence of the content."
      },
      {
        "Data Fields": "reviewer",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individual or organization primarily responsible for review of some aspect of the content."
      },
      {
        "Data Fields": "endorser",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individual or organization responsible for officially endorsing the content for use in some setting."
      },
      {
        "Data Fields": "relatedArtifact",
        "Type": "RelatedArtifact",
        
        
        "Required": "False",
        "Description": "Related artifacts such as additional documentation, justification, or bibliographic references."
      },
      {
        "Data Fields": "library",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "A reference to a Library resource containing any formal logic used by the activity definition."
      },
      {
        "Data Fields": "kind",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "A description of the kind of resource the activity definition is representing. For example, a MedicationRequest, a ServiceRequest, or a CommunicationRequest. Typically, but not always, this is a Request resource."
      },
      {
        "Data Fields": "profile",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "A profile to which the target of the activity definition is expected to conform."
      },
      {
        "Data Fields": "code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Detailed description of the type of activity; e.g. What lab test, what procedure, what kind of encounter."
      },
      {
        "Data Fields": "intent",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Indicates the level of authority/intentionality associated with the activity and where the request should fit into the workflow chain."
      },
      {
        "Data Fields": "priority",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Indicates how quickly the activity  should be addressed with respect to other requests."
      },
      {
        "Data Fields": "doNotPerform",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Set this to true if the definition is to indicate that a particular activity should NOT be performed. If true, this element should be interpreted to reinforce a negative coding. For example NPO as a code with a doNotPerform of true would still indicate to NOT perform the action."
      },
      {
        "Data Fields": "timingTiming",
        "Type": "Timing",
        
        
        "Required": "False",
        "Description": "The period, timing or frequency upon which the described activity is to occur."
      },
      {
        "Data Fields": "timingDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The period, timing or frequency upon which the described activity is to occur."
      },
      {
        "Data Fields": "timingAge",
        "Type": "Age",
        
        
        "Required": "False",
        "Description": "The period, timing or frequency upon which the described activity is to occur."
      },
      {
        "Data Fields": "timingPeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The period, timing or frequency upon which the described activity is to occur."
      },
      {
        "Data Fields": "timingRange",
        "Type": "Range",
        
        
        "Required": "False",
        "Description": "The period, timing or frequency upon which the described activity is to occur."
      },
      {
        "Data Fields": "timingDuration",
        "Type": "Duration",
        
        
        "Required": "False",
        "Description": "The period, timing or frequency upon which the described activity is to occur."
      },
      {
        "Data Fields": "location",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Identifies the facility where the activity will occur; e.g. home, hospital, specific clinic, etc."
      },
      {
        "Data Fields": "participant",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Indicates who should participate in performing the action described."
      },
      {
        "Data Fields": "participant.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "participant.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "participant.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "participant.type",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The type of participant in the action."
      },
      {
        "Data Fields": "participant.role",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The role the participant should play in performing the described action."
      },
      {
        "Data Fields": "productReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Identifies the food, drug or other product being consumed or supplied in the activity."
      },
      {
        "Data Fields": "productCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Identifies the food, drug or other product being consumed or supplied in the activity."
      },
      {
        "Data Fields": "quantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "Identifies the quantity expected to be consumed at once (per dose, per meal, etc.)."
      },
      {
        "Data Fields": "dosage",
        "Type": "Dosage",
        
        
        "Required": "False",
        "Description": "Provides detailed dosage instructions in the same way that they are described for MedicationRequest resources."
      },
      {
        "Data Fields": "bodySite",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Indicates the sites on the subject's body where the procedure should be performed (I.e. the target sites)."
      },
      {
        "Data Fields": "specimenRequirement",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Defines specimen requirements for the action to be performed, such as required specimens for a lab test."
      },
      {
        "Data Fields": "observationRequirement",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Defines observation requirements for the action to be performed, such as body weight or surface area."
      },
      {
        "Data Fields": "observationResultRequirement",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Defines the observations that are expected to be produced by the action."
      },
      {
        "Data Fields": "transform",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "A reference to a StructureMap resource that defines a transform that can be executed to produce the intent resource using the ActivityDefinition instance as the input."
      },
      {
        "Data Fields": "dynamicValue",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Dynamic values that will be evaluated to produce values for elements of the resulting resource. For example, if the dosage of a medication must be computed based on the patient's weight, a dynamic value would be used to specify an expression that calculated the weight, and the path on the request resource that would contain the result."
      },
      {
        "Data Fields": "dynamicValue.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "dynamicValue.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "dynamicValue.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "dynamicValue.path",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The path to the element to be customized. This is the path on the resource that will hold the result of the calculation defined by the expression. The specified path SHALL be a FHIRPath resolveable on the specified target type of the ActivityDefinition, and SHALL consist only of identifiers, constant indexers, and a restricted subset of functions. The path is allowed to contain qualifiers (.) to traverse sub-elements, as well as indexers ([x]) to traverse multiple-cardinality sub-elements (see the [Simple FHIRPath Profile](fhirpath.html#simple) for full details)."
      },
      {
        "Data Fields": "dynamicValue.expression",
        "Type": "Expression",
        
        
        "Required": "False",
        "Description": "An expression specifying the value of the customized element."
      }
    ],
    "AdverseEvent": [
      {
        "Data Fields": "resourceType",
        "Type": "AdverseEvent",
        
        
        "Required": "True",
        "Description": "This is a AdverseEvent resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Business identifiers assigned to this adverse event by the performer or other systems which remain constant as the resource is updated and propagates from server to server."
      },
      {
        "Data Fields": "actuality",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Whether the event actually happened, or just had the potential to. Note that this is independent of whether anyone was affected or harmed or how severely."
      },
      {
        "Data Fields": "category",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The overall type of event, intended for search and filtering purposes."
      },
      {
        "Data Fields": "event",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "This element defines the specific type of event that occurred or that was prevented from occurring."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "This subject or group impacted by the event."
      },
      {
        "Data Fields": "encounter",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The Encounter during which AdverseEvent was created or to which the creation of this record is tightly associated."
      },
      {
        "Data Fields": "date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date (and perhaps time) when the adverse event occurred."
      },
      {
        "Data Fields": "detected",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "Estimated or actual date the AdverseEvent began, in the opinion of the reporter."
      },
      {
        "Data Fields": "recordedDate",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date on which the existence of the AdverseEvent was first recorded."
      },
      {
        "Data Fields": "resultingCondition",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Includes information about the reaction that occurred as a result of exposure to a substance (for example, a drug or a chemical)."
      },
      {
        "Data Fields": "location",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The information about where the adverse event occurred."
      },
      {
        "Data Fields": "seriousness",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Assessment whether this event was of real importance."
      },
      {
        "Data Fields": "severity",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Describes the severity of the adverse event, in relation to the subject. Contrast to AdverseEvent.seriousness - a severe rash might not be serious, but a mild heart problem is."
      },
      {
        "Data Fields": "outcome",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Describes the type of outcome from the adverse event."
      },
      {
        "Data Fields": "recorder",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Information on who recorded the adverse event.  May be the patient or a practitioner."
      },
      {
        "Data Fields": "contributor",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Parties that may or should contribute or have contributed information to the adverse event, which can consist of one or more activities.  Such information includes information leading to the decision to perform the activity and how to perform the activity (e.g. consultant), information that the activity itself seeks to reveal (e.g. informant of clinical history), or information about what activity was performed (e.g. informant witness)."
      },
      {
        "Data Fields": "suspectEntity",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Describes the entity that is suspected to have caused the adverse event."
      },
      {
        "Data Fields": "suspectEntity.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "suspectEntity.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "suspectEntity.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "suspectEntity.instance",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Identifies the actual instance of what caused the adverse event.  May be a substance, medication, medication administration, medication statement or a device."
      },
      {
        "Data Fields": "suspectEntity.causality",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Information on the possible cause of the event."
      },
      {
        "Data Fields": "suspectEntity.causality.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "suspectEntity.causality.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "suspectEntity.causality.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "suspectEntity.causality.assessment",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Assessment of if the entity caused the event."
      },
      {
        "Data Fields": "suspectEntity.causality.productRelatedness",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "AdverseEvent.suspectEntity.causalityProductRelatedness."
      },
      {
        "Data Fields": "suspectEntity.causality.author",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "AdverseEvent.suspectEntity.causalityAuthor."
      },
      {
        "Data Fields": "suspectEntity.causality.method",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "ProbabilityScale | Bayesian | Checklist."
      },
      {
        "Data Fields": "subjectMedicalHistory",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "AdverseEvent.subjectMedicalHistory."
      },
      {
        "Data Fields": "referenceDocument",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "AdverseEvent.referenceDocument."
      },
      {
        "Data Fields": "study",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "AdverseEvent.study."
      }
    ],
    "AllergyIntolerance": [
      {
        "Data Fields": "resourceType",
        "Type": "AllergyIntolerance",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "This is a AllergyIntolerance resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Business identifiers assigned to this AllergyIntolerance by the performer or other systems which remain constant as the resource is updated and propagates from server to server."
      },
      {
        "Data Fields": "clinicalStatus",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The clinical status of the allergy or intolerance."
      },
      {
        "Data Fields": "verificationStatus",
        "Type": "CodeableConcept",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Assertion about certainty associated with the propensity, or potential risk, of a reaction to the identified substance (including pharmaceutical product)."
      },
      {
        "Data Fields": "type",
        "Type": "code",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Identification of the underlying physiological mechanism for the reaction risk."
      },
      {
        "Data Fields": "category",
        "Type": "code",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Category of the identified substance."
      },
      {
        "Data Fields": "criticality",
        "Type": "code",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Estimate of the potential clinical harm, or seriousness, of the reaction to the identified substance."
      },
      {
        "Data Fields": "code",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Code for an allergy or intolerance statement (either a positive or a negated/excluded statement).  This may be a code for a substance or pharmaceutical product that is considered to be responsible for the adverse reaction risk (e.g., \"Latex\"), an allergy or intolerance condition (e.g., \"Latex allergy\"), or a negated/excluded code for a specific substance or class (e.g., \"No latex allergy\") or a general or categorical negated statement (e.g.,  \"No known allergy\", \"No known drug allergies\").  Note: the substance for a specific reaction may be different from the substance identified as the cause of the risk, but it must be consistent with it. For instance, it may be a more specific substance (e.g. a brand medication) or a composite product that includes the identified substance. It must be clinically safe to only process the 'code' and ignore the 'reaction.substance'.  If a receiving system is unable to confirm that AllergyIntolerance.reaction.substance falls within the semantic scope of AllergyIntolerance.code, then the receiving system should ignore AllergyIntolerance.reaction.substance."
      },
      {
        "Data Fields": "patient",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "The patient who has the allergy or intolerance."
      },
      {
        "Data Fields": "encounter",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The encounter when the allergy or intolerance was asserted."
      },
      {
        "Data Fields": "onsetDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Estimated or actual date,  date-time, or age when allergy or intolerance was identified."
      },
      {
        "Data Fields": "onsetAge",
        "Type": "Age",
        
        
        "Required": "False",
        "Description": "Estimated or actual date,  date-time, or age when allergy or intolerance was identified."
      },
      {
        "Data Fields": "onsetPeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "Estimated or actual date,  date-time, or age when allergy or intolerance was identified."
      },
      {
        "Data Fields": "onsetRange",
        "Type": "Range",
        
        
        "Required": "False",
        "Description": "Estimated or actual date,  date-time, or age when allergy or intolerance was identified."
      },
      {
        "Data Fields": "onsetString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Estimated or actual date,  date-time, or age when allergy or intolerance was identified."
      },
      {
        "Data Fields": "recordedDate",
        "Type": "dateTime",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The recordedDate represents when this particular AllergyIntolerance record was created in the system, which is often a system-generated date."
      },
      {
        "Data Fields": "recorder",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Individual who recorded the record and takes responsibility for its content."
      },
      {
        "Data Fields": "asserter",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The source of the information about the allergy that is recorded."
      },
      {
        "Data Fields": "lastOccurrence",
        "Type": "dateTime",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Represents the date and/or time of the last known occurrence of a reaction event."
      },
      {
        "Data Fields": "note",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "Additional narrative about the propensity for the Adverse Reaction, not captured in other fields."
      },
      {
        "Data Fields": "reaction",
        "Type": "BackboneElement",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Details about each adverse reaction event linked to exposure to the identified substance."
      },
      {
        "Data Fields": "reaction.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "reaction.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "reaction.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "reaction.substance",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Identification of the specific substance (or pharmaceutical product) considered to be responsible for the Adverse Reaction event. Note: the substance for a specific reaction may be different from the substance identified as the cause of the risk, but it must be consistent with it. For instance, it may be a more specific substance (e.g. a brand medication) or a composite product that includes the identified substance. It must be clinically safe to only process the 'code' and ignore the 'reaction.substance'.  If a receiving system is unable to confirm that AllergyIntolerance.reaction.substance falls within the semantic scope of AllergyIntolerance.code, then the receiving system should ignore AllergyIntolerance.reaction.substance."
      },
      {
        "Data Fields": "reaction.manifestation",
        "Type": "CodeableConcept",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Clinical symptoms and/or signs that are observed or associated with the adverse reaction event."
      },
      {
        "Data Fields": "reaction.description",
        "Type": "string",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Text description about the reaction as a whole, including details of the manifestation if required."
      },
      {
        "Data Fields": "reaction.onset",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "Record of the date and/or time of the onset of the Reaction."
      },
      {
        "Data Fields": "reaction.severity",
        "Type": "code",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Clinical assessment of the severity of the reaction event as a whole, potentially considering multiple different manifestations."
      },
      {
        "Data Fields": "reaction.exposureRoute",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Identification of the route by which the subject was exposed to the substance."
      },
      {
        "Data Fields": "reaction.note",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "Additional text about the adverse reaction event not captured in other fields."
      }
    ],
    "Appointment": [
      {
        "Data Fields": "resourceType",
        "Type": "Appointment",
        
        
        "Required": "True",
        "Description": "This is a Appointment resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "This records identifiers associated with this appointment concern that are defined by business processes and/or used to refer to it when a direct URL reference to the resource itself is not appropriate (e.g. in CDA documents, or in written / printed documentation)."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The overall status of the Appointment. Each of the participants has their own participation status which indicates their involvement in the process, however this status indicates the shared status."
      },
      {
        "Data Fields": "cancelationReason",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The coded reason for the appointment being cancelled. This is often used in reporting/billing/futher processing to determine if further actions are required, or specific fees apply."
      },
      {
        "Data Fields": "serviceCategory",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A broad categorization of the service that is to be performed during this appointment."
      },
      {
        "Data Fields": "serviceType",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The specific service that is to be performed during this appointment."
      },
      {
        "Data Fields": "specialty",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The specialty of a practitioner that would be required to perform the service requested in this appointment."
      },
      {
        "Data Fields": "appointmentType",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The style of appointment or patient that has been booked in the slot (not service type)."
      },
      {
        "Data Fields": "reasonCode",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The coded reason that this appointment is being scheduled. This is more clinical than administrative."
      },
      {
        "Data Fields": "reasonReference",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Reason the appointment has been scheduled to take place, as specified using information from another resource. When the patient arrives and the encounter begins it may be used as the admission diagnosis. The indication will typically be a Condition (with other resources referenced in the evidence.detail), or a Procedure."
      },
      {
        "Data Fields": "priority",
        "Type": "unsignedInt",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The priority of the appointment. Can be used to make informed decisions if needing to re-prioritize appointments. (The iCal Standard specifies 0 as undefined, 1 as highest, 9 as lowest priority)."
      },
      {
        "Data Fields": "description",
        "Type": "string",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The brief description of the appointment as would be shown on a subject line in a meeting request, or appointment list. Detailed or expanded information should be put in the comment field."
      },
      {
        "Data Fields": "supportingInformation",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Additional information to support the appointment provided when making the appointment."
      },
      {
        "Data Fields": "start",
        "Type": "instant",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Date/Time that the appointment is to take place."
      },
      {
        "Data Fields": "end",
        "Type": "instant",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Date/Time that the appointment is to conclude."
      },
      {
        "Data Fields": "minutesDuration",
        "Type": "positiveInt",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Number of minutes that the appointment is to take. This can be less than the duration between the start and end times.  For example, where the actual time of appointment is only an estimate or if a 30 minute appointment is being requested, but any time would work.  Also, if there is, for example, a planned 15 minute break in the middle of a long appointment, the duration may be 15 minutes less than the difference between the start and end."
      },
      {
        "Data Fields": "slot",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The slots from the participants' schedules that will be filled by the appointment."
      },
      {
        "Data Fields": "created",
        "Type": "dateTime",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The date that this appointment was initially created. This could be different to the meta.lastModified value on the initial entry, as this could have been before the resource was created on the FHIR server, and should remain unchanged over the lifespan of the appointment."
      },
      {
        "Data Fields": "comment",
        "Type": "string",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Additional comments about the appointment."
      },
      {
        "Data Fields": "patientInstruction",
        "Type": "string",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "While Appointment.comment contains information for internal use, Appointment.patientInstructions is used to capture patient facing information about the Appointment (e.g. please bring your referral or fast from 8pm night before)."
      },
      {
        "Data Fields": "basedOn",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The service request this appointment is allocated to assess (e.g. incoming referral or procedure request)."
      },
      {
        "Data Fields": "participant",
        "Type": "BackboneElement",
        "Fold Availability": "True",
        
        "Required": "True",
        "Description": "List of participants involved in the appointment."
      },
      {
        "Data Fields": "participant.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "participant.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "participant.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "participant.type",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Role of participant in the appointment."
      },
      {
        "Data Fields": "participant.actor",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A Person, Location/HealthcareService or Device that is participating in the appointment."
      },
      {
        "Data Fields": "participant.required",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Whether this participant is required to be present at the meeting. This covers a use-case where two doctors need to meet to discuss the results for a specific patient, and the patient is not required to be present."
      },
      {
        "Data Fields": "participant.status",
        "Type": "code",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Participation status of the actor."
      },
      {
        "Data Fields": "participant.period",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "Participation period of the actor."
      },
      {
        "Data Fields": "requestedPeriod",
        "Type": "Period",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A set of date ranges (potentially including times) that the appointment is preferred to be scheduled within.  The duration (usually in minutes) could also be provided to indicate the length of the appointment to fill and populate the start/end times for the actual allocated time. However, in other situations the duration may be calculated by the scheduling system."
      }
    ],
    "AppointmentResponse": [
      {
        "Data Fields": "resourceType",
        "Type": "AppointmentResponse",
        
        
        "Required": "True",
        "Description": "This is a AppointmentResponse resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "This records identifiers associated with this appointment response concern that are defined by business processes and/ or used to refer to it when a direct URL reference to the resource itself is not appropriate."
      },
      {
        "Data Fields": "appointment",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "Appointment that this response is replying to."
      },
      {
        "Data Fields": "start",
        "Type": "instant",
        
        
        "Required": "False",
        "Description": "Date/Time that the appointment is to take place, or requested new start time."
      },
      {
        "Data Fields": "end",
        "Type": "instant",
        
        
        "Required": "False",
        "Description": "This may be either the same as the appointment request to confirm the details of the appointment, or alternately a new time to request a re-negotiation of the end time."
      },
      {
        "Data Fields": "participantType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Role of participant in the appointment."
      },
      {
        "Data Fields": "actor",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A Person, Location, HealthcareService, or Device that is participating in the appointment."
      },
      {
        "Data Fields": "participantStatus",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Participation status of the participant. When the status is declined or tentative if the start/end times are different to the appointment, then these times should be interpreted as a requested time change. When the status is accepted, the times can either be the time of the appointment (as a confirmation of the time) or can be empty."
      },
      {
        "Data Fields": "comment",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Additional comments about the appointment."
      }
    ],
    "AuditEvent": [
      {
        "Data Fields": "resourceType",
        "Type": "AuditEvent",
        
        
        "Required": "True",
        "Description": "This is a AuditEvent resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "type",
        "Type": "Coding",
        
        
        "Required": "True",
        "Description": "Identifier for a family of the event.  For example, a menu item, program, rule, policy, function code, application name or URL. It identifies the performed function."
      },
      {
        "Data Fields": "subtype",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "Identifier for the category of event."
      },
      {
        "Data Fields": "action",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Indicator for type of action performed during the event that generated the audit."
      },
      {
        "Data Fields": "period",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The period during which the activity occurred."
      },
      {
        "Data Fields": "recorded",
        "Type": "instant",
        
        
        "Required": "False",
        "Description": "The time when the event was recorded."
      },
      {
        "Data Fields": "outcome",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Indicates whether the event succeeded or failed."
      },
      {
        "Data Fields": "outcomeDesc",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A free text description of the outcome of the event."
      },
      {
        "Data Fields": "purposeOfEvent",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The purposeOfUse (reason) that was used during the event being recorded."
      },
      {
        "Data Fields": "agent",
        "Type": "BackboneElement",
        
        
        "Required": "True",
        "Description": "An actor taking an active role in the event or activity that is logged."
      },
      {
        "Data Fields": "agent.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "agent.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "agent.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "agent.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Specification of the participation type the user plays when performing the event."
      },
      {
        "Data Fields": "agent.role",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The security role that the user was acting under, that come from local codes defined by the access control security system (e.g. RBAC, ABAC) used in the local context."
      },
      {
        "Data Fields": "agent.who",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Reference to who this agent is that was involved in the event."
      },
      {
        "Data Fields": "agent.altId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Alternative agent Identifier. For a human, this should be a user identifier text string from authentication system. This identifier would be one known to a common authentication system (e.g. single sign-on), if available."
      },
      {
        "Data Fields": "agent.name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Human-meaningful name for the agent."
      },
      {
        "Data Fields": "agent.requestor",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Indicator that the user is or is not the requestor, or initiator, for the event being audited."
      },
      {
        "Data Fields": "agent.location",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Where the event occurred."
      },
      {
        "Data Fields": "agent.policy",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "The policy or plan that authorized the activity being recorded. Typically, a single activity may have multiple applicable policies, such as patient consent, guarantor funding, etc. The policy would also indicate the security token used."
      },
      {
        "Data Fields": "agent.media",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "Type of media involved. Used when the event is about exporting/importing onto media."
      },
      {
        "Data Fields": "agent.network",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Logical network location for application activity, if the activity has a network location."
      },
      {
        "Data Fields": "agent.network.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "agent.network.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "agent.network.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "agent.network.address",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "An identifier for the network access point of the user device for the audit event."
      },
      {
        "Data Fields": "agent.network.type",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "An identifier for the type of network access point that originated the audit event."
      },
      {
        "Data Fields": "agent.purposeOfUse",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The reason (purpose of use), specific to this agent, that was used during the event being recorded."
      },
      {
        "Data Fields": "source",
        "Type": "BackboneElement",
        
        
        "Required": "True",
        "Description": "The system that is reporting the event."
      },
      {
        "Data Fields": "source.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "source.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "source.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "source.site",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Logical source location within the healthcare enterprise network.  For example, a hospital or other provider location within a multi-entity provider group."
      },
      {
        "Data Fields": "source.observer",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Identifier of the source where the event was detected."
      },
      {
        "Data Fields": "source.type",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "Code specifying the type of source where event originated."
      },
      {
        "Data Fields": "entity",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Specific instances of data or objects that have been accessed."
      },
      {
        "Data Fields": "entity.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "entity.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "entity.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "entity.what",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Identifies a specific instance of the entity. The reference should be version specific."
      },
      {
        "Data Fields": "entity.type",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "The type of the object that was involved in this audit event."
      },
      {
        "Data Fields": "entity.role",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "Code representing the role the entity played in the event being audited."
      },
      {
        "Data Fields": "entity.lifecycle",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "Identifier for the data life-cycle stage for the entity."
      },
      {
        "Data Fields": "entity.securityLabel",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "Security labels for the identified entity."
      },
      {
        "Data Fields": "entity.name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A name of the entity in the audit event."
      },
      {
        "Data Fields": "entity.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Text that describes the entity in more detail."
      },
      {
        "Data Fields": "entity.query",
        "Type": "base64Binary",
        
        
        "Required": "False",
        "Description": "The query parameters for a query-type entities."
      },
      {
        "Data Fields": "entity.detail",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Tagged value pairs for conveying additional information about the entity."
      },
      {
        "Data Fields": "entity.detail.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "entity.detail.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "entity.detail.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "entity.detail.type",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The type of extra detail provided in the value."
      },
      {
        "Data Fields": "entity.detail.valueString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The  value of the extra detail."
      },
      {
        "Data Fields": "entity.detail.valueBase64Binary",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The  value of the extra detail."
      }
    ],
    "Basic": [
      {
        "Data Fields": "resourceType",
        "Type": "Basic",
        
        
        "Required": "True",
        "Description": "This is a Basic resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Identifier assigned to the resource for business purposes, outside the context of FHIR."
      },
      {
        "Data Fields": "code",
        "Type": "CodeableConcept",
        
        
        "Required": "True",
        "Description": "Identifies the 'type' of resource - equivalent to the resource name for other resources."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Identifies the patient, practitioner, device or any other resource that is the \"focus\" of this resource."
      },
      {
        "Data Fields": "created",
        "Type": "date",
        
        
        "Required": "False",
        "Description": "Identifies when the resource was first created."
      },
      {
        "Data Fields": "author",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Indicates who was responsible for creating the resource instance."
      }
    ],
    "Binary": [
      {
        "Data Fields": "resourceType",
        "Type": "Binary",
        
        
        "Required": "True",
        "Description": "This is a Binary resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "contentType",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "MimeType of the binary content represented as a standard MimeType (BCP 13)."
      },
      {
        "Data Fields": "securityContext",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "This element identifies another resource that can be used as a proxy of the security sensitivity to use when deciding and enforcing access control rules for the Binary resource. Given that the Binary resource contains very few elements that can be used to determine the sensitivity of the data and relationships to individuals, the referenced resource stands in as a proxy equivalent for this purpose. This referenced resource may be related to the Binary (e.g. Media, DocumentReference), or may be some non-related Resource purely as a security proxy. E.g. to identify that the binary resource relates to a patient, and access should only be granted to applications that have access to the patient."
      },
      {
        "Data Fields": "data",
        "Type": "base64Binary",
        
        
        "Required": "False",
        "Description": "The actual content, base64 encoded."
      }
    ],
    "BiologicallyDerivedProduct": [
      {
        "Data Fields": "resourceType",
        "Type": "BiologicallyDerivedProduct",
        
        
        "Required": "True",
        "Description": "This is a BiologicallyDerivedProduct resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "This records identifiers associated with this biologically derived product instance that are defined by business processes and/or used to refer to it when a direct URL reference to the resource itself is not appropriate (e.g. in CDA documents, or in written / printed documentation)."
      },
      {
        "Data Fields": "productCategory",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Broad category of this product."
      },
      {
        "Data Fields": "productCode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code that identifies the kind of this biologically derived product (SNOMED Ctcode)."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Whether the product is currently available."
      },
      {
        "Data Fields": "request",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Procedure request to obtain this biologically derived product."
      },
      {
        "Data Fields": "quantity",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "Number of discrete units within this product."
      },
      {
        "Data Fields": "parent",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Parent product (if any)."
      },
      {
        "Data Fields": "collection",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "How this product was collected."
      },
      {
        "Data Fields": "collection.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "collection.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "collection.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "collection.collector",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Healthcare professional who is performing the collection."
      },
      {
        "Data Fields": "collection.source",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The patient or entity, such as a hospital or vendor in the case of a processed/manipulated/manufactured product, providing the product."
      },
      {
        "Data Fields": "collection.collectedDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Time of product collection."
      },
      {
        "Data Fields": "collection.collectedPeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "Time of product collection."
      },
      {
        "Data Fields": "processing",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Any processing of the product during collection that does not change the fundamental nature of the product. For example adding anti-coagulants during the collection of Peripheral Blood Stem Cells."
      },
      {
        "Data Fields": "processing.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "processing.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "processing.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "processing.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Description of of processing."
      },
      {
        "Data Fields": "processing.procedure",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Procesing code."
      },
      {
        "Data Fields": "processing.additive",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Substance added during processing."
      },
      {
        "Data Fields": "processing.timeDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Time of processing."
      },
      {
        "Data Fields": "processing.timePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "Time of processing."
      },
      {
        "Data Fields": "manipulation",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Any manipulation of product post-collection that is intended to alter the product.  For example a buffy-coat enrichment or CD8 reduction of Peripheral Blood Stem Cells to make it more suitable for infusion."
      },
      {
        "Data Fields": "manipulation.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "manipulation.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "manipulation.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "manipulation.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Description of manipulation."
      },
      {
        "Data Fields": "manipulation.timeDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Time of manipulation."
      },
      {
        "Data Fields": "manipulation.timePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "Time of manipulation."
      },
      {
        "Data Fields": "storage",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Product storage."
      },
      {
        "Data Fields": "storage.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "storage.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "storage.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "storage.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Description of storage."
      },
      {
        "Data Fields": "storage.temperature",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "Storage temperature."
      },
      {
        "Data Fields": "storage.scale",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Temperature scale used."
      },
      {
        "Data Fields": "storage.duration",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "Storage timeperiod."
      }
    ],
    "BodyStructure": [
      {
        "Data Fields": "resourceType",
        "Type": "BodyStructure",
        
        
        "Required": "True",
        "Description": "This is a BodyStructure resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Identifier for this instance of the anatomical structure."
      },
      {
        "Data Fields": "active",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Whether this body site is in active use."
      },
      {
        "Data Fields": "morphology",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The kind of structure being represented by the body structure at `BodyStructure.location`.  This can define both normal and abnormal morphologies."
      },
      {
        "Data Fields": "location",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The anatomical location or region of the specimen, lesion, or body structure."
      },
      {
        "Data Fields": "locationQualifier",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Qualifier to refine the anatomical location.  These include qualifiers for laterality, relative location, directionality, number, and plane."
      },
      {
        "Data Fields": "description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A summary, characterization or explanation of the body structure."
      },
      {
        "Data Fields": "image",
        "Type": "Attachment",
        
        
        "Required": "False",
        "Description": "Image or images used to identify a location."
      },
      {
        "Data Fields": "patient",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "The person to which the body site belongs."
      }
    ],
    "Bundle": [
      {
        "Data Fields": "resourceType",
        "Type": "Bundle",
        
        
        "Required": "True",
        "Description": "This is a Bundle resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A persistent identifier for the bundle that won't change as a bundle is copied from server to server."
      },
      {
        "Data Fields": "type",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Indicates the purpose of this bundle - how it is intended to be used."
      },
      {
        "Data Fields": "timestamp",
        "Type": "instant",
        
        
        "Required": "False",
        "Description": "The date/time that the bundle was assembled - i.e. when the resources were placed in the bundle."
      },
      {
        "Data Fields": "total",
        "Type": "unsignedInt",
        
        
        "Required": "False",
        "Description": "If a set of search matches, this is the total number of entries of type 'match' across all pages in the search.  It does not include search.mode = 'include' or 'outcome' entries and it does not provide a count of the number of entries in the Bundle."
      },
      {
        "Data Fields": "link",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A series of links that provide context to this bundle."
      },
      {
        "Data Fields": "link.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "link.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "link.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "link.relation",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A name which details the functional use for this link - see [http://www.iana.org/assignments/link-relations/link-relations.xhtml#link-relations-1](http://www.iana.org/assignments/link-relations/link-relations.xhtml#link-relations-1)."
      },
      {
        "Data Fields": "link.url",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "The reference details for the link."
      },
      {
        "Data Fields": "entry",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "An entry in a bundle resource - will either contain a resource or information about a resource (transactions and history only)."
      },
      {
        "Data Fields": "entry.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "entry.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "entry.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "entry.link",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A series of links that provide context to this entry."
      },
      {
        "Data Fields": "entry.link.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "entry.link.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "entry.link.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "entry.link.relation",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A name which details the functional use for this link - see [http://www.iana.org/assignments/link-relations/link-relations.xhtml#link-relations-1](http://www.iana.org/assignments/link-relations/link-relations.xhtml#link-relations-1)."
      },
      {
        "Data Fields": "entry.link.url",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "The reference details for the link."
      },
      {
        "Data Fields": "entry.fullUrl",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "The Absolute URL for the resource.  The fullUrl SHALL NOT disagree with the id in the resource - i.e. if the fullUrl is not a urn:uuid, the URL shall be version-independent URL consistent with the Resource.id. The fullUrl is a version independent reference to the resource. The fullUrl element SHALL have a value except that:  * fullUrl can be empty on a POST (although it does not need to when specifying a temporary id for reference in the bundle) * Results from operations might involve resources that are not identified."
      },
      {
        "Data Fields": "entry.resource",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The Resource for the entry. The purpose/meaning of the resource is determined by the Bundle.type."
      },
      {
        "Data Fields": "entry.search",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Information about the search process that lead to the creation of this entry."
      },
      {
        "Data Fields": "entry.search.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "entry.search.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "entry.search.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "entry.search.mode",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Why this entry is in the result set - whether it's included as a match or because of an _include requirement, or to convey information or warning information about the search process."
      },
      {
        "Data Fields": "entry.search.score",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "When searching, the server's search ranking score for the entry."
      },
      {
        "Data Fields": "entry.request",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Additional information about how this entry should be processed as part of a transaction or batch.  For history, it shows how the entry was processed to create the version contained in the entry."
      },
      {
        "Data Fields": "entry.request.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "entry.request.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "entry.request.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "entry.request.method",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "In a transaction or batch, this is the HTTP action to be executed for this entry. In a history bundle, this indicates the HTTP action that occurred."
      },
      {
        "Data Fields": "entry.request.url",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "The URL for this entry, relative to the root (the address to which the request is posted)."
      },
      {
        "Data Fields": "entry.request.ifNoneMatch",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "If the ETag values match, return a 304 Not Modified status. See the API documentation for [\"Conditional Read\"](http.html#cread)."
      },
      {
        "Data Fields": "entry.request.ifModifiedSince",
        "Type": "instant",
        
        
        "Required": "False",
        "Description": "Only perform the operation if the last updated date matches. See the API documentation for [\"Conditional Read\"](http.html#cread)."
      },
      {
        "Data Fields": "entry.request.ifMatch",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Only perform the operation if the Etag value matches. For more information, see the API section [\"Managing Resource Contention\"](http.html#concurrency)."
      },
      {
        "Data Fields": "entry.request.ifNoneExist",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Instruct the server not to perform the create if a specified resource already exists. For further information, see the API documentation for [\"Conditional Create\"](http.html#ccreate). This is just the query portion of the URL - what follows the \"?\" (not including the \"?\")."
      },
      {
        "Data Fields": "entry.response",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Indicates the results of processing the corresponding 'request' entry in the batch or transaction being responded to or what the results of an operation where when returning history."
      },
      {
        "Data Fields": "entry.response.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "entry.response.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "entry.response.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "entry.response.status",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The status code returned by processing this entry. The status SHALL start with a 3 digit HTTP code (e.g. 404) and may contain the standard HTTP description associated with the status code."
      },
      {
        "Data Fields": "entry.response.location",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "The location header created by processing this operation, populated if the operation returns a location."
      },
      {
        "Data Fields": "entry.response.etag",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The Etag for the resource, if the operation for the entry produced a versioned resource (see [Resource Metadata and Versioning](http.html#versioning) and [Managing Resource Contention](http.html#concurrency))."
      },
      {
        "Data Fields": "entry.response.lastModified",
        "Type": "instant",
        
        
        "Required": "False",
        "Description": "The date/time that the resource was modified on the server."
      },
      {
        "Data Fields": "entry.response.outcome",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "An OperationOutcome containing hints and warnings produced as part of processing this entry in a batch or transaction."
      },
      {
        "Data Fields": "signature",
        "Type": "Signature",
        
        
        "Required": "False",
        "Description": "Digital Signature - base64 encoded. XML-DSig or a JWT."
      }
    ],
    "CapabilityStatement": [
      {
        "Data Fields": "resourceType",
        "Type": "CapabilityStatement",
        
        
        "Required": "True",
        "Description": "This is a CapabilityStatement resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "url",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "An absolute URI that is used to identify this capability statement when it is referenced in a specification, model, design or an instance; also called its canonical identifier. This SHOULD be globally unique and SHOULD be a literal address at which at which an authoritative instance of this capability statement is (or will be) published. This URL can be the target of a canonical reference. It SHALL remain the same when the capability statement is stored on different servers."
      },
      {
        "Data Fields": "version",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The identifier that is used to identify this version of the capability statement when it is referenced in a specification, model, design or instance. This is an arbitrary value managed by the capability statement author and is not expected to be globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if a managed version is not available. There is also no expectation that versions can be placed in a lexicographical sequence."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A natural language name identifying the capability statement. This name should be usable as an identifier for the module by machine processing applications such as code generation."
      },
      {
        "Data Fields": "title",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A short, descriptive, user-friendly title for the capability statement."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The status of this capability statement. Enables tracking the life-cycle of the content."
      },
      {
        "Data Fields": "experimental",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "A Boolean value to indicate that this capability statement is authored for testing purposes (or education/evaluation/marketing) and is not intended to be used for genuine usage."
      },
      {
        "Data Fields": "date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date  (and optionally time) when the capability statement was published. The date must change when the business version changes and it must change if the status code changes. In addition, it should change when the substantive content of the capability statement changes."
      },
      {
        "Data Fields": "publisher",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the organization or individual that published the capability statement."
      },
      {
        "Data Fields": "contact",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "Contact details to assist a user in finding and communicating with the publisher."
      },
      {
        "Data Fields": "description",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A free text natural language description of the capability statement from a consumer's perspective. Typically, this is used when the capability statement describes a desired rather than an actual solution, for example as a formal expression of requirements as part of an RFP."
      },
      {
        "Data Fields": "useContext",
        "Type": "UsageContext",
        
        
        "Required": "False",
        "Description": "The content was developed with a focus and intent of supporting the contexts that are listed. These contexts may be general categories (gender, age, ...) or may be references to specific programs (insurance plans, studies, ...) and may be used to assist with indexing and searching for appropriate capability statement instances."
      },
      {
        "Data Fields": "jurisdiction",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A legal or geographic region in which the capability statement is intended to be used."
      },
      {
        "Data Fields": "purpose",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Explanation of why this capability statement is needed and why it has been designed as it has."
      },
      {
        "Data Fields": "copyright",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A copyright statement relating to the capability statement and/or its contents. Copyright statements are generally legal restrictions on the use and publishing of the capability statement."
      },
      {
        "Data Fields": "kind",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The way that this statement is intended to be used, to describe an actual running instance of software, a particular product (kind, not instance of software) or a class of implementation (e.g. a desired purchase)."
      },
      {
        "Data Fields": "instantiates",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "Reference to a canonical URL of another CapabilityStatement that this software implements. This capability statement is a published API description that corresponds to a business service. The server may actually implement a subset of the capability statement it claims to implement, so the capability statement must specify the full capability details."
      },
      {
        "Data Fields": "imports",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "Reference to a canonical URL of another CapabilityStatement that this software adds to. The capability statement automatically includes everything in the other statement, and it is not duplicated, though the server may repeat the same resources, interactions and operations to add additional details to them."
      },
      {
        "Data Fields": "software",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Software that is covered by this capability statement.  It is used when the capability statement describes the capabilities of a particular software version, independent of an installation."
      },
      {
        "Data Fields": "software.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "software.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "software.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "software.name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Name the software is known by."
      },
      {
        "Data Fields": "software.version",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The version identifier for the software covered by this statement."
      },
      {
        "Data Fields": "software.releaseDate",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "Date this version of the software was released."
      },
      {
        "Data Fields": "implementation",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Identifies a specific implementation instance that is described by the capability statement - i.e. a particular installation, rather than the capabilities of a software program."
      },
      {
        "Data Fields": "implementation.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "implementation.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "implementation.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "implementation.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Information about the specific installation that this capability statement relates to."
      },
      {
        "Data Fields": "implementation.url",
        "Type": "url",
        
        
        "Required": "False",
        "Description": "An absolute base URL for the implementation.  This forms the base for REST interfaces as well as the mailbox and document interfaces."
      },
      {
        "Data Fields": "implementation.custodian",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The organization responsible for the management of the instance and oversight of the data on the server at the specified URL."
      },
      {
        "Data Fields": "fhirVersion",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The version of the FHIR specification that this CapabilityStatement describes (which SHALL be the same as the FHIR version of the CapabilityStatement itself). There is no default value."
      },
      {
        "Data Fields": "format",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "A list of the formats supported by this implementation using their content types."
      },
      {
        "Data Fields": "patchFormat",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "A list of the patch formats supported by this implementation using their content types."
      },
      {
        "Data Fields": "implementationGuide",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "A list of implementation guides that the server does (or should) support in their entirety."
      },
      {
        "Data Fields": "rest",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A definition of the restful capabilities of the solution, if any."
      },
      {
        "Data Fields": "rest.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "rest.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "rest.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "rest.mode",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Identifies whether this portion of the statement is describing the ability to initiate or receive restful operations."
      },
      {
        "Data Fields": "rest.documentation",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Information about the system's restful capabilities that apply across all applications, such as security."
      },
      {
        "Data Fields": "rest.security",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Information about security implementation from an interface perspective - what a client needs to know."
      },
      {
        "Data Fields": "rest.security.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "rest.security.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "rest.security.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "rest.security.cors",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Server adds CORS headers when responding to requests - this enables Javascript applications to use the server."
      },
      {
        "Data Fields": "rest.security.service",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Types of security services that are supported/required by the system."
      },
      {
        "Data Fields": "rest.security.description",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "General description of how security works."
      },
      {
        "Data Fields": "rest.resource",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A specification of the restful capabilities of the solution for a specific resource type."
      },
      {
        "Data Fields": "rest.resource.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "rest.resource.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "rest.resource.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "rest.resource.type",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "A type of resource exposed via the restful interface."
      },
      {
        "Data Fields": "rest.resource.profile",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "A specification of the profile that describes the solution's overall support for the resource, including any constraints on cardinality, bindings, lengths or other limitations. See further discussion in [Using Profiles](profiling.html#profile-uses)."
      },
      {
        "Data Fields": "rest.resource.supportedProfile",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "A list of profiles that represent different use cases supported by the system. For a server, \"supported by the system\" means the system hosts/produces a set of resources that are conformant to a particular profile, and allows clients that use its services to search using this profile and to find appropriate data. For a client, it means the system will search by this profile and process data according to the guidance implicit in the profile. See further discussion in [Using Profiles](profiling.html#profile-uses)."
      },
      {
        "Data Fields": "rest.resource.documentation",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Additional information about the resource type used by the system."
      },
      {
        "Data Fields": "rest.resource.interaction",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Identifies a restful operation supported by the solution."
      },
      {
        "Data Fields": "rest.resource.interaction.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "rest.resource.interaction.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "rest.resource.interaction.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "rest.resource.interaction.code",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Coded identifier of the operation, supported by the system resource."
      },
      {
        "Data Fields": "rest.resource.interaction.documentation",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Guidance specific to the implementation of this operation, such as 'delete is a logical delete' or 'updates are only allowed with version id' or 'creates permitted from pre-authorized certificates only'."
      },
      {
        "Data Fields": "rest.resource.versioning",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "This field is set to no-version to specify that the system does not support (server) or use (client) versioning for this resource type. If this has some other value, the server must at least correctly track and populate the versionId meta-property on resources. If the value is 'versioned-update', then the server supports all the versioning features, including using e-tags for version integrity in the API."
      },
      {
        "Data Fields": "rest.resource.readHistory",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "A flag for whether the server is able to return past versions as part of the vRead operation."
      },
      {
        "Data Fields": "rest.resource.updateCreate",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "A flag to indicate that the server allows or needs to allow the client to create new identities on the server (that is, the client PUTs to a location where there is no existing resource). Allowing this operation means that the server allows the client to create new identities on the server."
      },
      {
        "Data Fields": "rest.resource.conditionalCreate",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "A flag that indicates that the server supports conditional create."
      },
      {
        "Data Fields": "rest.resource.conditionalRead",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "A code that indicates how the server supports conditional read."
      },
      {
        "Data Fields": "rest.resource.conditionalUpdate",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "A flag that indicates that the server supports conditional update."
      },
      {
        "Data Fields": "rest.resource.conditionalDelete",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "A code that indicates how the server supports conditional delete."
      },
      {
        "Data Fields": "rest.resource.referencePolicy",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "A set of flags that defines how references are supported."
      },
      {
        "Data Fields": "rest.resource.searchInclude",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A list of _include values supported by the server."
      },
      {
        "Data Fields": "rest.resource.searchRevInclude",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A list of _revinclude (reverse include) values supported by the server."
      },
      {
        "Data Fields": "rest.resource.searchParam",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Search parameters for implementations to support and/or make use of - either references to ones defined in the specification, or additional ones defined for/by the implementation."
      },
      {
        "Data Fields": "rest.resource.searchParam.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "rest.resource.searchParam.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "rest.resource.searchParam.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "rest.resource.searchParam.name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the search parameter used in the interface."
      },
      {
        "Data Fields": "rest.resource.searchParam.definition",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "An absolute URI that is a formal reference to where this parameter was first defined, so that a client can be confident of the meaning of the search parameter (a reference to [[[SearchParameter.url]]]). This element SHALL be populated if the search parameter refers to a SearchParameter defined by the FHIR core specification or externally defined IGs."
      },
      {
        "Data Fields": "rest.resource.searchParam.type",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The type of value a search parameter refers to, and how the content is interpreted."
      },
      {
        "Data Fields": "rest.resource.searchParam.documentation",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "This allows documentation of any distinct behaviors about how the search parameter is used.  For example, text matching algorithms."
      },
      {
        "Data Fields": "rest.resource.operation",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Definition of an operation or a named query together with its parameters and their meaning and type. Consult the definition of the operation for details about how to invoke the operation, and the parameters."
      },
      {
        "Data Fields": "rest.resource.operation.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "rest.resource.operation.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "rest.resource.operation.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "rest.resource.operation.name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the operation or query. For an operation, this is the name  prefixed with $ and used in the URL. For a query, this is the name used in the _query parameter when the query is called."
      },
      {
        "Data Fields": "rest.resource.operation.definition",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "Where the formal definition can be found. If a server references the base definition of an Operation (i.e. from the specification itself such as ```http://hl7.org/fhir/OperationDefinition/ValueSet-expand```), that means it supports the full capabilities of the operation - e.g. both GET and POST invocation.  If it only supports a subset, it must define its own custom [[[OperationDefinition]]] with a 'base' of the original OperationDefinition.  The custom definition would describe the specific subset of functionality supported."
      },
      {
        "Data Fields": "rest.resource.operation.documentation",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Documentation that describes anything special about the operation behavior, possibly detailing different behavior for system, type and instance-level invocation of the operation."
      },
      {
        "Data Fields": "rest.interaction",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A specification of restful operations supported by the system."
      },
      {
        "Data Fields": "rest.interaction.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "rest.interaction.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "rest.interaction.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "rest.interaction.code",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "A coded identifier of the operation, supported by the system."
      },
      {
        "Data Fields": "rest.interaction.documentation",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Guidance specific to the implementation of this operation, such as limitations on the kind of transactions allowed, or information about system wide search is implemented."
      },
      {
        "Data Fields": "rest.searchParam",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Search parameters that are supported for searching all resources for implementations to support and/or make use of - either references to ones defined in the specification, or additional ones defined for/by the implementation."
      },
      {
        "Data Fields": "rest.searchParam.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "rest.searchParam.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "rest.searchParam.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "rest.searchParam.name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the search parameter used in the interface."
      },
      {
        "Data Fields": "rest.searchParam.definition",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "An absolute URI that is a formal reference to where this parameter was first defined, so that a client can be confident of the meaning of the search parameter (a reference to [[[SearchParameter.url]]]). This element SHALL be populated if the search parameter refers to a SearchParameter defined by the FHIR core specification or externally defined IGs."
      },
      {
        "Data Fields": "rest.searchParam.type",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The type of value a search parameter refers to, and how the content is interpreted."
      },
      {
        "Data Fields": "rest.searchParam.documentation",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "This allows documentation of any distinct behaviors about how the search parameter is used.  For example, text matching algorithms."
      },
      {
        "Data Fields": "rest.operation",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Definition of an operation or a named query together with its parameters and their meaning and type."
      },
      {
        "Data Fields": "rest.operation.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "rest.operation.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "rest.operation.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "rest.operation.name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the operation or query. For an operation, this is the name  prefixed with $ and used in the URL. For a query, this is the name used in the _query parameter when the query is called."
      },
      {
        "Data Fields": "rest.operation.definition",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "Where the formal definition can be found. If a server references the base definition of an Operation (i.e. from the specification itself such as ```http://hl7.org/fhir/OperationDefinition/ValueSet-expand```), that means it supports the full capabilities of the operation - e.g. both GET and POST invocation.  If it only supports a subset, it must define its own custom [[[OperationDefinition]]] with a 'base' of the original OperationDefinition.  The custom definition would describe the specific subset of functionality supported."
      },
      {
        "Data Fields": "rest.operation.documentation",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Documentation that describes anything special about the operation behavior, possibly detailing different behavior for system, type and instance-level invocation of the operation."
      },
      {
        "Data Fields": "rest.compartment",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "An absolute URI which is a reference to the definition of a compartment that the system supports. The reference is to a CompartmentDefinition resource by its canonical URL ."
      },
      {
        "Data Fields": "messaging",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A description of the messaging capabilities of the solution."
      },
      {
        "Data Fields": "messaging.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "messaging.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "messaging.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "messaging.endpoint",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "An endpoint (network accessible address) to which messages and/or replies are to be sent."
      },
      {
        "Data Fields": "messaging.endpoint.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "messaging.endpoint.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "messaging.endpoint.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "messaging.endpoint.protocol",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "A list of the messaging transport protocol(s) identifiers, supported by this endpoint."
      },
      {
        "Data Fields": "messaging.endpoint.address",
        "Type": "url",
        
        
        "Required": "False",
        "Description": "The network address of the endpoint. For solutions that do not use network addresses for routing, it can be just an identifier."
      },
      {
        "Data Fields": "messaging.reliableCache",
        "Type": "unsignedInt",
        
        
        "Required": "False",
        "Description": "Length if the receiver's reliable messaging cache in minutes (if a receiver) or how long the cache length on the receiver should be (if a sender)."
      },
      {
        "Data Fields": "messaging.documentation",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Documentation about the system's messaging capabilities for this endpoint not otherwise documented by the capability statement.  For example, the process for becoming an authorized messaging exchange partner."
      },
      {
        "Data Fields": "messaging.supportedMessage",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "References to message definitions for messages this system can send or receive."
      },
      {
        "Data Fields": "messaging.supportedMessage.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "messaging.supportedMessage.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "messaging.supportedMessage.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "messaging.supportedMessage.mode",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The mode of this event declaration - whether application is sender or receiver."
      },
      {
        "Data Fields": "messaging.supportedMessage.definition",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "Points to a message definition that identifies the messaging event, message structure, allowed responses, etc."
      },
      {
        "Data Fields": "document",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A document definition."
      },
      {
        "Data Fields": "document.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "document.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "document.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "document.mode",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Mode of this document declaration - whether an application is a producer or consumer."
      },
      {
        "Data Fields": "document.documentation",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A description of how the application supports or uses the specified document profile.  For example, when documents are created, what action is taken with consumed documents, etc."
      },
      {
        "Data Fields": "document.profile",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "A profile on the document Bundle that constrains which resources are present, and their contents."
      }
    ],
    "CarePlan": [
      {
        "Data Fields": "resourceType",
        "Type": "CarePlan",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "This is a CarePlan resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Business identifiers assigned to this care plan by the performer or other systems which remain constant as the resource is updated and propagates from server to server."
      },
      {
        "Data Fields": "instantiatesCanonical",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "The URL pointing to a FHIR-defined protocol, guideline, questionnaire or other definition that is adhered to in whole or in part by this CarePlan."
      },
      {
        "Data Fields": "instantiatesUri",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "The URL pointing to an externally maintained protocol, guideline, questionnaire or other definition that is adhered to in whole or in part by this CarePlan."
      },
      {
        "Data Fields": "basedOn",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A care plan that is fulfilled in whole or in part by this care plan."
      },
      {
        "Data Fields": "replaces",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Completed or terminated care plan whose function is taken by this new care plan."
      },
      {
        "Data Fields": "partOf",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A larger care plan of which this particular care plan is a component or step."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Indicates whether the plan is currently being acted upon, represents future intentions or is now a historical record."
      },
      {
        "Data Fields": "intent",
        "Type": "code",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Indicates the level of authority/intentionality associated with the care plan and where the care plan fits into the workflow chain."
      },
      {
        "Data Fields": "category",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Identifies what \"kind\" of plan this is to support differentiation between multiple co-existing plans; e.g. \"Home health\", \"psychiatric\", \"asthma\", \"disease management\", \"wellness plan\", etc."
      },
      {
        "Data Fields": "title",
        "Type": "string",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Human-friendly name for the care plan."
      },
      {
        "Data Fields": "description",
        "Type": "string",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A description of the scope and nature of the plan."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "Identifies the patient or group whose intended care is described by the plan."
      },
      {
        "Data Fields": "encounter",
        "Type": "Reference",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The Encounter during which this CarePlan was created or to which the creation of this record is tightly associated."
      },
      {
        "Data Fields": "period",
        "Type": "Period",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Indicates when the plan did (or is intended to) come into effect and end."
      },
      {
        "Data Fields": "created",
        "Type": "dateTime",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Represents when this particular CarePlan record was created in the system, which is often a system-generated date."
      },
      {
        "Data Fields": "author",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "When populated, the author is responsible for the care plan.  The care plan is attributed to the author."
      },
      {
        "Data Fields": "contributor",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Identifies the individual(s) or organization who provided the contents of the care plan."
      },
      {
        "Data Fields": "careTeam",
        "Type": "Reference",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Identifies all people and organizations who are expected to be involved in the care envisioned by this plan."
      },
      {
        "Data Fields": "addresses",
        "Type": "Reference",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Identifies the conditions/problems/concerns/diagnoses/etc. whose management and/or mitigation are handled by this plan."
      },
      {
        "Data Fields": "supportingInfo",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Identifies portions of the patient's record that specifically influenced the formation of the plan.  These might include comorbidities, recent procedures, limitations, recent assessments, etc."
      },
      {
        "Data Fields": "goal",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Describes the intended objective(s) of carrying out the care plan."
      },
      {
        "Data Fields": "activity",
        "Type": "BackboneElement",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Identifies a planned action to occur as part of the plan.  For example, a medication to be used, lab tests to perform, self-monitoring, education, etc."
      },
      {
        "Data Fields": "activity.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "activity.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "activity.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "activity.outcomeCodeableConcept",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Identifies the outcome at the point when the status of the activity is assessed.  For example, the outcome of an education activity could be patient understands (or not)."
      },
      {
        "Data Fields": "activity.outcomeReference",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Details of the outcome or action resulting from the activity.  The reference to an \"event\" resource, such as Procedure or Encounter or Observation, is the result/outcome of the activity itself.  The activity can be conveyed using CarePlan.activity.detail OR using the CarePlan.activity.reference (a reference to a “request” resource)."
      },
      {
        "Data Fields": "activity.progress",
        "Type": "Annotation",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Notes about the adherence/status/progress of the activity."
      },
      {
        "Data Fields": "activity.reference",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The details of the proposed activity represented in a specific resource."
      },
      {
        "Data Fields": "activity.detail",
        "Type": "BackboneElement",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A simple summary of a planned activity suitable for a general care plan system (e.g. form driven) that doesn't know about specific resources such as procedure etc."
      },
      {
        "Data Fields": "activity.detail.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "activity.detail.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "activity.detail.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "activity.detail.kind",
        "Type": "code",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A description of the kind of resource the in-line definition of a care plan activity is representing.  The CarePlan.activity.detail is an in-line definition when a resource is not referenced using CarePlan.activity.reference.  For example, a MedicationRequest, a ServiceRequest, or a CommunicationRequest."
      },
      {
        "Data Fields": "activity.detail.instantiatesCanonical",
        "Type": "canonical",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The URL pointing to a FHIR-defined protocol, guideline, questionnaire or other definition that is adhered to in whole or in part by this CarePlan activity."
      },
      {
        "Data Fields": "activity.detail.instantiatesUri",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "The URL pointing to an externally maintained protocol, guideline, questionnaire or other definition that is adhered to in whole or in part by this CarePlan activity."
      },
      {
        "Data Fields": "activity.detail.code",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Detailed description of the type of planned activity; e.g. what lab test, what procedure, what kind of encounter."
      },
      {
        "Data Fields": "activity.detail.reasonCode",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Provides the rationale that drove the inclusion of this particular activity as part of the plan or the reason why the activity was prohibited."
      },
      {
        "Data Fields": "activity.detail.reasonReference",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Indicates another resource, such as the health condition(s), whose existence justifies this request and drove the inclusion of this particular activity as part of the plan."
      },
      {
        "Data Fields": "activity.detail.goal",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Internal reference that identifies the goals that this activity is intended to contribute towards meeting."
      },
      {
        "Data Fields": "activity.detail.status",
        "Type": "code",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Identifies what progress is being made for the specific activity."
      },
      {
        "Data Fields": "activity.detail.statusReason",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Provides reason why the activity isn't yet started, is on hold, was cancelled, etc."
      },
      {
        "Data Fields": "activity.detail.doNotPerform",
        "Type": "boolean",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "If true, indicates that the described activity is one that must NOT be engaged in when following the plan.  If false, or missing, indicates that the described activity is one that should be engaged in when following the plan."
      },
      {
        "Data Fields": "activity.detail.scheduledTiming",
        "Type": "Timing",
        
        
        "Required": "False",
        "Description": "The period, timing or frequency upon which the described activity is to occur."
      },
      {
        "Data Fields": "activity.detail.scheduledPeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The period, timing or frequency upon which the described activity is to occur."
      },
      {
        "Data Fields": "activity.detail.scheduledString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The period, timing or frequency upon which the described activity is to occur."
      },
      {
        "Data Fields": "activity.detail.location",
        "Type": "Reference",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Identifies the facility where the activity will occur; e.g. home, hospital, specific clinic, etc."
      },
      {
        "Data Fields": "activity.detail.performer",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Identifies who's expected to be involved in the activity."
      },
      {
        "Data Fields": "activity.detail.productCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Identifies the food, drug or other product to be consumed or supplied in the activity."
      },
      {
        "Data Fields": "activity.detail.productReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Identifies the food, drug or other product to be consumed or supplied in the activity."
      },
      {
        "Data Fields": "activity.detail.dailyAmount",
        "Type": "Quantity",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Identifies the quantity expected to be consumed in a given day."
      },
      {
        "Data Fields": "activity.detail.quantity",
        "Type": "Quantity",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Identifies the quantity expected to be supplied, administered or consumed by the subject."
      },
      {
        "Data Fields": "activity.detail.description",
        "Type": "string",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "This provides a textual description of constraints on the intended activity occurrence, including relation to other activities.  It may also include objectives, pre-conditions and end-conditions.  Finally, it may convey specifics about the activity such as body site, method, route, etc."
      },
      {
        "Data Fields": "note",
        "Type": "Annotation",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "General notes about the care plan not covered elsewhere."
      }
    ],
    "CareTeam": [
      {
        "Data Fields": "resourceType",
        "Type": "CareTeam",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "This is a CareTeam resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Business identifiers assigned to this care team by the performer or other systems which remain constant as the resource is updated and propagates from server to server."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Indicates the current state of the care team."
      },
      {
        "Data Fields": "category",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Identifies what kind of team.  This is to support differentiation between multiple co-existing teams, such as care plan team, episode of care team, longitudinal care team."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A label for human use intended to distinguish like teams.  E.g. the \"red\" vs. \"green\" trauma teams."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Identifies the patient or group whose intended care is handled by the team."
      },
      {
        "Data Fields": "encounter",
        "Type": "Reference",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The Encounter during which this CareTeam was created or to which the creation of this record is tightly associated."
      },
      {
        "Data Fields": "period",
        "Type": "Period",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Indicates when the team did (or is intended to) come into effect and end."
      },
      {
        "Data Fields": "participant",
        "Type": "BackboneElement",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Identifies all people and organizations who are expected to be involved in the care team."
      },
      {
        "Data Fields": "participant.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "participant.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "participant.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "participant.role",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Indicates specific responsibility of an individual within the care team, such as \"Primary care physician\", \"Trained social worker counselor\", \"Caregiver\", etc."
      },
      {
        "Data Fields": "participant.member",
        "Type": "Reference",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The specific person or organization who is participating/expected to participate in the care team."
      },
      {
        "Data Fields": "participant.onBehalfOf",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The organization of the practitioner."
      },
      {
        "Data Fields": "participant.period",
        "Type": "Period",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Indicates when the specific member or organization did (or is intended to) come into effect and end."
      },
      {
        "Data Fields": "reasonCode",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Describes why the care team exists."
      },
      {
        "Data Fields": "reasonReference",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Condition(s) that this care team addresses."
      },
      {
        "Data Fields": "managingOrganization",
        "Type": "Reference",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The organization responsible for the care team."
      },
      {
        "Data Fields": "telecom",
        "Type": "ContactPoint",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A central contact detail for the care team (that applies to all members)."
      },
      {
        "Data Fields": "note",
        "Type": "Annotation",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Comments made about the CareTeam."
      }
    ],
    "CatalogEntry": [
      {
        "Data Fields": "resourceType",
        "Type": "CatalogEntry",
        
        
        "Required": "True",
        "Description": "This is a CatalogEntry resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Used in supporting different identifiers for the same product, e.g. manufacturer code and retailer code."
      },
      {
        "Data Fields": "type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The type of item - medication, device, service, protocol or other."
      },
      {
        "Data Fields": "orderable",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Whether the entry represents an orderable item."
      },
      {
        "Data Fields": "referencedItem",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "The item in a catalog or definition."
      },
      {
        "Data Fields": "additionalIdentifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Used in supporting related concepts, e.g. NDC to RxNorm."
      },
      {
        "Data Fields": "classification",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Classes of devices, or ATC for medication."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Used to support catalog exchange even for unsupported products, e.g. getting list of medications even if not prescribable."
      },
      {
        "Data Fields": "validityPeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The time period in which this catalog entry is expected to be active."
      },
      {
        "Data Fields": "validTo",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date until which this catalog entry is expected to be active."
      },
      {
        "Data Fields": "lastUpdated",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "Typically date of issue is different from the beginning of the validity. This can be used to see when an item was last updated."
      },
      {
        "Data Fields": "additionalCharacteristic",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Used for examplefor Out of Formulary, or any specifics."
      },
      {
        "Data Fields": "additionalClassification",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "User for example for ATC classification, or."
      },
      {
        "Data Fields": "relatedEntry",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Used for example, to point to a substance, or to a device used to administer a medication."
      },
      {
        "Data Fields": "relatedEntry.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "relatedEntry.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "relatedEntry.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "relatedEntry.relationtype",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The type of relation to the related item: child, parent, packageContent, containerPackage, usedIn, uses, requires, etc."
      },
      {
        "Data Fields": "relatedEntry.item",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The reference to the related item."
      }
    ],
    "ChargeItem": [
      {
        "Data Fields": "resourceType",
        "Type": "ChargeItem",
        
        
        "Required": "True",
        "Description": "This is a ChargeItem resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Identifiers assigned to this event performer or other systems."
      },
      {
        "Data Fields": "definitionUri",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "References the (external) source of pricing information, rules of application for the code this ChargeItem uses."
      },
      {
        "Data Fields": "definitionCanonical",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "References the source of pricing information, rules of application for the code this ChargeItem uses."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The current state of the ChargeItem."
      },
      {
        "Data Fields": "partOf",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "ChargeItems can be grouped to larger ChargeItems covering the whole set."
      },
      {
        "Data Fields": "code",
        "Type": "CodeableConcept",
        
        
        "Required": "True",
        "Description": "A code that identifies the charge, like a billing code."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "The individual or set of individuals the action is being or was performed on."
      },
      {
        "Data Fields": "context",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The encounter or episode of care that establishes the context for this event."
      },
      {
        "Data Fields": "occurrenceDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Date/time(s) or duration when the charged service was applied."
      },
      {
        "Data Fields": "occurrencePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "Date/time(s) or duration when the charged service was applied."
      },
      {
        "Data Fields": "occurrenceTiming",
        "Type": "Timing",
        
        
        "Required": "False",
        "Description": "Date/time(s) or duration when the charged service was applied."
      },
      {
        "Data Fields": "performer",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Indicates who or what performed or participated in the charged service."
      },
      {
        "Data Fields": "performer.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "performer.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "performer.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "performer.function",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Describes the type of performance or participation(e.g. primary surgeon, anesthesiologiest, etc.)."
      },
      {
        "Data Fields": "performer.actor",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The device, practitioner, etc. who performed or participated in the service."
      },
      {
        "Data Fields": "performingOrganization",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The organization requesting the service."
      },
      {
        "Data Fields": "requestingOrganization",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The organization performing the service."
      },
      {
        "Data Fields": "costCenter",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The financial cost center permits the tracking of charge attribution."
      },
      {
        "Data Fields": "quantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "Quantity of which the charge item has been serviced."
      },
      {
        "Data Fields": "bodysite",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The anatomical location where the related service has been applied."
      },
      {
        "Data Fields": "factorOverride",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "Factor overriding the factor determined by the rules associated with the code."
      },
      {
        "Data Fields": "priceOverride",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "Total price of the charge overriding the list price associated with the code."
      },
      {
        "Data Fields": "overrideReason",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "If the list price or the rule-based factor associated with the code is overridden, this attribute can capture a text to indicate the  reason for this action."
      },
      {
        "Data Fields": "enterer",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The device, practitioner, etc. who entered the charge item."
      },
      {
        "Data Fields": "enteredDate",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "Date the charge item was entered."
      },
      {
        "Data Fields": "reason",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Describes why the event occurred in coded or textual form."
      },
      {
        "Data Fields": "service",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Indicated the rendered service that caused this charge."
      },
      {
        "Data Fields": "productReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Identifies the device, food, drug or other product being charged either by type code or reference to an instance."
      },
      {
        "Data Fields": "productCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Identifies the device, food, drug or other product being charged either by type code or reference to an instance."
      },
      {
        "Data Fields": "account",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Account into which this ChargeItems belongs."
      },
      {
        "Data Fields": "note",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "Comments made about the event by the performer, subject or other participants."
      },
      {
        "Data Fields": "supportingInformation",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Further information supporting this charge."
      }
    ],
    "ChargeItemDefinition": [
      {
        "Data Fields": "resourceType",
        "Type": "ChargeItemDefinition",
        
        
        "Required": "True",
        "Description": "This is a ChargeItemDefinition resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "url",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "An absolute URI that is used to identify this charge item definition when it is referenced in a specification, model, design or an instance; also called its canonical identifier. This SHOULD be globally unique and SHOULD be a literal address at which at which an authoritative instance of this charge item definition is (or will be) published. This URL can be the target of a canonical reference. It SHALL remain the same when the charge item definition is stored on different servers."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A formal identifier that is used to identify this charge item definition when it is represented in other formats, or referenced in a specification, model, design or an instance."
      },
      {
        "Data Fields": "version",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The identifier that is used to identify this version of the charge item definition when it is referenced in a specification, model, design or instance. This is an arbitrary value managed by the charge item definition author and is not expected to be globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if a managed version is not available. There is also no expectation that versions can be placed in a lexicographical sequence. To provide a version consistent with the Decision Support Service specification, use the format Major.Minor.Revision (e.g. 1.0.0). For more information on versioning knowledge assets, refer to the Decision Support Service specification. Note that a version is required for non-experimental active assets."
      },
      {
        "Data Fields": "title",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A short, descriptive, user-friendly title for the charge item definition."
      },
      {
        "Data Fields": "derivedFromUri",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "The URL pointing to an externally-defined charge item definition that is adhered to in whole or in part by this definition."
      },
      {
        "Data Fields": "partOf",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "A larger definition of which this particular definition is a component or step."
      },
      {
        "Data Fields": "replaces",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "As new versions of a protocol or guideline are defined, allows identification of what versions are replaced by a new instance."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The current state of the ChargeItemDefinition."
      },
      {
        "Data Fields": "experimental",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "A Boolean value to indicate that this charge item definition is authored for testing purposes (or education/evaluation/marketing) and is not intended to be used for genuine usage."
      },
      {
        "Data Fields": "date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date  (and optionally time) when the charge item definition was published. The date must change when the business version changes and it must change if the status code changes. In addition, it should change when the substantive content of the charge item definition changes."
      },
      {
        "Data Fields": "publisher",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the organization or individual that published the charge item definition."
      },
      {
        "Data Fields": "contact",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "Contact details to assist a user in finding and communicating with the publisher."
      },
      {
        "Data Fields": "description",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A free text natural language description of the charge item definition from a consumer's perspective."
      },
      {
        "Data Fields": "useContext",
        "Type": "UsageContext",
        
        
        "Required": "False",
        "Description": "The content was developed with a focus and intent of supporting the contexts that are listed. These contexts may be general categories (gender, age, ...) or may be references to specific programs (insurance plans, studies, ...) and may be used to assist with indexing and searching for appropriate charge item definition instances."
      },
      {
        "Data Fields": "jurisdiction",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A legal or geographic region in which the charge item definition is intended to be used."
      },
      {
        "Data Fields": "copyright",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A copyright statement relating to the charge item definition and/or its contents. Copyright statements are generally legal restrictions on the use and publishing of the charge item definition."
      },
      {
        "Data Fields": "approvalDate",
        "Type": "date",
        
        
        "Required": "False",
        "Description": "The date on which the resource content was approved by the publisher. Approval happens once when the content is officially approved for usage."
      },
      {
        "Data Fields": "lastReviewDate",
        "Type": "date",
        
        
        "Required": "False",
        "Description": "The date on which the resource content was last reviewed. Review happens periodically after approval but does not change the original approval date."
      },
      {
        "Data Fields": "effectivePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The period during which the charge item definition content was or is planned to be in active use."
      },
      {
        "Data Fields": "code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The defined billing details in this resource pertain to the given billing code."
      },
      {
        "Data Fields": "instance",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The defined billing details in this resource pertain to the given product instance(s)."
      },
      {
        "Data Fields": "applicability",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Expressions that describe applicability criteria for the billing code."
      },
      {
        "Data Fields": "applicability.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "applicability.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "applicability.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "applicability.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A brief, natural language description of the condition that effectively communicates the intended semantics."
      },
      {
        "Data Fields": "applicability.language",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The media type of the language for the expression, e.g. \"text/cql\" for Clinical Query Language expressions or \"text/fhirpath\" for FHIRPath expressions."
      },
      {
        "Data Fields": "applicability.expression",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "An expression that returns true or false, indicating whether the condition is satisfied. When using FHIRPath expressions, the %context environment variable must be replaced at runtime with the ChargeItem resource to which this definition is applied."
      },
      {
        "Data Fields": "propertyGroup",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Group of properties which are applicable under the same conditions. If no applicability rules are established for the group, then all properties always apply."
      },
      {
        "Data Fields": "propertyGroup.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "propertyGroup.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "propertyGroup.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "propertyGroup.applicability",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Expressions that describe applicability criteria for the priceComponent."
      },
      {
        "Data Fields": "propertyGroup.applicability.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "propertyGroup.applicability.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "propertyGroup.applicability.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "propertyGroup.applicability.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A brief, natural language description of the condition that effectively communicates the intended semantics."
      },
      {
        "Data Fields": "propertyGroup.applicability.language",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The media type of the language for the expression, e.g. \"text/cql\" for Clinical Query Language expressions or \"text/fhirpath\" for FHIRPath expressions."
      },
      {
        "Data Fields": "propertyGroup.applicability.expression",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "An expression that returns true or false, indicating whether the condition is satisfied. When using FHIRPath expressions, the %context environment variable must be replaced at runtime with the ChargeItem resource to which this definition is applied."
      },
      {
        "Data Fields": "propertyGroup.priceComponent",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The price for a ChargeItem may be calculated as a base price with surcharges/deductions that apply in certain conditions. A ChargeItemDefinition resource that defines the prices, factors and conditions that apply to a billing code is currently under development. The priceComponent element can be used to offer transparency to the recipient of the Invoice of how the prices have been calculated."
      },
      {
        "Data Fields": "propertyGroup.priceComponent.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "propertyGroup.priceComponent.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "propertyGroup.priceComponent.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "propertyGroup.priceComponent.type",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "This code identifies the type of the component."
      },
      {
        "Data Fields": "propertyGroup.priceComponent.code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code that identifies the component. Codes may be used to differentiate between kinds of taxes, surcharges, discounts etc."
      },
      {
        "Data Fields": "propertyGroup.priceComponent.factor",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "The factor that has been applied on the base price for calculating this component."
      },
      {
        "Data Fields": "propertyGroup.priceComponent.amount",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "The amount calculated for this component."
      }
    ],
    "Claim": [
      {
        "Data Fields": "resourceType",
        "Type": "Claim",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "This is a Claim resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A unique identifier assigned to this claim."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The status of the resource instance."
      },
      {
        "Data Fields": "type",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "The category of claim, e.g. oral, pharmacy, vision, institutional, professional."
      },
      {
        "Data Fields": "subType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A finer grained suite of claim type codes which may convey additional information such as Inpatient vs Outpatient and/or a specialty service."
      },
      {
        "Data Fields": "use",
        "Type": "code",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A code to indicate whether the nature of the request is: to request adjudication of products and services previously rendered; or requesting authorization and adjudication for provision in the future; or requesting the non-binding adjudication of the listed products and services which could be provided in the future."
      },
      {
        "Data Fields": "patient",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "The party to whom the professional services and/or products have been supplied or are being considered and for whom actual or forecast reimbursement is sought."
      },
      {
        "Data Fields": "billablePeriod",
        "Type": "Period",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The period for which charges are being submitted."
      },
      {
        "Data Fields": "created",
        "Type": "dateTime",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The date this resource was created."
      },
      {
        "Data Fields": "enterer",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Individual who created the claim, predetermination or preauthorization."
      },
      {
        "Data Fields": "insurer",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The Insurer who is target of the request."
      },
      {
        "Data Fields": "provider",
        "Type": "Reference",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "The provider which is responsible for the claim, predetermination or preauthorization."
      },
      {
        "Data Fields": "priority",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "The provider-required urgency of processing the request. Typical values include: stat, routine deferred."
      },
      {
        "Data Fields": "fundsReserve",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A code to indicate whether and for whom funds are to be reserved for future claims."
      },
      {
        "Data Fields": "related",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Other claims which are related to this claim such as prior submissions or claims for related services or for the same event."
      },
      {
        "Data Fields": "related.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "related.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "related.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "related.claim",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Reference to a related claim."
      },
      {
        "Data Fields": "related.relationship",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A code to convey how the claims are related."
      },
      {
        "Data Fields": "related.reference",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "An alternate organizational reference to the case or file to which this particular claim pertains."
      },
      {
        "Data Fields": "prescription",
        "Type": "Reference",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Prescription to support the dispensing of pharmacy, device or vision products."
      },
      {
        "Data Fields": "originalPrescription",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Original prescription which has been superseded by this prescription to support the dispensing of pharmacy services, medications or products."
      },
      {
        "Data Fields": "payee",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The party to be reimbursed for cost of the products and services according to the terms of the policy."
      },
      {
        "Data Fields": "payee.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "payee.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "payee.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "payee.type",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Type of Party to be reimbursed: subscriber, provider, other."
      },
      {
        "Data Fields": "payee.party",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Reference to the individual or organization to whom any payment will be made."
      },
      {
        "Data Fields": "referral",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A reference to a referral resource."
      },
      {
        "Data Fields": "facility",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Facility where the services were provided."
      },
      {
        "Data Fields": "careTeam",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The members of the team who provided the products and services."
      },
      {
        "Data Fields": "careTeam.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "careTeam.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "careTeam.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "careTeam.sequence",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "A number to uniquely identify care team entries."
      },
      {
        "Data Fields": "careTeam.provider",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Member of the team who provided the product or service."
      },
      {
        "Data Fields": "careTeam.responsible",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "The party who is billing and/or responsible for the claimed products or services."
      },
      {
        "Data Fields": "careTeam.role",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The lead, assisting or supervising practitioner and their discipline if a multidisciplinary team."
      },
      {
        "Data Fields": "careTeam.qualification",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The qualification of the practitioner which is applicable for this service."
      },
      {
        "Data Fields": "supportingInfo",
        "Type": "BackboneElement",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Additional information codes regarding exceptions, special considerations, the condition, situation, prior or concurrent issues."
      },
      {
        "Data Fields": "supportingInfo.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "supportingInfo.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "supportingInfo.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "supportingInfo.sequence",
        "Type": "positiveInt",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A number to uniquely identify supporting information entries."
      },
      {
        "Data Fields": "supportingInfo.category",
        "Type": "CodeableConcept",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The general class of the information supplied: information; exception; accident, employment; onset, etc."
      },
      {
        "Data Fields": "supportingInfo.code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "System and code pertaining to the specific information regarding special conditions relating to the setting, treatment or patient  for which care is sought."
      },
      {
        "Data Fields": "supportingInfo.timingDate",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The date when or period to which this information refers."
      },
      {
        "Data Fields": "supportingInfo.timingPeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The date when or period to which this information refers."
      },
      {
        "Data Fields": "supportingInfo.valueBoolean",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Additional data or information such as resources, documents, images etc. including references to the data or the actual inclusion of the data."
      },
      {
        "Data Fields": "supportingInfo.valueString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Additional data or information such as resources, documents, images etc. including references to the data or the actual inclusion of the data."
      },
      {
        "Data Fields": "supportingInfo.valueQuantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "Additional data or information such as resources, documents, images etc. including references to the data or the actual inclusion of the data."
      },
      {
        "Data Fields": "supportingInfo.valueAttachment",
        "Type": "Attachment",
        
        
        "Required": "False",
        "Description": "Additional data or information such as resources, documents, images etc. including references to the data or the actual inclusion of the data."
      },
      {
        "Data Fields": "supportingInfo.valueReference",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Additional data or information such as resources, documents, images etc. including references to the data or the actual inclusion of the data."
      },
      {
        "Data Fields": "supportingInfo.reason",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Provides the reason in the situation where a reason code is required in addition to the content."
      },
      {
        "Data Fields": "diagnosis",
        "Type": "BackboneElement",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Information about diagnoses relevant to the claim items."
      },
      {
        "Data Fields": "diagnosis.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "diagnosis.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "diagnosis.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "diagnosis.sequence",
        "Type": "positiveInt",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A number to uniquely identify diagnosis entries."
      },
      {
        "Data Fields": "diagnosis.diagnosisCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The nature of illness or problem in a coded form or as a reference to an external defined Condition."
      },
      {
        "Data Fields": "diagnosis.diagnosisReference",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The nature of illness or problem in a coded form or as a reference to an external defined Condition."
      },
      {
        "Data Fields": "diagnosis.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "When the condition was observed or the relative ranking."
      },
      {
        "Data Fields": "diagnosis.onAdmission",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Indication of whether the diagnosis was present on admission to a facility."
      },
      {
        "Data Fields": "diagnosis.packageCode",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A package billing code or bundle code used to group products and services to a particular health condition (such as heart attack) which is based on a predetermined grouping code system."
      },
      {
        "Data Fields": "procedure",
        "Type": "BackboneElement",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Procedures performed on the patient relevant to the billing items with the claim."
      },
      {
        "Data Fields": "procedure.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "procedure.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "procedure.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "procedure.sequence",
        "Type": "positiveInt",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A number to uniquely identify procedure entries."
      },
      {
        "Data Fields": "procedure.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "When the condition was observed or the relative ranking."
      },
      {
        "Data Fields": "procedure.date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "Date and optionally time the procedure was performed."
      },
      {
        "Data Fields": "procedure.procedureCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The code or reference to a Procedure resource which identifies the clinical intervention performed."
      },
      {
        "Data Fields": "procedure.procedureReference",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The code or reference to a Procedure resource which identifies the clinical intervention performed."
      },
      {
        "Data Fields": "procedure.udi",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Unique Device Identifiers associated with this line item."
      },
      {
        "Data Fields": "insurance",
        "Type": "BackboneElement",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "Financial instruments for reimbursement for the health care products and services specified on the claim."
      },
      {
        "Data Fields": "insurance.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "insurance.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "insurance.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "insurance.sequence",
        "Type": "positiveInt",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A number to uniquely identify insurance entries and provide a sequence of coverages to convey coordination of benefit order."
      },
      {
        "Data Fields": "insurance.focal",
        "Type": "boolean",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A flag to indicate that this Coverage is to be used for adjudication of this claim when set to true."
      },
      {
        "Data Fields": "insurance.identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "The business identifier to be used when the claim is sent for adjudication against this insurance policy."
      },
      {
        "Data Fields": "insurance.coverage",
        "Type": "Reference",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Reference to the insurance card level information contained in the Coverage resource. The coverage issuing insurer will use these details to locate the patient's actual coverage within the insurer's information system."
      },
      {
        "Data Fields": "insurance.businessArrangement",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A business agreement number established between the provider and the insurer for special business processing purposes."
      },
      {
        "Data Fields": "insurance.preAuthRef",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Reference numbers previously provided by the insurer to the provider to be quoted on subsequent claims containing services or products related to the prior authorization."
      },
      {
        "Data Fields": "insurance.claimResponse",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The result of the adjudication of the line items for the Coverage specified in this insurance."
      },
      {
        "Data Fields": "accident",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Details of an accident which resulted in injuries which required the products and services listed in the claim."
      },
      {
        "Data Fields": "accident.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "accident.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "accident.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "accident.date",
        "Type": "date",
        
        
        "Required": "False",
        "Description": "Date of an accident event  related to the products and services contained in the claim."
      },
      {
        "Data Fields": "accident.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The type or context of the accident event for the purposes of selection of potential insurance coverages and determination of coordination between insurers."
      },
      {
        "Data Fields": "accident.locationAddress",
        "Type": "Address",
        
        
        "Required": "False",
        "Description": "The physical location of the accident event."
      },
      {
        "Data Fields": "accident.locationReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The physical location of the accident event."
      },
      {
        "Data Fields": "item",
        "Type": "BackboneElement",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A claim line. Either a simple  product or service or a 'group' of details which can each be a simple items or groups of sub-details."
      },
      {
        "Data Fields": "item.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "item.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "item.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "item.sequence",
        "Type": "positiveInt",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A number to uniquely identify item entries."
      },
      {
        "Data Fields": "item.careTeamSequence",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "CareTeam members related to this service or product."
      },
      {
        "Data Fields": "item.diagnosisSequence",
        "Type": "positiveInt",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Diagnosis applicable for this service or product."
      },
      {
        "Data Fields": "item.procedureSequence",
        "Type": "positiveInt",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Procedures applicable for this service or product."
      },
      {
        "Data Fields": "item.informationSequence",
        "Type": "positiveInt",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Exceptions, special conditions and supporting information applicable for this service or product."
      },
      {
        "Data Fields": "item.revenue",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The type of revenue or cost center providing the product and/or service."
      },
      {
        "Data Fields": "item.category",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Code to identify the general type of benefits under which products and services are provided."
      },
      {
        "Data Fields": "item.productOrService",
        "Type": "CodeableConcept",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "When the value is a group code then this item collects a set of related claim details, otherwise this contains the product, service, drug or other billing code for the item."
      },
      {
        "Data Fields": "item.modifier",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Item typification or modifiers codes to convey additional context for the product or service."
      },
      {
        "Data Fields": "item.programCode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Identifies the program under which this may be recovered."
      },
      {
        "Data Fields": "item.servicedDate",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The date or dates when the service or product was supplied, performed or completed."
      },
      {
        "Data Fields": "item.servicedPeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The date or dates when the service or product was supplied, performed or completed."
      },
      {
        "Data Fields": "item.locationCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Where the product or service was provided."
      },
      {
        "Data Fields": "item.locationAddress",
        "Type": "Address",
        
        
        "Required": "False",
        "Description": "Where the product or service was provided."
      },
      {
        "Data Fields": "item.locationReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Where the product or service was provided."
      },
      {
        "Data Fields": "item.quantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The number of repetitions of a service or product."
      },
      {
        "Data Fields": "item.unitPrice",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "If the item is not a group then this is the fee for the product or service, otherwise this is the total of the fees for the details of the group."
      },
      {
        "Data Fields": "item.factor",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "A real number that represents a multiplier used in determining the overall value of services delivered and/or goods received. The concept of a Factor allows for a discount or surcharge multiplier to be applied to a monetary amount."
      },
      {
        "Data Fields": "item.net",
        "Type": "Money",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The quantity times the unit price for an additional service or product or charge."
      },
      {
        "Data Fields": "item.udi",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Unique Device Identifiers associated with this line item."
      },
      {
        "Data Fields": "item.bodySite",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Physical service site on the patient (limb, tooth, etc.)."
      },
      {
        "Data Fields": "item.subSite",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A region or surface of the bodySite, e.g. limb region or tooth surface(s)."
      },
      {
        "Data Fields": "item.encounter",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The Encounters during which this Claim was created or to which the creation of this record is tightly associated."
      },
      {
        "Data Fields": "item.detail",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A claim detail line. Either a simple (a product or service) or a 'group' of sub-details which are simple items."
      },
      {
        "Data Fields": "item.detail.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "item.detail.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "item.detail.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "item.detail.sequence",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "A number to uniquely identify item entries."
      },
      {
        "Data Fields": "item.detail.revenue",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The type of revenue or cost center providing the product and/or service."
      },
      {
        "Data Fields": "item.detail.category",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Code to identify the general type of benefits under which products and services are provided."
      },
      {
        "Data Fields": "item.detail.productOrService",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "When the value is a group code then this item collects a set of related claim details, otherwise this contains the product, service, drug or other billing code for the item."
      },
      {
        "Data Fields": "item.detail.modifier",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Item typification or modifiers codes to convey additional context for the product or service."
      },
      {
        "Data Fields": "item.detail.programCode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Identifies the program under which this may be recovered."
      },
      {
        "Data Fields": "item.detail.quantity",
        "Type": "Quantity",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The number of repetitions of a service or product."
      },
      {
        "Data Fields": "item.detail.unitPrice",
        "Type": "Money",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "If the item is not a group then this is the fee for the product or service, otherwise this is the total of the fees for the details of the group."
      },
      {
        "Data Fields": "item.detail.factor",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "A real number that represents a multiplier used in determining the overall value of services delivered and/or goods received. The concept of a Factor allows for a discount or surcharge multiplier to be applied to a monetary amount."
      },
      {
        "Data Fields": "item.detail.net",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "The quantity times the unit price for an additional service or product or charge."
      },
      {
        "Data Fields": "item.detail.udi",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Unique Device Identifiers associated with this line item."
      },
      {
        "Data Fields": "item.detail.subDetail",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A claim detail line. Either a simple (a product or service) or a 'group' of sub-details which are simple items."
      },
      {
        "Data Fields": "item.detail.subDetail.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "item.detail.subDetail.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "item.detail.subDetail.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "item.detail.subDetail.sequence",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "A number to uniquely identify item entries."
      },
      {
        "Data Fields": "item.detail.subDetail.revenue",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The type of revenue or cost center providing the product and/or service."
      },
      {
        "Data Fields": "item.detail.subDetail.category",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Code to identify the general type of benefits under which products and services are provided."
      },
      {
        "Data Fields": "item.detail.subDetail.productOrService",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "When the value is a group code then this item collects a set of related claim details, otherwise this contains the product, service, drug or other billing code for the item."
      },
      {
        "Data Fields": "item.detail.subDetail.modifier",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Item typification or modifiers codes to convey additional context for the product or service."
      },
      {
        "Data Fields": "item.detail.subDetail.programCode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Identifies the program under which this may be recovered."
      },
      {
        "Data Fields": "item.detail.subDetail.quantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The number of repetitions of a service or product."
      },
      {
        "Data Fields": "item.detail.subDetail.unitPrice",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "If the item is not a group then this is the fee for the product or service, otherwise this is the total of the fees for the details of the group."
      },
      {
        "Data Fields": "item.detail.subDetail.factor",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "A real number that represents a multiplier used in determining the overall value of services delivered and/or goods received. The concept of a Factor allows for a discount or surcharge multiplier to be applied to a monetary amount."
      },
      {
        "Data Fields": "item.detail.subDetail.net",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "The quantity times the unit price for an additional service or product or charge."
      },
      {
        "Data Fields": "item.detail.subDetail.udi",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Unique Device Identifiers associated with this line item."
      },
      {
        "Data Fields": "total",
        "Type": "Money",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The total value of the all the items in the claim."
      }
    ],
    "ClaimResponse": [
      {
        "Data Fields": "resourceType",
        "Type": "ClaimResponse",
        
        
        "Required": "True",
        "Description": "This is a ClaimResponse resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A unique identifier assigned to this claim response."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The status of the resource instance."
      },
      {
        "Data Fields": "type",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "True",
        "Description": "A finer grained suite of claim type codes which may convey additional information such as Inpatient vs Outpatient and/or a specialty service."
      },
      {
        "Data Fields": "subType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A finer grained suite of claim type codes which may convey additional information such as Inpatient vs Outpatient and/or a specialty service."
      },
      {
        "Data Fields": "use",
        "Type": "code",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A code to indicate whether the nature of the request is: to request adjudication of products and services previously rendered; or requesting authorization and adjudication for provision in the future; or requesting the non-binding adjudication of the listed products and services which could be provided in the future."
      },
      {
        "Data Fields": "patient",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "The party to whom the professional services and/or products have been supplied or are being considered and for whom actual for facast reimbursement is sought."
      },
      {
        "Data Fields": "created",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date this resource was created."
      },
      {
        "Data Fields": "insurer",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "The party responsible for authorization, adjudication and reimbursement."
      },
      {
        "Data Fields": "requestor",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The provider which is responsible for the claim, predetermination or preauthorization."
      },
      {
        "Data Fields": "request",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Original request resource reference."
      },
      {
        "Data Fields": "outcome",
        "Type": "code",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The outcome of the claim, predetermination, or preauthorization processing."
      },
      {
        "Data Fields": "disposition",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A human readable description of the status of the adjudication."
      },
      {
        "Data Fields": "preAuthRef",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Reference from the Insurer which is used in later communications which refers to this adjudication."
      },
      {
        "Data Fields": "preAuthPeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The time frame during which this authorization is effective."
      },
      {
        "Data Fields": "payeeType",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Type of Party to be reimbursed: subscriber, provider, other."
      },
      {
        "Data Fields": "item",
        "Type": "BackboneElement",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A claim line. Either a simple (a product or service) or a 'group' of details which can also be a simple items or groups of sub-details."
      },
      {
        "Data Fields": "item.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "item.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "item.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "item.itemSequence",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "A number to uniquely reference the claim item entries."
      },
      {
        "Data Fields": "item.noteNumber",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "The numbers associated with notes below which apply to the adjudication of this item."
      },
      {
        "Data Fields": "item.adjudication",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "If this item is a group then the values here are a summary of the adjudication of the detail items. If this item is a simple product or service then this is the result of the adjudication of this item."
      },
      {
        "Data Fields": "item.adjudication.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "item.adjudication.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "item.adjudication.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "item.adjudication.category",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code to indicate the information type of this adjudication record. Information types may include the value submitted, maximum values or percentages allowed or payable under the plan, amounts that: the patient is responsible for in aggregate or pertaining to this item; amounts paid by other coverages; and, the benefit payable for this item."
      },
      {
        "Data Fields": "item.adjudication.reason",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code supporting the understanding of the adjudication result and explaining variance from expected amount."
      },
      {
        "Data Fields": "item.adjudication.amount",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "Monetary amount associated with the category."
      },
      {
        "Data Fields": "item.adjudication.value",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "A non-monetary value associated with the category. Mutually exclusive to the amount element above."
      },
      {
        "Data Fields": "item.detail",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A claim detail. Either a simple (a product or service) or a 'group' of sub-details which are simple items."
      },
      {
        "Data Fields": "item.detail.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "item.detail.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "item.detail.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "item.detail.detailSequence",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "A number to uniquely reference the claim detail entry."
      },
      {
        "Data Fields": "item.detail.noteNumber",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "The numbers associated with notes below which apply to the adjudication of this item."
      },
      {
        "Data Fields": "item.detail.adjudication",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The adjudication results."
      },
      {
        "Data Fields": "item.detail.adjudication.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "item.detail.adjudication.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "item.detail.adjudication.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "item.detail.adjudication.category",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code to indicate the information type of this adjudication record. Information types may include the value submitted, maximum values or percentages allowed or payable under the plan, amounts that: the patient is responsible for in aggregate or pertaining to this item; amounts paid by other coverages; and, the benefit payable for this item."
      },
      {
        "Data Fields": "item.detail.adjudication.reason",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code supporting the understanding of the adjudication result and explaining variance from expected amount."
      },
      {
        "Data Fields": "item.detail.adjudication.amount",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "Monetary amount associated with the category."
      },
      {
        "Data Fields": "item.detail.adjudication.value",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "A non-monetary value associated with the category. Mutually exclusive to the amount element above."
      },
      {
        "Data Fields": "item.detail.subDetail",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A sub-detail adjudication of a simple product or service."
      },
      {
        "Data Fields": "item.detail.subDetail.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "item.detail.subDetail.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "item.detail.subDetail.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "item.detail.subDetail.subDetailSequence",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "A number to uniquely reference the claim sub-detail entry."
      },
      {
        "Data Fields": "item.detail.subDetail.noteNumber",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "The numbers associated with notes below which apply to the adjudication of this item."
      },
      {
        "Data Fields": "item.detail.subDetail.adjudication",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The adjudication results."
      },
      {
        "Data Fields": "item.detail.subDetail.adjudication.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "item.detail.subDetail.adjudication.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "item.detail.subDetail.adjudication.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "item.detail.subDetail.adjudication.category",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code to indicate the information type of this adjudication record. Information types may include the value submitted, maximum values or percentages allowed or payable under the plan, amounts that: the patient is responsible for in aggregate or pertaining to this item; amounts paid by other coverages; and, the benefit payable for this item."
      },
      {
        "Data Fields": "item.detail.subDetail.adjudication.reason",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code supporting the understanding of the adjudication result and explaining variance from expected amount."
      },
      {
        "Data Fields": "item.detail.subDetail.adjudication.amount",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "Monetary amount associated with the category."
      },
      {
        "Data Fields": "item.detail.subDetail.adjudication.value",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "A non-monetary value associated with the category. Mutually exclusive to the amount element above."
      },
      {
        "Data Fields": "addItem",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The first-tier service adjudications for payor added product or service lines."
      },
      {
        "Data Fields": "addItem.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "addItem.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "addItem.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "addItem.itemSequence",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "Claim items which this service line is intended to replace."
      },
      {
        "Data Fields": "addItem.detailSequence",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "The sequence number of the details within the claim item which this line is intended to replace."
      },
      {
        "Data Fields": "addItem.subdetailSequence",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "The sequence number of the sub-details within the details within the claim item which this line is intended to replace."
      },
      {
        "Data Fields": "addItem.provider",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The providers who are authorized for the services rendered to the patient."
      },
      {
        "Data Fields": "addItem.productOrService",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "When the value is a group code then this item collects a set of related claim details, otherwise this contains the product, service, drug or other billing code for the item."
      },
      {
        "Data Fields": "addItem.modifier",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Item typification or modifiers codes to convey additional context for the product or service."
      },
      {
        "Data Fields": "addItem.programCode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Identifies the program under which this may be recovered."
      },
      {
        "Data Fields": "addItem.servicedDate",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The date or dates when the service or product was supplied, performed or completed."
      },
      {
        "Data Fields": "addItem.servicedPeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The date or dates when the service or product was supplied, performed or completed."
      },
      {
        "Data Fields": "addItem.locationCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Where the product or service was provided."
      },
      {
        "Data Fields": "addItem.locationAddress",
        "Type": "Address",
        
        
        "Required": "False",
        "Description": "Where the product or service was provided."
      },
      {
        "Data Fields": "addItem.locationReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Where the product or service was provided."
      },
      {
        "Data Fields": "addItem.quantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The number of repetitions of a service or product."
      },
      {
        "Data Fields": "addItem.unitPrice",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "If the item is not a group then this is the fee for the product or service, otherwise this is the total of the fees for the details of the group."
      },
      {
        "Data Fields": "addItem.factor",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "A real number that represents a multiplier used in determining the overall value of services delivered and/or goods received. The concept of a Factor allows for a discount or surcharge multiplier to be applied to a monetary amount."
      },
      {
        "Data Fields": "addItem.net",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "The quantity times the unit price for an additional service or product or charge."
      },
      {
        "Data Fields": "addItem.bodySite",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Physical service site on the patient (limb, tooth, etc.)."
      },
      {
        "Data Fields": "addItem.subSite",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A region or surface of the bodySite, e.g. limb region or tooth surface(s)."
      },
      {
        "Data Fields": "addItem.noteNumber",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "The numbers associated with notes below which apply to the adjudication of this item."
      },
      {
        "Data Fields": "addItem.adjudication",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The adjudication results."
      },
      {
        "Data Fields": "addItem.adjudication.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "addItem.adjudication.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "addItem.adjudication.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "addItem.adjudication.category",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code to indicate the information type of this adjudication record. Information types may include the value submitted, maximum values or percentages allowed or payable under the plan, amounts that: the patient is responsible for in aggregate or pertaining to this item; amounts paid by other coverages; and, the benefit payable for this item."
      },
      {
        "Data Fields": "addItem.adjudication.reason",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code supporting the understanding of the adjudication result and explaining variance from expected amount."
      },
      {
        "Data Fields": "addItem.adjudication.amount",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "Monetary amount associated with the category."
      },
      {
        "Data Fields": "addItem.adjudication.value",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "A non-monetary value associated with the category. Mutually exclusive to the amount element above."
      },
      {
        "Data Fields": "addItem.detail",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The second-tier service adjudications for payor added services."
      },
      {
        "Data Fields": "addItem.detail.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "addItem.detail.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "addItem.detail.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "addItem.detail.productOrService",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "When the value is a group code then this item collects a set of related claim details, otherwise this contains the product, service, drug or other billing code for the item."
      },
      {
        "Data Fields": "addItem.detail.modifier",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Item typification or modifiers codes to convey additional context for the product or service."
      },
      {
        "Data Fields": "addItem.detail.quantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The number of repetitions of a service or product."
      },
      {
        "Data Fields": "addItem.detail.unitPrice",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "If the item is not a group then this is the fee for the product or service, otherwise this is the total of the fees for the details of the group."
      },
      {
        "Data Fields": "addItem.detail.factor",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "A real number that represents a multiplier used in determining the overall value of services delivered and/or goods received. The concept of a Factor allows for a discount or surcharge multiplier to be applied to a monetary amount."
      },
      {
        "Data Fields": "addItem.detail.net",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "The quantity times the unit price for an additional service or product or charge."
      },
      {
        "Data Fields": "addItem.detail.noteNumber",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "The numbers associated with notes below which apply to the adjudication of this item."
      },
      {
        "Data Fields": "addItem.detail.adjudication",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The adjudication results."
      },
      {
        "Data Fields": "addItem.detail.adjudication.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "addItem.detail.adjudication.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "addItem.detail.adjudication.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "addItem.detail.adjudication.category",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code to indicate the information type of this adjudication record. Information types may include the value submitted, maximum values or percentages allowed or payable under the plan, amounts that: the patient is responsible for in aggregate or pertaining to this item; amounts paid by other coverages; and, the benefit payable for this item."
      },
      {
        "Data Fields": "addItem.detail.adjudication.reason",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code supporting the understanding of the adjudication result and explaining variance from expected amount."
      },
      {
        "Data Fields": "addItem.detail.adjudication.amount",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "Monetary amount associated with the category."
      },
      {
        "Data Fields": "addItem.detail.adjudication.value",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "A non-monetary value associated with the category. Mutually exclusive to the amount element above."
      },
      {
        "Data Fields": "addItem.detail.subDetail",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The third-tier service adjudications for payor added services."
      },
      {
        "Data Fields": "addItem.detail.subDetail.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "addItem.detail.subDetail.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "addItem.detail.subDetail.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "addItem.detail.subDetail.productOrService",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "When the value is a group code then this item collects a set of related claim details, otherwise this contains the product, service, drug or other billing code for the item."
      },
      {
        "Data Fields": "addItem.detail.subDetail.modifier",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Item typification or modifiers codes to convey additional context for the product or service."
      },
      {
        "Data Fields": "addItem.detail.subDetail.quantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The number of repetitions of a service or product."
      },
      {
        "Data Fields": "addItem.detail.subDetail.unitPrice",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "If the item is not a group then this is the fee for the product or service, otherwise this is the total of the fees for the details of the group."
      },
      {
        "Data Fields": "addItem.detail.subDetail.factor",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "A real number that represents a multiplier used in determining the overall value of services delivered and/or goods received. The concept of a Factor allows for a discount or surcharge multiplier to be applied to a monetary amount."
      },
      {
        "Data Fields": "addItem.detail.subDetail.net",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "The quantity times the unit price for an additional service or product or charge."
      },
      {
        "Data Fields": "addItem.detail.subDetail.noteNumber",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "The numbers associated with notes below which apply to the adjudication of this item."
      },
      {
        "Data Fields": "addItem.detail.subDetail.adjudication",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The adjudication results."
      },
      {
        "Data Fields": "addItem.detail.subDetail.adjudication.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "addItem.detail.subDetail.adjudication.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "addItem.detail.subDetail.adjudication.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "addItem.detail.subDetail.adjudication.category",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code to indicate the information type of this adjudication record. Information types may include the value submitted, maximum values or percentages allowed or payable under the plan, amounts that: the patient is responsible for in aggregate or pertaining to this item; amounts paid by other coverages; and, the benefit payable for this item."
      },
      {
        "Data Fields": "addItem.detail.subDetail.adjudication.reason",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code supporting the understanding of the adjudication result and explaining variance from expected amount."
      },
      {
        "Data Fields": "addItem.detail.subDetail.adjudication.amount",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "Monetary amount associated with the category."
      },
      {
        "Data Fields": "addItem.detail.subDetail.adjudication.value",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "A non-monetary value associated with the category. Mutually exclusive to the amount element above."
      },
      {
        "Data Fields": "adjudication",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The adjudication results which are presented at the header level rather than at the line-item or add-item levels."
      },
      {
        "Data Fields": "adjudication.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "adjudication.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "adjudication.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "adjudication.category",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code to indicate the information type of this adjudication record. Information types may include the value submitted, maximum values or percentages allowed or payable under the plan, amounts that: the patient is responsible for in aggregate or pertaining to this item; amounts paid by other coverages; and, the benefit payable for this item."
      },
      {
        "Data Fields": "adjudication.reason",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code supporting the understanding of the adjudication result and explaining variance from expected amount."
      },
      {
        "Data Fields": "adjudication.amount",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "Monetary amount associated with the category."
      },
      {
        "Data Fields": "adjudication.value",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "A non-monetary value associated with the category. Mutually exclusive to the amount element above."
      },
      {
        "Data Fields": "total",
        "Type": "BackboneElement",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Categorized monetary totals for the adjudication."
      },
      {
        "Data Fields": "total.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "total.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "total.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "total.category",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code to indicate the information type of this adjudication record. Information types may include: the value submitted, maximum values or percentages allowed or payable under the plan, amounts that the patient is responsible for in aggregate or pertaining to this item, amounts paid by other coverages, and the benefit payable for this item."
      },
      {
        "Data Fields": "total.amount",
        "Type": "Money",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Monetary total amount associated with the category."
      },
      {
        "Data Fields": "payment",
        "Type": "BackboneElement",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Payment details for the adjudication of the claim."
      },
      {
        "Data Fields": "payment.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "payment.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "payment.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "payment.type",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Whether this represents partial or complete payment of the benefits payable."
      },
      {
        "Data Fields": "payment.adjustment",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "Total amount of all adjustments to this payment included in this transaction which are not related to this claim's adjudication."
      },
      {
        "Data Fields": "payment.adjustmentReason",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Reason for the payment adjustment."
      },
      {
        "Data Fields": "payment.date",
        "Type": "date",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Estimated date the payment will be issued or the actual issue date of payment."
      },
      {
        "Data Fields": "payment.amount",
        "Type": "Money",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Benefits payable less any payment adjustment."
      },
      {
        "Data Fields": "payment.identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Issuer's unique identifier for the payment instrument."
      },
      {
        "Data Fields": "fundsReserve",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code, used only on a response to a preauthorization, to indicate whether the benefits payable have been reserved and for whom."
      },
      {
        "Data Fields": "formCode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code for the form to be used for printing the content."
      },
      {
        "Data Fields": "form",
        "Type": "Attachment",
        
        
        "Required": "False",
        "Description": "The actual form, by reference or inclusion, for printing the content or an EOB."
      },
      {
        "Data Fields": "processNote",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A note that describes or explains adjudication results in a human readable form."
      },
      {
        "Data Fields": "processNote.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "processNote.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "processNote.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "processNote.number",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "A number to uniquely identify a note entry."
      },
      {
        "Data Fields": "processNote.type",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The business purpose of the note text."
      },
      {
        "Data Fields": "processNote.text",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The explanation or description associated with the processing."
      },
      {
        "Data Fields": "processNote.language",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code to define the language used in the text of the note."
      },
      {
        "Data Fields": "communicationRequest",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Request for additional supporting or authorizing information."
      },
      {
        "Data Fields": "insurance",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Financial instruments for reimbursement for the health care products and services specified on the claim."
      },
      {
        "Data Fields": "insurance.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "insurance.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "insurance.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "insurance.sequence",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "A number to uniquely identify insurance entries and provide a sequence of coverages to convey coordination of benefit order."
      },
      {
        "Data Fields": "insurance.focal",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "A flag to indicate that this Coverage is to be used for adjudication of this claim when set to true."
      },
      {
        "Data Fields": "insurance.coverage",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Reference to the insurance card level information contained in the Coverage resource. The coverage issuing insurer will use these details to locate the patient's actual coverage within the insurer's information system."
      },
      {
        "Data Fields": "insurance.businessArrangement",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A business agreement number established between the provider and the insurer for special business processing purposes."
      },
      {
        "Data Fields": "insurance.claimResponse",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The result of the adjudication of the line items for the Coverage specified in this insurance."
      },
      {
        "Data Fields": "error",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Errors encountered during the processing of the adjudication."
      },
      {
        "Data Fields": "error.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "error.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "error.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "error.itemSequence",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "The sequence number of the line item submitted which contains the error. This value is omitted when the error occurs outside of the item structure."
      },
      {
        "Data Fields": "error.detailSequence",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "The sequence number of the detail within the line item submitted which contains the error. This value is omitted when the error occurs outside of the item structure."
      },
      {
        "Data Fields": "error.subDetailSequence",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "The sequence number of the sub-detail within the detail within the line item submitted which contains the error. This value is omitted when the error occurs outside of the item structure."
      },
      {
        "Data Fields": "error.code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "An error code, from a specified code system, which details why the claim could not be adjudicated."
      }
    ],
    "ClinicalImpression": [
      {
        "Data Fields": "resourceType",
        "Type": "ClinicalImpression",
        
        
        "Required": "True",
        "Description": "This is a ClinicalImpression resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Business identifiers assigned to this clinical impression by the performer or other systems which remain constant as the resource is updated and propagates from server to server."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Identifies the workflow status of the assessment."
      },
      {
        "Data Fields": "statusReason",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Captures the reason for the current state of the ClinicalImpression."
      },
      {
        "Data Fields": "code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Categorizes the type of clinical assessment performed."
      },
      {
        "Data Fields": "description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A summary of the context and/or cause of the assessment - why / where it was performed, and what patient events/status prompted it."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "The patient or group of individuals assessed as part of this record."
      },
      {
        "Data Fields": "encounter",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The Encounter during which this ClinicalImpression was created or to which the creation of this record is tightly associated."
      },
      {
        "Data Fields": "effectiveDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The point in time or period over which the subject was assessed."
      },
      {
        "Data Fields": "effectivePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The point in time or period over which the subject was assessed."
      },
      {
        "Data Fields": "date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "Indicates when the documentation of the assessment was complete."
      },
      {
        "Data Fields": "assessor",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The clinician performing the assessment."
      },
      {
        "Data Fields": "previous",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A reference to the last assessment that was conducted on this patient. Assessments are often/usually ongoing in nature; a care provider (practitioner or team) will make new assessments on an ongoing basis as new data arises or the patient's conditions changes."
      },
      {
        "Data Fields": "problem",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A list of the relevant problems/conditions for a patient."
      },
      {
        "Data Fields": "investigation",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "One or more sets of investigations (signs, symptoms, etc.). The actual grouping of investigations varies greatly depending on the type and context of the assessment. These investigations may include data generated during the assessment process, or data previously generated and recorded that is pertinent to the outcomes."
      },
      {
        "Data Fields": "investigation.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "investigation.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "investigation.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "investigation.code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A name/code for the group (\"set\") of investigations. Typically, this will be something like \"signs\", \"symptoms\", \"clinical\", \"diagnostic\", but the list is not constrained, and others such groups such as (exposure|family|travel|nutritional) history may be used."
      },
      {
        "Data Fields": "investigation.item",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A record of a specific investigation that was undertaken."
      },
      {
        "Data Fields": "protocol",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "Reference to a specific published clinical protocol that was followed during this assessment, and/or that provides evidence in support of the diagnosis."
      },
      {
        "Data Fields": "summary",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A text summary of the investigations and the diagnosis."
      },
      {
        "Data Fields": "finding",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Specific findings or diagnoses that were considered likely or relevant to ongoing treatment."
      },
      {
        "Data Fields": "finding.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "finding.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "finding.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "finding.itemCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Specific text or code for finding or diagnosis, which may include ruled-out or resolved conditions."
      },
      {
        "Data Fields": "finding.itemReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Specific reference for finding or diagnosis, which may include ruled-out or resolved conditions."
      },
      {
        "Data Fields": "finding.basis",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Which investigations support finding or diagnosis."
      },
      {
        "Data Fields": "prognosisCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Estimate of likely outcome."
      },
      {
        "Data Fields": "prognosisReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "RiskAssessment expressing likely outcome."
      },
      {
        "Data Fields": "supportingInfo",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Information supporting the clinical impression."
      },
      {
        "Data Fields": "note",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "Commentary about the impression, typically recorded after the impression itself was made, though supplemental notes by the original author could also appear."
      }
    ],
    "CodeSystem": [
      {
        "Data Fields": "resourceType",
        "Type": "CodeSystem",
        
        
        "Required": "True",
        "Description": "This is a CodeSystem resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "url",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "An absolute URI that is used to identify this code system when it is referenced in a specification, model, design or an instance; also called its canonical identifier. This SHOULD be globally unique and SHOULD be a literal address at which at which an authoritative instance of this code system is (or will be) published. This URL can be the target of a canonical reference. It SHALL remain the same when the code system is stored on different servers. This is used in [Coding](datatypes.html#Coding).system."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A formal identifier that is used to identify this code system when it is represented in other formats, or referenced in a specification, model, design or an instance."
      },
      {
        "Data Fields": "version",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The identifier that is used to identify this version of the code system when it is referenced in a specification, model, design or instance. This is an arbitrary value managed by the code system author and is not expected to be globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if a managed version is not available. There is also no expectation that versions can be placed in a lexicographical sequence. This is used in [Coding](datatypes.html#Coding).version."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A natural language name identifying the code system. This name should be usable as an identifier for the module by machine processing applications such as code generation."
      },
      {
        "Data Fields": "title",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A short, descriptive, user-friendly title for the code system."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The date (and optionally time) when the code system resource was created or revised."
      },
      {
        "Data Fields": "experimental",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "A Boolean value to indicate that this code system is authored for testing purposes (or education/evaluation/marketing) and is not intended to be used for genuine usage."
      },
      {
        "Data Fields": "date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date  (and optionally time) when the code system was published. The date must change when the business version changes and it must change if the status code changes. In addition, it should change when the substantive content of the code system changes."
      },
      {
        "Data Fields": "publisher",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the organization or individual that published the code system."
      },
      {
        "Data Fields": "contact",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "Contact details to assist a user in finding and communicating with the publisher."
      },
      {
        "Data Fields": "description",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A free text natural language description of the code system from a consumer's perspective."
      },
      {
        "Data Fields": "useContext",
        "Type": "UsageContext",
        
        
        "Required": "False",
        "Description": "The content was developed with a focus and intent of supporting the contexts that are listed. These contexts may be general categories (gender, age, ...) or may be references to specific programs (insurance plans, studies, ...) and may be used to assist with indexing and searching for appropriate code system instances."
      },
      {
        "Data Fields": "jurisdiction",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A legal or geographic region in which the code system is intended to be used."
      },
      {
        "Data Fields": "purpose",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Explanation of why this code system is needed and why it has been designed as it has."
      },
      {
        "Data Fields": "copyright",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A copyright statement relating to the code system and/or its contents. Copyright statements are generally legal restrictions on the use and publishing of the code system."
      },
      {
        "Data Fields": "caseSensitive",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "If code comparison is case sensitive when codes within this system are compared to each other."
      },
      {
        "Data Fields": "valueSet",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "Canonical reference to the value set that contains the entire code system."
      },
      {
        "Data Fields": "hierarchyMeaning",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The meaning of the hierarchy of concepts as represented in this resource."
      },
      {
        "Data Fields": "compositional",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "The code system defines a compositional (post-coordination) grammar."
      },
      {
        "Data Fields": "versionNeeded",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "This flag is used to signify that the code system does not commit to concept permanence across versions. If true, a version must be specified when referencing this code system."
      },
      {
        "Data Fields": "content",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The extent of the content of the code system (the concepts and codes it defines) are represented in this resource instance."
      },
      {
        "Data Fields": "supplements",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "The canonical URL of the code system that this code system supplement is adding designations and properties to."
      },
      {
        "Data Fields": "count",
        "Type": "unsignedInt",
        
        
        "Required": "False",
        "Description": "The total number of concepts defined by the code system. Where the code system has a compositional grammar, the basis of this count is defined by the system steward."
      },
      {
        "Data Fields": "filter",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A filter that can be used in a value set compose statement when selecting concepts using a filter."
      },
      {
        "Data Fields": "filter.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "filter.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "filter.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "filter.code",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The code that identifies this filter when it is used as a filter in [[[ValueSet]]].compose.include.filter."
      },
      {
        "Data Fields": "filter.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A description of how or why the filter is used."
      },
      {
        "Data Fields": "filter.operator",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "A list of operators that can be used with the filter."
      },
      {
        "Data Fields": "filter.value",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A description of what the value for the filter should be."
      },
      {
        "Data Fields": "property",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A property defines an additional slot through which additional information can be provided about a concept."
      },
      {
        "Data Fields": "property.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "property.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "property.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "property.code",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "A code that is used to identify the property. The code is used internally (in CodeSystem.concept.property.code) and also externally, such as in property filters."
      },
      {
        "Data Fields": "property.uri",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "Reference to the formal meaning of the property. One possible source of meaning is the [Concept Properties](codesystem-concept-properties.html) code system."
      },
      {
        "Data Fields": "property.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A description of the property- why it is defined, and how its value might be used."
      },
      {
        "Data Fields": "property.type",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The type of the property value. Properties of type \"code\" contain a code defined by the code system (e.g. a reference to another defined concept)."
      },
      {
        "Data Fields": "concept",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Concepts that are in the code system. The concept definitions are inherently hierarchical, but the definitions must be consulted to determine what the meanings of the hierarchical relationships are."
      },
      {
        "Data Fields": "concept.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "concept.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "concept.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "concept.code",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "A code - a text symbol - that uniquely identifies the concept within the code system."
      },
      {
        "Data Fields": "concept.display",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A human readable string that is the recommended default way to present this concept to a user."
      },
      {
        "Data Fields": "concept.definition",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The formal definition of the concept. The code system resource does not make formal definitions required, because of the prevalence of legacy systems. However, they are highly recommended, as without them there is no formal meaning associated with the concept."
      },
      {
        "Data Fields": "concept.designation",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Additional representations for the concept - other languages, aliases, specialized purposes, used for particular purposes, etc."
      },
      {
        "Data Fields": "concept.designation.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "concept.designation.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "concept.designation.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "concept.designation.language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The language this designation is defined for."
      },
      {
        "Data Fields": "concept.designation.use",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "A code that details how this designation would be used."
      },
      {
        "Data Fields": "concept.designation.value",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The text value for this designation."
      },
      {
        "Data Fields": "concept.property",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A property value for this concept."
      },
      {
        "Data Fields": "concept.property.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "concept.property.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "concept.property.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "concept.property.code",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "A code that is a reference to CodeSystem.property.code."
      },
      {
        "Data Fields": "concept.property.valueCode",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of this property."
      },
      {
        "Data Fields": "concept.property.valueCoding",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "The value of this property."
      },
      {
        "Data Fields": "concept.property.valueString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of this property."
      },
      {
        "Data Fields": "concept.property.valueInteger",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "The value of this property."
      },
      {
        "Data Fields": "concept.property.valueBoolean",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "The value of this property."
      },
      {
        "Data Fields": "concept.property.valueDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of this property."
      },
      {
        "Data Fields": "concept.property.valueDecimal",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "The value of this property."
      }
    ],
    "Communication": [
      {
        "Data Fields": "resourceType",
        "Type": "Communication",
        
        
        "Required": "True",
        "Description": "This is a Communication resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Business identifiers assigned to this communication by the performer or other systems which remain constant as the resource is updated and propagates from server to server."
      },
      {
        "Data Fields": "instantiatesCanonical",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "The URL pointing to a FHIR-defined protocol, guideline, orderset or other definition that is adhered to in whole or in part by this Communication."
      },
      {
        "Data Fields": "instantiatesUri",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "The URL pointing to an externally maintained protocol, guideline, orderset or other definition that is adhered to in whole or in part by this Communication."
      },
      {
        "Data Fields": "basedOn",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "An order, proposal or plan fulfilled in whole or in part by this Communication."
      },
      {
        "Data Fields": "partOf",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Part of this action."
      },
      {
        "Data Fields": "inResponseTo",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Prior communication that this communication is in response to."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The status of the transmission."
      },
      {
        "Data Fields": "statusReason",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Captures the reason for the current state of the Communication."
      },
      {
        "Data Fields": "category",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The type of message conveyed such as alert, notification, reminder, instruction, etc."
      },
      {
        "Data Fields": "priority",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Characterizes how quickly the planned or in progress communication must be addressed. Includes concepts such as stat, urgent, routine."
      },
      {
        "Data Fields": "medium",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A channel that was used for this communication (e.g. email, fax)."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The patient or group that was the focus of this communication."
      },
      {
        "Data Fields": "topic",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Description of the purpose/content, similar to a subject line in an email."
      },
      {
        "Data Fields": "about",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Other resources that pertain to this communication and to which this communication should be associated."
      },
      {
        "Data Fields": "encounter",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The Encounter during which this Communication was created or to which the creation of this record is tightly associated."
      },
      {
        "Data Fields": "sent",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The time when this communication was sent."
      },
      {
        "Data Fields": "received",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The time when this communication arrived at the destination."
      },
      {
        "Data Fields": "recipient",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The entity (e.g. person, organization, clinical information system, care team or device) which was the target of the communication. If receipts need to be tracked by an individual, a separate resource instance will need to be created for each recipient.  Multiple recipient communications are intended where either receipts are not tracked (e.g. a mass mail-out) or a receipt is captured in aggregate (all emails confirmed received by a particular time)."
      },
      {
        "Data Fields": "sender",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The entity (e.g. person, organization, clinical information system, or device) which was the source of the communication."
      },
      {
        "Data Fields": "reasonCode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The reason or justification for the communication."
      },
      {
        "Data Fields": "reasonReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Indicates another resource whose existence justifies this communication."
      },
      {
        "Data Fields": "payload",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Text, attachment(s), or resource(s) that was communicated to the recipient."
      },
      {
        "Data Fields": "payload.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "payload.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "payload.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "payload.contentString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A communicated content (or for multi-part communications, one portion of the communication)."
      },
      {
        "Data Fields": "payload.contentAttachment",
        "Type": "Attachment",
        
        
        "Required": "False",
        "Description": "A communicated content (or for multi-part communications, one portion of the communication)."
      },
      {
        "Data Fields": "payload.contentReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A communicated content (or for multi-part communications, one portion of the communication)."
      },
      {
        "Data Fields": "note",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "Additional notes or commentary about the communication by the sender, receiver or other interested parties."
      }
    ],
    "CommunicationRequest": [
      {
        "Data Fields": "resourceType",
        "Type": "CommunicationRequest",
        
        
        "Required": "True",
        "Description": "This is a CommunicationRequest resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Business identifiers assigned to this communication request by the performer or other systems which remain constant as the resource is updated and propagates from server to server."
      },
      {
        "Data Fields": "basedOn",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A plan or proposal that is fulfilled in whole or in part by this request."
      },
      {
        "Data Fields": "replaces",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Completed or terminated request(s) whose function is taken by this new request."
      },
      {
        "Data Fields": "groupIdentifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A shared identifier common to all requests that were authorized more or less simultaneously by a single author, representing the identifier of the requisition, prescription or similar form."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The status of the proposal or order."
      },
      {
        "Data Fields": "statusReason",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Captures the reason for the current state of the CommunicationRequest."
      },
      {
        "Data Fields": "category",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The type of message to be sent such as alert, notification, reminder, instruction, etc."
      },
      {
        "Data Fields": "priority",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Characterizes how quickly the proposed act must be initiated. Includes concepts such as stat, urgent, routine."
      },
      {
        "Data Fields": "doNotPerform",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "If true indicates that the CommunicationRequest is asking for the specified action to *not* occur."
      },
      {
        "Data Fields": "medium",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A channel that was used for this communication (e.g. email, fax)."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The patient or group that is the focus of this communication request."
      },
      {
        "Data Fields": "about",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Other resources that pertain to this communication request and to which this communication request should be associated."
      },
      {
        "Data Fields": "encounter",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The Encounter during which this CommunicationRequest was created or to which the creation of this record is tightly associated."
      },
      {
        "Data Fields": "payload",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Text, attachment(s), or resource(s) to be communicated to the recipient."
      },
      {
        "Data Fields": "payload.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "payload.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "payload.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "payload.contentString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The communicated content (or for multi-part communications, one portion of the communication)."
      },
      {
        "Data Fields": "payload.contentAttachment",
        "Type": "Attachment",
        
        
        "Required": "False",
        "Description": "The communicated content (or for multi-part communications, one portion of the communication)."
      },
      {
        "Data Fields": "payload.contentReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The communicated content (or for multi-part communications, one portion of the communication)."
      },
      {
        "Data Fields": "occurrenceDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The time when this communication is to occur."
      },
      {
        "Data Fields": "occurrencePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The time when this communication is to occur."
      },
      {
        "Data Fields": "authoredOn",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "For draft requests, indicates the date of initial creation.  For requests with other statuses, indicates the date of activation."
      },
      {
        "Data Fields": "requester",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The device, individual, or organization who initiated the request and has responsibility for its activation."
      },
      {
        "Data Fields": "recipient",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The entity (e.g. person, organization, clinical information system, device, group, or care team) which is the intended target of the communication."
      },
      {
        "Data Fields": "sender",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The entity (e.g. person, organization, clinical information system, or device) which is to be the source of the communication."
      },
      {
        "Data Fields": "reasonCode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Describes why the request is being made in coded or textual form."
      },
      {
        "Data Fields": "reasonReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Indicates another resource whose existence justifies this request."
      },
      {
        "Data Fields": "note",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "Comments made about the request by the requester, sender, recipient, subject or other participants."
      }
    ],
    "CompartmentDefinition": [
      {
        "Data Fields": "resourceType",
        "Type": "CompartmentDefinition",
        
        
        "Required": "True",
        "Description": "This is a CompartmentDefinition resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "url",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "An absolute URI that is used to identify this compartment definition when it is referenced in a specification, model, design or an instance; also called its canonical identifier. This SHOULD be globally unique and SHOULD be a literal address at which at which an authoritative instance of this compartment definition is (or will be) published. This URL can be the target of a canonical reference. It SHALL remain the same when the compartment definition is stored on different servers."
      },
      {
        "Data Fields": "version",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The identifier that is used to identify this version of the compartment definition when it is referenced in a specification, model, design or instance. This is an arbitrary value managed by the compartment definition author and is not expected to be globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if a managed version is not available. There is also no expectation that versions can be placed in a lexicographical sequence."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A natural language name identifying the compartment definition. This name should be usable as an identifier for the module by machine processing applications such as code generation."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The status of this compartment definition. Enables tracking the life-cycle of the content."
      },
      {
        "Data Fields": "experimental",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "A Boolean value to indicate that this compartment definition is authored for testing purposes (or education/evaluation/marketing) and is not intended to be used for genuine usage."
      },
      {
        "Data Fields": "date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date  (and optionally time) when the compartment definition was published. The date must change when the business version changes and it must change if the status code changes. In addition, it should change when the substantive content of the compartment definition changes."
      },
      {
        "Data Fields": "publisher",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the organization or individual that published the compartment definition."
      },
      {
        "Data Fields": "contact",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "Contact details to assist a user in finding and communicating with the publisher."
      },
      {
        "Data Fields": "description",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A free text natural language description of the compartment definition from a consumer's perspective."
      },
      {
        "Data Fields": "useContext",
        "Type": "UsageContext",
        
        
        "Required": "False",
        "Description": "The content was developed with a focus and intent of supporting the contexts that are listed. These contexts may be general categories (gender, age, ...) or may be references to specific programs (insurance plans, studies, ...) and may be used to assist with indexing and searching for appropriate compartment definition instances."
      },
      {
        "Data Fields": "purpose",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Explanation of why this compartment definition is needed and why it has been designed as it has."
      },
      {
        "Data Fields": "code",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Which compartment this definition describes."
      },
      {
        "Data Fields": "search",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Whether the search syntax is supported,."
      },
      {
        "Data Fields": "resource",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Information about how a resource is related to the compartment."
      },
      {
        "Data Fields": "resource.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "resource.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "resource.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "resource.code",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The name of a resource supported by the server."
      },
      {
        "Data Fields": "resource.param",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of a search parameter that represents the link to the compartment. More than one may be listed because a resource may be linked to a compartment in more than one way,."
      },
      {
        "Data Fields": "resource.documentation",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Additional documentation about the resource and compartment."
      }
    ],
    "Composition": [
      {
        "Data Fields": "resourceType",
        "Type": "Composition",
        
        
        "Required": "True",
        "Description": "This is a Composition resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A version-independent identifier for the Composition. This identifier stays constant as the composition is changed over time."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The workflow/clinical status of this composition. The status is a marker for the clinical standing of the document."
      },
      {
        "Data Fields": "type",
        "Type": "CodeableConcept",
        
        
        "Required": "True",
        "Description": "Specifies the particular kind of composition (e.g. History and Physical, Discharge Summary, Progress Note). This usually equates to the purpose of making the composition."
      },
      {
        "Data Fields": "category",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A categorization for the type of the composition - helps for indexing and searching. This may be implied by or derived from the code specified in the Composition Type."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Who or what the composition is about. The composition can be about a person, (patient or healthcare practitioner), a device (e.g. a machine) or even a group of subjects (such as a document about a herd of livestock, or a set of patients that share a common exposure)."
      },
      {
        "Data Fields": "encounter",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Describes the clinical encounter or type of care this documentation is associated with."
      },
      {
        "Data Fields": "date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The composition editing time, when the composition was last logically changed by the author."
      },
      {
        "Data Fields": "author",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "Identifies who is responsible for the information in the composition, not necessarily who typed it in."
      },
      {
        "Data Fields": "title",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Official human-readable label for the composition."
      },
      {
        "Data Fields": "confidentiality",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The code specifying the level of confidentiality of the Composition."
      },
      {
        "Data Fields": "attester",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A participant who has attested to the accuracy of the composition/document."
      },
      {
        "Data Fields": "attester.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "attester.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "attester.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "attester.mode",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The type of attestation the authenticator offers."
      },
      {
        "Data Fields": "attester.time",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "When the composition was attested by the party."
      },
      {
        "Data Fields": "attester.party",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Who attested the composition in the specified way."
      },
      {
        "Data Fields": "custodian",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Identifies the organization or group who is responsible for ongoing maintenance of and access to the composition/document information."
      },
      {
        "Data Fields": "relatesTo",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Relationships that this composition has with other compositions or documents that already exist."
      },
      {
        "Data Fields": "relatesTo.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "relatesTo.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "relatesTo.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "relatesTo.code",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The type of relationship that this composition has with anther composition or document."
      },
      {
        "Data Fields": "relatesTo.targetIdentifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "The target composition/document of this relationship."
      },
      {
        "Data Fields": "relatesTo.targetReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The target composition/document of this relationship."
      },
      {
        "Data Fields": "event",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The clinical service, such as a colonoscopy or an appendectomy, being documented."
      },
      {
        "Data Fields": "event.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "event.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "event.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "event.code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "This list of codes represents the main clinical acts, such as a colonoscopy or an appendectomy, being documented. In some cases, the event is inherent in the typeCode, such as a \"History and Physical Report\" in which the procedure being documented is necessarily a \"History and Physical\" act."
      },
      {
        "Data Fields": "event.period",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The period of time covered by the documentation. There is no assertion that the documentation is a complete representation for this period, only that it documents events during this time."
      },
      {
        "Data Fields": "event.detail",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The description and/or reference of the event(s) being documented. For example, this could be used to document such a colonoscopy or an appendectomy."
      },
      {
        "Data Fields": "section",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The root of the sections that make up the composition."
      },
      {
        "Data Fields": "section.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "section.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "section.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "section.title",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The label for this particular section.  This will be part of the rendered content for the document, and is often used to build a table of contents."
      },
      {
        "Data Fields": "section.code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code identifying the kind of content contained within the section. This must be consistent with the section title."
      },
      {
        "Data Fields": "section.author",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Identifies who is responsible for the information in this section, not necessarily who typed it in."
      },
      {
        "Data Fields": "section.focus",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The actual focus of the section when it is not the subject of the composition, but instead represents something or someone associated with the subject such as (for a patient subject) a spouse, parent, fetus, or donor. If not focus is specified, the focus is assumed to be focus of the parent section, or, for a section in the Composition itself, the subject of the composition. Sections with a focus SHALL only include resources where the logical subject (patient, subject, focus, etc.) matches the section focus, or the resources have no logical subject (few resources)."
      },
      {
        "Data Fields": "section.text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains the attested content of the section, used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative."
      },
      {
        "Data Fields": "section.mode",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "How the entry list was prepared - whether it is a working list that is suitable for being maintained on an ongoing basis, or if it represents a snapshot of a list of items from another source, or whether it is a prepared list where items may be marked as added, modified or deleted."
      },
      {
        "Data Fields": "section.orderedBy",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Specifies the order applied to the items in the section entries."
      },
      {
        "Data Fields": "section.entry",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A reference to the actual resource from which the narrative in the section is derived."
      },
      {
        "Data Fields": "section.emptyReason",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "If the section is empty, why the list is empty. An empty section typically has some text explaining the empty reason."
      }
    ],
    "ConceptMap": [
      {
        "Data Fields": "resourceType",
        "Type": "ConceptMap",
        
        
        "Required": "True",
        "Description": "This is a ConceptMap resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "url",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "An absolute URI that is used to identify this concept map when it is referenced in a specification, model, design or an instance; also called its canonical identifier. This SHOULD be globally unique and SHOULD be a literal address at which at which an authoritative instance of this concept map is (or will be) published. This URL can be the target of a canonical reference. It SHALL remain the same when the concept map is stored on different servers."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A formal identifier that is used to identify this concept map when it is represented in other formats, or referenced in a specification, model, design or an instance."
      },
      {
        "Data Fields": "version",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The identifier that is used to identify this version of the concept map when it is referenced in a specification, model, design or instance. This is an arbitrary value managed by the concept map author and is not expected to be globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if a managed version is not available. There is also no expectation that versions can be placed in a lexicographical sequence."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A natural language name identifying the concept map. This name should be usable as an identifier for the module by machine processing applications such as code generation."
      },
      {
        "Data Fields": "title",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A short, descriptive, user-friendly title for the concept map."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The status of this concept map. Enables tracking the life-cycle of the content."
      },
      {
        "Data Fields": "experimental",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "A Boolean value to indicate that this concept map is authored for testing purposes (or education/evaluation/marketing) and is not intended to be used for genuine usage."
      },
      {
        "Data Fields": "date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date  (and optionally time) when the concept map was published. The date must change when the business version changes and it must change if the status code changes. In addition, it should change when the substantive content of the concept map changes."
      },
      {
        "Data Fields": "publisher",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the organization or individual that published the concept map."
      },
      {
        "Data Fields": "contact",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "Contact details to assist a user in finding and communicating with the publisher."
      },
      {
        "Data Fields": "description",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A free text natural language description of the concept map from a consumer's perspective."
      },
      {
        "Data Fields": "useContext",
        "Type": "UsageContext",
        
        
        "Required": "False",
        "Description": "The content was developed with a focus and intent of supporting the contexts that are listed. These contexts may be general categories (gender, age, ...) or may be references to specific programs (insurance plans, studies, ...) and may be used to assist with indexing and searching for appropriate concept map instances."
      },
      {
        "Data Fields": "jurisdiction",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A legal or geographic region in which the concept map is intended to be used."
      },
      {
        "Data Fields": "purpose",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Explanation of why this concept map is needed and why it has been designed as it has."
      },
      {
        "Data Fields": "copyright",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A copyright statement relating to the concept map and/or its contents. Copyright statements are generally legal restrictions on the use and publishing of the concept map."
      },
      {
        "Data Fields": "sourceUri",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Identifier for the source value set that contains the concepts that are being mapped and provides context for the mappings."
      },
      {
        "Data Fields": "sourceCanonical",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Identifier for the source value set that contains the concepts that are being mapped and provides context for the mappings."
      },
      {
        "Data Fields": "targetUri",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The target value set provides context for the mappings. Note that the mapping is made between concepts, not between value sets, but the value set provides important context about how the concept mapping choices are made."
      },
      {
        "Data Fields": "targetCanonical",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The target value set provides context for the mappings. Note that the mapping is made between concepts, not between value sets, but the value set provides important context about how the concept mapping choices are made."
      },
      {
        "Data Fields": "group",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A group of mappings that all have the same source and target system."
      },
      {
        "Data Fields": "group.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "group.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "group.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "group.source",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "An absolute URI that identifies the source system where the concepts to be mapped are defined."
      },
      {
        "Data Fields": "group.sourceVersion",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The specific version of the code system, as determined by the code system authority."
      },
      {
        "Data Fields": "group.target",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "An absolute URI that identifies the target system that the concepts will be mapped to."
      },
      {
        "Data Fields": "group.targetVersion",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The specific version of the code system, as determined by the code system authority."
      },
      {
        "Data Fields": "group.element",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Mappings for an individual concept in the source to one or more concepts in the target."
      },
      {
        "Data Fields": "group.element.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "group.element.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "group.element.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "group.element.code",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Identity (code or path) or the element/item being mapped."
      },
      {
        "Data Fields": "group.element.display",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The display for the code. The display is only provided to help editors when editing the concept map."
      },
      {
        "Data Fields": "group.element.target",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A concept from the target value set that this concept maps to."
      },
      {
        "Data Fields": "group.element.target.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "group.element.target.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "group.element.target.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "group.element.target.code",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Identity (code or path) or the element/item that the map refers to."
      },
      {
        "Data Fields": "group.element.target.display",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The display for the code. The display is only provided to help editors when editing the concept map."
      },
      {
        "Data Fields": "group.element.target.equivalence",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The equivalence between the source and target concepts (counting for the dependencies and products). The equivalence is read from target to source (e.g. the target is 'wider' than the source)."
      },
      {
        "Data Fields": "group.element.target.comment",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A description of status/issues in mapping that conveys additional information not represented in  the structured data."
      },
      {
        "Data Fields": "group.element.target.dependsOn",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A set of additional dependencies for this mapping to hold. This mapping is only applicable if the specified element can be resolved, and it has the specified value."
      },
      {
        "Data Fields": "group.element.target.dependsOn.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "group.element.target.dependsOn.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "group.element.target.dependsOn.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "group.element.target.dependsOn.property",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to an element that holds a coded value that corresponds to a code system property. The idea is that the information model carries an element somewhere that is labeled to correspond with a code system property."
      },
      {
        "Data Fields": "group.element.target.dependsOn.system",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "An absolute URI that identifies the code system of the dependency code (if the source/dependency is a value set that crosses code systems)."
      },
      {
        "Data Fields": "group.element.target.dependsOn.value",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Identity (code or path) or the element/item/ValueSet/text that the map depends on / refers to."
      },
      {
        "Data Fields": "group.element.target.dependsOn.display",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The display for the code. The display is only provided to help editors when editing the concept map."
      },
      {
        "Data Fields": "group.element.target.product",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A set of additional outcomes from this mapping to other elements. To properly execute this mapping, the specified element must be mapped to some data element or source that is in context. The mapping may still be useful without a place for the additional data elements, but the equivalence cannot be relied on."
      },
      {
        "Data Fields": "group.element.target.product.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "group.element.target.product.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "group.element.target.product.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "group.element.target.product.property",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to an element that holds a coded value that corresponds to a code system property. The idea is that the information model carries an element somewhere that is labeled to correspond with a code system property."
      },
      {
        "Data Fields": "group.element.target.product.system",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "An absolute URI that identifies the code system of the dependency code (if the source/dependency is a value set that crosses code systems)."
      },
      {
        "Data Fields": "group.element.target.product.value",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Identity (code or path) or the element/item/ValueSet/text that the map depends on / refers to."
      },
      {
        "Data Fields": "group.element.target.product.display",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The display for the code. The display is only provided to help editors when editing the concept map."
      },
      {
        "Data Fields": "group.unmapped",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "What to do when there is no mapping for the source concept. \"Unmapped\" does not include codes that are unmatched, and the unmapped element is ignored in a code is specified to have equivalence = unmatched."
      },
      {
        "Data Fields": "group.unmapped.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "group.unmapped.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "group.unmapped.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "group.unmapped.mode",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Defines which action to take if there is no match for the source concept in the target system designated for the group. One of 3 actions are possible: use the unmapped code (this is useful when doing a mapping between versions, and only a few codes have changed), use a fixed code (a default code), or alternatively, a reference to a different concept map can be provided (by canonical URL)."
      },
      {
        "Data Fields": "group.unmapped.code",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The fixed code to use when the mode = 'fixed'  - all unmapped codes are mapped to a single fixed code."
      },
      {
        "Data Fields": "group.unmapped.display",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The display for the code. The display is only provided to help editors when editing the concept map."
      },
      {
        "Data Fields": "group.unmapped.url",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "The canonical reference to an additional ConceptMap resource instance to use for mapping if this ConceptMap resource contains no matching mapping for the source concept."
      }
    ],
    "Condition": [
      {
        "Data Fields": "resourceType",
        "Type": "Condition",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "This is a Condition resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Business identifiers assigned to this condition by the performer or other systems which remain constant as the resource is updated and propagates from server to server."
      },
      {
        "Data Fields": "clinicalStatus",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The clinical status of the condition."
      },
      {
        "Data Fields": "verificationStatus",
        "Type": "CodeableConcept",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The verification status to support the clinical status of the condition."
      },
      {
        "Data Fields": "category",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A category assigned to the condition."
      },
      {
        "Data Fields": "severity",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A subjective assessment of the severity of the condition as evaluated by the clinician."
      },
      {
        "Data Fields": "code",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Identification of the condition, problem or diagnosis."
      },
      {
        "Data Fields": "bodySite",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The anatomical location where this condition manifests itself."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "Indicates the patient or group who the condition record is associated with."
      },
      {
        "Data Fields": "encounter",
        "Type": "Reference",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The Encounter during which this Condition was created or to which the creation of this record is tightly associated."
      },
      {
        "Data Fields": "onsetDateTime",
        "Type": "string",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Estimated or actual date or date-time  the condition began, in the opinion of the clinician."
      },
      {
        "Data Fields": "onsetAge",
        "Type": "Age",
        
        
        "Required": "False",
        "Description": "Estimated or actual date or date-time  the condition began, in the opinion of the clinician."
      },
      {
        "Data Fields": "onsetPeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "Estimated or actual date or date-time  the condition began, in the opinion of the clinician."
      },
      {
        "Data Fields": "onsetRange",
        "Type": "Range",
        
        
        "Required": "False",
        "Description": "Estimated or actual date or date-time  the condition began, in the opinion of the clinician."
      },
      {
        "Data Fields": "onsetString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Estimated or actual date or date-time  the condition began, in the opinion of the clinician."
      },
      {
        "Data Fields": "abatementDateTime",
        "Type": "string",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The date or estimated date that the condition resolved or went into remission. This is called \"abatement\" because of the many overloaded connotations associated with \"remission\" or \"resolution\" - Conditions are never really resolved, but they can abate."
      },
      {
        "Data Fields": "abatementAge",
        "Type": "Age",
        
        
        "Required": "False",
        "Description": "The date or estimated date that the condition resolved or went into remission. This is called \"abatement\" because of the many overloaded connotations associated with \"remission\" or \"resolution\" - Conditions are never really resolved, but they can abate."
      },
      {
        "Data Fields": "abatementPeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The date or estimated date that the condition resolved or went into remission. This is called \"abatement\" because of the many overloaded connotations associated with \"remission\" or \"resolution\" - Conditions are never really resolved, but they can abate."
      },
      {
        "Data Fields": "abatementRange",
        "Type": "Range",
        
        
        "Required": "False",
        "Description": "The date or estimated date that the condition resolved or went into remission. This is called \"abatement\" because of the many overloaded connotations associated with \"remission\" or \"resolution\" - Conditions are never really resolved, but they can abate."
      },
      {
        "Data Fields": "abatementString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The date or estimated date that the condition resolved or went into remission. This is called \"abatement\" because of the many overloaded connotations associated with \"remission\" or \"resolution\" - Conditions are never really resolved, but they can abate."
      },
      {
        "Data Fields": "recordedDate",
        "Type": "dateTime",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The recordedDate represents when this particular Condition record was created in the system, which is often a system-generated date."
      },
      {
        "Data Fields": "recorder",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Individual who recorded the record and takes responsibility for its content."
      },
      {
        "Data Fields": "asserter",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Individual who is making the condition statement."
      },
      {
        "Data Fields": "stage",
        "Type": "BackboneElement",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Clinical stage or grade of a condition. May include formal severity assessments."
      },
      {
        "Data Fields": "stage.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "stage.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "stage.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "stage.summary",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A simple summary of the stage such as \"Stage 3\". The determination of the stage is disease-specific."
      },
      {
        "Data Fields": "stage.assessment",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Reference to a formal record of the evidence on which the staging assessment is based."
      },
      {
        "Data Fields": "stage.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The kind of staging, such as pathological or clinical staging."
      },
      {
        "Data Fields": "evidence",
        "Type": "BackboneElement",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Supporting evidence / manifestations that are the basis of the Condition's verification status, such as evidence that confirmed or refuted the condition."
      },
      {
        "Data Fields": "evidence.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "evidence.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "evidence.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "evidence.code",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A manifestation or symptom that led to the recording of this condition."
      },
      {
        "Data Fields": "evidence.detail",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Links to other relevant information, including pathology reports."
      },
      {
        "Data Fields": "note",
        "Type": "Annotation",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Additional information about the Condition. This is a general notes/comments entry  for description of the Condition, its diagnosis and prognosis."
      }
    ],
    "Consent": [
      {
        "Data Fields": "resourceType",
        "Type": "Consent",
        
        
        "Required": "True",
        "Description": "This is a Consent resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Unique identifier for this copy of the Consent Statement."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Indicates the current state of this consent."
      },
      {
        "Data Fields": "scope",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "True",
        "Description": "A selector of the type of consent being presented: ADR, Privacy, Treatment, Research.  This list is now extensible."
      },
      {
        "Data Fields": "category",
        "Type": "CodeableConcept",
        
        
        "Required": "True",
        "Description": "A classification of the type of consents found in the statement. This element supports indexing and retrieval of consent statements."
      },
      {
        "Data Fields": "patient",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The patient/healthcare consumer to whom this consent applies."
      },
      {
        "Data Fields": "dateTime",
        "Type": "dateTime",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "When this  Consent was issued / created / indexed."
      },
      {
        "Data Fields": "performer",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Either the Grantor, which is the entity responsible for granting the rights listed in a Consent Directive or the Grantee, which is the entity responsible for complying with the Consent Directive, including any obligations or limitations on authorizations and enforcement of prohibitions."
      },
      {
        "Data Fields": "organization",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The organization that manages the consent, and the framework within which it is executed."
      },
      {
        "Data Fields": "sourceAttachment",
        "Type": "Attachment",
        
        
        "Required": "False",
        "Description": "The source on which this consent statement is based. The source might be a scanned original paper form, or a reference to a consent that links back to such a source, a reference to a document repository (e.g. XDS) that stores the original consent document."
      },
      {
        "Data Fields": "sourceReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The source on which this consent statement is based. The source might be a scanned original paper form, or a reference to a consent that links back to such a source, a reference to a document repository (e.g. XDS) that stores the original consent document."
      },
      {
        "Data Fields": "policy",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The references to the policies that are included in this consent scope. Policies may be organizational, but are often defined jurisdictionally, or in law."
      },
      {
        "Data Fields": "policy.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "policy.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "policy.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "policy.authority",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "Entity or Organization having regulatory jurisdiction or accountability for  enforcing policies pertaining to Consent Directives."
      },
      {
        "Data Fields": "policy.uri",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "The references to the policies that are included in this consent scope. Policies may be organizational, but are often defined jurisdictionally, or in law."
      },
      {
        "Data Fields": "policyRule",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A reference to the specific base computable regulation or policy."
      },
      {
        "Data Fields": "verification",
        "Type": "BackboneElement",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Whether a treatment instruction (e.g. artificial respiration yes or no) was verified with the patient, his/her family or another authorized person."
      },
      {
        "Data Fields": "verification.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "verification.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "verification.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "verification.verified",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Has the instruction been verified."
      },
      {
        "Data Fields": "verification.verifiedWith",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Who verified the instruction (Patient, Relative or other Authorized Person)."
      },
      {
        "Data Fields": "verification.verificationDate",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "Date verification was collected."
      },
      {
        "Data Fields": "provision",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "An exception to the base policy of this consent. An exception can be an addition or removal of access permissions."
      },
      {
        "Data Fields": "provision.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "provision.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "provision.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "provision.type",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Action  to take - permit or deny - when the rule conditions are met.  Not permitted in root rule, required in all nested rules."
      },
      {
        "Data Fields": "provision.period",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The timeframe in this rule is valid."
      },
      {
        "Data Fields": "provision.actor",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Who or what is controlled by this rule. Use group to identify a set of actors by some property they share (e.g. 'admitting officers')."
      },
      {
        "Data Fields": "provision.actor.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "provision.actor.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "provision.actor.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "provision.actor.role",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "How the individual is involved in the resources content that is described in the exception."
      },
      {
        "Data Fields": "provision.actor.reference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The resource that identifies the actor. To identify actors by type, use group to identify a set of actors by some property they share (e.g. 'admitting officers')."
      },
      {
        "Data Fields": "provision.action",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Actions controlled by this Rule."
      },
      {
        "Data Fields": "provision.securityLabel",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "A security label, comprised of 0..* security label fields (Privacy tags), which define which resources are controlled by this exception."
      },
      {
        "Data Fields": "provision.purpose",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "The context of the activities a user is taking - why the user is accessing the data - that are controlled by this rule."
      },
      {
        "Data Fields": "provision.class",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "The class of information covered by this rule. The type can be a FHIR resource type, a profile on a type, or a CDA document, or some other type that indicates what sort of information the consent relates to."
      },
      {
        "Data Fields": "provision.code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "If this code is found in an instance, then the rule applies."
      },
      {
        "Data Fields": "provision.dataPeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "Clinical or Operational Relevant period of time that bounds the data controlled by this rule."
      },
      {
        "Data Fields": "provision.data",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The resources controlled by this rule if specific resources are referenced."
      },
      {
        "Data Fields": "provision.data.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "provision.data.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "provision.data.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "provision.data.meaning",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "How the resource reference is interpreted when testing consent restrictions."
      },
      {
        "Data Fields": "provision.data.reference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A reference to a specific resource that defines which resources are covered by this consent."
      }
    ],
    "Contract": [
      {
        "Data Fields": "resourceType",
        "Type": "Contract",
        
        
        "Required": "True",
        "Description": "This is a Contract resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Unique identifier for this Contract or a derivative that references a Source Contract."
      },
      {
        "Data Fields": "url",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "Canonical identifier for this contract, represented as a URI (globally unique)."
      },
      {
        "Data Fields": "version",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "An edition identifier used for business purposes to label business significant variants."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The status of the resource instance."
      },
      {
        "Data Fields": "legalState",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Legal states of the formation of a legal instrument, which is a formally executed written document that can be formally attributed to its author, records and formally expresses a legally enforceable act, process, or contractual duty, obligation, or right, and therefore evidences that act, process, or agreement."
      },
      {
        "Data Fields": "instantiatesCanonical",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The URL pointing to a FHIR-defined Contract Definition that is adhered to in whole or part by this Contract."
      },
      {
        "Data Fields": "instantiatesUri",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "The URL pointing to an externally maintained definition that is adhered to in whole or in part by this Contract."
      },
      {
        "Data Fields": "contentDerivative",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The minimal content derived from the basal information source at a specific stage in its lifecycle."
      },
      {
        "Data Fields": "issued",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "When this  Contract was issued."
      },
      {
        "Data Fields": "applies",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "Relevant time or time-period when this Contract is applicable."
      },
      {
        "Data Fields": "expirationType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Event resulting in discontinuation or termination of this Contract instance by one or more parties to the contract."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The target entity impacted by or of interest to parties to the agreement."
      },
      {
        "Data Fields": "authority",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A formally or informally recognized grouping of people, principals, organizations, or jurisdictions formed for the purpose of achieving some form of collective action such as the promulgation, administration and enforcement of contracts and policies."
      },
      {
        "Data Fields": "domain",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Recognized governance framework or system operating with a circumscribed scope in accordance with specified principles, policies, processes or procedures for managing rights, actions, or behaviors of parties or principals relative to resources."
      },
      {
        "Data Fields": "site",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Sites in which the contract is complied with,  exercised, or in force."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A natural language name identifying this Contract definition, derivative, or instance in any legal state. Provides additional information about its content. This name should be usable as an identifier for the module by machine processing applications such as code generation."
      },
      {
        "Data Fields": "title",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A short, descriptive, user-friendly title for this Contract definition, derivative, or instance in any legal state.t giving additional information about its content."
      },
      {
        "Data Fields": "subtitle",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "An explanatory or alternate user-friendly title for this Contract definition, derivative, or instance in any legal state.t giving additional information about its content."
      },
      {
        "Data Fields": "alias",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Alternative representation of the title for this Contract definition, derivative, or instance in any legal state., e.g., a domain specific contract number related to legislation."
      },
      {
        "Data Fields": "author",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The individual or organization that authored the Contract definition, derivative, or instance in any legal state."
      },
      {
        "Data Fields": "scope",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A selector of legal concerns for this Contract definition, derivative, or instance in any legal state."
      },
      {
        "Data Fields": "topicCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Narrows the range of legal concerns to focus on the achievement of specific contractual objectives."
      },
      {
        "Data Fields": "topicReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Narrows the range of legal concerns to focus on the achievement of specific contractual objectives."
      },
      {
        "Data Fields": "type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A high-level category for the legal instrument, whether constructed as a Contract definition, derivative, or instance in any legal state.  Provides additional information about its content within the context of the Contract's scope to distinguish the kinds of systems that would be interested in the contract."
      },
      {
        "Data Fields": "subType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Sub-category for the Contract that distinguishes the kinds of systems that would be interested in the Contract within the context of the Contract's scope."
      },
      {
        "Data Fields": "contentDefinition",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Precusory content developed with a focus and intent of supporting the formation a Contract instance, which may be associated with and transformable into a Contract."
      },
      {
        "Data Fields": "contentDefinition.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "contentDefinition.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "contentDefinition.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "contentDefinition.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Precusory content structure and use, i.e., a boilerplate, template, application for a contract such as an insurance policy or benefits under a program, e.g., workers compensation."
      },
      {
        "Data Fields": "contentDefinition.subType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Detailed Precusory content type."
      },
      {
        "Data Fields": "contentDefinition.publisher",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The  individual or organization that published the Contract precursor content."
      },
      {
        "Data Fields": "contentDefinition.publicationDate",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date (and optionally time) when the contract was published. The date must change when the business version changes and it must change if the status code changes. In addition, it should change when the substantive content of the contract changes."
      },
      {
        "Data Fields": "contentDefinition.publicationStatus",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "amended | appended | cancelled | disputed | entered-in-error | executable | executed | negotiable | offered | policy | rejected | renewed | revoked | resolved | terminated."
      },
      {
        "Data Fields": "contentDefinition.copyright",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A copyright statement relating to Contract precursor content. Copyright statements are generally legal restrictions on the use and publishing of the Contract precursor content."
      },
      {
        "Data Fields": "term",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "One or more Contract Provisions, which may be related and conveyed as a group, and may contain nested groups."
      },
      {
        "Data Fields": "term.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "term.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "term.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "term.identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Unique identifier for this particular Contract Provision."
      },
      {
        "Data Fields": "term.issued",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "When this Contract Provision was issued."
      },
      {
        "Data Fields": "term.applies",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "Relevant time or time-period when this Contract Provision is applicable."
      },
      {
        "Data Fields": "term.topicCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The entity that the term applies to."
      },
      {
        "Data Fields": "term.topicReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The entity that the term applies to."
      },
      {
        "Data Fields": "term.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A legal clause or condition contained within a contract that requires one or both parties to perform a particular requirement by some specified time or prevents one or both parties from performing a particular requirement by some specified time."
      },
      {
        "Data Fields": "term.subType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A specialized legal clause or condition based on overarching contract type."
      },
      {
        "Data Fields": "term.text",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Statement of a provision in a policy or a contract."
      },
      {
        "Data Fields": "term.securityLabel",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Security labels that protect the handling of information about the term and its elements, which may be specifically identified.."
      },
      {
        "Data Fields": "term.securityLabel.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "term.securityLabel.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "term.securityLabel.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "term.securityLabel.number",
        "Type": "unsignedInt",
        
        
        "Required": "False",
        "Description": "Number used to link this term or term element to the applicable Security Label."
      },
      {
        "Data Fields": "term.securityLabel.classification",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "Security label privacy tag that species the level of confidentiality protection required for this term and/or term elements."
      },
      {
        "Data Fields": "term.securityLabel.category",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "Security label privacy tag that species the applicable privacy and security policies governing this term and/or term elements."
      },
      {
        "Data Fields": "term.securityLabel.control",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "Security label privacy tag that species the manner in which term and/or term elements are to be protected."
      },
      {
        "Data Fields": "term.offer",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The matter of concern in the context of this provision of the agrement."
      },
      {
        "Data Fields": "term.offer.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "term.offer.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "term.offer.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "term.offer.identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Unique identifier for this particular Contract Provision."
      },
      {
        "Data Fields": "term.offer.party",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Offer Recipient."
      },
      {
        "Data Fields": "term.offer.party.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "term.offer.party.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "term.offer.party.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "term.offer.party.reference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Participant in the offer."
      },
      {
        "Data Fields": "term.offer.party.role",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "How the party participates in the offer."
      },
      {
        "Data Fields": "term.offer.topic",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The owner of an asset has the residual control rights over the asset: the right to decide all usages of the asset in any way not inconsistent with a prior contract, custom, or law (Hart, 1995, p. 30)."
      },
      {
        "Data Fields": "term.offer.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Type of Contract Provision such as specific requirements, purposes for actions, obligations, prohibitions, e.g. life time maximum benefit."
      },
      {
        "Data Fields": "term.offer.decision",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Type of choice made by accepting party with respect to an offer made by an offeror/ grantee."
      },
      {
        "Data Fields": "term.offer.decisionMode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "How the decision about a Contract was conveyed."
      },
      {
        "Data Fields": "term.offer.answer",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Response to offer text."
      },
      {
        "Data Fields": "term.offer.answer.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "term.offer.answer.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "term.offer.answer.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "term.offer.answer.valueBoolean",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.offer.answer.valueDecimal",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.offer.answer.valueInteger",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.offer.answer.valueDate",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.offer.answer.valueDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.offer.answer.valueTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.offer.answer.valueString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.offer.answer.valueUri",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.offer.answer.valueAttachment",
        "Type": "Attachment",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.offer.answer.valueCoding",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.offer.answer.valueQuantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.offer.answer.valueReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.offer.text",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Human readable form of this Contract Offer."
      },
      {
        "Data Fields": "term.offer.linkId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The id of the clause or question text of the offer in the referenced questionnaire/response."
      },
      {
        "Data Fields": "term.offer.securityLabelNumber",
        "Type": "unsignedInt",
        
        
        "Required": "False",
        "Description": "Security labels that protects the offer."
      },
      {
        "Data Fields": "term.asset",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Contract Term Asset List."
      },
      {
        "Data Fields": "term.asset.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "term.asset.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "term.asset.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "term.asset.scope",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Differentiates the kind of the asset ."
      },
      {
        "Data Fields": "term.asset.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Target entity type about which the term may be concerned."
      },
      {
        "Data Fields": "term.asset.typeReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Associated entities."
      },
      {
        "Data Fields": "term.asset.subtype",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "May be a subtype or part of an offered asset."
      },
      {
        "Data Fields": "term.asset.relationship",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "Specifies the applicability of the term to an asset resource instance, and instances it refers to orinstances that refer to it, and/or are owned by the offeree."
      },
      {
        "Data Fields": "term.asset.context",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Circumstance of the asset."
      },
      {
        "Data Fields": "term.asset.context.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "term.asset.context.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "term.asset.context.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "term.asset.context.reference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Asset context reference may include the creator, custodian, or owning Person or Organization (e.g., bank, repository),  location held, e.g., building,  jurisdiction."
      },
      {
        "Data Fields": "term.asset.context.code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Coded representation of the context generally or of the Referenced entity, such as the asset holder type or location."
      },
      {
        "Data Fields": "term.asset.context.text",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Context description."
      },
      {
        "Data Fields": "term.asset.condition",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Description of the quality and completeness of the asset that imay be a factor in its valuation."
      },
      {
        "Data Fields": "term.asset.periodType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Type of Asset availability for use or ownership."
      },
      {
        "Data Fields": "term.asset.period",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "Asset relevant contractual time period."
      },
      {
        "Data Fields": "term.asset.usePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "Time period of asset use."
      },
      {
        "Data Fields": "term.asset.text",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Clause or question text (Prose Object) concerning the asset in a linked form, such as a QuestionnaireResponse used in the formation of the contract."
      },
      {
        "Data Fields": "term.asset.linkId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Id [identifier??] of the clause or question text about the asset in the referenced form or QuestionnaireResponse."
      },
      {
        "Data Fields": "term.asset.answer",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Response to assets."
      },
      {
        "Data Fields": "term.asset.answer.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "term.asset.answer.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "term.asset.answer.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "term.asset.answer.valueBoolean",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.asset.answer.valueDecimal",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.asset.answer.valueInteger",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.asset.answer.valueDate",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.asset.answer.valueDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.asset.answer.valueTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.asset.answer.valueString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.asset.answer.valueUri",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.asset.answer.valueAttachment",
        "Type": "Attachment",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.asset.answer.valueCoding",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.asset.answer.valueQuantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.asset.answer.valueReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.asset.securityLabelNumber",
        "Type": "unsignedInt",
        
        
        "Required": "False",
        "Description": "Security labels that protects the asset."
      },
      {
        "Data Fields": "term.asset.valuedItem",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Contract Valued Item List."
      },
      {
        "Data Fields": "term.asset.valuedItem.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "term.asset.valuedItem.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "term.asset.valuedItem.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "term.asset.valuedItem.entityCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Specific type of Contract Valued Item that may be priced."
      },
      {
        "Data Fields": "term.asset.valuedItem.entityReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Specific type of Contract Valued Item that may be priced."
      },
      {
        "Data Fields": "term.asset.valuedItem.identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Identifies a Contract Valued Item instance."
      },
      {
        "Data Fields": "term.asset.valuedItem.effectiveTime",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "Indicates the time during which this Contract ValuedItem information is effective."
      },
      {
        "Data Fields": "term.asset.valuedItem.quantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "Specifies the units by which the Contract Valued Item is measured or counted, and quantifies the countable or measurable Contract Valued Item instances."
      },
      {
        "Data Fields": "term.asset.valuedItem.unitPrice",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "A Contract Valued Item unit valuation measure."
      },
      {
        "Data Fields": "term.asset.valuedItem.factor",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "A real number that represents a multiplier used in determining the overall value of the Contract Valued Item delivered. The concept of a Factor allows for a discount or surcharge multiplier to be applied to a monetary amount."
      },
      {
        "Data Fields": "term.asset.valuedItem.points",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "An amount that expresses the weighting (based on difficulty, cost and/or resource intensiveness) associated with the Contract Valued Item delivered. The concept of Points allows for assignment of point values for a Contract Valued Item, such that a monetary amount can be assigned to each point."
      },
      {
        "Data Fields": "term.asset.valuedItem.net",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "Expresses the product of the Contract Valued Item unitQuantity and the unitPriceAmt. For example, the formula: unit Quantity * unit Price (Cost per Point) * factor Number  * points = net Amount. Quantity, factor and points are assumed to be 1 if not supplied."
      },
      {
        "Data Fields": "term.asset.valuedItem.payment",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Terms of valuation."
      },
      {
        "Data Fields": "term.asset.valuedItem.paymentDate",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "When payment is due."
      },
      {
        "Data Fields": "term.asset.valuedItem.responsible",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Who will make payment."
      },
      {
        "Data Fields": "term.asset.valuedItem.recipient",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Who will receive payment."
      },
      {
        "Data Fields": "term.asset.valuedItem.linkId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Id  of the clause or question text related to the context of this valuedItem in the referenced form or QuestionnaireResponse."
      },
      {
        "Data Fields": "term.asset.valuedItem.securityLabelNumber",
        "Type": "unsignedInt",
        
        
        "Required": "False",
        "Description": "A set of security labels that define which terms are controlled by this condition."
      },
      {
        "Data Fields": "term.action",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "An actor taking a role in an activity for which it can be assigned some degree of responsibility for the activity taking place."
      },
      {
        "Data Fields": "term.action.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "term.action.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "term.action.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "term.action.doNotPerform",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "True if the term prohibits the  action."
      },
      {
        "Data Fields": "term.action.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Activity or service obligation to be done or not done, performed or not performed, effectuated or not by this Contract term."
      },
      {
        "Data Fields": "term.action.subject",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Entity of the action."
      },
      {
        "Data Fields": "term.action.subject.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "term.action.subject.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "term.action.subject.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "term.action.subject.reference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The entity the action is performed or not performed on or for."
      },
      {
        "Data Fields": "term.action.subject.role",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Role type of agent assigned roles in this Contract."
      },
      {
        "Data Fields": "term.action.intent",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Reason or purpose for the action stipulated by this Contract Provision."
      },
      {
        "Data Fields": "term.action.linkId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Id [identifier??] of the clause or question text related to this action in the referenced form or QuestionnaireResponse."
      },
      {
        "Data Fields": "term.action.status",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Current state of the term action."
      },
      {
        "Data Fields": "term.action.context",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Encounter or Episode with primary association to specified term activity."
      },
      {
        "Data Fields": "term.action.contextLinkId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Id [identifier??] of the clause or question text related to the requester of this action in the referenced form or QuestionnaireResponse."
      },
      {
        "Data Fields": "term.action.occurrenceDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "When action happens."
      },
      {
        "Data Fields": "term.action.occurrencePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "When action happens."
      },
      {
        "Data Fields": "term.action.occurrenceTiming",
        "Type": "Timing",
        
        
        "Required": "False",
        "Description": "When action happens."
      },
      {
        "Data Fields": "term.action.requester",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Who or what initiated the action and has responsibility for its activation."
      },
      {
        "Data Fields": "term.action.requesterLinkId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Id [identifier??] of the clause or question text related to the requester of this action in the referenced form or QuestionnaireResponse."
      },
      {
        "Data Fields": "term.action.performerType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The type of individual that is desired or required to perform or not perform the action."
      },
      {
        "Data Fields": "term.action.performerRole",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The type of role or competency of an individual desired or required to perform or not perform the action."
      },
      {
        "Data Fields": "term.action.performer",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Indicates who or what is being asked to perform (or not perform) the ction."
      },
      {
        "Data Fields": "term.action.performerLinkId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Id [identifier??] of the clause or question text related to the reason type or reference of this  action in the referenced form or QuestionnaireResponse."
      },
      {
        "Data Fields": "term.action.reasonCode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Rationale for the action to be performed or not performed. Describes why the action is permitted or prohibited."
      },
      {
        "Data Fields": "term.action.reasonReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Indicates another resource whose existence justifies permitting or not permitting this action."
      },
      {
        "Data Fields": "term.action.reason",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Describes why the action is to be performed or not performed in textual form."
      },
      {
        "Data Fields": "term.action.reasonLinkId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Id [identifier??] of the clause or question text related to the reason type or reference of this  action in the referenced form or QuestionnaireResponse."
      },
      {
        "Data Fields": "term.action.note",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "Comments made about the term action made by the requester, performer, subject or other participants."
      },
      {
        "Data Fields": "term.action.securityLabelNumber",
        "Type": "unsignedInt",
        
        
        "Required": "False",
        "Description": "Security labels that protects the action."
      },
      {
        "Data Fields": "term.group",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Nested group of Contract Provisions."
      },
      {
        "Data Fields": "term.group.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "term.group.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "term.group.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "term.group.identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Unique identifier for this particular Contract Provision."
      },
      {
        "Data Fields": "term.group.issued",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "When this Contract Provision was issued."
      },
      {
        "Data Fields": "term.group.applies",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "Relevant time or time-period when this Contract Provision is applicable."
      },
      {
        "Data Fields": "term.group.topicCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The entity that the term applies to."
      },
      {
        "Data Fields": "term.group.topicReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The entity that the term applies to."
      },
      {
        "Data Fields": "term.group.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A legal clause or condition contained within a contract that requires one or both parties to perform a particular requirement by some specified time or prevents one or both parties from performing a particular requirement by some specified time."
      },
      {
        "Data Fields": "term.group.subType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A specialized legal clause or condition based on overarching contract type."
      },
      {
        "Data Fields": "term.group.text",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Statement of a provision in a policy or a contract."
      },
      {
        "Data Fields": "term.group.securityLabel",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Security labels that protect the handling of information about the term and its elements, which may be specifically identified.."
      },
      {
        "Data Fields": "term.group.securityLabel.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "term.group.securityLabel.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "term.group.securityLabel.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "term.group.securityLabel.number",
        "Type": "unsignedInt",
        
        
        "Required": "False",
        "Description": "Number used to link this term or term element to the applicable Security Label."
      },
      {
        "Data Fields": "term.group.securityLabel.classification",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "Security label privacy tag that species the level of confidentiality protection required for this term and/or term elements."
      },
      {
        "Data Fields": "term.group.securityLabel.category",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "Security label privacy tag that species the applicable privacy and security policies governing this term and/or term elements."
      },
      {
        "Data Fields": "term.group.securityLabel.control",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "Security label privacy tag that species the manner in which term and/or term elements are to be protected."
      },
      {
        "Data Fields": "term.group.offer",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The matter of concern in the context of this provision of the agrement."
      },
      {
        "Data Fields": "term.group.offer.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "term.group.offer.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "term.group.offer.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "term.group.offer.identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Unique identifier for this particular Contract Provision."
      },
      {
        "Data Fields": "term.group.offer.party",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Offer Recipient."
      },
      {
        "Data Fields": "term.group.offer.party.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "term.group.offer.party.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "term.group.offer.party.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "term.group.offer.party.reference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Participant in the offer."
      },
      {
        "Data Fields": "term.group.offer.party.role",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "How the party participates in the offer."
      },
      {
        "Data Fields": "term.group.offer.topic",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The owner of an asset has the residual control rights over the asset: the right to decide all usages of the asset in any way not inconsistent with a prior contract, custom, or law (Hart, 1995, p. 30)."
      },
      {
        "Data Fields": "term.group.offer.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Type of Contract Provision such as specific requirements, purposes for actions, obligations, prohibitions, e.g. life time maximum benefit."
      },
      {
        "Data Fields": "term.group.offer.decision",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Type of choice made by accepting party with respect to an offer made by an offeror/ grantee."
      },
      {
        "Data Fields": "term.group.offer.decisionMode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "How the decision about a Contract was conveyed."
      },
      {
        "Data Fields": "term.group.offer.answer",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Response to offer text."
      },
      {
        "Data Fields": "term.group.offer.answer.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "term.group.offer.answer.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "term.group.offer.answer.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "term.group.offer.answer.valueBoolean",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.group.offer.answer.valueDecimal",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.group.offer.answer.valueInteger",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.group.offer.answer.valueDate",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.group.offer.answer.valueDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.group.offer.answer.valueTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.group.offer.answer.valueString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.group.offer.answer.valueUri",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.group.offer.answer.valueAttachment",
        "Type": "Attachment",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.group.offer.answer.valueCoding",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.group.offer.answer.valueQuantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.group.offer.answer.valueReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.group.offer.text",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Human readable form of this Contract Offer."
      },
      {
        "Data Fields": "term.group.offer.linkId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The id of the clause or question text of the offer in the referenced questionnaire/response."
      },
      {
        "Data Fields": "term.group.offer.securityLabelNumber",
        "Type": "unsignedInt",
        
        
        "Required": "False",
        "Description": "Security labels that protects the offer."
      },
      {
        "Data Fields": "term.group.asset",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Contract Term Asset List."
      },
      {
        "Data Fields": "term.group.asset.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "term.group.asset.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "term.group.asset.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "term.group.asset.scope",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Differentiates the kind of the asset ."
      },
      {
        "Data Fields": "term.group.asset.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Target entity type about which the term may be concerned."
      },
      {
        "Data Fields": "term.group.asset.typeReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Associated entities."
      },
      {
        "Data Fields": "term.group.asset.subtype",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "May be a subtype or part of an offered asset."
      },
      {
        "Data Fields": "term.group.asset.relationship",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "Specifies the applicability of the term to an asset resource instance, and instances it refers to orinstances that refer to it, and/or are owned by the offeree."
      },
      {
        "Data Fields": "term.group.asset.context",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Circumstance of the asset."
      },
      {
        "Data Fields": "term.group.asset.context.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "term.group.asset.context.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "term.group.asset.context.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "term.group.asset.context.reference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Asset context reference may include the creator, custodian, or owning Person or Organization (e.g., bank, repository),  location held, e.g., building,  jurisdiction."
      },
      {
        "Data Fields": "term.group.asset.context.code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Coded representation of the context generally or of the Referenced entity, such as the asset holder type or location."
      },
      {
        "Data Fields": "term.group.asset.context.text",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Context description."
      },
      {
        "Data Fields": "term.group.asset.condition",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Description of the quality and completeness of the asset that imay be a factor in its valuation."
      },
      {
        "Data Fields": "term.group.asset.periodType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Type of Asset availability for use or ownership."
      },
      {
        "Data Fields": "term.group.asset.period",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "Asset relevant contractual time period."
      },
      {
        "Data Fields": "term.group.asset.usePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "Time period of asset use."
      },
      {
        "Data Fields": "term.group.asset.text",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Clause or question text (Prose Object) concerning the asset in a linked form, such as a QuestionnaireResponse used in the formation of the contract."
      },
      {
        "Data Fields": "term.group.asset.linkId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Id [identifier??] of the clause or question text about the asset in the referenced form or QuestionnaireResponse."
      },
      {
        "Data Fields": "term.group.asset.answer",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Response to assets."
      },
      {
        "Data Fields": "term.group.asset.answer.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "term.group.asset.answer.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "term.group.asset.answer.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "term.group.asset.answer.valueBoolean",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.group.asset.answer.valueDecimal",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.group.asset.answer.valueInteger",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.group.asset.answer.valueDate",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.group.asset.answer.valueDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.group.asset.answer.valueTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.group.asset.answer.valueString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.group.asset.answer.valueUri",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.group.asset.answer.valueAttachment",
        "Type": "Attachment",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.group.asset.answer.valueCoding",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.group.asset.answer.valueQuantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.group.asset.answer.valueReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Response to an offer clause or question text,  which enables selection of values to be agreed to, e.g., the period of participation, the date of occupancy of a rental, warrently duration, or whether biospecimen may be used for further research."
      },
      {
        "Data Fields": "term.group.asset.securityLabelNumber",
        "Type": "unsignedInt",
        
        
        "Required": "False",
        "Description": "Security labels that protects the asset."
      },
      {
        "Data Fields": "term.group.asset.valuedItem",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Contract Valued Item List."
      },
      {
        "Data Fields": "term.group.asset.valuedItem.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "term.group.asset.valuedItem.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "term.group.asset.valuedItem.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "term.group.asset.valuedItem.entityCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Specific type of Contract Valued Item that may be priced."
      },
      {
        "Data Fields": "term.group.asset.valuedItem.entityReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Specific type of Contract Valued Item that may be priced."
      },
      {
        "Data Fields": "term.group.asset.valuedItem.identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Identifies a Contract Valued Item instance."
      },
      {
        "Data Fields": "term.group.asset.valuedItem.effectiveTime",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "Indicates the time during which this Contract ValuedItem information is effective."
      },
      {
        "Data Fields": "term.group.asset.valuedItem.quantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "Specifies the units by which the Contract Valued Item is measured or counted, and quantifies the countable or measurable Contract Valued Item instances."
      },
      {
        "Data Fields": "term.group.asset.valuedItem.unitPrice",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "A Contract Valued Item unit valuation measure."
      },
      {
        "Data Fields": "term.group.asset.valuedItem.factor",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "A real number that represents a multiplier used in determining the overall value of the Contract Valued Item delivered. The concept of a Factor allows for a discount or surcharge multiplier to be applied to a monetary amount."
      },
      {
        "Data Fields": "term.group.asset.valuedItem.points",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "An amount that expresses the weighting (based on difficulty, cost and/or resource intensiveness) associated with the Contract Valued Item delivered. The concept of Points allows for assignment of point values for a Contract Valued Item, such that a monetary amount can be assigned to each point."
      },
      {
        "Data Fields": "term.group.asset.valuedItem.net",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "Expresses the product of the Contract Valued Item unitQuantity and the unitPriceAmt. For example, the formula: unit Quantity * unit Price (Cost per Point) * factor Number  * points = net Amount. Quantity, factor and points are assumed to be 1 if not supplied."
      },
      {
        "Data Fields": "term.group.asset.valuedItem.payment",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Terms of valuation."
      },
      {
        "Data Fields": "term.group.asset.valuedItem.paymentDate",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "When payment is due."
      },
      {
        "Data Fields": "term.group.asset.valuedItem.responsible",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Who will make payment."
      },
      {
        "Data Fields": "term.group.asset.valuedItem.recipient",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Who will receive payment."
      },
      {
        "Data Fields": "term.group.asset.valuedItem.linkId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Id  of the clause or question text related to the context of this valuedItem in the referenced form or QuestionnaireResponse."
      },
      {
        "Data Fields": "term.group.asset.valuedItem.securityLabelNumber",
        "Type": "unsignedInt",
        
        
        "Required": "False",
        "Description": "A set of security labels that define which terms are controlled by this condition."
      },
      {
        "Data Fields": "term.group.action",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "An actor taking a role in an activity for which it can be assigned some degree of responsibility for the activity taking place."
      },
      {
        "Data Fields": "term.group.action.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "term.group.action.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "term.group.action.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "term.group.action.doNotPerform",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "True if the term prohibits the  action."
      },
      {
        "Data Fields": "term.group.action.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Activity or service obligation to be done or not done, performed or not performed, effectuated or not by this Contract term."
      },
      {
        "Data Fields": "term.group.action.subject",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Entity of the action."
      },
      {
        "Data Fields": "term.group.action.subject.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "term.group.action.subject.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "term.group.action.subject.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "term.group.action.subject.reference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The entity the action is performed or not performed on or for."
      },
      {
        "Data Fields": "term.group.action.subject.role",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Role type of agent assigned roles in this Contract."
      },
      {
        "Data Fields": "term.group.action.intent",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Reason or purpose for the action stipulated by this Contract Provision."
      },
      {
        "Data Fields": "term.group.action.linkId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Id [identifier??] of the clause or question text related to this action in the referenced form or QuestionnaireResponse."
      },
      {
        "Data Fields": "term.group.action.status",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Current state of the term action."
      },
      {
        "Data Fields": "term.group.action.context",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Encounter or Episode with primary association to specified term activity."
      },
      {
        "Data Fields": "term.group.action.contextLinkId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Id [identifier??] of the clause or question text related to the requester of this action in the referenced form or QuestionnaireResponse."
      },
      {
        "Data Fields": "term.group.action.occurrenceDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "When action happens."
      },
      {
        "Data Fields": "term.group.action.occurrencePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "When action happens."
      },
      {
        "Data Fields": "term.group.action.occurrenceTiming",
        "Type": "Timing",
        
        
        "Required": "False",
        "Description": "When action happens."
      },
      {
        "Data Fields": "term.group.action.requester",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Who or what initiated the action and has responsibility for its activation."
      },
      {
        "Data Fields": "term.group.action.requesterLinkId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Id [identifier??] of the clause or question text related to the requester of this action in the referenced form or QuestionnaireResponse."
      },
      {
        "Data Fields": "term.group.action.performerType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The type of individual that is desired or required to perform or not perform the action."
      },
      {
        "Data Fields": "term.group.action.performerRole",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The type of role or competency of an individual desired or required to perform or not perform the action."
      },
      {
        "Data Fields": "term.group.action.performer",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Indicates who or what is being asked to perform (or not perform) the ction."
      },
      {
        "Data Fields": "term.group.action.performerLinkId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Id [identifier??] of the clause or question text related to the reason type or reference of this  action in the referenced form or QuestionnaireResponse."
      },
      {
        "Data Fields": "term.group.action.reasonCode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Rationale for the action to be performed or not performed. Describes why the action is permitted or prohibited."
      },
      {
        "Data Fields": "term.group.action.reasonReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Indicates another resource whose existence justifies permitting or not permitting this action."
      },
      {
        "Data Fields": "term.group.action.reason",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Describes why the action is to be performed or not performed in textual form."
      },
      {
        "Data Fields": "term.group.action.reasonLinkId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Id [identifier??] of the clause or question text related to the reason type or reference of this  action in the referenced form or QuestionnaireResponse."
      },
      {
        "Data Fields": "term.group.action.note",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "Comments made about the term action made by the requester, performer, subject or other participants."
      },
      {
        "Data Fields": "term.group.action.securityLabelNumber",
        "Type": "unsignedInt",
        
        
        "Required": "False",
        "Description": "Security labels that protects the action."
      },
      {
        "Data Fields": "supportingInfo",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Information that may be needed by/relevant to the performer in their execution of this term action."
      },
      {
        "Data Fields": "relevantHistory",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Links to Provenance records for past versions of this Contract definition, derivative, or instance, which identify key state transitions or updates that are likely to be relevant to a user looking at the current version of the Contract.  The Provence.entity indicates the target that was changed in the update. http://build.fhir.org/provenance-definitions.html#Provenance.entity."
      },
      {
        "Data Fields": "signer",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Parties with legal standing in the Contract, including the principal parties, the grantor(s) and grantee(s), which are any person or organization bound by the contract, and any ancillary parties, which facilitate the execution of the contract such as a notary or witness."
      },
      {
        "Data Fields": "signer.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "signer.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "signer.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "signer.type",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "Role of this Contract signer, e.g. notary, grantee."
      },
      {
        "Data Fields": "signer.party",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Party which is a signator to this Contract."
      },
      {
        "Data Fields": "signer.signature",
        "Type": "Signature",
        
        
        "Required": "False",
        "Description": "Legally binding Contract DSIG signature contents in Base64."
      },
      {
        "Data Fields": "friendly",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The \"patient friendly language\" versionof the Contract in whole or in parts. \"Patient friendly language\" means the representation of the Contract and Contract Provisions in a manner that is readily accessible and understandable by a layperson in accordance with best practices for communication styles that ensure that those agreeing to or signing the Contract understand the roles, actions, obligations, responsibilities, and implication of the agreement."
      },
      {
        "Data Fields": "friendly.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "friendly.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "friendly.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "friendly.contentAttachment",
        "Type": "Attachment",
        
        
        "Required": "False",
        "Description": "Human readable rendering of this Contract in a format and representation intended to enhance comprehension and ensure understandability."
      },
      {
        "Data Fields": "friendly.contentReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Human readable rendering of this Contract in a format and representation intended to enhance comprehension and ensure understandability."
      },
      {
        "Data Fields": "legal",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "List of Legal expressions or representations of this Contract."
      },
      {
        "Data Fields": "legal.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "legal.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "legal.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "legal.contentAttachment",
        "Type": "Attachment",
        
        
        "Required": "False",
        "Description": "Contract legal text in human renderable form."
      },
      {
        "Data Fields": "legal.contentReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Contract legal text in human renderable form."
      },
      {
        "Data Fields": "rule",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "List of Computable Policy Rule Language Representations of this Contract."
      },
      {
        "Data Fields": "rule.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "rule.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "rule.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "rule.contentAttachment",
        "Type": "Attachment",
        
        
        "Required": "False",
        "Description": "Computable Contract conveyed using a policy rule language (e.g. XACML, DKAL, SecPal)."
      },
      {
        "Data Fields": "rule.contentReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Computable Contract conveyed using a policy rule language (e.g. XACML, DKAL, SecPal)."
      },
      {
        "Data Fields": "legallyBindingAttachment",
        "Type": "Attachment",
        
        
        "Required": "False",
        "Description": "Legally binding Contract: This is the signed and legally recognized representation of the Contract, which is considered the \"source of truth\" and which would be the basis for legal action related to enforcement of this Contract."
      },
      {
        "Data Fields": "legallyBindingReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Legally binding Contract: This is the signed and legally recognized representation of the Contract, which is considered the \"source of truth\" and which would be the basis for legal action related to enforcement of this Contract."
      }
    ],
    "Coverage": [
      {
        "Data Fields": "resourceType",
        "Type": "Coverage",
        
        
        "Required": "True",
        "Description": "This is a Coverage resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A unique identifier assigned to this coverage."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The status of the resource instance."
      },
      {
        "Data Fields": "type",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The type of coverage: social program, medical plan, accident coverage (workers compensation, auto), group health or payment by an individual or organization."
      },
      {
        "Data Fields": "policyHolder",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The party who 'owns' the insurance policy."
      },
      {
        "Data Fields": "subscriber",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The party who has signed-up for or 'owns' the contractual relationship to the policy or to whom the benefit of the policy for services rendered to them or their family is due."
      },
      {
        "Data Fields": "subscriberId",
        "Type": "string",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The insurer assigned ID for the Subscriber."
      },
      {
        "Data Fields": "beneficiary",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "True",
        "Description": "The party who benefits from the insurance coverage; the patient when products and/or services are provided."
      },
      {
        "Data Fields": "dependent",
        "Type": "string",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A unique identifier for a dependent under the coverage."
      },
      {
        "Data Fields": "relationship",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The relationship of beneficiary (patient) to the subscriber."
      },
      {
        "Data Fields": "period",
        "Type": "Period",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Time period during which the coverage is in force. A missing start date indicates the start date isn't known, a missing end date means the coverage is continuing to be in force."
      },
      {
        "Data Fields": "payor",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "True",
        "Description": "The program or plan underwriter or payor including both insurance and non-insurance agreements, such as patient-pay agreements."
      },
      {
        "Data Fields": "class",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A suite of underwriter specific classifiers."
      },
      {
        "Data Fields": "class.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "class.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "class.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "class.type",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The type of classification for which an insurer-specific class label or number and optional name is provided, for example may be used to identify a class of coverage or employer group, Policy, Plan."
      },
      {
        "Data Fields": "class.value",
        "Type": "string",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The alphanumeric string value associated with the insurer issued label."
      },
      {
        "Data Fields": "class.name",
        "Type": "string",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A short description for the class."
      },
      {
        "Data Fields": "order",
        "Type": "positiveInt",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The order of applicability of this coverage relative to other coverages which are currently in force. Note, there may be gaps in the numbering and this does not imply primary, secondary etc. as the specific positioning of coverages depends upon the episode of care."
      },
      {
        "Data Fields": "network",
        "Type": "string",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The insurer-specific identifier for the insurer-defined network of providers to which the beneficiary may seek treatment which will be covered at the 'in-network' rate, otherwise 'out of network' terms and conditions apply."
      },
      {
        "Data Fields": "costToBeneficiary",
        "Type": "BackboneElement",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A suite of codes indicating the cost category and associated amount which have been detailed in the policy and may have been  included on the health card."
      },
      {
        "Data Fields": "costToBeneficiary.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "costToBeneficiary.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "costToBeneficiary.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "costToBeneficiary.type",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The category of patient centric costs associated with treatment."
      },
      {
        "Data Fields": "costToBeneficiary.valueQuantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The amount due from the patient for the cost category."
      },
      {
        "Data Fields": "costToBeneficiary.valueMoney",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "The amount due from the patient for the cost category."
      },
      {
        "Data Fields": "costToBeneficiary.exception",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A suite of codes indicating exceptions or reductions to patient costs and their effective periods."
      },
      {
        "Data Fields": "costToBeneficiary.exception.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "costToBeneficiary.exception.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "costToBeneficiary.exception.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "costToBeneficiary.exception.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The code for the specific exception."
      },
      {
        "Data Fields": "costToBeneficiary.exception.period",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The timeframe during when the exception is in force."
      },
      {
        "Data Fields": "subrogation",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "When 'subrogation=true' this insurance instance has been included not for adjudication but to provide insurers with the details to recover costs."
      },
      {
        "Data Fields": "contract",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The policy(s) which constitute this insurance coverage."
      }
    ],
    "CoverageEligibilityRequest": [
      {
        "Data Fields": "resourceType",
        "Type": "CoverageEligibilityRequest",
        
        
        "Required": "True",
        "Description": "This is a CoverageEligibilityRequest resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A unique identifier assigned to this coverage eligiblity request."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The status of the resource instance."
      },
      {
        "Data Fields": "priority",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "When the requestor expects the processor to complete processing."
      },
      {
        "Data Fields": "purpose",
        "Type": "code",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Code to specify whether requesting: prior authorization requirements for some service categories or billing codes; benefits for coverages specified or discovered; discovery and return of coverages for the patient; and/or validation that the specified coverage is in-force at the date/period specified or 'now' if not specified."
      },
      {
        "Data Fields": "patient",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "True",
        "Description": "The party who is the beneficiary of the supplied coverage and for whom eligibility is sought."
      },
      {
        "Data Fields": "servicedDate",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The date or dates when the enclosed suite of services were performed or completed."
      },
      {
        "Data Fields": "servicedPeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The date or dates when the enclosed suite of services were performed or completed."
      },
      {
        "Data Fields": "created",
        "Type": "dateTime",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The date when this resource was created."
      },
      {
        "Data Fields": "enterer",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Person who created the request."
      },
      {
        "Data Fields": "provider",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The provider which is responsible for the request."
      },
      {
        "Data Fields": "insurer",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "True",
        "Description": "The Insurer who issued the coverage in question and is the recipient of the request."
      },
      {
        "Data Fields": "facility",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Facility where the services are intended to be provided."
      },
      {
        "Data Fields": "supportingInfo",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Additional information codes regarding exceptions, special considerations, the condition, situation, prior or concurrent issues."
      },
      {
        "Data Fields": "supportingInfo.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "supportingInfo.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "supportingInfo.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "supportingInfo.sequence",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "A number to uniquely identify supporting information entries."
      },
      {
        "Data Fields": "supportingInfo.information",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Additional data or information such as resources, documents, images etc. including references to the data or the actual inclusion of the data."
      },
      {
        "Data Fields": "supportingInfo.appliesToAll",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "The supporting materials are applicable for all detail items, product/servce categories and specific billing codes."
      },
      {
        "Data Fields": "insurance",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Financial instruments for reimbursement for the health care products and services."
      },
      {
        "Data Fields": "insurance.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "insurance.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "insurance.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "insurance.focal",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "A flag to indicate that this Coverage is to be used for evaluation of this request when set to true."
      },
      {
        "Data Fields": "insurance.coverage",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Reference to the insurance card level information contained in the Coverage resource. The coverage issuing insurer will use these details to locate the patient's actual coverage within the insurer's information system."
      },
      {
        "Data Fields": "insurance.businessArrangement",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A business agreement number established between the provider and the insurer for special business processing purposes."
      },
      {
        "Data Fields": "item",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Service categories or billable services for which benefit details and/or an authorization prior to service delivery may be required by the payor."
      },
      {
        "Data Fields": "item.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "item.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "item.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "item.supportingInfoSequence",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "Exceptions, special conditions and supporting information applicable for this service or product line."
      },
      {
        "Data Fields": "item.category",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Code to identify the general type of benefits under which products and services are provided."
      },
      {
        "Data Fields": "item.productOrService",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "This contains the product, service, drug or other billing code for the item."
      },
      {
        "Data Fields": "item.modifier",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Item typification or modifiers codes to convey additional context for the product or service."
      },
      {
        "Data Fields": "item.provider",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The practitioner who is responsible for the product or service to be rendered to the patient."
      },
      {
        "Data Fields": "item.quantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The number of repetitions of a service or product."
      },
      {
        "Data Fields": "item.unitPrice",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "The amount charged to the patient by the provider for a single unit."
      },
      {
        "Data Fields": "item.facility",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Facility where the services will be provided."
      },
      {
        "Data Fields": "item.diagnosis",
        "Type": "BackboneElement",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Patient diagnosis for which care is sought."
      },
      {
        "Data Fields": "item.diagnosis.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "item.diagnosis.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "item.diagnosis.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "item.diagnosis.diagnosisCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The nature of illness or problem in a coded form or as a reference to an external defined Condition."
      },
      {
        "Data Fields": "item.diagnosis.diagnosisReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The nature of illness or problem in a coded form or as a reference to an external defined Condition."
      },
      {
        "Data Fields": "item.detail",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The plan/proposal/order describing the proposed service in detail."
      }
    ],
    "CoverageEligibilityResponse": [
      {
        "Data Fields": "resourceType",
        "Type": "CoverageEligibilityResponse",
        
        
        "Required": "True",
        "Description": "This is a CoverageEligibilityResponse resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A unique identifier assigned to this coverage eligiblity request."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The status of the resource instance."
      },
      {
        "Data Fields": "purpose",
        "Type": "code",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Code to specify whether requesting: prior authorization requirements for some service categories or billing codes; benefits for coverages specified or discovered; discovery and return of coverages for the patient; and/or validation that the specified coverage is in-force at the date/period specified or 'now' if not specified."
      },
      {
        "Data Fields": "patient",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "True",
        "Description": "The party who is the beneficiary of the supplied coverage and for whom eligibility is sought."
      },
      {
        "Data Fields": "servicedDate",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The date or dates when the enclosed suite of services were performed or completed."
      },
      {
        "Data Fields": "servicedPeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The date or dates when the enclosed suite of services were performed or completed."
      },
      {
        "Data Fields": "created",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date this resource was created."
      },
      {
        "Data Fields": "requestor",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The provider which is responsible for the request."
      },
      {
        "Data Fields": "request",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "Reference to the original request resource."
      },
      {
        "Data Fields": "outcome",
        "Type": "code",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The outcome of the request processing."
      },
      {
        "Data Fields": "disposition",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A human readable description of the status of the adjudication."
      },
      {
        "Data Fields": "insurer",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "The Insurer who issued the coverage in question and is the author of the response."
      },
      {
        "Data Fields": "insurance",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Financial instruments for reimbursement for the health care products and services."
      },
      {
        "Data Fields": "insurance.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "insurance.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "insurance.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "insurance.coverage",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Reference to the insurance card level information contained in the Coverage resource. The coverage issuing insurer will use these details to locate the patient's actual coverage within the insurer's information system."
      },
      {
        "Data Fields": "insurance.inforce",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Flag indicating if the coverage provided is inforce currently if no service date(s) specified or for the whole duration of the service dates."
      },
      {
        "Data Fields": "insurance.benefitPeriod",
        "Type": "Period",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The term of the benefits documented in this response."
      },
      {
        "Data Fields": "insurance.item",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Benefits and optionally current balances, and authorization details by category or service."
      },
      {
        "Data Fields": "insurance.item.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "insurance.item.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "insurance.item.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "insurance.item.category",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Code to identify the general type of benefits under which products and services are provided."
      },
      {
        "Data Fields": "insurance.item.productOrService",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "This contains the product, service, drug or other billing code for the item."
      },
      {
        "Data Fields": "insurance.item.modifier",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Item typification or modifiers codes to convey additional context for the product or service."
      },
      {
        "Data Fields": "insurance.item.provider",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The practitioner who is eligible for the provision of the product or service."
      },
      {
        "Data Fields": "insurance.item.excluded",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "True if the indicated class of service is excluded from the plan, missing or False indicates the product or service is included in the coverage."
      },
      {
        "Data Fields": "insurance.item.name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A short name or tag for the benefit."
      },
      {
        "Data Fields": "insurance.item.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A richer description of the benefit or services covered."
      },
      {
        "Data Fields": "insurance.item.network",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Is a flag to indicate whether the benefits refer to in-network providers or out-of-network providers."
      },
      {
        "Data Fields": "insurance.item.unit",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Indicates if the benefits apply to an individual or to the family."
      },
      {
        "Data Fields": "insurance.item.term",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The term or period of the values such as 'maximum lifetime benefit' or 'maximum annual visits'."
      },
      {
        "Data Fields": "insurance.item.benefit",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Benefits used to date."
      },
      {
        "Data Fields": "insurance.item.benefit.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "insurance.item.benefit.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "insurance.item.benefit.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "insurance.item.benefit.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Classification of benefit being provided."
      },
      {
        "Data Fields": "insurance.item.benefit.allowedUnsignedInt",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "The quantity of the benefit which is permitted under the coverage."
      },
      {
        "Data Fields": "insurance.item.benefit.allowedString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The quantity of the benefit which is permitted under the coverage."
      },
      {
        "Data Fields": "insurance.item.benefit.allowedMoney",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "The quantity of the benefit which is permitted under the coverage."
      },
      {
        "Data Fields": "insurance.item.benefit.usedUnsignedInt",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "The quantity of the benefit which have been consumed to date."
      },
      {
        "Data Fields": "insurance.item.benefit.usedString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The quantity of the benefit which have been consumed to date."
      },
      {
        "Data Fields": "insurance.item.benefit.usedMoney",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "The quantity of the benefit which have been consumed to date."
      },
      {
        "Data Fields": "insurance.item.authorizationRequired",
        "Type": "boolean",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A boolean flag indicating whether a preauthorization is required prior to actual service delivery."
      },
      {
        "Data Fields": "insurance.item.authorizationSupporting",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Codes or comments regarding information or actions associated with the preauthorization."
      },
      {
        "Data Fields": "insurance.item.authorizationUrl",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A web location for obtaining requirements or descriptive information regarding the preauthorization."
      },
      {
        "Data Fields": "preAuthRef",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A reference from the Insurer to which these services pertain to be used on further communication and as proof that the request occurred."
      },
      {
        "Data Fields": "form",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code for the form to be used for printing the content."
      },
      {
        "Data Fields": "error",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Errors encountered during the processing of the request."
      },
      {
        "Data Fields": "error.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "error.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "error.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "error.code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "An error code,from a specified code system, which details why the eligibility check could not be performed."
      }
    ],
    "DetectedIssue": [
      {
        "Data Fields": "resourceType",
        "Type": "DetectedIssue",
        
        
        "Required": "True",
        "Description": "This is a DetectedIssue resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Business identifier associated with the detected issue record."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Indicates the status of the detected issue."
      },
      {
        "Data Fields": "code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Identifies the general type of issue identified."
      },
      {
        "Data Fields": "severity",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Indicates the degree of importance associated with the identified issue based on the potential impact on the patient."
      },
      {
        "Data Fields": "patient",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Indicates the patient whose record the detected issue is associated with."
      },
      {
        "Data Fields": "identifiedDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The date or period when the detected issue was initially identified."
      },
      {
        "Data Fields": "identifiedPeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The date or period when the detected issue was initially identified."
      },
      {
        "Data Fields": "author",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Individual or device responsible for the issue being raised.  For example, a decision support application or a pharmacist conducting a medication review."
      },
      {
        "Data Fields": "implicated",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Indicates the resource representing the current activity or proposed activity that is potentially problematic."
      },
      {
        "Data Fields": "evidence",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Supporting evidence or manifestations that provide the basis for identifying the detected issue such as a GuidanceResponse or MeasureReport."
      },
      {
        "Data Fields": "evidence.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "evidence.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "evidence.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "evidence.code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A manifestation that led to the recording of this detected issue."
      },
      {
        "Data Fields": "evidence.detail",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Links to resources that constitute evidence for the detected issue such as a GuidanceResponse or MeasureReport."
      },
      {
        "Data Fields": "detail",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A textual explanation of the detected issue."
      },
      {
        "Data Fields": "reference",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "The literature, knowledge-base or similar reference that describes the propensity for the detected issue identified."
      },
      {
        "Data Fields": "mitigation",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Indicates an action that has been taken or is committed to reduce or eliminate the likelihood of the risk identified by the detected issue from manifesting.  Can also reflect an observation of known mitigating factors that may reduce/eliminate the need for any action."
      },
      {
        "Data Fields": "mitigation.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "mitigation.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "mitigation.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "mitigation.action",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Describes the action that was taken or the observation that was made that reduces/eliminates the risk associated with the identified issue."
      },
      {
        "Data Fields": "mitigation.date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "Indicates when the mitigating action was documented."
      },
      {
        "Data Fields": "mitigation.author",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Identifies the practitioner who determined the mitigation and takes responsibility for the mitigation step occurring."
      }
    ],
    "Device": [
      {
        "Data Fields": "resourceType",
        "Type": "Device",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "This is a Device resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Unique instance identifiers assigned to a device by manufacturers other organizations or owners."
      },
      {
        "Data Fields": "definition",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The reference to the definition for the device."
      },
      {
        "Data Fields": "udiCarrier",
        "Type": "BackboneElement",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Unique device identifier (UDI) assigned to device label or package.  Note that the Device may include multiple udiCarriers as it either may include just the udiCarrier for the jurisdiction it is sold, or for multiple jurisdictions it could have been sold."
      },
      {
        "Data Fields": "udiCarrier.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "udiCarrier.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "udiCarrier.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "udiCarrier.deviceIdentifier",
        "Type": "string",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The device identifier (DI) is a mandatory, fixed portion of a UDI that identifies the labeler and the specific version or model of a device."
      },
      {
        "Data Fields": "udiCarrier.issuer",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "Organization that is charged with issuing UDIs for devices.  For example, the US FDA issuers include : 1) GS1:  http://hl7.org/fhir/NamingSystem/gs1-di,  2) HIBCC: http://hl7.org/fhir/NamingSystem/hibcc-dI,  3) ICCBBA for blood containers: http://hl7.org/fhir/NamingSystem/iccbba-blood-di,  4) ICCBA for other devices: http://hl7.org/fhir/NamingSystem/iccbba-other-di."
      },
      {
        "Data Fields": "udiCarrier.jurisdiction",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "The identity of the authoritative source for UDI generation within a  jurisdiction.  All UDIs are globally unique within a single namespace with the appropriate repository uri as the system.  For example,  UDIs of devices managed in the U.S. by the FDA, the value is  http://hl7.org/fhir/NamingSystem/fda-udi."
      },
      {
        "Data Fields": "udiCarrier.carrierAIDC",
        "Type": "base64Binary",
        
        
        "Required": "False",
        "Description": "The full UDI carrier of the Automatic Identification and Data Capture (AIDC) technology representation of the barcode string as printed on the packaging of the device - e.g., a barcode or RFID.   Because of limitations on character sets in XML and the need to round-trip JSON data through XML, AIDC Formats *SHALL* be base64 encoded."
      },
      {
        "Data Fields": "udiCarrier.carrierHRF",
        "Type": "string",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The full UDI carrier as the human readable form (HRF) representation of the barcode string as printed on the packaging of the device."
      },
      {
        "Data Fields": "udiCarrier.entryType",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "A coded entry to indicate how the data was entered."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Status of the Device availability."
      },
      {
        "Data Fields": "statusReason",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Reason for the dtatus of the Device availability."
      },
      {
        "Data Fields": "distinctIdentifier",
        "Type": "string",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The distinct identification string as required by regulation for a human cell, tissue, or cellular and tissue-based product."
      },
      {
        "Data Fields": "manufacturer",
        "Type": "string",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A name of the manufacturer."
      },
      {
        "Data Fields": "manufactureDate",
        "Type": "dateTime",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The date and time when the device was manufactured."
      },
      {
        "Data Fields": "expirationDate",
        "Type": "dateTime",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The date and time beyond which this device is no longer valid or should not be used (if applicable)."
      },
      {
        "Data Fields": "lotNumber",
        "Type": "string",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Lot number assigned by the manufacturer."
      },
      {
        "Data Fields": "serialNumber",
        "Type": "string",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The serial number assigned by the organization when the device was manufactured."
      },
      {
        "Data Fields": "deviceName",
        "Type": "BackboneElement",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "This represents the manufacturer's name of the device as provided by the device, from a UDI label, or by a person describing the Device.  This typically would be used when a person provides the name(s) or when the device represents one of the names available from DeviceDefinition."
      },
      {
        "Data Fields": "deviceName.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "deviceName.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "deviceName.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "deviceName.name",
        "Type": "string",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The name of the device."
      },
      {
        "Data Fields": "deviceName.type",
        "Type": "code",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The type of deviceName. UDILabelName | UserFriendlyName | PatientReportedName | ManufactureDeviceName | ModelName."
      },
      {
        "Data Fields": "modelNumber",
        "Type": "string",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The model number for the device."
      },
      {
        "Data Fields": "partNumber",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The part number of the device."
      },
      {
        "Data Fields": "type",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The kind or type of device."
      },
      {
        "Data Fields": "specialization",
        "Type": "BackboneElement",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The capabilities supported on a  device, the standards to which the device conforms for a particular purpose, and used for the communication."
      },
      {
        "Data Fields": "specialization.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "specialization.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "specialization.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "specialization.systemType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The standard that is used to operate and communicate."
      },
      {
        "Data Fields": "specialization.version",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The version of the standard that is used to operate and communicate."
      },
      {
        "Data Fields": "version",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The actual design of the device or software version running on the device."
      },
      {
        "Data Fields": "version.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "version.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "version.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "version.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The type of the device version."
      },
      {
        "Data Fields": "version.component",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A single component of the device version."
      },
      {
        "Data Fields": "version.value",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The version text."
      },
      {
        "Data Fields": "property",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The actual configuration settings of a device as it actually operates, e.g., regulation status, time properties."
      },
      {
        "Data Fields": "property.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "property.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "property.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "property.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Code that specifies the property DeviceDefinitionPropetyCode (Extensible)."
      },
      {
        "Data Fields": "property.valueQuantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "Property value as a quantity."
      },
      {
        "Data Fields": "property.valueCode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Property value as a code, e.g., NTP4 (synced to NTP)."
      },
      {
        "Data Fields": "patient",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Patient information, If the device is affixed to a person."
      },
      {
        "Data Fields": "owner",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "An organization that is responsible for the provision and ongoing maintenance of the device."
      },
      {
        "Data Fields": "contact",
        "Type": "ContactPoint",
        
        
        "Required": "False",
        "Description": "Contact details for an organization or a particular human that is responsible for the device."
      },
      {
        "Data Fields": "location",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The place where the device can be found."
      },
      {
        "Data Fields": "url",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A network address on which the device may be contacted directly."
      },
      {
        "Data Fields": "note",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "Descriptive information, usage information or implantation information that is not captured in an existing element."
      },
      {
        "Data Fields": "safety",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Provides additional safety characteristics about a medical device.  For example devices containing latex."
      },
      {
        "Data Fields": "parent",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The parent device."
      }
    ],
    "DeviceDefinition": [
      {
        "Data Fields": "resourceType",
        "Type": "DeviceDefinition",
        
        
        "Required": "True",
        "Description": "This is a DeviceDefinition resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Unique instance identifiers assigned to a device by the software, manufacturers, other organizations or owners. For example: handle ID."
      },
      {
        "Data Fields": "udiDeviceIdentifier",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Unique device identifier (UDI) assigned to device label or package.  Note that the Device may include multiple udiCarriers as it either may include just the udiCarrier for the jurisdiction it is sold, or for multiple jurisdictions it could have been sold."
      },
      {
        "Data Fields": "udiDeviceIdentifier.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "udiDeviceIdentifier.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "udiDeviceIdentifier.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "udiDeviceIdentifier.deviceIdentifier",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The identifier that is to be associated with every Device that references this DeviceDefintiion for the issuer and jurisdication porvided in the DeviceDefinition.udiDeviceIdentifier."
      },
      {
        "Data Fields": "udiDeviceIdentifier.issuer",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "The organization that assigns the identifier algorithm."
      },
      {
        "Data Fields": "udiDeviceIdentifier.jurisdiction",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "The jurisdiction to which the deviceIdentifier applies."
      },
      {
        "Data Fields": "manufacturerString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A name of the manufacturer."
      },
      {
        "Data Fields": "manufacturerReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A name of the manufacturer."
      },
      {
        "Data Fields": "deviceName",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A name given to the device to identify it."
      },
      {
        "Data Fields": "deviceName.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "deviceName.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "deviceName.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "deviceName.name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the device."
      },
      {
        "Data Fields": "deviceName.type",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The type of deviceName. UDILabelName | UserFriendlyName | PatientReportedName | ManufactureDeviceName | ModelName."
      },
      {
        "Data Fields": "modelNumber",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The model number for the device."
      },
      {
        "Data Fields": "type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "What kind of device or device system this is."
      },
      {
        "Data Fields": "specialization",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The capabilities supported on a  device, the standards to which the device conforms for a particular purpose, and used for the communication."
      },
      {
        "Data Fields": "specialization.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "specialization.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "specialization.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "specialization.systemType",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The standard that is used to operate and communicate."
      },
      {
        "Data Fields": "specialization.version",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The version of the standard that is used to operate and communicate."
      },
      {
        "Data Fields": "version",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The available versions of the device, e.g., software versions."
      },
      {
        "Data Fields": "safety",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Safety characteristics of the device."
      },
      {
        "Data Fields": "shelfLifeStorage",
        "Type": "ProductShelfLife",
        
        
        "Required": "False",
        "Description": "Shelf Life and storage information."
      },
      {
        "Data Fields": "physicalCharacteristics",
        "Type": "ProdCharacteristic",
        
        
        "Required": "False",
        "Description": "Dimensions, color etc."
      },
      {
        "Data Fields": "languageCode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Language code for the human-readable text strings produced by the device (all supported)."
      },
      {
        "Data Fields": "capability",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Device capabilities."
      },
      {
        "Data Fields": "capability.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "capability.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "capability.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "capability.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Type of capability."
      },
      {
        "Data Fields": "capability.description",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Description of capability."
      },
      {
        "Data Fields": "property",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The actual configuration settings of a device as it actually operates, e.g., regulation status, time properties."
      },
      {
        "Data Fields": "property.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "property.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "property.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "property.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Code that specifies the property DeviceDefinitionPropetyCode (Extensible)."
      },
      {
        "Data Fields": "property.valueQuantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "Property value as a quantity."
      },
      {
        "Data Fields": "property.valueCode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Property value as a code, e.g., NTP4 (synced to NTP)."
      },
      {
        "Data Fields": "owner",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "An organization that is responsible for the provision and ongoing maintenance of the device."
      },
      {
        "Data Fields": "contact",
        "Type": "ContactPoint",
        
        
        "Required": "False",
        "Description": "Contact details for an organization or a particular human that is responsible for the device."
      },
      {
        "Data Fields": "url",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A network address on which the device may be contacted directly."
      },
      {
        "Data Fields": "onlineInformation",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "Access to on-line information about the device."
      },
      {
        "Data Fields": "note",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "Descriptive information, usage information or implantation information that is not captured in an existing element."
      },
      {
        "Data Fields": "quantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The quantity of the device present in the packaging (e.g. the number of devices present in a pack, or the number of devices in the same package of the medicinal product)."
      },
      {
        "Data Fields": "parentDevice",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The parent device it can be part of."
      },
      {
        "Data Fields": "material",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A substance used to create the material(s) of which the device is made."
      },
      {
        "Data Fields": "material.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "material.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "material.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "material.substance",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The substance."
      },
      {
        "Data Fields": "material.alternate",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Indicates an alternative material of the device."
      },
      {
        "Data Fields": "material.allergenicIndicator",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Whether the substance is a known or suspected allergen."
      }
    ],
    "DeviceMetric": [
      {
        "Data Fields": "resourceType",
        "Type": "DeviceMetric",
        
        
        "Required": "True",
        "Description": "This is a DeviceMetric resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Unique instance identifiers assigned to a device by the device or gateway software, manufacturers, other organizations or owners. For example: handle ID."
      },
      {
        "Data Fields": "type",
        "Type": "CodeableConcept",
        
        
        "Required": "True",
        "Description": "Describes the type of the metric. For example: Heart Rate, PEEP Setting, etc."
      },
      {
        "Data Fields": "unit",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Describes the unit that an observed value determined for this metric will have. For example: Percent, Seconds, etc."
      },
      {
        "Data Fields": "source",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Describes the link to the  Device that this DeviceMetric belongs to and that contains administrative device information such as manufacturer, serial number, etc."
      },
      {
        "Data Fields": "parent",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Describes the link to the  Device that this DeviceMetric belongs to and that provide information about the location of this DeviceMetric in the containment structure of the parent Device. An example would be a Device that represents a Channel. This reference can be used by a client application to distinguish DeviceMetrics that have the same type, but should be interpreted based on their containment location."
      },
      {
        "Data Fields": "operationalStatus",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Indicates current operational state of the device. For example: On, Off, Standby, etc."
      },
      {
        "Data Fields": "color",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Describes the color representation for the metric. This is often used to aid clinicians to track and identify parameter types by color. In practice, consider a Patient Monitor that has ECG/HR and Pleth for example; the parameters are displayed in different characteristic colors, such as HR-blue, BP-green, and PR and SpO2- magenta."
      },
      {
        "Data Fields": "category",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Indicates the category of the observation generation process. A DeviceMetric can be for example a setting, measurement, or calculation."
      },
      {
        "Data Fields": "measurementPeriod",
        "Type": "Timing",
        
        
        "Required": "False",
        "Description": "Describes the measurement repetition time. This is not necessarily the same as the update period. The measurement repetition time can range from milliseconds up to hours. An example for a measurement repetition time in the range of milliseconds is the sampling rate of an ECG. An example for a measurement repetition time in the range of hours is a NIBP that is triggered automatically every hour. The update period may be different than the measurement repetition time, if the device does not update the published observed value with the same frequency as it was measured."
      },
      {
        "Data Fields": "calibration",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Describes the calibrations that have been performed or that are required to be performed."
      },
      {
        "Data Fields": "calibration.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "calibration.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "calibration.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "calibration.type",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Describes the type of the calibration method."
      },
      {
        "Data Fields": "calibration.state",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Describes the state of the calibration."
      },
      {
        "Data Fields": "calibration.time",
        "Type": "instant",
        
        
        "Required": "False",
        "Description": "Describes the time last calibration has been performed."
      }
    ],
    "DeviceRequest": [
      {
        "Data Fields": "resourceType",
        "Type": "DeviceRequest",
        
        
        "Required": "True",
        "Description": "This is a DeviceRequest resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Identifiers assigned to this order by the orderer or by the receiver."
      },
      {
        "Data Fields": "instantiatesCanonical",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "The URL pointing to a FHIR-defined protocol, guideline, orderset or other definition that is adhered to in whole or in part by this DeviceRequest."
      },
      {
        "Data Fields": "instantiatesUri",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "The URL pointing to an externally maintained protocol, guideline, orderset or other definition that is adhered to in whole or in part by this DeviceRequest."
      },
      {
        "Data Fields": "basedOn",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Plan/proposal/order fulfilled by this request."
      },
      {
        "Data Fields": "priorRequest",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The request takes the place of the referenced completed or terminated request(s)."
      },
      {
        "Data Fields": "groupIdentifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Composite request this is part of."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The status of the request."
      },
      {
        "Data Fields": "intent",
        "Type": "code",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Whether the request is a proposal, plan, an original order or a reflex order."
      },
      {
        "Data Fields": "priority",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Indicates how quickly the {{title}} should be addressed with respect to other requests."
      },
      {
        "Data Fields": "codeReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The details of the device to be used."
      },
      {
        "Data Fields": "codeCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The details of the device to be used."
      },
      {
        "Data Fields": "parameter",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Specific parameters for the ordered item.  For example, the prism value for lenses."
      },
      {
        "Data Fields": "parameter.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "parameter.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "parameter.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "parameter.code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code or string that identifies the device detail being asserted."
      },
      {
        "Data Fields": "parameter.valueCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The value of the device detail."
      },
      {
        "Data Fields": "parameter.valueQuantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The value of the device detail."
      },
      {
        "Data Fields": "parameter.valueRange",
        "Type": "Range",
        
        
        "Required": "False",
        "Description": "The value of the device detail."
      },
      {
        "Data Fields": "parameter.valueBoolean",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "The value of the device detail."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "True",
        "Description": "The patient who will use the device."
      },
      {
        "Data Fields": "encounter",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "An encounter that provides additional context in which this request is made."
      },
      {
        "Data Fields": "occurrenceDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The timing schedule for the use of the device. The Schedule data type allows many different expressions, for example. \"Every 8 hours\"; \"Three times a day\"; \"1/2 an hour before breakfast for 10 days from 23-Dec 2011:\"; \"15 Oct 2013, 17 Oct 2013 and 1 Nov 2013\"."
      },
      {
        "Data Fields": "occurrencePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The timing schedule for the use of the device. The Schedule data type allows many different expressions, for example. \"Every 8 hours\"; \"Three times a day\"; \"1/2 an hour before breakfast for 10 days from 23-Dec 2011:\"; \"15 Oct 2013, 17 Oct 2013 and 1 Nov 2013\"."
      },
      {
        "Data Fields": "occurrenceTiming",
        "Type": "Timing",
        
        
        "Required": "False",
        "Description": "The timing schedule for the use of the device. The Schedule data type allows many different expressions, for example. \"Every 8 hours\"; \"Three times a day\"; \"1/2 an hour before breakfast for 10 days from 23-Dec 2011:\"; \"15 Oct 2013, 17 Oct 2013 and 1 Nov 2013\"."
      },
      {
        "Data Fields": "authoredOn",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "When the request transitioned to being actionable."
      },
      {
        "Data Fields": "requester",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The individual who initiated the request and has responsibility for its activation."
      },
      {
        "Data Fields": "performerType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Desired type of performer for doing the diagnostic testing."
      },
      {
        "Data Fields": "performer",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The desired performer for doing the diagnostic testing."
      },
      {
        "Data Fields": "reasonCode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Reason or justification for the use of this device."
      },
      {
        "Data Fields": "reasonReference",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Reason or justification for the use of this device."
      },
      {
        "Data Fields": "insurance",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Insurance plans, coverage extensions, pre-authorizations and/or pre-determinations that may be required for delivering the requested service."
      },
      {
        "Data Fields": "supportingInfo",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Additional clinical information about the patient that may influence the request fulfilment.  For example, this may include where on the subject's body the device will be used (i.e. the target site)."
      },
      {
        "Data Fields": "note",
        "Type": "Annotation",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Details about this request that were not represented at all or sufficiently in one of the attributes provided in a class. These may include for example a comment, an instruction, or a note associated with the statement."
      },
      {
        "Data Fields": "relevantHistory",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Key events in the history of the request."
      }
    ],
    "DeviceUseStatement": [
      {
        "Data Fields": "resourceType",
        "Type": "DeviceUseStatement",
        
        
        "Required": "True",
        "Description": "This is a DeviceUseStatement resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "An external identifier for this statement such as an IRI."
      },
      {
        "Data Fields": "basedOn",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A plan, proposal or order that is fulfilled in whole or in part by this DeviceUseStatement."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "A code representing the patient or other source's judgment about the state of the device used that this statement is about.  Generally this will be active or completed."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "The patient who used the device."
      },
      {
        "Data Fields": "derivedFrom",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Allows linking the DeviceUseStatement to the underlying Request, or to other information that supports or is used to derive the DeviceUseStatement."
      },
      {
        "Data Fields": "timingTiming",
        "Type": "Timing",
        
        
        "Required": "False",
        "Description": "How often the device was used."
      },
      {
        "Data Fields": "timingPeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "How often the device was used."
      },
      {
        "Data Fields": "timingDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "How often the device was used."
      },
      {
        "Data Fields": "recordedOn",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The time at which the statement was made/recorded."
      },
      {
        "Data Fields": "source",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Who reported the device was being used by the patient."
      },
      {
        "Data Fields": "device",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "The details of the device used."
      },
      {
        "Data Fields": "reasonCode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Reason or justification for the use of the device."
      },
      {
        "Data Fields": "reasonReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Indicates another resource whose existence justifies this DeviceUseStatement."
      },
      {
        "Data Fields": "bodySite",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Indicates the anotomic location on the subject's body where the device was used ( i.e. the target)."
      },
      {
        "Data Fields": "note",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "Details about the device statement that were not represented at all or sufficiently in one of the attributes provided in a class. These may include for example a comment, an instruction, or a note associated with the statement."
      }
    ],
    "DiagnosticReport": [
      {
        "Data Fields": "resourceType",
        "Type": "DiagnosticReport",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "This is a DiagnosticReport resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Identifiers assigned to this report by the performer or other systems."
      },
      {
        "Data Fields": "basedOn",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Details concerning a service requested."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The status of the diagnostic report."
      },
      {
        "Data Fields": "category",
        "Type": "CodeableConcept",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A code that classifies the clinical discipline, department or diagnostic service that created the report (e.g. cardiology, biochemistry, hematology, MRI). This is used for searching, sorting and display purposes."
      },
      {
        "Data Fields": "code",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "A code or name that describes this diagnostic report."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The subject of the report. Usually, but not always, this is a patient. However, diagnostic services also perform analyses on specimens collected from a variety of other sources."
      },
      {
        "Data Fields": "encounter",
        "Type": "Reference",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The healthcare event  (e.g. a patient and healthcare provider interaction) which this DiagnosticReport is about."
      },
      {
        "Data Fields": "effectiveDateTime",
        "Type": "string",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The time or time-period the observed values are related to. When the subject of the report is a patient, this is usually either the time of the procedure or of specimen collection(s), but very often the source of the date/time is not known, only the date/time itself."
      },
      {
        "Data Fields": "effectivePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The time or time-period the observed values are related to. When the subject of the report is a patient, this is usually either the time of the procedure or of specimen collection(s), but very often the source of the date/time is not known, only the date/time itself."
      },
      {
        "Data Fields": "issued",
        "Type": "instant",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The date and time that this version of the report was made available to providers, typically after the report was reviewed and verified."
      },
      {
        "Data Fields": "performer",
        "Type": "Reference",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The diagnostic service that is responsible for issuing the report."
      },
      {
        "Data Fields": "resultsInterpreter",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The practitioner or organization that is responsible for the report's conclusions and interpretations."
      },
      {
        "Data Fields": "specimen",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Details about the specimens on which this diagnostic report is based."
      },
      {
        "Data Fields": "result",
        "Type": "Reference",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "[Observations](observation.html)  that are part of this diagnostic report."
      },
      {
        "Data Fields": "imagingStudy",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "One or more links to full details of any imaging performed during the diagnostic investigation. Typically, this is imaging performed by DICOM enabled modalities, but this is not required. A fully enabled PACS viewer can use this information to provide views of the source images."
      },
      {
        "Data Fields": "media",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A list of key images associated with this report. The images are generally created during the diagnostic process, and may be directly of the patient, or of treated specimens (i.e. slides of interest)."
      },
      {
        "Data Fields": "media.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "media.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "media.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "media.comment",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A comment about the image. Typically, this is used to provide an explanation for why the image is included, or to draw the viewer's attention to important features."
      },
      {
        "Data Fields": "media.link",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Reference to the image source."
      },
      {
        "Data Fields": "conclusion",
        "Type": "string",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Concise and clinically contextualized summary conclusion (interpretation/impression) of the diagnostic report."
      },
      {
        "Data Fields": "conclusionCode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "One or more codes that represent the summary conclusion (interpretation/impression) of the diagnostic report."
      },
      {
        "Data Fields": "presentedForm",
        "Type": "Attachment",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Rich text representation of the entire result as issued by the diagnostic service. Multiple formats are allowed but they SHALL be semantically equivalent."
      }
    ],
    "DocumentManifest": [
      {
        "Data Fields": "resourceType",
        "Type": "DocumentManifest",
        
        
        "Required": "True",
        "Description": "This is a DocumentManifest resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "masterIdentifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A single identifier that uniquely identifies this manifest. Principally used to refer to the manifest in non-FHIR contexts."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Other identifiers associated with the document manifest, including version independent  identifiers."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The status of this document manifest."
      },
      {
        "Data Fields": "type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The code specifying the type of clinical activity that resulted in placing the associated content into the DocumentManifest."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Who or what the set of documents is about. The documents can be about a person, (patient or healthcare practitioner), a device (i.e. machine) or even a group of subjects (such as a document about a herd of farm animals, or a set of patients that share a common exposure). If the documents cross more than one subject, then more than one subject is allowed here (unusual use case)."
      },
      {
        "Data Fields": "created",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "When the document manifest was created for submission to the server (not necessarily the same thing as the actual resource last modified time, since it may be modified, replicated, etc.)."
      },
      {
        "Data Fields": "author",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Identifies who is the author of the manifest. Manifest author is not necessarly the author of the references included."
      },
      {
        "Data Fields": "recipient",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A patient, practitioner, or organization for which this set of documents is intended."
      },
      {
        "Data Fields": "source",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "Identifies the source system, application, or software that produced the document manifest."
      },
      {
        "Data Fields": "description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Human-readable description of the source document. This is sometimes known as the \"title\"."
      },
      {
        "Data Fields": "content",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "The list of Resources that consist of the parts of this manifest."
      },
      {
        "Data Fields": "related",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Related identifiers or resources associated with the DocumentManifest."
      },
      {
        "Data Fields": "related.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "related.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "related.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "related.identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Related identifier to this DocumentManifest.  For example, Order numbers, accession numbers, XDW workflow numbers."
      },
      {
        "Data Fields": "related.ref",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Related Resource to this DocumentManifest. For example, Order, ServiceRequest,  Procedure, EligibilityRequest, etc."
      }
    ],
    "DocumentReference": [
      {
        "Data Fields": "resourceType",
        "Type": "DocumentReference",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "This is a DocumentReference resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "masterIdentifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Document identifier as assigned by the source of the document. This identifier is specific to this version of the document. This unique identifier may be used elsewhere to identify this version of the document."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Other identifiers associated with the document, including version independent identifiers."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The status of this document reference."
      },
      {
        "Data Fields": "docStatus",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The status of the underlying document."
      },
      {
        "Data Fields": "type",
        "Type": "CodeableConcept",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Specifies the particular kind of document referenced  (e.g. History and Physical, Discharge Summary, Progress Note). This usually equates to the purpose of making the document referenced."
      },
      {
        "Data Fields": "category",
        "Type": "CodeableConcept",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A categorization for the type of document referenced - helps for indexing and searching. This may be implied by or derived from the code specified in the DocumentReference.type."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Who or what the document is about. The document can be about a person, (patient or healthcare practitioner), a device (e.g. a machine) or even a group of subjects (such as a document about a herd of farm animals, or a set of patients that share a common exposure)."
      },
      {
        "Data Fields": "date",
        "Type": "instant",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "When the document reference was created."
      },
      {
        "Data Fields": "author",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Identifies who is responsible for adding the information to the document."
      },
      {
        "Data Fields": "authenticator",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Which person or organization authenticates that this document is valid."
      },
      {
        "Data Fields": "custodian",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Identifies the organization or group who is responsible for ongoing maintenance of and access to the document."
      },
      {
        "Data Fields": "relatesTo",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Relationships that this document has with other document references that already exist."
      },
      {
        "Data Fields": "relatesTo.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "relatesTo.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "relatesTo.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "relatesTo.code",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The type of relationship that this document has with anther document."
      },
      {
        "Data Fields": "relatesTo.target",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The target document of this relationship."
      },
      {
        "Data Fields": "description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Human-readable description of the source document."
      },
      {
        "Data Fields": "securityLabel",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A set of Security-Tag codes specifying the level of privacy/security of the Document. Note that DocumentReference.meta.security contains the security labels of the \"reference\" to the document, while DocumentReference.securityLabel contains a snapshot of the security labels on the document the reference refers to."
      },
      {
        "Data Fields": "content",
        "Type": "BackboneElement",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "The document and format referenced. There may be multiple content element repetitions, each with a different format."
      },
      {
        "Data Fields": "content.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "content.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "content.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "content.attachment",
        "Type": "Attachment",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The document or URL of the document along with critical metadata to prove content has integrity."
      },
      {
        "Data Fields": "content.format",
        "Type": "Coding",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "An identifier of the document encoding, structure, and template that the document conforms to beyond the base format indicated in the mimeType."
      },
      {
        "Data Fields": "context",
        "Type": "BackboneElement",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The clinical context in which the document was prepared."
      },
      {
        "Data Fields": "context.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "context.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "context.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "context.encounter",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Describes the clinical encounter or type of care that the document content is associated with."
      },
      {
        "Data Fields": "context.event",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "This list of codes represents the main clinical acts, such as a colonoscopy or an appendectomy, being documented. In some cases, the event is inherent in the type Code, such as a \"History and Physical Report\" in which the procedure being documented is necessarily a \"History and Physical\" act."
      },
      {
        "Data Fields": "context.period",
        "Type": "Period",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The time period over which the service that is described by the document was provided."
      },
      {
        "Data Fields": "context.facilityType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The kind of facility where the patient was seen."
      },
      {
        "Data Fields": "context.practiceSetting",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "This property may convey specifics about the practice setting where the content was created, often reflecting the clinical specialty."
      },
      {
        "Data Fields": "context.sourcePatientInfo",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The Patient Information as known when the document was published. May be a reference to a version specific, or contained."
      },
      {
        "Data Fields": "context.related",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Related identifiers or resources associated with the DocumentReference."
      }
    ],
    "EffectEvidenceSynthesis": [
      {
        "Data Fields": "resourceType",
        "Type": "EffectEvidenceSynthesis",
        
        
        "Required": "True",
        "Description": "This is a EffectEvidenceSynthesis resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "url",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "An absolute URI that is used to identify this effect evidence synthesis when it is referenced in a specification, model, design or an instance; also called its canonical identifier. This SHOULD be globally unique and SHOULD be a literal address at which at which an authoritative instance of this effect evidence synthesis is (or will be) published. This URL can be the target of a canonical reference. It SHALL remain the same when the effect evidence synthesis is stored on different servers."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A formal identifier that is used to identify this effect evidence synthesis when it is represented in other formats, or referenced in a specification, model, design or an instance."
      },
      {
        "Data Fields": "version",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The identifier that is used to identify this version of the effect evidence synthesis when it is referenced in a specification, model, design or instance. This is an arbitrary value managed by the effect evidence synthesis author and is not expected to be globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if a managed version is not available. There is also no expectation that versions can be placed in a lexicographical sequence."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A natural language name identifying the effect evidence synthesis. This name should be usable as an identifier for the module by machine processing applications such as code generation."
      },
      {
        "Data Fields": "title",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A short, descriptive, user-friendly title for the effect evidence synthesis."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The status of this effect evidence synthesis. Enables tracking the life-cycle of the content."
      },
      {
        "Data Fields": "date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date  (and optionally time) when the effect evidence synthesis was published. The date must change when the business version changes and it must change if the status code changes. In addition, it should change when the substantive content of the effect evidence synthesis changes."
      },
      {
        "Data Fields": "publisher",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the organization or individual that published the effect evidence synthesis."
      },
      {
        "Data Fields": "contact",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "Contact details to assist a user in finding and communicating with the publisher."
      },
      {
        "Data Fields": "description",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A free text natural language description of the effect evidence synthesis from a consumer's perspective."
      },
      {
        "Data Fields": "note",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "A human-readable string to clarify or explain concepts about the resource."
      },
      {
        "Data Fields": "useContext",
        "Type": "UsageContext",
        
        
        "Required": "False",
        "Description": "The content was developed with a focus and intent of supporting the contexts that are listed. These contexts may be general categories (gender, age, ...) or may be references to specific programs (insurance plans, studies, ...) and may be used to assist with indexing and searching for appropriate effect evidence synthesis instances."
      },
      {
        "Data Fields": "jurisdiction",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A legal or geographic region in which the effect evidence synthesis is intended to be used."
      },
      {
        "Data Fields": "copyright",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A copyright statement relating to the effect evidence synthesis and/or its contents. Copyright statements are generally legal restrictions on the use and publishing of the effect evidence synthesis."
      },
      {
        "Data Fields": "approvalDate",
        "Type": "date",
        
        
        "Required": "False",
        "Description": "The date on which the resource content was approved by the publisher. Approval happens once when the content is officially approved for usage."
      },
      {
        "Data Fields": "lastReviewDate",
        "Type": "date",
        
        
        "Required": "False",
        "Description": "The date on which the resource content was last reviewed. Review happens periodically after approval but does not change the original approval date."
      },
      {
        "Data Fields": "effectivePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The period during which the effect evidence synthesis content was or is planned to be in active use."
      },
      {
        "Data Fields": "topic",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Descriptive topics related to the content of the EffectEvidenceSynthesis. Topics provide a high-level categorization grouping types of EffectEvidenceSynthesiss that can be useful for filtering and searching."
      },
      {
        "Data Fields": "author",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individiual or organization primarily involved in the creation and maintenance of the content."
      },
      {
        "Data Fields": "editor",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individual or organization primarily responsible for internal coherence of the content."
      },
      {
        "Data Fields": "reviewer",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individual or organization primarily responsible for review of some aspect of the content."
      },
      {
        "Data Fields": "endorser",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individual or organization responsible for officially endorsing the content for use in some setting."
      },
      {
        "Data Fields": "relatedArtifact",
        "Type": "RelatedArtifact",
        
        
        "Required": "False",
        "Description": "Related artifacts such as additional documentation, justification, or bibliographic references."
      },
      {
        "Data Fields": "synthesisType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Type of synthesis eg meta-analysis."
      },
      {
        "Data Fields": "studyType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Type of study eg randomized trial."
      },
      {
        "Data Fields": "population",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "A reference to a EvidenceVariable resource that defines the population for the research."
      },
      {
        "Data Fields": "exposure",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "A reference to a EvidenceVariable resource that defines the exposure for the research."
      },
      {
        "Data Fields": "exposureAlternative",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "A reference to a EvidenceVariable resource that defines the comparison exposure for the research."
      },
      {
        "Data Fields": "outcome",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "A reference to a EvidenceVariable resomece that defines the outcome for the research."
      },
      {
        "Data Fields": "sampleSize",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A description of the size of the sample involved in the synthesis."
      },
      {
        "Data Fields": "sampleSize.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "sampleSize.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "sampleSize.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "sampleSize.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Human-readable summary of sample size."
      },
      {
        "Data Fields": "sampleSize.numberOfStudies",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "Number of studies included in this evidence synthesis."
      },
      {
        "Data Fields": "sampleSize.numberOfParticipants",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "Number of participants included in this evidence synthesis."
      },
      {
        "Data Fields": "resultsByExposure",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A description of the results for each exposure considered in the effect estimate."
      },
      {
        "Data Fields": "resultsByExposure.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "resultsByExposure.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "resultsByExposure.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "resultsByExposure.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Human-readable summary of results by exposure state."
      },
      {
        "Data Fields": "resultsByExposure.exposureState",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Whether these results are for the exposure state or alternative exposure state."
      },
      {
        "Data Fields": "resultsByExposure.variantState",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Used to define variant exposure states such as low-risk state."
      },
      {
        "Data Fields": "resultsByExposure.riskEvidenceSynthesis",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Reference to a RiskEvidenceSynthesis resource."
      },
      {
        "Data Fields": "effectEstimate",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The estimated effect of the exposure variant."
      },
      {
        "Data Fields": "effectEstimate.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "effectEstimate.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "effectEstimate.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "effectEstimate.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Human-readable summary of effect estimate."
      },
      {
        "Data Fields": "effectEstimate.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Examples include relative risk and mean difference."
      },
      {
        "Data Fields": "effectEstimate.variantState",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Used to define variant exposure states such as low-risk state."
      },
      {
        "Data Fields": "effectEstimate.value",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "The point estimate of the effect estimate."
      },
      {
        "Data Fields": "effectEstimate.unitOfMeasure",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Specifies the UCUM unit for the outcome."
      },
      {
        "Data Fields": "effectEstimate.precisionEstimate",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A description of the precision of the estimate for the effect."
      },
      {
        "Data Fields": "effectEstimate.precisionEstimate.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "effectEstimate.precisionEstimate.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "effectEstimate.precisionEstimate.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "effectEstimate.precisionEstimate.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Examples include confidence interval and interquartile range."
      },
      {
        "Data Fields": "effectEstimate.precisionEstimate.level",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "Use 95 for a 95% confidence interval."
      },
      {
        "Data Fields": "effectEstimate.precisionEstimate.from",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "Lower bound of confidence interval."
      },
      {
        "Data Fields": "effectEstimate.precisionEstimate.to",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "Upper bound of confidence interval."
      },
      {
        "Data Fields": "certainty",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A description of the certainty of the effect estimate."
      },
      {
        "Data Fields": "certainty.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "certainty.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "certainty.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "certainty.rating",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A rating of the certainty of the effect estimate."
      },
      {
        "Data Fields": "certainty.note",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "A human-readable string to clarify or explain concepts about the resource."
      },
      {
        "Data Fields": "certainty.certaintySubcomponent",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A description of a component of the overall certainty."
      },
      {
        "Data Fields": "certainty.certaintySubcomponent.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "certainty.certaintySubcomponent.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "certainty.certaintySubcomponent.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "certainty.certaintySubcomponent.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Type of subcomponent of certainty rating."
      },
      {
        "Data Fields": "certainty.certaintySubcomponent.rating",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A rating of a subcomponent of rating certainty."
      },
      {
        "Data Fields": "certainty.certaintySubcomponent.note",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "A human-readable string to clarify or explain concepts about the resource."
      }
    ],
    "Encounter": [
      {
        "Data Fields": "resourceType",
        "Type": "Encounter",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "This is a Encounter resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Identifier(s) by which this encounter is known."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "planned | arrived | triaged | in-progress | onleave | finished | cancelled +."
      },
      {
        "Data Fields": "statusHistory",
        "Type": "BackboneElement",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The status history permits the encounter resource to contain the status history without needing to read through the historical versions of the resource, or even have the server store them."
      },
      {
        "Data Fields": "statusHistory.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "statusHistory.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "statusHistory.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "statusHistory.status",
        "Type": "code",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "planned | arrived | triaged | in-progress | onleave | finished | cancelled +."
      },
      {
        "Data Fields": "statusHistory.period",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The time that the episode was in the specified status."
      },
      {
        "Data Fields": "class",
        "Type": "Coding",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "Concepts representing classification of patient encounter such as ambulatory (outpatient), inpatient, emergency, home health or others due to local variations."
      },
      {
        "Data Fields": "classHistory",
        "Type": "BackboneElement",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The class history permits the tracking of the encounters transitions without needing to go  through the resource history.  This would be used for a case where an admission starts of as an emergency encounter, then transitions into an inpatient scenario. Doing this and not restarting a new encounter ensures that any lab/diagnostic results can more easily follow the patient and not require re-processing and not get lost or cancelled during a kind of discharge from emergency to inpatient."
      },
      {
        "Data Fields": "classHistory.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "classHistory.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "classHistory.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "classHistory.class",
        "Type": "Coding",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "inpatient | outpatient | ambulatory | emergency +."
      },
      {
        "Data Fields": "classHistory.period",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The time that the episode was in the specified class."
      },
      {
        "Data Fields": "type",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Specific type of encounter (e.g. e-mail consultation, surgical day-care, skilled nursing, rehabilitation)."
      },
      {
        "Data Fields": "serviceType",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Broad categorization of the service that is to be provided (e.g. cardiology)."
      },
      {
        "Data Fields": "priority",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Indicates the urgency of the encounter."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The patient or group present at the encounter."
      },
      {
        "Data Fields": "episodeOfCare",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Where a specific encounter should be classified as a part of a specific episode(s) of care this field should be used. This association can facilitate grouping of related encounters together for a specific purpose, such as government reporting, issue tracking, association via a common problem.  The association is recorded on the encounter as these are typically created after the episode of care and grouped on entry rather than editing the episode of care to append another encounter to it (the episode of care could span years)."
      },
      {
        "Data Fields": "basedOn",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The request this encounter satisfies (e.g. incoming referral or procedure request)."
      },
      {
        "Data Fields": "participant",
        "Type": "BackboneElement",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The list of people responsible for providing the service."
      },
      {
        "Data Fields": "participant.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "participant.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "participant.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "participant.type",
        "Type": "CodeableConcept",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Role of participant in encounter."
      },
      {
        "Data Fields": "participant.period",
        "Type": "Period",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The period of time that the specified participant participated in the encounter. These can overlap or be sub-sets of the overall encounter's period."
      },
      {
        "Data Fields": "participant.individual",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Persons involved in the encounter other than the patient."
      },
      {
        "Data Fields": "appointment",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The appointment that scheduled this encounter."
      },
      {
        "Data Fields": "period",
        "Type": "Period",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The start and end time of the encounter."
      },
      {
        "Data Fields": "length",
        "Type": "Duration",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Quantity of time the encounter lasted. This excludes the time during leaves of absence."
      },
      {
        "Data Fields": "reasonCode",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Reason the encounter takes place, expressed as a code. For admissions, this can be used for a coded admission diagnosis."
      },
      {
        "Data Fields": "reasonReference",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Reason the encounter takes place, expressed as a code. For admissions, this can be used for a coded admission diagnosis."
      },
      {
        "Data Fields": "diagnosis",
        "Type": "BackboneElement",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The list of diagnosis relevant to this encounter."
      },
      {
        "Data Fields": "diagnosis.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "diagnosis.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "diagnosis.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "diagnosis.condition",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Reason the encounter takes place, as specified using information from another resource. For admissions, this is the admission diagnosis. The indication will typically be a Condition (with other resources referenced in the evidence.detail), or a Procedure."
      },
      {
        "Data Fields": "diagnosis.use",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Role that this diagnosis has within the encounter (e.g. admission, billing, discharge …)."
      },
      {
        "Data Fields": "diagnosis.rank",
        "Type": "positiveInt",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Ranking of the diagnosis (for each role type)."
      },
      {
        "Data Fields": "account",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The set of accounts that may be used for billing for this Encounter."
      },
      {
        "Data Fields": "hospitalization",
        "Type": "BackboneElement",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Details about the admission to a healthcare service."
      },
      {
        "Data Fields": "hospitalization.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "hospitalization.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "hospitalization.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "hospitalization.preAdmissionIdentifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Pre-admission identifier."
      },
      {
        "Data Fields": "hospitalization.origin",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The location/organization from which the patient came before admission."
      },
      {
        "Data Fields": "hospitalization.admitSource",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "From where patient was admitted (physician referral, transfer)."
      },
      {
        "Data Fields": "hospitalization.reAdmission",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Whether this hospitalization is a readmission and why if known."
      },
      {
        "Data Fields": "hospitalization.dietPreference",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Diet preferences reported by the patient."
      },
      {
        "Data Fields": "hospitalization.specialCourtesy",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Special courtesies (VIP, board member)."
      },
      {
        "Data Fields": "hospitalization.specialArrangement",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Any special requests that have been made for this hospitalization encounter, such as the provision of specific equipment or other things."
      },
      {
        "Data Fields": "hospitalization.destination",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Location/organization to which the patient is discharged."
      },
      {
        "Data Fields": "hospitalization.dischargeDisposition",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Category or kind of location after discharge."
      },
      {
        "Data Fields": "location",
        "Type": "BackboneElement",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "List of locations where  the patient has been during this encounter."
      },
      {
        "Data Fields": "location.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "location.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "location.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "location.status",
        "Type": "code",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The status of the participants' presence at the specified location during the period specified. If the participant is no longer at the location, then the period will have an end date/time."
      },
      {
        "Data Fields": "location.physicalType",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "This will be used to specify the required levels (bed/ward/room/etc.) desired to be recorded to simplify either messaging or query."
      },
      {
        "Data Fields": "location.period",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "Time period during which the patient was present at the location."
      },
      {
        "Data Fields": "serviceProvider",
        "Type": "Reference",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The organization that is primarily responsible for this Encounter's services. This MAY be the same as the organization on the Patient record, however it could be different, such as if the actor performing the services was from an external organization (which may be billed seperately) for an external consultation.  Refer to the example bundle showing an abbreviated set of Encounters for a colonoscopy."
      },
      {
        "Data Fields": "partOf",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Another Encounter of which this encounter is a part of (administratively or in time)."
      }
    ],
    "Endpoint": [
      {
        "Data Fields": "resourceType",
        "Type": "Endpoint",
        
        
        "Required": "True",
        "Description": "This is a Endpoint resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Identifier for the organization that is used to identify the endpoint across multiple disparate systems."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "active | suspended | error | off | test."
      },
      {
        "Data Fields": "connectionType",
        "Type": "Coding",
        
        
        "Required": "True",
        "Description": "A coded value that represents the technical details of the usage of this endpoint, such as what WSDLs should be used in what way. (e.g. XDS.b/DICOM/cds-hook)."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A friendly name that this endpoint can be referred to with."
      },
      {
        "Data Fields": "managingOrganization",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The organization that manages this endpoint (even if technically another organization is hosting this in the cloud, it is the organization associated with the data)."
      },
      {
        "Data Fields": "contact",
        "Type": "ContactPoint",
        
        
        "Required": "False",
        "Description": "Contact details for a human to contact about the subscription. The primary use of this for system administrator troubleshooting."
      },
      {
        "Data Fields": "period",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The interval during which the endpoint is expected to be operational."
      },
      {
        "Data Fields": "payloadType",
        "Type": "CodeableConcept",
        
        
        "Required": "True",
        "Description": "The payload type describes the acceptable content that can be communicated on the endpoint."
      },
      {
        "Data Fields": "payloadMimeType",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The mime type to send the payload in - e.g. application/fhir+xml, application/fhir+json. If the mime type is not specified, then the sender could send any content (including no content depending on the connectionType)."
      },
      {
        "Data Fields": "address",
        "Type": "url",
        
        
        "Required": "False",
        "Description": "The uri that describes the actual end-point to connect to."
      },
      {
        "Data Fields": "header",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Additional headers / information to send as part of the notification."
      }
    ],
    "EnrollmentRequest": [
      {
        "Data Fields": "resourceType",
        "Type": "EnrollmentRequest",
        
        
        "Required": "True",
        "Description": "This is a EnrollmentRequest resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "The Response business identifier."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The status of the resource instance."
      },
      {
        "Data Fields": "created",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date when this resource was created."
      },
      {
        "Data Fields": "insurer",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The Insurer who is target  of the request."
      },
      {
        "Data Fields": "provider",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The practitioner who is responsible for the services rendered to the patient."
      },
      {
        "Data Fields": "candidate",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Patient Resource."
      },
      {
        "Data Fields": "coverage",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Reference to the program or plan identification, underwriter or payor."
      }
    ],
    "EnrollmentResponse": [
      {
        "Data Fields": "resourceType",
        "Type": "EnrollmentResponse",
        
        
        "Required": "True",
        "Description": "This is a EnrollmentResponse resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "The Response business identifier."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The status of the resource instance."
      },
      {
        "Data Fields": "request",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Original request resource reference."
      },
      {
        "Data Fields": "outcome",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Processing status: error, complete."
      },
      {
        "Data Fields": "disposition",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A description of the status of the adjudication."
      },
      {
        "Data Fields": "created",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date when the enclosed suite of services were performed or completed."
      },
      {
        "Data Fields": "organization",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The Insurer who produced this adjudicated response."
      },
      {
        "Data Fields": "requestProvider",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The practitioner who is responsible for the services rendered to the patient."
      }
    ],
    "EpisodeOfCare": [
      {
        "Data Fields": "resourceType",
        "Type": "EpisodeOfCare",
        
        
        "Required": "True",
        "Description": "This is a EpisodeOfCare resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "The EpisodeOfCare may be known by different identifiers for different contexts of use, such as when an external agency is tracking the Episode for funding purposes."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "planned | waitlist | active | onhold | finished | cancelled."
      },
      {
        "Data Fields": "statusHistory",
        "Type": "BackboneElement",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The history of statuses that the EpisodeOfCare has been through (without requiring processing the history of the resource)."
      },
      {
        "Data Fields": "statusHistory.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "statusHistory.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "statusHistory.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "statusHistory.status",
        "Type": "code",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "planned | waitlist | active | onhold | finished | cancelled."
      },
      {
        "Data Fields": "statusHistory.period",
        "Type": "Period",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The period during this EpisodeOfCare that the specific status applied."
      },
      {
        "Data Fields": "type",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A classification of the type of episode of care; e.g. specialist referral, disease management, type of funded care."
      },
      {
        "Data Fields": "diagnosis",
        "Type": "BackboneElement",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The list of diagnosis relevant to this episode of care."
      },
      {
        "Data Fields": "diagnosis.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "diagnosis.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "diagnosis.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "diagnosis.condition",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A list of conditions/problems/diagnoses that this episode of care is intended to be providing care for."
      },
      {
        "Data Fields": "diagnosis.role",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Role that this diagnosis has within the episode of care (e.g. admission, billing, discharge …)."
      },
      {
        "Data Fields": "diagnosis.rank",
        "Type": "positiveInt",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Ranking of the diagnosis (for each role type)."
      },
      {
        "Data Fields": "patient",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "The patient who is the focus of this episode of care."
      },
      {
        "Data Fields": "managingOrganization",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The organization that has assumed the specific responsibilities for the specified duration."
      },
      {
        "Data Fields": "period",
        "Type": "Period",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The interval during which the managing organization assumes the defined responsibility."
      },
      {
        "Data Fields": "referralRequest",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Referral Request(s) that are fulfilled by this EpisodeOfCare, incoming referrals."
      },
      {
        "Data Fields": "careManager",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The practitioner that is the care manager/care coordinator for this patient."
      },
      {
        "Data Fields": "team",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The list of practitioners that may be facilitating this episode of care for specific purposes."
      },
      {
        "Data Fields": "account",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The set of accounts that may be used for billing for this EpisodeOfCare."
      }
    ],
    "EventDefinition": [
      {
        "Data Fields": "resourceType",
        "Type": "EventDefinition",
        
        
        "Required": "True",
        "Description": "This is a EventDefinition resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "url",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "An absolute URI that is used to identify this event definition when it is referenced in a specification, model, design or an instance; also called its canonical identifier. This SHOULD be globally unique and SHOULD be a literal address at which at which an authoritative instance of this event definition is (or will be) published. This URL can be the target of a canonical reference. It SHALL remain the same when the event definition is stored on different servers."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A formal identifier that is used to identify this event definition when it is represented in other formats, or referenced in a specification, model, design or an instance."
      },
      {
        "Data Fields": "version",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The identifier that is used to identify this version of the event definition when it is referenced in a specification, model, design or instance. This is an arbitrary value managed by the event definition author and is not expected to be globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if a managed version is not available. There is also no expectation that versions can be placed in a lexicographical sequence."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A natural language name identifying the event definition. This name should be usable as an identifier for the module by machine processing applications such as code generation."
      },
      {
        "Data Fields": "title",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A short, descriptive, user-friendly title for the event definition."
      },
      {
        "Data Fields": "subtitle",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "An explanatory or alternate title for the event definition giving additional information about its content."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The status of this event definition. Enables tracking the life-cycle of the content."
      },
      {
        "Data Fields": "experimental",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "A Boolean value to indicate that this event definition is authored for testing purposes (or education/evaluation/marketing) and is not intended to be used for genuine usage."
      },
      {
        "Data Fields": "subjectCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code or group definition that describes the intended subject of the event definition."
      },
      {
        "Data Fields": "subjectReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A code or group definition that describes the intended subject of the event definition."
      },
      {
        "Data Fields": "date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date  (and optionally time) when the event definition was published. The date must change when the business version changes and it must change if the status code changes. In addition, it should change when the substantive content of the event definition changes."
      },
      {
        "Data Fields": "publisher",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the organization or individual that published the event definition."
      },
      {
        "Data Fields": "contact",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "Contact details to assist a user in finding and communicating with the publisher."
      },
      {
        "Data Fields": "description",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A free text natural language description of the event definition from a consumer's perspective."
      },
      {
        "Data Fields": "useContext",
        "Type": "UsageContext",
        
        
        "Required": "False",
        "Description": "The content was developed with a focus and intent of supporting the contexts that are listed. These contexts may be general categories (gender, age, ...) or may be references to specific programs (insurance plans, studies, ...) and may be used to assist with indexing and searching for appropriate event definition instances."
      },
      {
        "Data Fields": "jurisdiction",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A legal or geographic region in which the event definition is intended to be used."
      },
      {
        "Data Fields": "purpose",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Explanation of why this event definition is needed and why it has been designed as it has."
      },
      {
        "Data Fields": "usage",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A detailed description of how the event definition is used from a clinical perspective."
      },
      {
        "Data Fields": "copyright",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A copyright statement relating to the event definition and/or its contents. Copyright statements are generally legal restrictions on the use and publishing of the event definition."
      },
      {
        "Data Fields": "approvalDate",
        "Type": "date",
        
        
        "Required": "False",
        "Description": "The date on which the resource content was approved by the publisher. Approval happens once when the content is officially approved for usage."
      },
      {
        "Data Fields": "lastReviewDate",
        "Type": "date",
        
        
        "Required": "False",
        "Description": "The date on which the resource content was last reviewed. Review happens periodically after approval but does not change the original approval date."
      },
      {
        "Data Fields": "effectivePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The period during which the event definition content was or is planned to be in active use."
      },
      {
        "Data Fields": "topic",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Descriptive topics related to the module. Topics provide a high-level categorization of the module that can be useful for filtering and searching."
      },
      {
        "Data Fields": "author",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individiual or organization primarily involved in the creation and maintenance of the content."
      },
      {
        "Data Fields": "editor",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individual or organization primarily responsible for internal coherence of the content."
      },
      {
        "Data Fields": "reviewer",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individual or organization primarily responsible for review of some aspect of the content."
      },
      {
        "Data Fields": "endorser",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individual or organization responsible for officially endorsing the content for use in some setting."
      },
      {
        "Data Fields": "relatedArtifact",
        "Type": "RelatedArtifact",
        
        
        "Required": "False",
        "Description": "Related resources such as additional documentation, justification, or bibliographic references."
      },
      {
        "Data Fields": "trigger",
        "Type": "TriggerDefinition",
        
        
        "Required": "True",
        "Description": "The trigger element defines when the event occurs. If more than one trigger condition is specified, the event fires whenever any one of the trigger conditions is met."
      }
    ],
    "Evidence": [
      {
        "Data Fields": "resourceType",
        "Type": "Evidence",
        
        
        "Required": "True",
        "Description": "This is a Evidence resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "url",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "An absolute URI that is used to identify this evidence when it is referenced in a specification, model, design or an instance; also called its canonical identifier. This SHOULD be globally unique and SHOULD be a literal address at which at which an authoritative instance of this evidence is (or will be) published. This URL can be the target of a canonical reference. It SHALL remain the same when the evidence is stored on different servers."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A formal identifier that is used to identify this evidence when it is represented in other formats, or referenced in a specification, model, design or an instance."
      },
      {
        "Data Fields": "version",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The identifier that is used to identify this version of the evidence when it is referenced in a specification, model, design or instance. This is an arbitrary value managed by the evidence author and is not expected to be globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if a managed version is not available. There is also no expectation that versions can be placed in a lexicographical sequence. To provide a version consistent with the Decision Support Service specification, use the format Major.Minor.Revision (e.g. 1.0.0). For more information on versioning knowledge assets, refer to the Decision Support Service specification. Note that a version is required for non-experimental active artifacts."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A natural language name identifying the evidence. This name should be usable as an identifier for the module by machine processing applications such as code generation."
      },
      {
        "Data Fields": "title",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A short, descriptive, user-friendly title for the evidence."
      },
      {
        "Data Fields": "shortTitle",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The short title provides an alternate title for use in informal descriptive contexts where the full, formal title is not necessary."
      },
      {
        "Data Fields": "subtitle",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "An explanatory or alternate title for the Evidence giving additional information about its content."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The status of this evidence. Enables tracking the life-cycle of the content."
      },
      {
        "Data Fields": "date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date  (and optionally time) when the evidence was published. The date must change when the business version changes and it must change if the status code changes. In addition, it should change when the substantive content of the evidence changes."
      },
      {
        "Data Fields": "publisher",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the organization or individual that published the evidence."
      },
      {
        "Data Fields": "contact",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "Contact details to assist a user in finding and communicating with the publisher."
      },
      {
        "Data Fields": "description",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A free text natural language description of the evidence from a consumer's perspective."
      },
      {
        "Data Fields": "note",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "A human-readable string to clarify or explain concepts about the resource."
      },
      {
        "Data Fields": "useContext",
        "Type": "UsageContext",
        
        
        "Required": "False",
        "Description": "The content was developed with a focus and intent of supporting the contexts that are listed. These contexts may be general categories (gender, age, ...) or may be references to specific programs (insurance plans, studies, ...) and may be used to assist with indexing and searching for appropriate evidence instances."
      },
      {
        "Data Fields": "jurisdiction",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A legal or geographic region in which the evidence is intended to be used."
      },
      {
        "Data Fields": "copyright",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A copyright statement relating to the evidence and/or its contents. Copyright statements are generally legal restrictions on the use and publishing of the evidence."
      },
      {
        "Data Fields": "approvalDate",
        "Type": "date",
        
        
        "Required": "False",
        "Description": "The date on which the resource content was approved by the publisher. Approval happens once when the content is officially approved for usage."
      },
      {
        "Data Fields": "lastReviewDate",
        "Type": "date",
        
        
        "Required": "False",
        "Description": "The date on which the resource content was last reviewed. Review happens periodically after approval but does not change the original approval date."
      },
      {
        "Data Fields": "effectivePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The period during which the evidence content was or is planned to be in active use."
      },
      {
        "Data Fields": "topic",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Descriptive topics related to the content of the Evidence. Topics provide a high-level categorization grouping types of Evidences that can be useful for filtering and searching."
      },
      {
        "Data Fields": "author",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individiual or organization primarily involved in the creation and maintenance of the content."
      },
      {
        "Data Fields": "editor",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individual or organization primarily responsible for internal coherence of the content."
      },
      {
        "Data Fields": "reviewer",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individual or organization primarily responsible for review of some aspect of the content."
      },
      {
        "Data Fields": "endorser",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individual or organization responsible for officially endorsing the content for use in some setting."
      },
      {
        "Data Fields": "relatedArtifact",
        "Type": "RelatedArtifact",
        
        
        "Required": "False",
        "Description": "Related artifacts such as additional documentation, justification, or bibliographic references."
      },
      {
        "Data Fields": "exposureBackground",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "A reference to a EvidenceVariable resource that defines the population for the research."
      },
      {
        "Data Fields": "exposureVariant",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A reference to a EvidenceVariable resource that defines the exposure for the research."
      },
      {
        "Data Fields": "outcome",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A reference to a EvidenceVariable resomece that defines the outcome for the research."
      }
    ],
    "EvidenceVariable": [
      {
        "Data Fields": "resourceType",
        "Type": "EvidenceVariable",
        
        
        "Required": "True",
        "Description": "This is a EvidenceVariable resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "url",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "An absolute URI that is used to identify this evidence variable when it is referenced in a specification, model, design or an instance; also called its canonical identifier. This SHOULD be globally unique and SHOULD be a literal address at which at which an authoritative instance of this evidence variable is (or will be) published. This URL can be the target of a canonical reference. It SHALL remain the same when the evidence variable is stored on different servers."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A formal identifier that is used to identify this evidence variable when it is represented in other formats, or referenced in a specification, model, design or an instance."
      },
      {
        "Data Fields": "version",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The identifier that is used to identify this version of the evidence variable when it is referenced in a specification, model, design or instance. This is an arbitrary value managed by the evidence variable author and is not expected to be globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if a managed version is not available. There is also no expectation that versions can be placed in a lexicographical sequence. To provide a version consistent with the Decision Support Service specification, use the format Major.Minor.Revision (e.g. 1.0.0). For more information on versioning knowledge assets, refer to the Decision Support Service specification. Note that a version is required for non-experimental active artifacts."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A natural language name identifying the evidence variable. This name should be usable as an identifier for the module by machine processing applications such as code generation."
      },
      {
        "Data Fields": "title",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A short, descriptive, user-friendly title for the evidence variable."
      },
      {
        "Data Fields": "shortTitle",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The short title provides an alternate title for use in informal descriptive contexts where the full, formal title is not necessary."
      },
      {
        "Data Fields": "subtitle",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "An explanatory or alternate title for the EvidenceVariable giving additional information about its content."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The status of this evidence variable. Enables tracking the life-cycle of the content."
      },
      {
        "Data Fields": "date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date  (and optionally time) when the evidence variable was published. The date must change when the business version changes and it must change if the status code changes. In addition, it should change when the substantive content of the evidence variable changes."
      },
      {
        "Data Fields": "publisher",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the organization or individual that published the evidence variable."
      },
      {
        "Data Fields": "contact",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "Contact details to assist a user in finding and communicating with the publisher."
      },
      {
        "Data Fields": "description",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A free text natural language description of the evidence variable from a consumer's perspective."
      },
      {
        "Data Fields": "note",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "A human-readable string to clarify or explain concepts about the resource."
      },
      {
        "Data Fields": "useContext",
        "Type": "UsageContext",
        
        
        "Required": "False",
        "Description": "The content was developed with a focus and intent of supporting the contexts that are listed. These contexts may be general categories (gender, age, ...) or may be references to specific programs (insurance plans, studies, ...) and may be used to assist with indexing and searching for appropriate evidence variable instances."
      },
      {
        "Data Fields": "jurisdiction",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A legal or geographic region in which the evidence variable is intended to be used."
      },
      {
        "Data Fields": "copyright",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A copyright statement relating to the evidence variable and/or its contents. Copyright statements are generally legal restrictions on the use and publishing of the evidence variable."
      },
      {
        "Data Fields": "approvalDate",
        "Type": "date",
        
        
        "Required": "False",
        "Description": "The date on which the resource content was approved by the publisher. Approval happens once when the content is officially approved for usage."
      },
      {
        "Data Fields": "lastReviewDate",
        "Type": "date",
        
        
        "Required": "False",
        "Description": "The date on which the resource content was last reviewed. Review happens periodically after approval but does not change the original approval date."
      },
      {
        "Data Fields": "effectivePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The period during which the evidence variable content was or is planned to be in active use."
      },
      {
        "Data Fields": "topic",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Descriptive topics related to the content of the EvidenceVariable. Topics provide a high-level categorization grouping types of EvidenceVariables that can be useful for filtering and searching."
      },
      {
        "Data Fields": "author",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individiual or organization primarily involved in the creation and maintenance of the content."
      },
      {
        "Data Fields": "editor",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individual or organization primarily responsible for internal coherence of the content."
      },
      {
        "Data Fields": "reviewer",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individual or organization primarily responsible for review of some aspect of the content."
      },
      {
        "Data Fields": "endorser",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individual or organization responsible for officially endorsing the content for use in some setting."
      },
      {
        "Data Fields": "relatedArtifact",
        "Type": "RelatedArtifact",
        
        
        "Required": "False",
        "Description": "Related artifacts such as additional documentation, justification, or bibliographic references."
      },
      {
        "Data Fields": "type",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The type of evidence element, a population, an exposure, or an outcome."
      },
      {
        "Data Fields": "characteristic",
        "Type": "BackboneElement",
        
        
        "Required": "True",
        "Description": "A characteristic that defines the members of the evidence element. Multiple characteristics are applied with \"and\" semantics."
      },
      {
        "Data Fields": "characteristic.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "characteristic.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "characteristic.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "characteristic.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A short, natural language description of the characteristic that could be used to communicate the criteria to an end-user."
      },
      {
        "Data Fields": "characteristic.definitionReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Define members of the evidence element using Codes (such as condition, medication, or observation), Expressions ( using an expression language such as FHIRPath or CQL) or DataRequirements (such as Diabetes diagnosis onset in the last year)."
      },
      {
        "Data Fields": "characteristic.definitionCanonical",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Define members of the evidence element using Codes (such as condition, medication, or observation), Expressions ( using an expression language such as FHIRPath or CQL) or DataRequirements (such as Diabetes diagnosis onset in the last year)."
      },
      {
        "Data Fields": "characteristic.definitionCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Define members of the evidence element using Codes (such as condition, medication, or observation), Expressions ( using an expression language such as FHIRPath or CQL) or DataRequirements (such as Diabetes diagnosis onset in the last year)."
      },
      {
        "Data Fields": "characteristic.definitionExpression",
        "Type": "Expression",
        
        
        "Required": "False",
        "Description": "Define members of the evidence element using Codes (such as condition, medication, or observation), Expressions ( using an expression language such as FHIRPath or CQL) or DataRequirements (such as Diabetes diagnosis onset in the last year)."
      },
      {
        "Data Fields": "characteristic.definitionDataRequirement",
        "Type": "DataRequirement",
        
        
        "Required": "False",
        "Description": "Define members of the evidence element using Codes (such as condition, medication, or observation), Expressions ( using an expression language such as FHIRPath or CQL) or DataRequirements (such as Diabetes diagnosis onset in the last year)."
      },
      {
        "Data Fields": "characteristic.definitionTriggerDefinition",
        "Type": "TriggerDefinition",
        
        
        "Required": "False",
        "Description": "Define members of the evidence element using Codes (such as condition, medication, or observation), Expressions ( using an expression language such as FHIRPath or CQL) or DataRequirements (such as Diabetes diagnosis onset in the last year)."
      },
      {
        "Data Fields": "characteristic.usageContext",
        "Type": "UsageContext",
        
        
        "Required": "False",
        "Description": "Use UsageContext to define the members of the population, such as Age Ranges, Genders, Settings."
      },
      {
        "Data Fields": "characteristic.exclude",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "When true, members with this characteristic are excluded from the element."
      },
      {
        "Data Fields": "characteristic.participantEffectiveDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Indicates what effective period the study covers."
      },
      {
        "Data Fields": "characteristic.participantEffectivePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "Indicates what effective period the study covers."
      },
      {
        "Data Fields": "characteristic.participantEffectiveDuration",
        "Type": "Duration",
        
        
        "Required": "False",
        "Description": "Indicates what effective period the study covers."
      },
      {
        "Data Fields": "characteristic.participantEffectiveTiming",
        "Type": "Timing",
        
        
        "Required": "False",
        "Description": "Indicates what effective period the study covers."
      },
      {
        "Data Fields": "characteristic.timeFromStart",
        "Type": "Duration",
        
        
        "Required": "False",
        "Description": "Indicates duration from the participant's study entry."
      },
      {
        "Data Fields": "characteristic.groupMeasure",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Indicates how elements are aggregated within the study effective period."
      }
    ],
    "ExampleScenario": [
      {
        "Data Fields": "resourceType",
        "Type": "ExampleScenario",
        
        
        "Required": "True",
        "Description": "This is a ExampleScenario resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "url",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "An absolute URI that is used to identify this example scenario when it is referenced in a specification, model, design or an instance; also called its canonical identifier. This SHOULD be globally unique and SHOULD be a literal address at which at which an authoritative instance of this example scenario is (or will be) published. This URL can be the target of a canonical reference. It SHALL remain the same when the example scenario is stored on different servers."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A formal identifier that is used to identify this example scenario when it is represented in other formats, or referenced in a specification, model, design or an instance."
      },
      {
        "Data Fields": "version",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The identifier that is used to identify this version of the example scenario when it is referenced in a specification, model, design or instance. This is an arbitrary value managed by the example scenario author and is not expected to be globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if a managed version is not available. There is also no expectation that versions can be placed in a lexicographical sequence."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A natural language name identifying the example scenario. This name should be usable as an identifier for the module by machine processing applications such as code generation."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The status of this example scenario. Enables tracking the life-cycle of the content."
      },
      {
        "Data Fields": "experimental",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "A Boolean value to indicate that this example scenario is authored for testing purposes (or education/evaluation/marketing) and is not intended to be used for genuine usage."
      },
      {
        "Data Fields": "date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date  (and optionally time) when the example scenario was published. The date must change when the business version changes and it must change if the status code changes. In addition, it should change when the substantive content of the example scenario changes. (e.g. the 'content logical definition')."
      },
      {
        "Data Fields": "publisher",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the organization or individual that published the example scenario."
      },
      {
        "Data Fields": "contact",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "Contact details to assist a user in finding and communicating with the publisher."
      },
      {
        "Data Fields": "useContext",
        "Type": "UsageContext",
        
        
        "Required": "False",
        "Description": "The content was developed with a focus and intent of supporting the contexts that are listed. These contexts may be general categories (gender, age, ...) or may be references to specific programs (insurance plans, studies, ...) and may be used to assist with indexing and searching for appropriate example scenario instances."
      },
      {
        "Data Fields": "jurisdiction",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A legal or geographic region in which the example scenario is intended to be used."
      },
      {
        "Data Fields": "copyright",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A copyright statement relating to the example scenario and/or its contents. Copyright statements are generally legal restrictions on the use and publishing of the example scenario."
      },
      {
        "Data Fields": "purpose",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "What the example scenario resource is created for. This should not be used to show the business purpose of the scenario itself, but the purpose of documenting a scenario."
      },
      {
        "Data Fields": "actor",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Actor participating in the resource."
      },
      {
        "Data Fields": "actor.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "actor.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "actor.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "actor.actorId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "ID or acronym of actor."
      },
      {
        "Data Fields": "actor.type",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The type of actor - person or system."
      },
      {
        "Data Fields": "actor.name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the actor as shown in the page."
      },
      {
        "Data Fields": "actor.description",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "The description of the actor."
      },
      {
        "Data Fields": "instance",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Each resource and each version that is present in the workflow."
      },
      {
        "Data Fields": "instance.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "instance.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "instance.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "instance.resourceId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The id of the resource for referencing."
      },
      {
        "Data Fields": "instance.resourceType",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The type of the resource."
      },
      {
        "Data Fields": "instance.name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A short name for the resource instance."
      },
      {
        "Data Fields": "instance.description",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Human-friendly description of the resource instance."
      },
      {
        "Data Fields": "instance.version",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A specific version of the resource."
      },
      {
        "Data Fields": "instance.version.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "instance.version.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "instance.version.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "instance.version.versionId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The identifier of a specific version of a resource."
      },
      {
        "Data Fields": "instance.version.description",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "The description of the resource version."
      },
      {
        "Data Fields": "instance.containedInstance",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Resources contained in the instance (e.g. the observations contained in a bundle)."
      },
      {
        "Data Fields": "instance.containedInstance.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "instance.containedInstance.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "instance.containedInstance.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "instance.containedInstance.resourceId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Each resource contained in the instance."
      },
      {
        "Data Fields": "instance.containedInstance.versionId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A specific version of a resource contained in the instance."
      },
      {
        "Data Fields": "process",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Each major process - a group of operations."
      },
      {
        "Data Fields": "process.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "process.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "process.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "process.title",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The diagram title of the group of operations."
      },
      {
        "Data Fields": "process.description",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A longer description of the group of operations."
      },
      {
        "Data Fields": "process.preConditions",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Description of initial status before the process starts."
      },
      {
        "Data Fields": "process.postConditions",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Description of final status after the process ends."
      },
      {
        "Data Fields": "process.step",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Each step of the process."
      },
      {
        "Data Fields": "process.step.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "process.step.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "process.step.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "process.step.pause",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "If there is a pause in the flow."
      },
      {
        "Data Fields": "process.step.operation",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Each interaction or action."
      },
      {
        "Data Fields": "process.step.operation.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "process.step.operation.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "process.step.operation.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "process.step.operation.number",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The sequential number of the interaction, e.g. 1.2.5."
      },
      {
        "Data Fields": "process.step.operation.type",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The type of operation - CRUD."
      },
      {
        "Data Fields": "process.step.operation.name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The human-friendly name of the interaction."
      },
      {
        "Data Fields": "process.step.operation.initiator",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Who starts the transaction."
      },
      {
        "Data Fields": "process.step.operation.receiver",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Who receives the transaction."
      },
      {
        "Data Fields": "process.step.operation.description",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A comment to be inserted in the diagram."
      },
      {
        "Data Fields": "process.step.operation.initiatorActive",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Whether the initiator is deactivated right after the transaction."
      },
      {
        "Data Fields": "process.step.operation.receiverActive",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Whether the receiver is deactivated right after the transaction."
      },
      {
        "Data Fields": "process.step.operation.request",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Each resource instance used by the initiator."
      },
      {
        "Data Fields": "process.step.operation.request.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "process.step.operation.request.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "process.step.operation.request.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "process.step.operation.request.resourceId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Each resource contained in the instance."
      },
      {
        "Data Fields": "process.step.operation.request.versionId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A specific version of a resource contained in the instance."
      },
      {
        "Data Fields": "process.step.operation.response",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Each resource instance used by the responder."
      },
      {
        "Data Fields": "process.step.operation.response.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "process.step.operation.response.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "process.step.operation.response.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "process.step.operation.response.resourceId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Each resource contained in the instance."
      },
      {
        "Data Fields": "process.step.operation.response.versionId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A specific version of a resource contained in the instance."
      },
      {
        "Data Fields": "process.step.alternative",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Indicates an alternative step that can be taken instead of the operations on the base step in exceptional/atypical circumstances."
      },
      {
        "Data Fields": "process.step.alternative.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "process.step.alternative.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "process.step.alternative.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "process.step.alternative.title",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The label to display for the alternative that gives a sense of the circumstance in which the alternative should be invoked."
      },
      {
        "Data Fields": "process.step.alternative.description",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A human-readable description of the alternative explaining when the alternative should occur rather than the base step."
      },
      {
        "Data Fields": "workflow",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "Another nested workflow."
      }
    ],
    "ExplanationOfBenefit": [
      {
        "Data Fields": "resourceType",
        "Type": "ExplanationOfBenefit",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "This is a ExplanationOfBenefit resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A unique identifier assigned to this explanation of benefit."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The status of the resource instance."
      },
      {
        "Data Fields": "type",
        "Type": "CodeableConcept",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "The category of claim, e.g. oral, pharmacy, vision, institutional, professional."
      },
      {
        "Data Fields": "subType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A finer grained suite of claim type codes which may convey additional information such as Inpatient vs Outpatient and/or a specialty service."
      },
      {
        "Data Fields": "use",
        "Type": "code",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A code to indicate whether the nature of the request is: to request adjudication of products and services previously rendered; or requesting authorization and adjudication for provision in the future; or requesting the non-binding adjudication of the listed products and services which could be provided in the future."
      },
      {
        "Data Fields": "patient",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "The party to whom the professional services and/or products have been supplied or are being considered and for whom actual for forecast reimbursement is sought."
      },
      {
        "Data Fields": "billablePeriod",
        "Type": "Period",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The period for which charges are being submitted."
      },
      {
        "Data Fields": "created",
        "Type": "dateTime",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The date this resource was created."
      },
      {
        "Data Fields": "enterer",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Individual who created the claim, predetermination or preauthorization."
      },
      {
        "Data Fields": "insurer",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "The party responsible for authorization, adjudication and reimbursement."
      },
      {
        "Data Fields": "provider",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "The provider which is responsible for the claim, predetermination or preauthorization."
      },
      {
        "Data Fields": "priority",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The provider-required urgency of processing the request. Typical values include: stat, routine deferred."
      },
      {
        "Data Fields": "fundsReserveRequested",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code to indicate whether and for whom funds are to be reserved for future claims."
      },
      {
        "Data Fields": "fundsReserve",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code, used only on a response to a preauthorization, to indicate whether the benefits payable have been reserved and for whom."
      },
      {
        "Data Fields": "related",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Other claims which are related to this claim such as prior submissions or claims for related services or for the same event."
      },
      {
        "Data Fields": "related.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "related.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "related.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "related.claim",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Reference to a related claim."
      },
      {
        "Data Fields": "related.relationship",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code to convey how the claims are related."
      },
      {
        "Data Fields": "related.reference",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "An alternate organizational reference to the case or file to which this particular claim pertains."
      },
      {
        "Data Fields": "prescription",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Prescription to support the dispensing of pharmacy, device or vision products."
      },
      {
        "Data Fields": "originalPrescription",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Original prescription which has been superseded by this prescription to support the dispensing of pharmacy services, medications or products."
      },
      {
        "Data Fields": "payee",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The party to be reimbursed for cost of the products and services according to the terms of the policy."
      },
      {
        "Data Fields": "payee.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "payee.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "payee.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "payee.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Type of Party to be reimbursed: Subscriber, provider, other."
      },
      {
        "Data Fields": "payee.party",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Reference to the individual or organization to whom any payment will be made."
      },
      {
        "Data Fields": "referral",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A reference to a referral resource."
      },
      {
        "Data Fields": "facility",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Facility where the services were provided."
      },
      {
        "Data Fields": "claim",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The business identifier for the instance of the adjudication request: claim predetermination or preauthorization."
      },
      {
        "Data Fields": "claimResponse",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The business identifier for the instance of the adjudication response: claim, predetermination or preauthorization response."
      },
      {
        "Data Fields": "outcome",
        "Type": "code",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The outcome of the claim, predetermination, or preauthorization processing."
      },
      {
        "Data Fields": "disposition",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A human readable description of the status of the adjudication."
      },
      {
        "Data Fields": "preAuthRef",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Reference from the Insurer which is used in later communications which refers to this adjudication."
      },
      {
        "Data Fields": "preAuthRefPeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The timeframe during which the supplied preauthorization reference may be quoted on claims to obtain the adjudication as provided."
      },
      {
        "Data Fields": "careTeam",
        "Type": "BackboneElement",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The members of the team who provided the products and services."
      },
      {
        "Data Fields": "careTeam.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "careTeam.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "careTeam.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "careTeam.sequence",
        "Type": "positiveInt",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A number to uniquely identify care team entries."
      },
      {
        "Data Fields": "careTeam.provider",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Member of the team who provided the product or service."
      },
      {
        "Data Fields": "careTeam.responsible",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "The party who is billing and/or responsible for the claimed products or services."
      },
      {
        "Data Fields": "careTeam.role",
        "Type": "CodeableConcept",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The lead, assisting or supervising practitioner and their discipline if a multidisciplinary team."
      },
      {
        "Data Fields": "careTeam.qualification",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The qualification of the practitioner which is applicable for this service."
      },
      {
        "Data Fields": "supportingInfo",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Additional information codes regarding exceptions, special considerations, the condition, situation, prior or concurrent issues."
      },
      {
        "Data Fields": "supportingInfo.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "supportingInfo.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "supportingInfo.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "supportingInfo.sequence",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "A number to uniquely identify supporting information entries."
      },
      {
        "Data Fields": "supportingInfo.category",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The general class of the information supplied: information; exception; accident, employment; onset, etc."
      },
      {
        "Data Fields": "supportingInfo.code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "System and code pertaining to the specific information regarding special conditions relating to the setting, treatment or patient  for which care is sought."
      },
      {
        "Data Fields": "supportingInfo.timingDate",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The date when or period to which this information refers."
      },
      {
        "Data Fields": "supportingInfo.timingPeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The date when or period to which this information refers."
      },
      {
        "Data Fields": "supportingInfo.valueBoolean",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Additional data or information such as resources, documents, images etc. including references to the data or the actual inclusion of the data."
      },
      {
        "Data Fields": "supportingInfo.valueString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Additional data or information such as resources, documents, images etc. including references to the data or the actual inclusion of the data."
      },
      {
        "Data Fields": "supportingInfo.valueQuantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "Additional data or information such as resources, documents, images etc. including references to the data or the actual inclusion of the data."
      },
      {
        "Data Fields": "supportingInfo.valueAttachment",
        "Type": "Attachment",
        
        
        "Required": "False",
        "Description": "Additional data or information such as resources, documents, images etc. including references to the data or the actual inclusion of the data."
      },
      {
        "Data Fields": "supportingInfo.valueReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Additional data or information such as resources, documents, images etc. including references to the data or the actual inclusion of the data."
      },
      {
        "Data Fields": "supportingInfo.reason",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "Provides the reason in the situation where a reason code is required in addition to the content."
      },
      {
        "Data Fields": "diagnosis",
        "Type": "BackboneElement",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Information about diagnoses relevant to the claim items."
      },
      {
        "Data Fields": "diagnosis.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "diagnosis.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "diagnosis.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "diagnosis.sequence",
        "Type": "positiveInt",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A number to uniquely identify diagnosis entries."
      },
      {
        "Data Fields": "diagnosis.diagnosisCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The nature of illness or problem in a coded form or as a reference to an external defined Condition."
      },
      {
        "Data Fields": "diagnosis.diagnosisReference",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The nature of illness or problem in a coded form or as a reference to an external defined Condition."
      },
      {
        "Data Fields": "diagnosis.type",
        "Type": "CodeableConcept",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "When the condition was observed or the relative ranking."
      },
      {
        "Data Fields": "diagnosis.onAdmission",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Indication of whether the diagnosis was present on admission to a facility."
      },
      {
        "Data Fields": "diagnosis.packageCode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A package billing code or bundle code used to group products and services to a particular health condition (such as heart attack) which is based on a predetermined grouping code system."
      },
      {
        "Data Fields": "procedure",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Procedures performed on the patient relevant to the billing items with the claim."
      },
      {
        "Data Fields": "procedure.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "procedure.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "procedure.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "procedure.sequence",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "A number to uniquely identify procedure entries."
      },
      {
        "Data Fields": "procedure.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "When the condition was observed or the relative ranking."
      },
      {
        "Data Fields": "procedure.date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "Date and optionally time the procedure was performed."
      },
      {
        "Data Fields": "procedure.procedureCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The code or reference to a Procedure resource which identifies the clinical intervention performed."
      },
      {
        "Data Fields": "procedure.procedureReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The code or reference to a Procedure resource which identifies the clinical intervention performed."
      },
      {
        "Data Fields": "procedure.udi",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Unique Device Identifiers associated with this line item."
      },
      {
        "Data Fields": "precedence",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "This indicates the relative order of a series of EOBs related to different coverages for the same suite of services."
      },
      {
        "Data Fields": "insurance",
        "Type": "BackboneElement",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "Financial instruments for reimbursement for the health care products and services specified on the claim."
      },
      {
        "Data Fields": "insurance.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "insurance.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "insurance.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "insurance.focal",
        "Type": "boolean",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A flag to indicate that this Coverage is to be used for adjudication of this claim when set to true."
      },
      {
        "Data Fields": "insurance.coverage",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Reference to the insurance card level information contained in the Coverage resource. The coverage issuing insurer will use these details to locate the patient's actual coverage within the insurer's information system."
      },
      {
        "Data Fields": "insurance.preAuthRef",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Reference numbers previously provided by the insurer to the provider to be quoted on subsequent claims containing services or products related to the prior authorization."
      },
      {
        "Data Fields": "accident",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Details of a accident which resulted in injuries which required the products and services listed in the claim."
      },
      {
        "Data Fields": "accident.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "accident.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "accident.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "accident.date",
        "Type": "date",
        
        
        "Required": "False",
        "Description": "Date of an accident event  related to the products and services contained in the claim."
      },
      {
        "Data Fields": "accident.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The type or context of the accident event for the purposes of selection of potential insurance coverages and determination of coordination between insurers."
      },
      {
        "Data Fields": "accident.locationAddress",
        "Type": "Address",
        
        
        "Required": "False",
        "Description": "The physical location of the accident event."
      },
      {
        "Data Fields": "accident.locationReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The physical location of the accident event."
      },
      {
        "Data Fields": "item",
        "Type": "BackboneElement",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A claim line. Either a simple (a product or service) or a 'group' of details which can also be a simple items or groups of sub-details."
      },
      {
        "Data Fields": "item.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "item.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "item.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "item.sequence",
        "Type": "positiveInt",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A number to uniquely identify item entries."
      },
      {
        "Data Fields": "item.careTeamSequence",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "Care team members related to this service or product."
      },
      {
        "Data Fields": "item.diagnosisSequence",
        "Type": "positiveInt",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Diagnoses applicable for this service or product."
      },
      {
        "Data Fields": "item.procedureSequence",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "Procedures applicable for this service or product."
      },
      {
        "Data Fields": "item.informationSequence",
        "Type": "positiveInt",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Exceptions, special conditions and supporting information applicable for this service or product."
      },
      {
        "Data Fields": "item.revenue",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The type of revenue or cost center providing the product and/or service."
      },
      {
        "Data Fields": "item.category",
        "Type": "CodeableConcept",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Code to identify the general type of benefits under which products and services are provided."
      },
      {
        "Data Fields": "item.productOrService",
        "Type": "CodeableConcept",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "When the value is a group code then this item collects a set of related claim details, otherwise this contains the product, service, drug or other billing code for the item."
      },
      {
        "Data Fields": "item.modifier",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Item typification or modifiers codes to convey additional context for the product or service."
      },
      {
        "Data Fields": "item.programCode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Identifies the program under which this may be recovered."
      },
      {
        "Data Fields": "item.servicedDate",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The date or dates when the service or product was supplied, performed or completed."
      },
      {
        "Data Fields": "item.servicedPeriod",
        "Type": "Period",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The date or dates when the service or product was supplied, performed or completed."
      },
      {
        "Data Fields": "item.locationCodeableConcept",
        "Type": "CodeableConcept",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Where the product or service was provided."
      },
      {
        "Data Fields": "item.locationAddress",
        "Type": "Address",
        
        
        "Required": "False",
        "Description": "Where the product or service was provided."
      },
      {
        "Data Fields": "item.locationReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Where the product or service was provided."
      },
      {
        "Data Fields": "item.quantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The number of repetitions of a service or product."
      },
      {
        "Data Fields": "item.unitPrice",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "If the item is not a group then this is the fee for the product or service, otherwise this is the total of the fees for the details of the group."
      },
      {
        "Data Fields": "item.factor",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "A real number that represents a multiplier used in determining the overall value of services delivered and/or goods received. The concept of a Factor allows for a discount or surcharge multiplier to be applied to a monetary amount."
      },
      {
        "Data Fields": "item.net",
        "Type": "Money",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The quantity times the unit price for an additional service or product or charge."
      },
      {
        "Data Fields": "item.udi",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Unique Device Identifiers associated with this line item."
      },
      {
        "Data Fields": "item.bodySite",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Physical service site on the patient (limb, tooth, etc.)."
      },
      {
        "Data Fields": "item.subSite",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A region or surface of the bodySite, e.g. limb region or tooth surface(s)."
      },
      {
        "Data Fields": "item.encounter",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A billed item may include goods or services provided in multiple encounters."
      },
      {
        "Data Fields": "item.noteNumber",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "The numbers associated with notes below which apply to the adjudication of this item."
      },
      {
        "Data Fields": "item.adjudication",
        "Type": "BackboneElement",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "If this item is a group then the values here are a summary of the adjudication of the detail items. If this item is a simple product or service then this is the result of the adjudication of this item."
      },
      {
        "Data Fields": "item.adjudication.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "item.adjudication.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "item.adjudication.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "item.adjudication.category",
        "Type": "CodeableConcept",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A code to indicate the information type of this adjudication record. Information types may include: the value submitted, maximum values or percentages allowed or payable under the plan, amounts that the patient is responsible for in-aggregate or pertaining to this item, amounts paid by other coverages, and the benefit payable for this item."
      },
      {
        "Data Fields": "item.adjudication.reason",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code supporting the understanding of the adjudication result and explaining variance from expected amount."
      },
      {
        "Data Fields": "item.adjudication.amount",
        "Type": "Money",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Monetary amount associated with the category."
      },
      {
        "Data Fields": "item.adjudication.value",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "A non-monetary value associated with the category. Mutually exclusive to the amount element above."
      },
      {
        "Data Fields": "item.detail",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Second-tier of goods and services."
      },
      {
        "Data Fields": "item.detail.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "item.detail.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "item.detail.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "item.detail.sequence",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "A claim detail line. Either a simple (a product or service) or a 'group' of sub-details which are simple items."
      },
      {
        "Data Fields": "item.detail.revenue",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The type of revenue or cost center providing the product and/or service."
      },
      {
        "Data Fields": "item.detail.category",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Code to identify the general type of benefits under which products and services are provided."
      },
      {
        "Data Fields": "item.detail.productOrService",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "When the value is a group code then this item collects a set of related claim details, otherwise this contains the product, service, drug or other billing code for the item."
      },
      {
        "Data Fields": "item.detail.modifier",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Item typification or modifiers codes to convey additional context for the product or service."
      },
      {
        "Data Fields": "item.detail.programCode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Identifies the program under which this may be recovered."
      },
      {
        "Data Fields": "item.detail.quantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The number of repetitions of a service or product."
      },
      {
        "Data Fields": "item.detail.unitPrice",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "If the item is not a group then this is the fee for the product or service, otherwise this is the total of the fees for the details of the group."
      },
      {
        "Data Fields": "item.detail.factor",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "A real number that represents a multiplier used in determining the overall value of services delivered and/or goods received. The concept of a Factor allows for a discount or surcharge multiplier to be applied to a monetary amount."
      },
      {
        "Data Fields": "item.detail.net",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "The quantity times the unit price for an additional service or product or charge."
      },
      {
        "Data Fields": "item.detail.udi",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Unique Device Identifiers associated with this line item."
      },
      {
        "Data Fields": "item.detail.noteNumber",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "The numbers associated with notes below which apply to the adjudication of this item."
      },
      {
        "Data Fields": "item.detail.adjudication",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The adjudication results."
      },
      {
        "Data Fields": "item.detail.adjudication.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "item.detail.adjudication.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "item.detail.adjudication.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "item.detail.adjudication.category",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code to indicate the information type of this adjudication record. Information types may include: the value submitted, maximum values or percentages allowed or payable under the plan, amounts that the patient is responsible for in-aggregate or pertaining to this item, amounts paid by other coverages, and the benefit payable for this item."
      },
      {
        "Data Fields": "item.detail.adjudication.reason",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code supporting the understanding of the adjudication result and explaining variance from expected amount."
      },
      {
        "Data Fields": "item.detail.adjudication.amount",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "Monetary amount associated with the category."
      },
      {
        "Data Fields": "item.detail.adjudication.value",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "A non-monetary value associated with the category. Mutually exclusive to the amount element above."
      },
      {
        "Data Fields": "item.detail.subDetail",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Third-tier of goods and services."
      },
      {
        "Data Fields": "item.detail.subDetail.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "item.detail.subDetail.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "item.detail.subDetail.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "item.detail.subDetail.sequence",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "A claim detail line. Either a simple (a product or service) or a 'group' of sub-details which are simple items."
      },
      {
        "Data Fields": "item.detail.subDetail.revenue",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The type of revenue or cost center providing the product and/or service."
      },
      {
        "Data Fields": "item.detail.subDetail.category",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Code to identify the general type of benefits under which products and services are provided."
      },
      {
        "Data Fields": "item.detail.subDetail.productOrService",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "When the value is a group code then this item collects a set of related claim details, otherwise this contains the product, service, drug or other billing code for the item."
      },
      {
        "Data Fields": "item.detail.subDetail.modifier",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Item typification or modifiers codes to convey additional context for the product or service."
      },
      {
        "Data Fields": "item.detail.subDetail.programCode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Identifies the program under which this may be recovered."
      },
      {
        "Data Fields": "item.detail.subDetail.quantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The number of repetitions of a service or product."
      },
      {
        "Data Fields": "item.detail.subDetail.unitPrice",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "If the item is not a group then this is the fee for the product or service, otherwise this is the total of the fees for the details of the group."
      },
      {
        "Data Fields": "item.detail.subDetail.factor",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "A real number that represents a multiplier used in determining the overall value of services delivered and/or goods received. The concept of a Factor allows for a discount or surcharge multiplier to be applied to a monetary amount."
      },
      {
        "Data Fields": "item.detail.subDetail.net",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "The quantity times the unit price for an additional service or product or charge."
      },
      {
        "Data Fields": "item.detail.subDetail.udi",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Unique Device Identifiers associated with this line item."
      },
      {
        "Data Fields": "item.detail.subDetail.noteNumber",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "The numbers associated with notes below which apply to the adjudication of this item."
      },
      {
        "Data Fields": "item.detail.subDetail.adjudication",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The adjudication results."
      },
      {
        "Data Fields": "item.detail.subDetail.adjudication.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "item.detail.subDetail.adjudication.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "item.detail.subDetail.adjudication.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "item.detail.subDetail.adjudication.category",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code to indicate the information type of this adjudication record. Information types may include: the value submitted, maximum values or percentages allowed or payable under the plan, amounts that the patient is responsible for in-aggregate or pertaining to this item, amounts paid by other coverages, and the benefit payable for this item."
      },
      {
        "Data Fields": "item.detail.subDetail.adjudication.reason",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code supporting the understanding of the adjudication result and explaining variance from expected amount."
      },
      {
        "Data Fields": "item.detail.subDetail.adjudication.amount",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "Monetary amount associated with the category."
      },
      {
        "Data Fields": "item.detail.subDetail.adjudication.value",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "A non-monetary value associated with the category. Mutually exclusive to the amount element above."
      },
      {
        "Data Fields": "addItem",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The first-tier service adjudications for payor added product or service lines."
      },
      {
        "Data Fields": "addItem.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "addItem.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "addItem.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "addItem.itemSequence",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "Claim items which this service line is intended to replace."
      },
      {
        "Data Fields": "addItem.detailSequence",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "The sequence number of the details within the claim item which this line is intended to replace."
      },
      {
        "Data Fields": "addItem.subDetailSequence",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "The sequence number of the sub-details woithin the details within the claim item which this line is intended to replace."
      },
      {
        "Data Fields": "addItem.provider",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The providers who are authorized for the services rendered to the patient."
      },
      {
        "Data Fields": "addItem.productOrService",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "When the value is a group code then this item collects a set of related claim details, otherwise this contains the product, service, drug or other billing code for the item."
      },
      {
        "Data Fields": "addItem.modifier",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Item typification or modifiers codes to convey additional context for the product or service."
      },
      {
        "Data Fields": "addItem.programCode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Identifies the program under which this may be recovered."
      },
      {
        "Data Fields": "addItem.servicedDate",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The date or dates when the service or product was supplied, performed or completed."
      },
      {
        "Data Fields": "addItem.servicedPeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The date or dates when the service or product was supplied, performed or completed."
      },
      {
        "Data Fields": "addItem.locationCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Where the product or service was provided."
      },
      {
        "Data Fields": "addItem.locationAddress",
        "Type": "Address",
        
        
        "Required": "False",
        "Description": "Where the product or service was provided."
      },
      {
        "Data Fields": "addItem.locationReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Where the product or service was provided."
      },
      {
        "Data Fields": "addItem.quantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The number of repetitions of a service or product."
      },
      {
        "Data Fields": "addItem.unitPrice",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "If the item is not a group then this is the fee for the product or service, otherwise this is the total of the fees for the details of the group."
      },
      {
        "Data Fields": "addItem.factor",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "A real number that represents a multiplier used in determining the overall value of services delivered and/or goods received. The concept of a Factor allows for a discount or surcharge multiplier to be applied to a monetary amount."
      },
      {
        "Data Fields": "addItem.net",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "The quantity times the unit price for an additional service or product or charge."
      },
      {
        "Data Fields": "addItem.bodySite",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Physical service site on the patient (limb, tooth, etc.)."
      },
      {
        "Data Fields": "addItem.subSite",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A region or surface of the bodySite, e.g. limb region or tooth surface(s)."
      },
      {
        "Data Fields": "addItem.noteNumber",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "The numbers associated with notes below which apply to the adjudication of this item."
      },
      {
        "Data Fields": "addItem.adjudication",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The adjudication results."
      },
      {
        "Data Fields": "addItem.adjudication.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "addItem.adjudication.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "addItem.adjudication.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "addItem.adjudication.category",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code to indicate the information type of this adjudication record. Information types may include: the value submitted, maximum values or percentages allowed or payable under the plan, amounts that the patient is responsible for in-aggregate or pertaining to this item, amounts paid by other coverages, and the benefit payable for this item."
      },
      {
        "Data Fields": "addItem.adjudication.reason",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code supporting the understanding of the adjudication result and explaining variance from expected amount."
      },
      {
        "Data Fields": "addItem.adjudication.amount",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "Monetary amount associated with the category."
      },
      {
        "Data Fields": "addItem.adjudication.value",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "A non-monetary value associated with the category. Mutually exclusive to the amount element above."
      },
      {
        "Data Fields": "addItem.detail",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The second-tier service adjudications for payor added services."
      },
      {
        "Data Fields": "addItem.detail.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "addItem.detail.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "addItem.detail.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "addItem.detail.productOrService",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "When the value is a group code then this item collects a set of related claim details, otherwise this contains the product, service, drug or other billing code for the item."
      },
      {
        "Data Fields": "addItem.detail.modifier",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Item typification or modifiers codes to convey additional context for the product or service."
      },
      {
        "Data Fields": "addItem.detail.quantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The number of repetitions of a service or product."
      },
      {
        "Data Fields": "addItem.detail.unitPrice",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "If the item is not a group then this is the fee for the product or service, otherwise this is the total of the fees for the details of the group."
      },
      {
        "Data Fields": "addItem.detail.factor",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "A real number that represents a multiplier used in determining the overall value of services delivered and/or goods received. The concept of a Factor allows for a discount or surcharge multiplier to be applied to a monetary amount."
      },
      {
        "Data Fields": "addItem.detail.net",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "The quantity times the unit price for an additional service or product or charge."
      },
      {
        "Data Fields": "addItem.detail.noteNumber",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "The numbers associated with notes below which apply to the adjudication of this item."
      },
      {
        "Data Fields": "addItem.detail.adjudication",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The adjudication results."
      },
      {
        "Data Fields": "addItem.detail.adjudication.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "addItem.detail.adjudication.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "addItem.detail.adjudication.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "addItem.detail.adjudication.category",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code to indicate the information type of this adjudication record. Information types may include: the value submitted, maximum values or percentages allowed or payable under the plan, amounts that the patient is responsible for in-aggregate or pertaining to this item, amounts paid by other coverages, and the benefit payable for this item."
      },
      {
        "Data Fields": "addItem.detail.adjudication.reason",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code supporting the understanding of the adjudication result and explaining variance from expected amount."
      },
      {
        "Data Fields": "addItem.detail.adjudication.amount",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "Monetary amount associated with the category."
      },
      {
        "Data Fields": "addItem.detail.adjudication.value",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "A non-monetary value associated with the category. Mutually exclusive to the amount element above."
      },
      {
        "Data Fields": "addItem.detail.subDetail",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The third-tier service adjudications for payor added services."
      },
      {
        "Data Fields": "addItem.detail.subDetail.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "addItem.detail.subDetail.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "addItem.detail.subDetail.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "addItem.detail.subDetail.productOrService",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "When the value is a group code then this item collects a set of related claim details, otherwise this contains the product, service, drug or other billing code for the item."
      },
      {
        "Data Fields": "addItem.detail.subDetail.modifier",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Item typification or modifiers codes to convey additional context for the product or service."
      },
      {
        "Data Fields": "addItem.detail.subDetail.quantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The number of repetitions of a service or product."
      },
      {
        "Data Fields": "addItem.detail.subDetail.unitPrice",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "If the item is not a group then this is the fee for the product or service, otherwise this is the total of the fees for the details of the group."
      },
      {
        "Data Fields": "addItem.detail.subDetail.factor",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "A real number that represents a multiplier used in determining the overall value of services delivered and/or goods received. The concept of a Factor allows for a discount or surcharge multiplier to be applied to a monetary amount."
      },
      {
        "Data Fields": "addItem.detail.subDetail.net",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "The quantity times the unit price for an additional service or product or charge."
      },
      {
        "Data Fields": "addItem.detail.subDetail.noteNumber",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "The numbers associated with notes below which apply to the adjudication of this item."
      },
      {
        "Data Fields": "addItem.detail.subDetail.adjudication",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The adjudication results."
      },
      {
        "Data Fields": "addItem.detail.subDetail.adjudication.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "addItem.detail.subDetail.adjudication.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "addItem.detail.subDetail.adjudication.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "addItem.detail.subDetail.adjudication.category",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code to indicate the information type of this adjudication record. Information types may include: the value submitted, maximum values or percentages allowed or payable under the plan, amounts that the patient is responsible for in-aggregate or pertaining to this item, amounts paid by other coverages, and the benefit payable for this item."
      },
      {
        "Data Fields": "addItem.detail.subDetail.adjudication.reason",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code supporting the understanding of the adjudication result and explaining variance from expected amount."
      },
      {
        "Data Fields": "addItem.detail.subDetail.adjudication.amount",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "Monetary amount associated with the category."
      },
      {
        "Data Fields": "addItem.detail.subDetail.adjudication.value",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "A non-monetary value associated with the category. Mutually exclusive to the amount element above."
      },
      {
        "Data Fields": "adjudication",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The adjudication results which are presented at the header level rather than at the line-item or add-item levels."
      },
      {
        "Data Fields": "adjudication.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "adjudication.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "adjudication.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "adjudication.category",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code to indicate the information type of this adjudication record. Information types may include: the value submitted, maximum values or percentages allowed or payable under the plan, amounts that the patient is responsible for in-aggregate or pertaining to this item, amounts paid by other coverages, and the benefit payable for this item."
      },
      {
        "Data Fields": "adjudication.reason",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code supporting the understanding of the adjudication result and explaining variance from expected amount."
      },
      {
        "Data Fields": "adjudication.amount",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "Monetary amount associated with the category."
      },
      {
        "Data Fields": "adjudication.value",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "A non-monetary value associated with the category. Mutually exclusive to the amount element above."
      },
      {
        "Data Fields": "total",
        "Type": "BackboneElement",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Categorized monetary totals for the adjudication."
      },
      {
        "Data Fields": "total.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "total.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "total.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "total.category",
        "Type": "CodeableConcept",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A code to indicate the information type of this adjudication record. Information types may include: the value submitted, maximum values or percentages allowed or payable under the plan, amounts that the patient is responsible for in aggregate or pertaining to this item, amounts paid by other coverages, and the benefit payable for this item."
      },
      {
        "Data Fields": "total.amount",
        "Type": "Money",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Monetary total amount associated with the category."
      },
      {
        "Data Fields": "payment",
        "Type": "BackboneElement",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Payment details for the adjudication of the claim."
      },
      {
        "Data Fields": "payment.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "payment.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "payment.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "payment.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Whether this represents partial or complete payment of the benefits payable."
      },
      {
        "Data Fields": "payment.adjustment",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "Total amount of all adjustments to this payment included in this transaction which are not related to this claim's adjudication."
      },
      {
        "Data Fields": "payment.adjustmentReason",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Reason for the payment adjustment."
      },
      {
        "Data Fields": "payment.date",
        "Type": "date",
        
        
        "Required": "False",
        "Description": "Estimated date the payment will be issued or the actual issue date of payment."
      },
      {
        "Data Fields": "payment.amount",
        "Type": "Money",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Benefits payable less any payment adjustment."
      },
      {
        "Data Fields": "payment.identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Issuer's unique identifier for the payment instrument."
      },
      {
        "Data Fields": "formCode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code for the form to be used for printing the content."
      },
      {
        "Data Fields": "form",
        "Type": "Attachment",
        
        
        "Required": "False",
        "Description": "The actual form, by reference or inclusion, for printing the content or an EOB."
      },
      {
        "Data Fields": "processNote",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A note that describes or explains adjudication results in a human readable form."
      },
      {
        "Data Fields": "processNote.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "processNote.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "processNote.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "processNote.number",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "A number to uniquely identify a note entry."
      },
      {
        "Data Fields": "processNote.type",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The business purpose of the note text."
      },
      {
        "Data Fields": "processNote.text",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The explanation or description associated with the processing."
      },
      {
        "Data Fields": "processNote.language",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code to define the language used in the text of the note."
      },
      {
        "Data Fields": "benefitPeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The term of the benefits documented in this response."
      },
      {
        "Data Fields": "benefitBalance",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Balance by Benefit Category."
      },
      {
        "Data Fields": "benefitBalance.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "benefitBalance.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "benefitBalance.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "benefitBalance.category",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Code to identify the general type of benefits under which products and services are provided."
      },
      {
        "Data Fields": "benefitBalance.excluded",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "True if the indicated class of service is excluded from the plan, missing or False indicates the product or service is included in the coverage."
      },
      {
        "Data Fields": "benefitBalance.name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A short name or tag for the benefit."
      },
      {
        "Data Fields": "benefitBalance.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A richer description of the benefit or services covered."
      },
      {
        "Data Fields": "benefitBalance.network",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Is a flag to indicate whether the benefits refer to in-network providers or out-of-network providers."
      },
      {
        "Data Fields": "benefitBalance.unit",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Indicates if the benefits apply to an individual or to the family."
      },
      {
        "Data Fields": "benefitBalance.term",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The term or period of the values such as 'maximum lifetime benefit' or 'maximum annual visits'."
      },
      {
        "Data Fields": "benefitBalance.financial",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Benefits Used to date."
      },
      {
        "Data Fields": "benefitBalance.financial.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "benefitBalance.financial.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "benefitBalance.financial.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "benefitBalance.financial.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Classification of benefit being provided."
      },
      {
        "Data Fields": "benefitBalance.financial.allowedUnsignedInt",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "The quantity of the benefit which is permitted under the coverage."
      },
      {
        "Data Fields": "benefitBalance.financial.allowedString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The quantity of the benefit which is permitted under the coverage."
      },
      {
        "Data Fields": "benefitBalance.financial.allowedMoney",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "The quantity of the benefit which is permitted under the coverage."
      },
      {
        "Data Fields": "benefitBalance.financial.usedUnsignedInt",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "The quantity of the benefit which have been consumed to date."
      },
      {
        "Data Fields": "benefitBalance.financial.usedMoney",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "The quantity of the benefit which have been consumed to date."
      }
    ],
    "FamilyMemberHistory": [
      {
        "Data Fields": "resourceType",
        "Type": "FamilyMemberHistory",
        
        
        "Required": "True",
        "Description": "This is a FamilyMemberHistory resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Business identifiers assigned to this family member history by the performer or other systems which remain constant as the resource is updated and propagates from server to server."
      },
      {
        "Data Fields": "instantiatesCanonical",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "The URL pointing to a FHIR-defined protocol, guideline, orderset or other definition that is adhered to in whole or in part by this FamilyMemberHistory."
      },
      {
        "Data Fields": "instantiatesUri",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "The URL pointing to an externally maintained protocol, guideline, orderset or other definition that is adhered to in whole or in part by this FamilyMemberHistory."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A code specifying the status of the record of the family history of a specific family member."
      },
      {
        "Data Fields": "dataAbsentReason",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Describes why the family member's history is not available."
      },
      {
        "Data Fields": "patient",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "The person who this history concerns."
      },
      {
        "Data Fields": "date",
        "Type": "dateTime",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The date (and possibly time) when the family member history was recorded or last updated."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "This will either be a name or a description; e.g. \"Aunt Susan\", \"my cousin with the red hair\"."
      },
      {
        "Data Fields": "relationship",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "True",
        "Description": "The type of relationship this person has to the patient (father, mother, brother etc.)."
      },
      {
        "Data Fields": "sex",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The birth sex of the family member."
      },
      {
        "Data Fields": "bornPeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The actual or approximate date of birth of the relative."
      },
      {
        "Data Fields": "bornDate",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The actual or approximate date of birth of the relative."
      },
      {
        "Data Fields": "bornString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The actual or approximate date of birth of the relative."
      },
      {
        "Data Fields": "ageAge",
        "Type": "Age",
        
        
        "Required": "False",
        "Description": "The age of the relative at the time the family member history is recorded."
      },
      {
        "Data Fields": "ageRange",
        "Type": "Range",
        
        
        "Required": "False",
        "Description": "The age of the relative at the time the family member history is recorded."
      },
      {
        "Data Fields": "ageString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The age of the relative at the time the family member history is recorded."
      },
      {
        "Data Fields": "estimatedAge",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "If true, indicates that the age value specified is an estimated value."
      },
      {
        "Data Fields": "deceasedBoolean",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Deceased flag or the actual or approximate age of the relative at the time of death for the family member history record."
      },
      {
        "Data Fields": "deceasedAge",
        "Type": "Age",
        
        
        "Required": "False",
        "Description": "Deceased flag or the actual or approximate age of the relative at the time of death for the family member history record."
      },
      {
        "Data Fields": "deceasedRange",
        "Type": "Range",
        
        
        "Required": "False",
        "Description": "Deceased flag or the actual or approximate age of the relative at the time of death for the family member history record."
      },
      {
        "Data Fields": "deceasedDate",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Deceased flag or the actual or approximate age of the relative at the time of death for the family member history record."
      },
      {
        "Data Fields": "deceasedString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Deceased flag or the actual or approximate age of the relative at the time of death for the family member history record."
      },
      {
        "Data Fields": "reasonCode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Describes why the family member history occurred in coded or textual form."
      },
      {
        "Data Fields": "reasonReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Indicates a Condition, Observation, AllergyIntolerance, or QuestionnaireResponse that justifies this family member history event."
      },
      {
        "Data Fields": "note",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "This property allows a non condition-specific note to the made about the related person. Ideally, the note would be in the condition property, but this is not always possible."
      },
      {
        "Data Fields": "condition",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The significant Conditions (or condition) that the family member had. This is a repeating section to allow a system to represent more than one condition per resource, though there is nothing stopping multiple resources - one per condition."
      },
      {
        "Data Fields": "condition.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "condition.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "condition.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "condition.code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The actual condition specified. Could be a coded condition (like MI or Diabetes) or a less specific string like 'cancer' depending on how much is known about the condition and the capabilities of the creating system."
      },
      {
        "Data Fields": "condition.outcome",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Indicates what happened following the condition.  If the condition resulted in death, deceased date is captured on the relation."
      },
      {
        "Data Fields": "condition.contributedToDeath",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "This condition contributed to the cause of death of the related person. If contributedToDeath is not populated, then it is unknown."
      },
      {
        "Data Fields": "condition.onsetAge",
        "Type": "Age",
        
        
        "Required": "False",
        "Description": "Either the age of onset, range of approximate age or descriptive string can be recorded.  For conditions with multiple occurrences, this describes the first known occurrence."
      },
      {
        "Data Fields": "condition.onsetRange",
        "Type": "Range",
        
        
        "Required": "False",
        "Description": "Either the age of onset, range of approximate age or descriptive string can be recorded.  For conditions with multiple occurrences, this describes the first known occurrence."
      },
      {
        "Data Fields": "condition.onsetPeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "Either the age of onset, range of approximate age or descriptive string can be recorded.  For conditions with multiple occurrences, this describes the first known occurrence."
      },
      {
        "Data Fields": "condition.onsetString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Either the age of onset, range of approximate age or descriptive string can be recorded.  For conditions with multiple occurrences, this describes the first known occurrence."
      },
      {
        "Data Fields": "condition.note",
        "Type": "Annotation",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "An area where general notes can be placed about this specific condition."
      }
    ],
    "Flag": [
      {
        "Data Fields": "resourceType",
        "Type": "Flag",
        
        
        "Required": "True",
        "Description": "This is a Flag resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Business identifiers assigned to this flag by the performer or other systems which remain constant as the resource is updated and propagates from server to server."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Supports basic workflow."
      },
      {
        "Data Fields": "category",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Allows a flag to be divided into different categories like clinical, administrative etc. Intended to be used as a means of filtering which flags are displayed to particular user or in a given context."
      },
      {
        "Data Fields": "code",
        "Type": "CodeableConcept",
        
        
        "Required": "True",
        "Description": "The coded value or textual component of the flag to display to the user."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "The patient, location, group, organization, or practitioner etc. this is about record this flag is associated with."
      },
      {
        "Data Fields": "period",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The period of time from the activation of the flag to inactivation of the flag. If the flag is active, the end of the period should be unspecified."
      },
      {
        "Data Fields": "encounter",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "This alert is only relevant during the encounter."
      },
      {
        "Data Fields": "author",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The person, organization or device that created the flag."
      }
    ],
    "Goal": [
      {
        "Data Fields": "resourceType",
        "Type": "Goal",
        
        
        "Required": "True",
        "Description": "This is a Goal resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Business identifiers assigned to this goal by the performer or other systems which remain constant as the resource is updated and propagates from server to server."
      },
      {
        "Data Fields": "lifecycleStatus",
        "Type": "code",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The state of the goal throughout its lifecycle."
      },
      {
        "Data Fields": "achievementStatus",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Describes the progression, or lack thereof, towards the goal against the target."
      },
      {
        "Data Fields": "category",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Indicates a category the goal falls within."
      },
      {
        "Data Fields": "priority",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Identifies the mutually agreed level of importance associated with reaching/sustaining the goal."
      },
      {
        "Data Fields": "description",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "True",
        "Description": "Human-readable and/or coded description of a specific desired objective of care, such as \"control blood pressure\" or \"negotiate an obstacle course\" or \"dance with child at wedding\"."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "True",
        "Description": "Identifies the patient, group or organization for whom the goal is being established."
      },
      {
        "Data Fields": "startDate",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The date or event after which the goal should begin being pursued."
      },
      {
        "Data Fields": "startCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The date or event after which the goal should begin being pursued."
      },
      {
        "Data Fields": "target",
        "Type": "BackboneElement",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Indicates what should be done by when."
      },
      {
        "Data Fields": "target.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "target.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "target.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "target.measure",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The parameter whose value is being tracked, e.g. body weight, blood pressure, or hemoglobin A1c level."
      },
      {
        "Data Fields": "target.detailQuantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The target value of the focus to be achieved to signify the fulfillment of the goal, e.g. 150 pounds, 7.0%. Either the high or low or both values of the range can be specified. When a low value is missing, it indicates that the goal is achieved at any focus value at or below the high value. Similarly, if the high value is missing, it indicates that the goal is achieved at any focus value at or above the low value."
      },
      {
        "Data Fields": "target.detailRange",
        "Type": "Range",
        
        
        "Required": "False",
        "Description": "The target value of the focus to be achieved to signify the fulfillment of the goal, e.g. 150 pounds, 7.0%. Either the high or low or both values of the range can be specified. When a low value is missing, it indicates that the goal is achieved at any focus value at or below the high value. Similarly, if the high value is missing, it indicates that the goal is achieved at any focus value at or above the low value."
      },
      {
        "Data Fields": "target.detailCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The target value of the focus to be achieved to signify the fulfillment of the goal, e.g. 150 pounds, 7.0%. Either the high or low or both values of the range can be specified. When a low value is missing, it indicates that the goal is achieved at any focus value at or below the high value. Similarly, if the high value is missing, it indicates that the goal is achieved at any focus value at or above the low value."
      },
      {
        "Data Fields": "target.detailString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The target value of the focus to be achieved to signify the fulfillment of the goal, e.g. 150 pounds, 7.0%. Either the high or low or both values of the range can be specified. When a low value is missing, it indicates that the goal is achieved at any focus value at or below the high value. Similarly, if the high value is missing, it indicates that the goal is achieved at any focus value at or above the low value."
      },
      {
        "Data Fields": "target.detailBoolean",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "The target value of the focus to be achieved to signify the fulfillment of the goal, e.g. 150 pounds, 7.0%. Either the high or low or both values of the range can be specified. When a low value is missing, it indicates that the goal is achieved at any focus value at or below the high value. Similarly, if the high value is missing, it indicates that the goal is achieved at any focus value at or above the low value."
      },
      {
        "Data Fields": "target.detailInteger",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "The target value of the focus to be achieved to signify the fulfillment of the goal, e.g. 150 pounds, 7.0%. Either the high or low or both values of the range can be specified. When a low value is missing, it indicates that the goal is achieved at any focus value at or below the high value. Similarly, if the high value is missing, it indicates that the goal is achieved at any focus value at or above the low value."
      },
      {
        "Data Fields": "target.detailRatio",
        "Type": "Ratio",
        
        
        "Required": "False",
        "Description": "The target value of the focus to be achieved to signify the fulfillment of the goal, e.g. 150 pounds, 7.0%. Either the high or low or both values of the range can be specified. When a low value is missing, it indicates that the goal is achieved at any focus value at or below the high value. Similarly, if the high value is missing, it indicates that the goal is achieved at any focus value at or above the low value."
      },
      {
        "Data Fields": "target.dueDate",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Indicates either the date or the duration after start by which the goal should be met."
      },
      {
        "Data Fields": "target.dueDuration",
        "Type": "Duration",
        
        
        "Required": "False",
        "Description": "Indicates either the date or the duration after start by which the goal should be met."
      },
      {
        "Data Fields": "statusDate",
        "Type": "date",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Identifies when the current status.  I.e. When initially created, when achieved, when cancelled, etc."
      },
      {
        "Data Fields": "statusReason",
        "Type": "string",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Captures the reason for the current status."
      },
      {
        "Data Fields": "expressedBy",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Indicates whose goal this is - patient goal, practitioner goal, etc."
      },
      {
        "Data Fields": "addresses",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The identified conditions and other health record elements that are intended to be addressed by the goal."
      },
      {
        "Data Fields": "note",
        "Type": "Annotation",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Any comments related to the goal."
      },
      {
        "Data Fields": "outcomeCode",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Identifies the change (or lack of change) at the point when the status of the goal is assessed."
      },
      {
        "Data Fields": "outcomeReference",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Details of what's changed (or not changed)."
      }
    ],
    "GraphDefinition": [
      {
        "Data Fields": "resourceType",
        "Type": "GraphDefinition",
        
        
        "Required": "True",
        "Description": "This is a GraphDefinition resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "url",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "An absolute URI that is used to identify this graph definition when it is referenced in a specification, model, design or an instance; also called its canonical identifier. This SHOULD be globally unique and SHOULD be a literal address at which at which an authoritative instance of this graph definition is (or will be) published. This URL can be the target of a canonical reference. It SHALL remain the same when the graph definition is stored on different servers."
      },
      {
        "Data Fields": "version",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The identifier that is used to identify this version of the graph definition when it is referenced in a specification, model, design or instance. This is an arbitrary value managed by the graph definition author and is not expected to be globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if a managed version is not available. There is also no expectation that versions can be placed in a lexicographical sequence."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A natural language name identifying the graph definition. This name should be usable as an identifier for the module by machine processing applications such as code generation."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The status of this graph definition. Enables tracking the life-cycle of the content."
      },
      {
        "Data Fields": "experimental",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "A Boolean value to indicate that this graph definition is authored for testing purposes (or education/evaluation/marketing) and is not intended to be used for genuine usage."
      },
      {
        "Data Fields": "date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date  (and optionally time) when the graph definition was published. The date must change when the business version changes and it must change if the status code changes. In addition, it should change when the substantive content of the graph definition changes."
      },
      {
        "Data Fields": "publisher",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the organization or individual that published the graph definition."
      },
      {
        "Data Fields": "contact",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "Contact details to assist a user in finding and communicating with the publisher."
      },
      {
        "Data Fields": "description",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A free text natural language description of the graph definition from a consumer's perspective."
      },
      {
        "Data Fields": "useContext",
        "Type": "UsageContext",
        
        
        "Required": "False",
        "Description": "The content was developed with a focus and intent of supporting the contexts that are listed. These contexts may be general categories (gender, age, ...) or may be references to specific programs (insurance plans, studies, ...) and may be used to assist with indexing and searching for appropriate graph definition instances."
      },
      {
        "Data Fields": "jurisdiction",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A legal or geographic region in which the graph definition is intended to be used."
      },
      {
        "Data Fields": "purpose",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Explanation of why this graph definition is needed and why it has been designed as it has."
      },
      {
        "Data Fields": "start",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The type of FHIR resource at which instances of this graph start."
      },
      {
        "Data Fields": "profile",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "The profile that describes the use of the base resource."
      },
      {
        "Data Fields": "link",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Links this graph makes rules about."
      },
      {
        "Data Fields": "link.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "link.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "link.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "link.path",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A FHIR expression that identifies one of FHIR References to other resources."
      },
      {
        "Data Fields": "link.sliceName",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Which slice (if profiled)."
      },
      {
        "Data Fields": "link.min",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "Minimum occurrences for this link."
      },
      {
        "Data Fields": "link.max",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Maximum occurrences for this link."
      },
      {
        "Data Fields": "link.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Information about why this link is of interest in this graph definition."
      },
      {
        "Data Fields": "link.target",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Potential target for the link."
      },
      {
        "Data Fields": "link.target.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "link.target.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "link.target.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "link.target.type",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Type of resource this link refers to."
      },
      {
        "Data Fields": "link.target.params",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A set of parameters to look up."
      },
      {
        "Data Fields": "link.target.profile",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "Profile for the target resource."
      },
      {
        "Data Fields": "link.target.compartment",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Compartment Consistency Rules."
      },
      {
        "Data Fields": "link.target.compartment.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "link.target.compartment.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "link.target.compartment.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "link.target.compartment.use",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Defines how the compartment rule is used - whether it it is used to test whether resources are subject to the rule, or whether it is a rule that must be followed."
      },
      {
        "Data Fields": "link.target.compartment.code",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Identifies the compartment."
      },
      {
        "Data Fields": "link.target.compartment.rule",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "identical | matching | different | no-rule | custom."
      },
      {
        "Data Fields": "link.target.compartment.expression",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Custom rule, as a FHIRPath expression."
      },
      {
        "Data Fields": "link.target.compartment.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Documentation for FHIRPath expression."
      }
    ],
    "Group": [
      {
        "Data Fields": "resourceType",
        "Type": "Group",
        
        
        "Required": "True",
        "Description": "This is a Group resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A unique business identifier for this group."
      },
      {
        "Data Fields": "active",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Indicates whether the record for the group is available for use or is merely being retained for historical purposes."
      },
      {
        "Data Fields": "type",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Identifies the broad classification of the kind of resources the group includes."
      },
      {
        "Data Fields": "actual",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "If true, indicates that the resource refers to a specific group of real individuals.  If false, the group defines a set of intended individuals."
      },
      {
        "Data Fields": "code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Provides a specific type of resource the group includes; e.g. \"cow\", \"syringe\", etc."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A label assigned to the group for human identification and communication."
      },
      {
        "Data Fields": "quantity",
        "Type": "unsignedInt",
        
        
        "Required": "False",
        "Description": "A count of the number of resource instances that are part of the group."
      },
      {
        "Data Fields": "managingEntity",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Entity responsible for defining and maintaining Group characteristics and/or registered members."
      },
      {
        "Data Fields": "characteristic",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Identifies traits whose presence r absence is shared by members of the group."
      },
      {
        "Data Fields": "characteristic.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "characteristic.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "characteristic.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "characteristic.code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code that identifies the kind of trait being asserted."
      },
      {
        "Data Fields": "characteristic.valueCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The value of the trait that holds (or does not hold - see 'exclude') for members of the group."
      },
      {
        "Data Fields": "characteristic.valueBoolean",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "The value of the trait that holds (or does not hold - see 'exclude') for members of the group."
      },
      {
        "Data Fields": "characteristic.valueQuantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The value of the trait that holds (or does not hold - see 'exclude') for members of the group."
      },
      {
        "Data Fields": "characteristic.valueRange",
        "Type": "Range",
        
        
        "Required": "False",
        "Description": "The value of the trait that holds (or does not hold - see 'exclude') for members of the group."
      },
      {
        "Data Fields": "characteristic.valueReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The value of the trait that holds (or does not hold - see 'exclude') for members of the group."
      },
      {
        "Data Fields": "characteristic.exclude",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "If true, indicates the characteristic is one that is NOT held by members of the group."
      },
      {
        "Data Fields": "characteristic.period",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The period over which the characteristic is tested; e.g. the patient had an operation during the month of June."
      },
      {
        "Data Fields": "member",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Identifies the resource instances that are members of the group."
      },
      {
        "Data Fields": "member.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "member.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "member.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "member.entity",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A reference to the entity that is a member of the group. Must be consistent with Group.type. If the entity is another group, then the type must be the same."
      },
      {
        "Data Fields": "member.period",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The period that the member was in the group, if known."
      },
      {
        "Data Fields": "member.inactive",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "A flag to indicate that the member is no longer in the group, but previously may have been a member."
      }
    ],
    "GuidanceResponse": [
      {
        "Data Fields": "resourceType",
        "Type": "GuidanceResponse",
        
        
        "Required": "True",
        "Description": "This is a GuidanceResponse resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "requestIdentifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "The identifier of the request associated with this response. If an identifier was given as part of the request, it will be reproduced here to enable the requester to more easily identify the response in a multi-request scenario."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Allows a service to provide  unique, business identifiers for the response."
      },
      {
        "Data Fields": "moduleUri",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "An identifier, CodeableConcept or canonical reference to the guidance that was requested."
      },
      {
        "Data Fields": "moduleCanonical",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "An identifier, CodeableConcept or canonical reference to the guidance that was requested."
      },
      {
        "Data Fields": "moduleCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "An identifier, CodeableConcept or canonical reference to the guidance that was requested."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The status of the response. If the evaluation is completed successfully, the status will indicate success. However, in order to complete the evaluation, the engine may require more information. In this case, the status will be data-required, and the response will contain a description of the additional required information. If the evaluation completed successfully, but the engine determines that a potentially more accurate response could be provided if more data was available, the status will be data-requested, and the response will contain a description of the additional requested information."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The patient for which the request was processed."
      },
      {
        "Data Fields": "encounter",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The encounter during which this response was created or to which the creation of this record is tightly associated."
      },
      {
        "Data Fields": "occurrenceDateTime",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "Indicates when the guidance response was processed."
      },
      {
        "Data Fields": "performer",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Provides a reference to the device that performed the guidance."
      },
      {
        "Data Fields": "reasonCode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Describes the reason for the guidance response in coded or textual form."
      },
      {
        "Data Fields": "reasonReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Indicates the reason the request was initiated. This is typically provided as a parameter to the evaluation and echoed by the service, although for some use cases, such as subscription- or event-based scenarios, it may provide an indication of the cause for the response."
      },
      {
        "Data Fields": "note",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "Provides a mechanism to communicate additional information about the response."
      },
      {
        "Data Fields": "evaluationMessage",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Messages resulting from the evaluation of the artifact or artifacts. As part of evaluating the request, the engine may produce informational or warning messages. These messages will be provided by this element."
      },
      {
        "Data Fields": "outputParameters",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The output parameters of the evaluation, if any. Many modules will result in the return of specific resources such as procedure or communication requests that are returned as part of the operation result. However, modules may define specific outputs that would be returned as the result of the evaluation, and these would be returned in this element."
      },
      {
        "Data Fields": "result",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The actions, if any, produced by the evaluation of the artifact."
      },
      {
        "Data Fields": "dataRequirement",
        "Type": "DataRequirement",
        
        
        "Required": "False",
        "Description": "If the evaluation could not be completed due to lack of information, or additional information would potentially result in a more accurate response, this element will a description of the data required in order to proceed with the evaluation. A subsequent request to the service should include this data."
      }
    ],
    "HealthcareService": [
      {
        "Data Fields": "resourceType",
        "Type": "HealthcareService",
        
        
        "Required": "True",
        "Description": "This is a HealthcareService resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "External identifiers for this item."
      },
      {
        "Data Fields": "active",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "This flag is used to mark the record to not be used. This is not used when a center is closed for maintenance, or for holidays, the notAvailable period is to be used for this."
      },
      {
        "Data Fields": "providedBy",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The organization that provides this healthcare service."
      },
      {
        "Data Fields": "category",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Identifies the broad category of service being performed or delivered."
      },
      {
        "Data Fields": "type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The specific type of service that may be delivered or performed."
      },
      {
        "Data Fields": "specialty",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Collection of specialties handled by the service site. This is more of a medical term."
      },
      {
        "Data Fields": "location",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The location(s) where this healthcare service may be provided."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Further description of the service as it would be presented to a consumer while searching."
      },
      {
        "Data Fields": "comment",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Any additional description of the service and/or any specific issues not covered by the other attributes, which can be displayed as further detail under the serviceName."
      },
      {
        "Data Fields": "extraDetails",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Extra details about the service that can't be placed in the other fields."
      },
      {
        "Data Fields": "photo",
        "Type": "Attachment",
        
        
        "Required": "False",
        "Description": "If there is a photo/symbol associated with this HealthcareService, it may be included here to facilitate quick identification of the service in a list."
      },
      {
        "Data Fields": "telecom",
        "Type": "ContactPoint",
        
        
        "Required": "False",
        "Description": "List of contacts related to this specific healthcare service."
      },
      {
        "Data Fields": "coverageArea",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The location(s) that this service is available to (not where the service is provided)."
      },
      {
        "Data Fields": "serviceProvisionCode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The code(s) that detail the conditions under which the healthcare service is available/offered."
      },
      {
        "Data Fields": "eligibility",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Does this service have specific eligibility requirements that need to be met in order to use the service?"
      },
      {
        "Data Fields": "eligibility.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "eligibility.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "eligibility.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "eligibility.code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Coded value for the eligibility."
      },
      {
        "Data Fields": "eligibility.comment",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Describes the eligibility conditions for the service."
      },
      {
        "Data Fields": "program",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Programs that this service is applicable to."
      },
      {
        "Data Fields": "characteristic",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Collection of characteristics (attributes)."
      },
      {
        "Data Fields": "communication",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Some services are specifically made available in multiple languages, this property permits a directory to declare the languages this is offered in. Typically this is only provided where a service operates in communities with mixed languages used."
      },
      {
        "Data Fields": "referralMethod",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Ways that the service accepts referrals, if this is not provided then it is implied that no referral is required."
      },
      {
        "Data Fields": "appointmentRequired",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Indicates whether or not a prospective consumer will require an appointment for a particular service at a site to be provided by the Organization. Indicates if an appointment is required for access to this service."
      },
      {
        "Data Fields": "availableTime",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A collection of times that the Service Site is available."
      },
      {
        "Data Fields": "availableTime.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "availableTime.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "availableTime.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "availableTime.daysOfWeek",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Indicates which days of the week are available between the start and end Times."
      },
      {
        "Data Fields": "availableTime.allDay",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Is this always available? (hence times are irrelevant) e.g. 24 hour service."
      },
      {
        "Data Fields": "availableTime.availableStartTime",
        "Type": "time",
        
        
        "Required": "False",
        "Description": "The opening time of day. Note: If the AllDay flag is set, then this time is ignored."
      },
      {
        "Data Fields": "availableTime.availableEndTime",
        "Type": "time",
        
        
        "Required": "False",
        "Description": "The closing time of day. Note: If the AllDay flag is set, then this time is ignored."
      },
      {
        "Data Fields": "notAvailable",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The HealthcareService is not available during this period of time due to the provided reason."
      },
      {
        "Data Fields": "notAvailable.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "notAvailable.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "notAvailable.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "notAvailable.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The reason that can be presented to the user as to why this time is not available."
      },
      {
        "Data Fields": "notAvailable.during",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "Service is not available (seasonally or for a public holiday) from this date."
      },
      {
        "Data Fields": "availabilityExceptions",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A description of site availability exceptions, e.g. public holiday availability. Succinctly describing all possible exceptions to normal site availability as details in the available Times and not available Times."
      },
      {
        "Data Fields": "endpoint",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Technical endpoints providing access to services operated for the specific healthcare services defined at this resource."
      }
    ],
    "ImagingStudy": [
      {
        "Data Fields": "resourceType",
        "Type": "ImagingStudy",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "This is a ImagingStudy resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Identifiers for the ImagingStudy such as DICOM Study Instance UID, and Accession Number."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The current state of the ImagingStudy."
      },
      {
        "Data Fields": "modality",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "A list of all the series.modality values that are actual acquisition modalities, i.e. those in the DICOM Context Group 29 (value set OID 1.2.840.10008.6.1.19)."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "The subject, typically a patient, of the imaging study."
      },
      {
        "Data Fields": "encounter",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The healthcare event (e.g. a patient and healthcare provider interaction) during which this ImagingStudy is made."
      },
      {
        "Data Fields": "started",
        "Type": "dateTime",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Date and time the study started."
      },
      {
        "Data Fields": "basedOn",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A list of the diagnostic requests that resulted in this imaging study being performed."
      },
      {
        "Data Fields": "referrer",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The requesting/referring physician."
      },
      {
        "Data Fields": "interpreter",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Who read the study and interpreted the images or other content."
      },
      {
        "Data Fields": "endpoint",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The network service providing access (e.g., query, view, or retrieval) for the study. See implementation notes for information about using DICOM endpoints. A study-level endpoint applies to each series in the study, unless overridden by a series-level endpoint with the same Endpoint.connectionType."
      },
      {
        "Data Fields": "numberOfSeries",
        "Type": "unsignedInt",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Number of Series in the Study. This value given may be larger than the number of series elements this Resource contains due to resource availability, security, or other factors. This element should be present if any series elements are present."
      },
      {
        "Data Fields": "numberOfInstances",
        "Type": "unsignedInt",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Number of SOP Instances in Study. This value given may be larger than the number of instance elements this resource contains due to resource availability, security, or other factors. This element should be present if any instance elements are present."
      },
      {
        "Data Fields": "procedureReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The procedure which this ImagingStudy was part of."
      },
      {
        "Data Fields": "procedureCode",
        "Type": "CodeableConcept",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The code for the performed procedure type."
      },
      {
        "Data Fields": "location",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The principal physical location where the ImagingStudy was performed."
      },
      {
        "Data Fields": "reasonCode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Description of clinical condition indicating why the ImagingStudy was requested."
      },
      {
        "Data Fields": "reasonReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Indicates another resource whose existence justifies this Study."
      },
      {
        "Data Fields": "note",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "Per the recommended DICOM mapping, this element is derived from the Study Description attribute (0008,1030). Observations or findings about the imaging study should be recorded in another resource, e.g. Observation, and not in this element."
      },
      {
        "Data Fields": "description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The Imaging Manager description of the study. Institution-generated description or classification of the Study (component) performed."
      },
      {
        "Data Fields": "series",
        "Type": "BackboneElement",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Each study has one or more series of images or other content."
      },
      {
        "Data Fields": "series.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "series.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "series.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "series.uid",
        "Type": "id",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The DICOM Series Instance UID for the series."
      },
      {
        "Data Fields": "series.number",
        "Type": "unsignedInt",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The numeric identifier of this series in the study."
      },
      {
        "Data Fields": "series.modality",
        "Type": "Coding",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The modality of this series sequence."
      },
      {
        "Data Fields": "series.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A description of the series."
      },
      {
        "Data Fields": "series.numberOfInstances",
        "Type": "unsignedInt",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Number of SOP Instances in the Study. The value given may be larger than the number of instance elements this resource contains due to resource availability, security, or other factors. This element should be present if any instance elements are present."
      },
      {
        "Data Fields": "series.endpoint",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The network service providing access (e.g., query, view, or retrieval) for this series. See implementation notes for information about using DICOM endpoints. A series-level endpoint, if present, has precedence over a study-level endpoint with the same Endpoint.connectionType."
      },
      {
        "Data Fields": "series.bodySite",
        "Type": "Coding",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The anatomic structures examined. See DICOM Part 16 Annex L (http://dicom.nema.org/medical/dicom/current/output/chtml/part16/chapter_L.html) for DICOM to SNOMED-CT mappings. The bodySite may indicate the laterality of body part imaged; if so, it shall be consistent with any content of ImagingStudy.series.laterality."
      },
      {
        "Data Fields": "series.laterality",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "The laterality of the (possibly paired) anatomic structures examined. E.g., the left knee, both lungs, or unpaired abdomen. If present, shall be consistent with any laterality information indicated in ImagingStudy.series.bodySite."
      },
      {
        "Data Fields": "series.specimen",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The specimen imaged, e.g., for whole slide imaging of a biopsy."
      },
      {
        "Data Fields": "series.started",
        "Type": "dateTime",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The date and time the series was started."
      },
      {
        "Data Fields": "series.performer",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Indicates who or what performed the series and how they were involved."
      },
      {
        "Data Fields": "series.performer.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "series.performer.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "series.performer.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "series.performer.function",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Distinguishes the type of involvement of the performer in the series."
      },
      {
        "Data Fields": "series.performer.actor",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Indicates who or what performed the series."
      },
      {
        "Data Fields": "series.instance",
        "Type": "BackboneElement",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A single SOP instance within the series, e.g. an image, or presentation state."
      },
      {
        "Data Fields": "series.instance.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "series.instance.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "series.instance.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "series.instance.uid",
        "Type": "id",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The DICOM SOP Instance UID for this image or other DICOM content."
      },
      {
        "Data Fields": "series.instance.sopClass",
        "Type": "Coding",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "DICOM instance  type."
      },
      {
        "Data Fields": "series.instance.number",
        "Type": "unsignedInt",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The number of instance in the series."
      },
      {
        "Data Fields": "series.instance.title",
        "Type": "string",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The description of the instance."
      }
    ],
    "Immunization": [
      {
        "Data Fields": "resourceType",
        "Type": "Immunization",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "This is a Immunization resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A unique identifier assigned to this immunization record."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Indicates the current status of the immunization event."
      },
      {
        "Data Fields": "statusReason",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Indicates the reason the immunization event was not performed."
      },
      {
        "Data Fields": "vaccineCode",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "Vaccine that was administered or was to be administered."
      },
      {
        "Data Fields": "patient",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "The patient who either received or did not receive the immunization."
      },
      {
        "Data Fields": "encounter",
        "Type": "Reference",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The visit or admission or other contact between patient and health care provider the immunization was performed as part of."
      },
      {
        "Data Fields": "occurrenceDateTime",
        "Type": "string",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Date vaccine administered or was to be administered."
      },
      {
        "Data Fields": "occurrenceString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Date vaccine administered or was to be administered."
      },
      {
        "Data Fields": "recorded",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date the occurrence of the immunization was first captured in the record - potentially significantly after the occurrence of the event."
      },
      {
        "Data Fields": "primarySource",
        "Type": "boolean",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "An indication that the content of the record is based on information from the person who administered the vaccine. This reflects the context under which the data was originally recorded."
      },
      {
        "Data Fields": "reportOrigin",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The source of the data when the report of the immunization event is not based on information from the person who administered the vaccine."
      },
      {
        "Data Fields": "location",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The service delivery location where the vaccine administration occurred."
      },
      {
        "Data Fields": "manufacturer",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Name of vaccine manufacturer."
      },
      {
        "Data Fields": "lotNumber",
        "Type": "string",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Lot number of the  vaccine product."
      },
      {
        "Data Fields": "expirationDate",
        "Type": "date",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Date vaccine batch expires."
      },
      {
        "Data Fields": "site",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Body site where vaccine was administered."
      },
      {
        "Data Fields": "route",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The path by which the vaccine product is taken into the body."
      },
      {
        "Data Fields": "doseQuantity",
        "Type": "Quantity",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The quantity of vaccine product that was administered."
      },
      {
        "Data Fields": "performer",
        "Type": "BackboneElement",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Indicates who performed the immunization event."
      },
      {
        "Data Fields": "performer.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "performer.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "performer.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "performer.function",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Describes the type of performance (e.g. ordering provider, administering provider, etc.)."
      },
      {
        "Data Fields": "performer.actor",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The practitioner or organization who performed the action."
      },
      {
        "Data Fields": "note",
        "Type": "Annotation",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Extra information about the immunization that is not conveyed by the other attributes."
      },
      {
        "Data Fields": "reasonCode",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Reasons why the vaccine was administered."
      },
      {
        "Data Fields": "reasonReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Condition, Observation or DiagnosticReport that supports why the immunization was administered."
      },
      {
        "Data Fields": "isSubpotent",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Indication if a dose is considered to be subpotent. By default, a dose should be considered to be potent."
      },
      {
        "Data Fields": "subpotentReason",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Reason why a dose is considered to be subpotent."
      },
      {
        "Data Fields": "education",
        "Type": "BackboneElement",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Educational material presented to the patient (or guardian) at the time of vaccine administration."
      },
      {
        "Data Fields": "education.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "education.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "education.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "education.documentType",
        "Type": "string",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Identifier of the material presented to the patient."
      },
      {
        "Data Fields": "education.reference",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "Reference pointer to the educational material given to the patient if the information was on line."
      },
      {
        "Data Fields": "education.publicationDate",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "Date the educational material was published."
      },
      {
        "Data Fields": "education.presentationDate",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "Date the educational material was given to the patient."
      },
      {
        "Data Fields": "programEligibility",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Indicates a patient's eligibility for a funding program."
      },
      {
        "Data Fields": "fundingSource",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Indicates the source of the vaccine actually administered. This may be different than the patient eligibility (e.g. the patient may be eligible for a publically purchased vaccine but due to inventory issues, vaccine purchased with private funds was actually administered)."
      },
      {
        "Data Fields": "reaction",
        "Type": "BackboneElement",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Categorical data indicating that an adverse event is associated in time to an immunization."
      },
      {
        "Data Fields": "reaction.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "reaction.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "reaction.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "reaction.date",
        "Type": "dateTime",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Date of reaction to the immunization."
      },
      {
        "Data Fields": "reaction.detail",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Details of the reaction."
      },
      {
        "Data Fields": "reaction.reported",
        "Type": "boolean",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Self-reported indicator."
      },
      {
        "Data Fields": "protocolApplied",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The protocol (set of recommendations) being followed by the provider who administered the dose."
      },
      {
        "Data Fields": "protocolApplied.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "protocolApplied.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "protocolApplied.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "protocolApplied.series",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "One possible path to achieve presumed immunity against a disease - within the context of an authority."
      },
      {
        "Data Fields": "protocolApplied.authority",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Indicates the authority who published the protocol (e.g. ACIP) that is being followed."
      },
      {
        "Data Fields": "protocolApplied.targetDisease",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The vaccine preventable disease the dose is being administered against."
      },
      {
        "Data Fields": "protocolApplied.doseNumberPositiveInt",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "Nominal position in a series."
      },
      {
        "Data Fields": "protocolApplied.doseNumberString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Nominal position in a series."
      },
      {
        "Data Fields": "protocolApplied.seriesDosesPositiveInt",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "The recommended number of doses to achieve immunity."
      },
      {
        "Data Fields": "protocolApplied.seriesDosesString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The recommended number of doses to achieve immunity."
      }
    ],
    "ImmunizationEvaluation": [
      {
        "Data Fields": "resourceType",
        "Type": "ImmunizationEvaluation",
        
        
        "Required": "True",
        "Description": "This is a ImmunizationEvaluation resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A unique identifier assigned to this immunization evaluation record."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Indicates the current status of the evaluation of the vaccination administration event."
      },
      {
        "Data Fields": "patient",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "The individual for whom the evaluation is being done."
      },
      {
        "Data Fields": "date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date the evaluation of the vaccine administration event was performed."
      },
      {
        "Data Fields": "authority",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Indicates the authority who published the protocol (e.g. ACIP)."
      },
      {
        "Data Fields": "targetDisease",
        "Type": "CodeableConcept",
        
        
        "Required": "True",
        "Description": "The vaccine preventable disease the dose is being evaluated against."
      },
      {
        "Data Fields": "immunizationEvent",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "The vaccine administration event being evaluated."
      },
      {
        "Data Fields": "doseStatus",
        "Type": "CodeableConcept",
        
        
        "Required": "True",
        "Description": "Indicates if the dose is valid or not valid with respect to the published recommendations."
      },
      {
        "Data Fields": "doseStatusReason",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Provides an explanation as to why the vaccine administration event is valid or not relative to the published recommendations."
      },
      {
        "Data Fields": "description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Additional information about the evaluation."
      },
      {
        "Data Fields": "series",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "One possible path to achieve presumed immunity against a disease - within the context of an authority."
      },
      {
        "Data Fields": "doseNumberPositiveInt",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "Nominal position in a series."
      },
      {
        "Data Fields": "doseNumberString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Nominal position in a series."
      },
      {
        "Data Fields": "seriesDosesPositiveInt",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "The recommended number of doses to achieve immunity."
      },
      {
        "Data Fields": "seriesDosesString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The recommended number of doses to achieve immunity."
      }
    ],
    "ImmunizationRecommendation": [
      {
        "Data Fields": "resourceType",
        "Type": "ImmunizationRecommendation",
        
        
        "Required": "True",
        "Description": "This is a ImmunizationRecommendation resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A unique identifier assigned to this particular recommendation record."
      },
      {
        "Data Fields": "patient",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "The patient the recommendation(s) are for."
      },
      {
        "Data Fields": "date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date the immunization recommendation(s) were created."
      },
      {
        "Data Fields": "authority",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Indicates the authority who published the protocol (e.g. ACIP)."
      },
      {
        "Data Fields": "recommendation",
        "Type": "BackboneElement",
        "Fold Availability": "True",
        
        "Required": "True",
        "Description": "Vaccine administration recommendations."
      },
      {
        "Data Fields": "recommendation.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "recommendation.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "recommendation.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "recommendation.vaccineCode",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Vaccine(s) or vaccine group that pertain to the recommendation."
      },
      {
        "Data Fields": "recommendation.targetDisease",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The targeted disease for the recommendation."
      },
      {
        "Data Fields": "recommendation.contraindicatedVaccineCode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Vaccine(s) which should not be used to fulfill the recommendation."
      },
      {
        "Data Fields": "recommendation.forecastStatus",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Indicates the patient status with respect to the path to immunity for the target disease."
      },
      {
        "Data Fields": "recommendation.forecastReason",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The reason for the assigned forecast status."
      },
      {
        "Data Fields": "recommendation.dateCriterion",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Vaccine date recommendations.  For example, earliest date to administer, latest date to administer, etc."
      },
      {
        "Data Fields": "recommendation.dateCriterion.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "recommendation.dateCriterion.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "recommendation.dateCriterion.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "recommendation.dateCriterion.code",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Date classification of recommendation.  For example, earliest date to give, latest date to give, etc."
      },
      {
        "Data Fields": "recommendation.dateCriterion.value",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date whose meaning is specified by dateCriterion.code."
      },
      {
        "Data Fields": "recommendation.description",
        "Type": "string",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Contains the description about the protocol under which the vaccine was administered."
      },
      {
        "Data Fields": "recommendation.series",
        "Type": "string",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "One possible path to achieve presumed immunity against a disease - within the context of an authority."
      },
      {
        "Data Fields": "recommendation.doseNumberPositiveInt",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "Nominal position of the recommended dose in a series (e.g. dose 2 is the next recommended dose)."
      },
      {
        "Data Fields": "recommendation.doseNumberString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Nominal position of the recommended dose in a series (e.g. dose 2 is the next recommended dose)."
      },
      {
        "Data Fields": "recommendation.seriesDosesPositiveInt",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "The recommended number of doses to achieve immunity."
      },
      {
        "Data Fields": "recommendation.seriesDosesString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The recommended number of doses to achieve immunity."
      },
      {
        "Data Fields": "recommendation.supportingImmunization",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Immunization event history and/or evaluation that supports the status and recommendation."
      },
      {
        "Data Fields": "recommendation.supportingPatientInformation",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Patient Information that supports the status and recommendation.  This includes patient observations, adverse reactions and allergy/intolerance information."
      }
    ],
    "ImplementationGuide": [
      {
        "Data Fields": "resourceType",
        "Type": "ImplementationGuide",
        
        
        "Required": "True",
        "Description": "This is a ImplementationGuide resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "url",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "An absolute URI that is used to identify this implementation guide when it is referenced in a specification, model, design or an instance; also called its canonical identifier. This SHOULD be globally unique and SHOULD be a literal address at which at which an authoritative instance of this implementation guide is (or will be) published. This URL can be the target of a canonical reference. It SHALL remain the same when the implementation guide is stored on different servers."
      },
      {
        "Data Fields": "version",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The identifier that is used to identify this version of the implementation guide when it is referenced in a specification, model, design or instance. This is an arbitrary value managed by the implementation guide author and is not expected to be globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if a managed version is not available. There is also no expectation that versions can be placed in a lexicographical sequence."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A natural language name identifying the implementation guide. This name should be usable as an identifier for the module by machine processing applications such as code generation."
      },
      {
        "Data Fields": "title",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A short, descriptive, user-friendly title for the implementation guide."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The status of this implementation guide. Enables tracking the life-cycle of the content."
      },
      {
        "Data Fields": "experimental",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "A Boolean value to indicate that this implementation guide is authored for testing purposes (or education/evaluation/marketing) and is not intended to be used for genuine usage."
      },
      {
        "Data Fields": "date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date  (and optionally time) when the implementation guide was published. The date must change when the business version changes and it must change if the status code changes. In addition, it should change when the substantive content of the implementation guide changes."
      },
      {
        "Data Fields": "publisher",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the organization or individual that published the implementation guide."
      },
      {
        "Data Fields": "contact",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "Contact details to assist a user in finding and communicating with the publisher."
      },
      {
        "Data Fields": "description",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A free text natural language description of the implementation guide from a consumer's perspective."
      },
      {
        "Data Fields": "useContext",
        "Type": "UsageContext",
        
        
        "Required": "False",
        "Description": "The content was developed with a focus and intent of supporting the contexts that are listed. These contexts may be general categories (gender, age, ...) or may be references to specific programs (insurance plans, studies, ...) and may be used to assist with indexing and searching for appropriate implementation guide instances."
      },
      {
        "Data Fields": "jurisdiction",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A legal or geographic region in which the implementation guide is intended to be used."
      },
      {
        "Data Fields": "copyright",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A copyright statement relating to the implementation guide and/or its contents. Copyright statements are generally legal restrictions on the use and publishing of the implementation guide."
      },
      {
        "Data Fields": "packageId",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The NPM package name for this Implementation Guide, used in the NPM package distribution, which is the primary mechanism by which FHIR based tooling manages IG dependencies. This value must be globally unique, and should be assigned with care."
      },
      {
        "Data Fields": "license",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The license that applies to this Implementation Guide, using an SPDX license code, or 'not-open-source'."
      },
      {
        "Data Fields": "fhirVersion",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The version(s) of the FHIR specification that this ImplementationGuide targets - e.g. describes how to use. The value of this element is the formal version of the specification, without the revision number, e.g. [publication].[major].[minor], which is 4.0.1. for this version."
      },
      {
        "Data Fields": "dependsOn",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Another implementation guide that this implementation depends on. Typically, an implementation guide uses value sets, profiles etc.defined in other implementation guides."
      },
      {
        "Data Fields": "dependsOn.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "dependsOn.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "dependsOn.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "dependsOn.uri",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "A canonical reference to the Implementation guide for the dependency."
      },
      {
        "Data Fields": "dependsOn.packageId",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The NPM package name for the Implementation Guide that this IG depends on."
      },
      {
        "Data Fields": "dependsOn.version",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The version of the IG that is depended on, when the correct version is required to understand the IG correctly."
      },
      {
        "Data Fields": "global",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A set of profiles that all resources covered by this implementation guide must conform to."
      },
      {
        "Data Fields": "global.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "global.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "global.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "global.type",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The type of resource that all instances must conform to."
      },
      {
        "Data Fields": "global.profile",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "A reference to the profile that all instances must conform to."
      },
      {
        "Data Fields": "definition",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The information needed by an IG publisher tool to publish the whole implementation guide."
      },
      {
        "Data Fields": "definition.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "definition.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "definition.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "definition.grouping",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A logical group of resources. Logical groups can be used when building pages."
      },
      {
        "Data Fields": "definition.grouping.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "definition.grouping.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "definition.grouping.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "definition.grouping.name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The human-readable title to display for the package of resources when rendering the implementation guide."
      },
      {
        "Data Fields": "definition.grouping.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Human readable text describing the package."
      },
      {
        "Data Fields": "definition.resource",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A resource that is part of the implementation guide. Conformance resources (value set, structure definition, capability statements etc.) are obvious candidates for inclusion, but any kind of resource can be included as an example resource."
      },
      {
        "Data Fields": "definition.resource.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "definition.resource.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "definition.resource.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "definition.resource.reference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Where this resource is found."
      },
      {
        "Data Fields": "definition.resource.fhirVersion",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Indicates the FHIR Version(s) this artifact is intended to apply to. If no versions are specified, the resource is assumed to apply to all the versions stated in ImplementationGuide.fhirVersion."
      },
      {
        "Data Fields": "definition.resource.name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A human assigned name for the resource. All resources SHOULD have a name, but the name may be extracted from the resource (e.g. ValueSet.name)."
      },
      {
        "Data Fields": "definition.resource.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A description of the reason that a resource has been included in the implementation guide."
      },
      {
        "Data Fields": "definition.resource.exampleBoolean",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "If true or a reference, indicates the resource is an example instance.  If a reference is present, indicates that the example is an example of the specified profile."
      },
      {
        "Data Fields": "definition.resource.exampleCanonical",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "If true or a reference, indicates the resource is an example instance.  If a reference is present, indicates that the example is an example of the specified profile."
      },
      {
        "Data Fields": "definition.resource.groupingId",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "Reference to the id of the grouping this resource appears in."
      },
      {
        "Data Fields": "definition.page",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A page / section in the implementation guide. The root page is the implementation guide home page."
      },
      {
        "Data Fields": "definition.page.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "definition.page.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "definition.page.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "definition.page.nameUrl",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The source address for the page."
      },
      {
        "Data Fields": "definition.page.nameReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The source address for the page."
      },
      {
        "Data Fields": "definition.page.title",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A short title used to represent this page in navigational structures such as table of contents, bread crumbs, etc."
      },
      {
        "Data Fields": "definition.page.generation",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "A code that indicates how the page is generated."
      },
      {
        "Data Fields": "definition.parameter",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Defines how IG is built by tools."
      },
      {
        "Data Fields": "definition.parameter.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "definition.parameter.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "definition.parameter.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "definition.parameter.code",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "apply | path-resource | path-pages | path-tx-cache | expansion-parameter | rule-broken-links | generate-xml | generate-json | generate-turtle | html-template."
      },
      {
        "Data Fields": "definition.parameter.value",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Value for named type."
      },
      {
        "Data Fields": "definition.template",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A template for building resources."
      },
      {
        "Data Fields": "definition.template.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "definition.template.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "definition.template.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "definition.template.code",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Type of template specified."
      },
      {
        "Data Fields": "definition.template.source",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The source location for the template."
      },
      {
        "Data Fields": "definition.template.scope",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The scope in which the template applies."
      },
      {
        "Data Fields": "manifest",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Information about an assembled implementation guide, created by the publication tooling."
      },
      {
        "Data Fields": "manifest.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "manifest.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "manifest.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "manifest.rendering",
        "Type": "url",
        
        
        "Required": "False",
        "Description": "A pointer to official web page, PDF or other rendering of the implementation guide."
      },
      {
        "Data Fields": "manifest.resource",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A resource that is part of the implementation guide. Conformance resources (value set, structure definition, capability statements etc.) are obvious candidates for inclusion, but any kind of resource can be included as an example resource."
      },
      {
        "Data Fields": "manifest.resource.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "manifest.resource.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "manifest.resource.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "manifest.resource.reference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Where this resource is found."
      },
      {
        "Data Fields": "manifest.resource.exampleBoolean",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "If true or a reference, indicates the resource is an example instance.  If a reference is present, indicates that the example is an example of the specified profile."
      },
      {
        "Data Fields": "manifest.resource.exampleCanonical",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "If true or a reference, indicates the resource is an example instance.  If a reference is present, indicates that the example is an example of the specified profile."
      },
      {
        "Data Fields": "manifest.resource.relativePath",
        "Type": "url",
        
        
        "Required": "False",
        "Description": "The relative path for primary page for this resource within the IG."
      },
      {
        "Data Fields": "manifest.page",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Information about a page within the IG."
      },
      {
        "Data Fields": "manifest.page.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "manifest.page.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "manifest.page.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "manifest.page.name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Relative path to the page."
      },
      {
        "Data Fields": "manifest.page.title",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Label for the page intended for human display."
      },
      {
        "Data Fields": "manifest.page.anchor",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of an anchor available on the page."
      },
      {
        "Data Fields": "manifest.image",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Indicates a relative path to an image that exists within the IG."
      },
      {
        "Data Fields": "manifest.other",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Indicates the relative path of an additional non-page, non-image file that is part of the IG - e.g. zip, jar and similar files that could be the target of a hyperlink in a derived IG."
      }
    ],
    "InsurancePlan": [
      {
        "Data Fields": "resourceType",
        "Type": "InsurancePlan",
        
        
        "Required": "True",
        "Description": "This is a InsurancePlan resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Business identifiers assigned to this health insurance product which remain constant as the resource is updated and propagates from server to server."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The current state of the health insurance product."
      },
      {
        "Data Fields": "type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The kind of health insurance product."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Official name of the health insurance product as designated by the owner."
      },
      {
        "Data Fields": "alias",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A list of alternate names that the product is known as, or was known as in the past."
      },
      {
        "Data Fields": "period",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The period of time that the health insurance product is available."
      },
      {
        "Data Fields": "ownedBy",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The entity that is providing  the health insurance product and underwriting the risk.  This is typically an insurance carriers, other third-party payers, or health plan sponsors comonly referred to as 'payers'."
      },
      {
        "Data Fields": "administeredBy",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "An organization which administer other services such as underwriting, customer service and/or claims processing on behalf of the health insurance product owner."
      },
      {
        "Data Fields": "coverageArea",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The geographic region in which a health insurance product's benefits apply."
      },
      {
        "Data Fields": "contact",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The contact for the health insurance product for a certain purpose."
      },
      {
        "Data Fields": "contact.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "contact.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "contact.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "contact.purpose",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Indicates a purpose for which the contact can be reached."
      },
      {
        "Data Fields": "contact.name",
        "Type": "HumanName",
        
        
        "Required": "False",
        "Description": "A name associated with the contact."
      },
      {
        "Data Fields": "contact.telecom",
        "Type": "ContactPoint",
        
        
        "Required": "False",
        "Description": "A contact detail (e.g. a telephone number or an email address) by which the party may be contacted."
      },
      {
        "Data Fields": "contact.address",
        "Type": "Address",
        
        
        "Required": "False",
        "Description": "Visiting or postal addresses for the contact."
      },
      {
        "Data Fields": "endpoint",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The technical endpoints providing access to services operated for the health insurance product."
      },
      {
        "Data Fields": "network",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Reference to the network included in the health insurance product."
      },
      {
        "Data Fields": "coverage",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Details about the coverage offered by the insurance product."
      },
      {
        "Data Fields": "coverage.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "coverage.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "coverage.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "coverage.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Type of coverage  (Medical; Dental; Mental Health; Substance Abuse; Vision; Drug; Short Term; Long Term Care; Hospice; Home Health)."
      },
      {
        "Data Fields": "coverage.network",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Reference to the network that providing the type of coverage."
      },
      {
        "Data Fields": "coverage.benefit",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Specific benefits under this type of coverage."
      },
      {
        "Data Fields": "coverage.benefit.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "coverage.benefit.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "coverage.benefit.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "coverage.benefit.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Type of benefit (primary care; speciality care; inpatient; outpatient)."
      },
      {
        "Data Fields": "coverage.benefit.requirement",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The referral requirements to have access/coverage for this benefit."
      },
      {
        "Data Fields": "coverage.benefit.limit",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The specific limits on the benefit."
      },
      {
        "Data Fields": "coverage.benefit.limit.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "coverage.benefit.limit.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "coverage.benefit.limit.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "coverage.benefit.limit.value",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The maximum amount of a service item a plan will pay for a covered benefit.  For examples. wellness visits, or eyeglasses."
      },
      {
        "Data Fields": "coverage.benefit.limit.code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The specific limit on the benefit."
      },
      {
        "Data Fields": "plan",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Details about an insurance plan."
      },
      {
        "Data Fields": "plan.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "plan.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "plan.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "plan.identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Business identifiers assigned to this health insurance plan which remain constant as the resource is updated and propagates from server to server."
      },
      {
        "Data Fields": "plan.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Type of plan. For example, \"Platinum\" or \"High Deductable\"."
      },
      {
        "Data Fields": "plan.coverageArea",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The geographic region in which a health insurance plan's benefits apply."
      },
      {
        "Data Fields": "plan.network",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Reference to the network that providing the type of coverage."
      },
      {
        "Data Fields": "plan.generalCost",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Overall costs associated with the plan."
      },
      {
        "Data Fields": "plan.generalCost.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "plan.generalCost.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "plan.generalCost.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "plan.generalCost.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Type of cost."
      },
      {
        "Data Fields": "plan.generalCost.groupSize",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "Number of participants enrolled in the plan."
      },
      {
        "Data Fields": "plan.generalCost.cost",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "Value of the cost."
      },
      {
        "Data Fields": "plan.generalCost.comment",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Additional information about the general costs associated with this plan."
      },
      {
        "Data Fields": "plan.specificCost",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Costs associated with the coverage provided by the product."
      },
      {
        "Data Fields": "plan.specificCost.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "plan.specificCost.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "plan.specificCost.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "plan.specificCost.category",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "General category of benefit (Medical; Dental; Vision; Drug; Mental Health; Substance Abuse; Hospice, Home Health)."
      },
      {
        "Data Fields": "plan.specificCost.benefit",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "List of the specific benefits under this category of benefit."
      },
      {
        "Data Fields": "plan.specificCost.benefit.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "plan.specificCost.benefit.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "plan.specificCost.benefit.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "plan.specificCost.benefit.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Type of specific benefit (preventative; primary care office visit; speciality office visit; hospitalization; emergency room; urgent care)."
      },
      {
        "Data Fields": "plan.specificCost.benefit.cost",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "List of the costs associated with a specific benefit."
      },
      {
        "Data Fields": "plan.specificCost.benefit.cost.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "plan.specificCost.benefit.cost.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "plan.specificCost.benefit.cost.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "plan.specificCost.benefit.cost.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Type of cost (copay; individual cap; family cap; coinsurance; deductible)."
      },
      {
        "Data Fields": "plan.specificCost.benefit.cost.applicability",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Whether the cost applies to in-network or out-of-network providers (in-network; out-of-network; other)."
      },
      {
        "Data Fields": "plan.specificCost.benefit.cost.qualifiers",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Additional information about the cost, such as information about funding sources (e.g. HSA, HRA, FSA, RRA)."
      },
      {
        "Data Fields": "plan.specificCost.benefit.cost.value",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The actual cost value. (some of the costs may be represented as percentages rather than currency, e.g. 10% coinsurance)."
      }
    ],
    "Invoice": [
      {
        "Data Fields": "resourceType",
        "Type": "Invoice",
        
        
        "Required": "True",
        "Description": "This is a Invoice resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Identifier of this Invoice, often used for reference in correspondence about this invoice or for tracking of payments."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The current state of the Invoice."
      },
      {
        "Data Fields": "cancelledReason",
        "Type": "string",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "In case of Invoice cancellation a reason must be given (entered in error, superseded by corrected invoice etc.)."
      },
      {
        "Data Fields": "type",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Type of Invoice depending on domain, realm an usage (e.g. internal/external, dental, preliminary)."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The individual or set of individuals receiving the goods and services billed in this invoice."
      },
      {
        "Data Fields": "recipient",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The individual or Organization responsible for balancing of this invoice."
      },
      {
        "Data Fields": "date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "Date/time(s) of when this Invoice was posted."
      },
      {
        "Data Fields": "participant",
        "Type": "BackboneElement",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Indicates who or what performed or participated in the charged service."
      },
      {
        "Data Fields": "participant.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "participant.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "participant.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "participant.role",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Describes the type of involvement (e.g. transcriptionist, creator etc.). If the invoice has been created automatically, the Participant may be a billing engine or another kind of device."
      },
      {
        "Data Fields": "participant.actor",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The device, practitioner, etc. who performed or participated in the service."
      },
      {
        "Data Fields": "issuer",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The organizationissuing the Invoice."
      },
      {
        "Data Fields": "account",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Account which is supposed to be balanced with this Invoice."
      },
      {
        "Data Fields": "lineItem",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Each line item represents one charge for goods and services rendered. Details such as date, code and amount are found in the referenced ChargeItem resource."
      },
      {
        "Data Fields": "lineItem.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "lineItem.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "lineItem.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "lineItem.sequence",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "Sequence in which the items appear on the invoice."
      },
      {
        "Data Fields": "lineItem.chargeItemReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The ChargeItem contains information such as the billing code, date, amount etc. If no further details are required for the lineItem, inline billing codes can be added using the CodeableConcept data type instead of the Reference."
      },
      {
        "Data Fields": "lineItem.chargeItemCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The ChargeItem contains information such as the billing code, date, amount etc. If no further details are required for the lineItem, inline billing codes can be added using the CodeableConcept data type instead of the Reference."
      },
      {
        "Data Fields": "lineItem.priceComponent",
        "Type": "BackboneElement",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The price for a ChargeItem may be calculated as a base price with surcharges/deductions that apply in certain conditions. A ChargeItemDefinition resource that defines the prices, factors and conditions that apply to a billing code is currently under development. The priceComponent element can be used to offer transparency to the recipient of the Invoice as to how the prices have been calculated."
      },
      {
        "Data Fields": "lineItem.priceComponent.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "lineItem.priceComponent.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "lineItem.priceComponent.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "lineItem.priceComponent.type",
        "Type": "code",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "This code identifies the type of the component."
      },
      {
        "Data Fields": "lineItem.priceComponent.code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code that identifies the component. Codes may be used to differentiate between kinds of taxes, surcharges, discounts etc."
      },
      {
        "Data Fields": "lineItem.priceComponent.factor",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "The factor that has been applied on the base price for calculating this component."
      },
      {
        "Data Fields": "lineItem.priceComponent.amount",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "The amount calculated for this component."
      },
      {
        "Data Fields": "totalPriceComponent",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The total amount for the Invoice may be calculated as the sum of the line items with surcharges/deductions that apply in certain conditions.  The priceComponent element can be used to offer transparency to the recipient of the Invoice of how the total price was calculated."
      },
      {
        "Data Fields": "totalPriceComponent.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "totalPriceComponent.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "totalPriceComponent.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "totalPriceComponent.type",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "This code identifies the type of the component."
      },
      {
        "Data Fields": "totalPriceComponent.code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code that identifies the component. Codes may be used to differentiate between kinds of taxes, surcharges, discounts etc."
      },
      {
        "Data Fields": "totalPriceComponent.factor",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "The factor that has been applied on the base price for calculating this component."
      },
      {
        "Data Fields": "totalPriceComponent.amount",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "The amount calculated for this component."
      },
      {
        "Data Fields": "totalNet",
        "Type": "Money",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Invoice total , taxes excluded."
      },
      {
        "Data Fields": "totalGross",
        "Type": "Money",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Invoice total, tax included."
      },
      {
        "Data Fields": "paymentTerms",
        "Type": "markdown",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Payment details such as banking details, period of payment, deductibles, methods of payment."
      },
      {
        "Data Fields": "note",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "Comments made about the invoice by the issuer, subject, or other participants."
      }
    ],
    "Library": [
      {
        "Data Fields": "resourceType",
        "Type": "Library",
        
        
        "Required": "True",
        "Description": "This is a Library resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "url",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "An absolute URI that is used to identify this library when it is referenced in a specification, model, design or an instance; also called its canonical identifier. This SHOULD be globally unique and SHOULD be a literal address at which at which an authoritative instance of this library is (or will be) published. This URL can be the target of a canonical reference. It SHALL remain the same when the library is stored on different servers."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A formal identifier that is used to identify this library when it is represented in other formats, or referenced in a specification, model, design or an instance. e.g. CMS or NQF identifiers for a measure artifact. Note that at least one identifier is required for non-experimental active artifacts."
      },
      {
        "Data Fields": "version",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The identifier that is used to identify this version of the library when it is referenced in a specification, model, design or instance. This is an arbitrary value managed by the library author and is not expected to be globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if a managed version is not available. There is also no expectation that versions can be placed in a lexicographical sequence. To provide a version consistent with the Decision Support Service specification, use the format Major.Minor.Revision (e.g. 1.0.0). For more information on versioning knowledge assets, refer to the Decision Support Service specification. Note that a version is required for non-experimental active artifacts."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A natural language name identifying the library. This name should be usable as an identifier for the module by machine processing applications such as code generation."
      },
      {
        "Data Fields": "title",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A short, descriptive, user-friendly title for the library."
      },
      {
        "Data Fields": "subtitle",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "An explanatory or alternate title for the library giving additional information about its content."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The status of this library. Enables tracking the life-cycle of the content."
      },
      {
        "Data Fields": "experimental",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "A Boolean value to indicate that this library is authored for testing purposes (or education/evaluation/marketing) and is not intended to be used for genuine usage."
      },
      {
        "Data Fields": "type",
        "Type": "CodeableConcept",
        
        
        "Required": "True",
        "Description": "Identifies the type of library such as a Logic Library, Model Definition, Asset Collection, or Module Definition."
      },
      {
        "Data Fields": "subjectCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code or group definition that describes the intended subject of the contents of the library."
      },
      {
        "Data Fields": "subjectReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A code or group definition that describes the intended subject of the contents of the library."
      },
      {
        "Data Fields": "date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date  (and optionally time) when the library was published. The date must change when the business version changes and it must change if the status code changes. In addition, it should change when the substantive content of the library changes."
      },
      {
        "Data Fields": "publisher",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the organization or individual that published the library."
      },
      {
        "Data Fields": "contact",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "Contact details to assist a user in finding and communicating with the publisher."
      },
      {
        "Data Fields": "description",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A free text natural language description of the library from a consumer's perspective."
      },
      {
        "Data Fields": "useContext",
        "Type": "UsageContext",
        
        
        "Required": "False",
        "Description": "The content was developed with a focus and intent of supporting the contexts that are listed. These contexts may be general categories (gender, age, ...) or may be references to specific programs (insurance plans, studies, ...) and may be used to assist with indexing and searching for appropriate library instances."
      },
      {
        "Data Fields": "jurisdiction",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A legal or geographic region in which the library is intended to be used."
      },
      {
        "Data Fields": "purpose",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Explanation of why this library is needed and why it has been designed as it has."
      },
      {
        "Data Fields": "usage",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A detailed description of how the library is used from a clinical perspective."
      },
      {
        "Data Fields": "copyright",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A copyright statement relating to the library and/or its contents. Copyright statements are generally legal restrictions on the use and publishing of the library."
      },
      {
        "Data Fields": "approvalDate",
        "Type": "date",
        
        
        "Required": "False",
        "Description": "The date on which the resource content was approved by the publisher. Approval happens once when the content is officially approved for usage."
      },
      {
        "Data Fields": "lastReviewDate",
        "Type": "date",
        
        
        "Required": "False",
        "Description": "The date on which the resource content was last reviewed. Review happens periodically after approval but does not change the original approval date."
      },
      {
        "Data Fields": "effectivePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The period during which the library content was or is planned to be in active use."
      },
      {
        "Data Fields": "topic",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Descriptive topics related to the content of the library. Topics provide a high-level categorization of the library that can be useful for filtering and searching."
      },
      {
        "Data Fields": "author",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individiual or organization primarily involved in the creation and maintenance of the content."
      },
      {
        "Data Fields": "editor",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individual or organization primarily responsible for internal coherence of the content."
      },
      {
        "Data Fields": "reviewer",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individual or organization primarily responsible for review of some aspect of the content."
      },
      {
        "Data Fields": "endorser",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individual or organization responsible for officially endorsing the content for use in some setting."
      },
      {
        "Data Fields": "relatedArtifact",
        "Type": "RelatedArtifact",
        
        
        "Required": "False",
        "Description": "Related artifacts such as additional documentation, justification, or bibliographic references."
      },
      {
        "Data Fields": "parameter",
        "Type": "ParameterDefinition",
        
        
        "Required": "False",
        "Description": "The parameter element defines parameters used by the library."
      },
      {
        "Data Fields": "dataRequirement",
        "Type": "DataRequirement",
        
        
        "Required": "False",
        "Description": "Describes a set of data that must be provided in order to be able to successfully perform the computations defined by the library."
      },
      {
        "Data Fields": "content",
        "Type": "Attachment",
        
        
        "Required": "False",
        "Description": "The content of the library as an Attachment. The content may be a reference to a url, or may be directly embedded as a base-64 string. Either way, the contentType of the attachment determines how to interpret the content."
      }
    ],
    "Linkage": [
      {
        "Data Fields": "resourceType",
        "Type": "Linkage",
        
        
        "Required": "True",
        "Description": "This is a Linkage resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "active",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Indicates whether the asserted set of linkages are considered to be \"in effect\"."
      },
      {
        "Data Fields": "author",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Identifies the user or organization responsible for asserting the linkages as well as the user or organization who establishes the context in which the nature of each linkage is evaluated."
      },
      {
        "Data Fields": "item",
        "Type": "BackboneElement",
        
        
        "Required": "True",
        "Description": "Identifies which record considered as the reference to the same real-world occurrence as well as how the items should be evaluated within the collection of linked items."
      },
      {
        "Data Fields": "item.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "item.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "item.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "item.type",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Distinguishes which item is \"source of truth\" (if any) and which items are no longer considered to be current representations."
      },
      {
        "Data Fields": "item.resource",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The resource instance being linked as part of the group."
      }
    ],
    "List": [
      {
        "Data Fields": "resourceType",
        "Type": "List",
        
        
        "Required": "True",
        "Description": "This is a List resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Identifier for the List assigned for business purposes outside the context of FHIR."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Indicates the current state of this list."
      },
      {
        "Data Fields": "mode",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "How this list was prepared - whether it is a working list that is suitable for being maintained on an ongoing basis, or if it represents a snapshot of a list of items from another source, or whether it is a prepared list where items may be marked as added, modified or deleted."
      },
      {
        "Data Fields": "title",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A label for the list assigned by the author."
      },
      {
        "Data Fields": "code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "This code defines the purpose of the list - why it was created."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The common subject (or patient) of the resources that are in the list if there is one."
      },
      {
        "Data Fields": "encounter",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The encounter that is the context in which this list was created."
      },
      {
        "Data Fields": "date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date that the list was prepared."
      },
      {
        "Data Fields": "source",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The entity responsible for deciding what the contents of the list were. Where the list was created by a human, this is the same as the author of the list."
      },
      {
        "Data Fields": "orderedBy",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "What order applies to the items in the list."
      },
      {
        "Data Fields": "note",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "Comments that apply to the overall list."
      },
      {
        "Data Fields": "entry",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Entries in this list."
      },
      {
        "Data Fields": "entry.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "entry.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "entry.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "entry.flag",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The flag allows the system constructing the list to indicate the role and significance of the item in the list."
      },
      {
        "Data Fields": "entry.deleted",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "True if this item is marked as deleted in the list."
      },
      {
        "Data Fields": "entry.date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "When this item was added to the list."
      },
      {
        "Data Fields": "entry.item",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A reference to the actual resource from which data was derived."
      },
      {
        "Data Fields": "emptyReason",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "If the list is empty, why the list is empty."
      }
    ],
    "Location": [
      {
        "Data Fields": "resourceType",
        "Type": "Location",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "This is a Location resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Unique code or number identifying the location to its users."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The status property covers the general availability of the resource, not the current value which may be covered by the operationStatus, or by a schedule/slots if they are configured for the location."
      },
      {
        "Data Fields": "operationalStatus",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "The operational status covers operation values most relevant to beds (but can also apply to rooms/units/chairs/etc. such as an isolation unit/dialysis chair). This typically covers concepts such as contamination, housekeeping, and other activities like maintenance."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Name of the location as used by humans. Does not need to be unique."
      },
      {
        "Data Fields": "alias",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A list of alternate names that the location is known as, or was known as, in the past."
      },
      {
        "Data Fields": "description",
        "Type": "string",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Description of the Location, which helps in finding or referencing the place."
      },
      {
        "Data Fields": "mode",
        "Type": "code",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Indicates whether a resource instance represents a specific location or a class of locations."
      },
      {
        "Data Fields": "type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Indicates the type of function performed at the location."
      },
      {
        "Data Fields": "telecom",
        "Type": "ContactPoint",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The contact details of communication devices available at the location. This can include phone numbers, fax numbers, mobile numbers, email addresses and web sites."
      },
      {
        "Data Fields": "address",
        "Type": "Address",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Physical location."
      },
      {
        "Data Fields": "physicalType",
        "Type": "CodeableConcept",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Physical form of the location, e.g. building, room, vehicle, road."
      },
      {
        "Data Fields": "position",
        "Type": "BackboneElement",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The absolute geographic location of the Location, expressed using the WGS84 datum (This is the same co-ordinate system used in KML)."
      },
      {
        "Data Fields": "position.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "position.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "position.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "position.longitude",
        "Type": "decimal",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Longitude. The value domain and the interpretation are the same as for the text of the longitude element in KML (see notes below)."
      },
      {
        "Data Fields": "position.latitude",
        "Type": "decimal",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Latitude. The value domain and the interpretation are the same as for the text of the latitude element in KML (see notes below)."
      },
      {
        "Data Fields": "position.altitude",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "Altitude. The value domain and the interpretation are the same as for the text of the altitude element in KML (see notes below)."
      },
      {
        "Data Fields": "managingOrganization",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The organization responsible for the provisioning and upkeep of the location."
      },
      {
        "Data Fields": "partOf",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Another Location of which this Location is physically a part of."
      },
      {
        "Data Fields": "hoursOfOperation",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "What days/times during a week is this location usually open."
      },
      {
        "Data Fields": "hoursOfOperation.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "hoursOfOperation.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "hoursOfOperation.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "hoursOfOperation.daysOfWeek",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Indicates which days of the week are available between the start and end Times."
      },
      {
        "Data Fields": "hoursOfOperation.allDay",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "The Location is open all day."
      },
      {
        "Data Fields": "hoursOfOperation.openingTime",
        "Type": "time",
        
        
        "Required": "False",
        "Description": "Time that the Location opens."
      },
      {
        "Data Fields": "hoursOfOperation.closingTime",
        "Type": "time",
        
        
        "Required": "False",
        "Description": "Time that the Location closes."
      },
      {
        "Data Fields": "availabilityExceptions",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A description of when the locations opening ours are different to normal, e.g. public holiday availability. Succinctly describing all possible exceptions to normal site availability as detailed in the opening hours Times."
      },
      {
        "Data Fields": "endpoint",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Technical endpoints providing access to services operated for the location."
      }
    ],
    "Measure": [
      {
        "Data Fields": "resourceType",
        "Type": "Measure",
        
        
        "Required": "True",
        "Description": "This is a Measure resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "url",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "An absolute URI that is used to identify this measure when it is referenced in a specification, model, design or an instance; also called its canonical identifier. This SHOULD be globally unique and SHOULD be a literal address at which at which an authoritative instance of this measure is (or will be) published. This URL can be the target of a canonical reference. It SHALL remain the same when the measure is stored on different servers."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A formal identifier that is used to identify this measure when it is represented in other formats, or referenced in a specification, model, design or an instance."
      },
      {
        "Data Fields": "version",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The identifier that is used to identify this version of the measure when it is referenced in a specification, model, design or instance. This is an arbitrary value managed by the measure author and is not expected to be globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if a managed version is not available. There is also no expectation that versions can be placed in a lexicographical sequence. To provide a version consistent with the Decision Support Service specification, use the format Major.Minor.Revision (e.g. 1.0.0). For more information on versioning knowledge assets, refer to the Decision Support Service specification. Note that a version is required for non-experimental active artifacts."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A natural language name identifying the measure. This name should be usable as an identifier for the module by machine processing applications such as code generation."
      },
      {
        "Data Fields": "title",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A short, descriptive, user-friendly title for the measure."
      },
      {
        "Data Fields": "subtitle",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "An explanatory or alternate title for the measure giving additional information about its content."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The status of this measure. Enables tracking the life-cycle of the content."
      },
      {
        "Data Fields": "experimental",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "A Boolean value to indicate that this measure is authored for testing purposes (or education/evaluation/marketing) and is not intended to be used for genuine usage."
      },
      {
        "Data Fields": "subjectCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The intended subjects for the measure. If this element is not provided, a Patient subject is assumed, but the subject of the measure can be anything."
      },
      {
        "Data Fields": "subjectReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The intended subjects for the measure. If this element is not provided, a Patient subject is assumed, but the subject of the measure can be anything."
      },
      {
        "Data Fields": "date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date  (and optionally time) when the measure was published. The date must change when the business version changes and it must change if the status code changes. In addition, it should change when the substantive content of the measure changes."
      },
      {
        "Data Fields": "publisher",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the organization or individual that published the measure."
      },
      {
        "Data Fields": "contact",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "Contact details to assist a user in finding and communicating with the publisher."
      },
      {
        "Data Fields": "description",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A free text natural language description of the measure from a consumer's perspective."
      },
      {
        "Data Fields": "useContext",
        "Type": "UsageContext",
        
        
        "Required": "False",
        "Description": "The content was developed with a focus and intent of supporting the contexts that are listed. These contexts may be general categories (gender, age, ...) or may be references to specific programs (insurance plans, studies, ...) and may be used to assist with indexing and searching for appropriate measure instances."
      },
      {
        "Data Fields": "jurisdiction",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A legal or geographic region in which the measure is intended to be used."
      },
      {
        "Data Fields": "purpose",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Explanation of why this measure is needed and why it has been designed as it has."
      },
      {
        "Data Fields": "usage",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A detailed description, from a clinical perspective, of how the measure is used."
      },
      {
        "Data Fields": "copyright",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A copyright statement relating to the measure and/or its contents. Copyright statements are generally legal restrictions on the use and publishing of the measure."
      },
      {
        "Data Fields": "approvalDate",
        "Type": "date",
        
        
        "Required": "False",
        "Description": "The date on which the resource content was approved by the publisher. Approval happens once when the content is officially approved for usage."
      },
      {
        "Data Fields": "lastReviewDate",
        "Type": "date",
        
        
        "Required": "False",
        "Description": "The date on which the resource content was last reviewed. Review happens periodically after approval but does not change the original approval date."
      },
      {
        "Data Fields": "effectivePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The period during which the measure content was or is planned to be in active use."
      },
      {
        "Data Fields": "topic",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Descriptive topics related to the content of the measure. Topics provide a high-level categorization grouping types of measures that can be useful for filtering and searching."
      },
      {
        "Data Fields": "author",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individiual or organization primarily involved in the creation and maintenance of the content."
      },
      {
        "Data Fields": "editor",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individual or organization primarily responsible for internal coherence of the content."
      },
      {
        "Data Fields": "reviewer",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individual or organization primarily responsible for review of some aspect of the content."
      },
      {
        "Data Fields": "endorser",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individual or organization responsible for officially endorsing the content for use in some setting."
      },
      {
        "Data Fields": "relatedArtifact",
        "Type": "RelatedArtifact",
        
        
        "Required": "False",
        "Description": "Related artifacts such as additional documentation, justification, or bibliographic references."
      },
      {
        "Data Fields": "library",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "A reference to a Library resource containing the formal logic used by the measure."
      },
      {
        "Data Fields": "disclaimer",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Notices and disclaimers regarding the use of the measure or related to intellectual property (such as code systems) referenced by the measure."
      },
      {
        "Data Fields": "scoring",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Indicates how the calculation is performed for the measure, including proportion, ratio, continuous-variable, and cohort. The value set is extensible, allowing additional measure scoring types to be represented."
      },
      {
        "Data Fields": "compositeScoring",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "If this is a composite measure, the scoring method used to combine the component measures to determine the composite score."
      },
      {
        "Data Fields": "type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Indicates whether the measure is used to examine a process, an outcome over time, a patient-reported outcome, or a structure measure such as utilization."
      },
      {
        "Data Fields": "riskAdjustment",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A description of the risk adjustment factors that may impact the resulting score for the measure and how they may be accounted for when computing and reporting measure results."
      },
      {
        "Data Fields": "rateAggregation",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Describes how to combine the information calculated, based on logic in each of several populations, into one summarized result."
      },
      {
        "Data Fields": "rationale",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Provides a succinct statement of the need for the measure. Usually includes statements pertaining to importance criterion: impact, gap in care, and evidence."
      },
      {
        "Data Fields": "clinicalRecommendationStatement",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Provides a summary of relevant clinical guidelines or other clinical recommendations supporting the measure."
      },
      {
        "Data Fields": "improvementNotation",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Information on whether an increase or decrease in score is the preferred result (e.g., a higher score indicates better quality OR a lower score indicates better quality OR quality is within a range)."
      },
      {
        "Data Fields": "definition",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Provides a description of an individual term used within the measure."
      },
      {
        "Data Fields": "guidance",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Additional guidance for the measure including how it can be used in a clinical context, and the intent of the measure."
      },
      {
        "Data Fields": "group",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A group of population criteria for the measure."
      },
      {
        "Data Fields": "group.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "group.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "group.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "group.code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Indicates a meaning for the group. This can be as simple as a unique identifier, or it can establish meaning in a broader context by drawing from a terminology, allowing groups to be correlated across measures."
      },
      {
        "Data Fields": "group.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The human readable description of this population group."
      },
      {
        "Data Fields": "group.population",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A population criteria for the measure."
      },
      {
        "Data Fields": "group.population.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "group.population.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "group.population.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "group.population.code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The type of population criteria."
      },
      {
        "Data Fields": "group.population.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The human readable description of this population criteria."
      },
      {
        "Data Fields": "group.population.criteria",
        "Type": "Expression",
        
        
        "Required": "False",
        "Description": "An expression that specifies the criteria for the population, typically the name of an expression in a library."
      },
      {
        "Data Fields": "group.stratifier",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The stratifier criteria for the measure report, specified as either the name of a valid CQL expression defined within a referenced library or a valid FHIR Resource Path."
      },
      {
        "Data Fields": "group.stratifier.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "group.stratifier.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "group.stratifier.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "group.stratifier.code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Indicates a meaning for the stratifier. This can be as simple as a unique identifier, or it can establish meaning in a broader context by drawing from a terminology, allowing stratifiers to be correlated across measures."
      },
      {
        "Data Fields": "group.stratifier.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The human readable description of this stratifier criteria."
      },
      {
        "Data Fields": "group.stratifier.criteria",
        "Type": "Expression",
        
        
        "Required": "False",
        "Description": "An expression that specifies the criteria for the stratifier. This is typically the name of an expression defined within a referenced library, but it may also be a path to a stratifier element."
      },
      {
        "Data Fields": "group.stratifier.component",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A component of the stratifier criteria for the measure report, specified as either the name of a valid CQL expression defined within a referenced library or a valid FHIR Resource Path."
      },
      {
        "Data Fields": "group.stratifier.component.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "group.stratifier.component.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "group.stratifier.component.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "group.stratifier.component.code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Indicates a meaning for the stratifier component. This can be as simple as a unique identifier, or it can establish meaning in a broader context by drawing from a terminology, allowing stratifiers to be correlated across measures."
      },
      {
        "Data Fields": "group.stratifier.component.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The human readable description of this stratifier criteria component."
      },
      {
        "Data Fields": "group.stratifier.component.criteria",
        "Type": "Expression",
        
        
        "Required": "False",
        "Description": "An expression that specifies the criteria for this component of the stratifier. This is typically the name of an expression defined within a referenced library, but it may also be a path to a stratifier element."
      },
      {
        "Data Fields": "supplementalData",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The supplemental data criteria for the measure report, specified as either the name of a valid CQL expression within a referenced library, or a valid FHIR Resource Path."
      },
      {
        "Data Fields": "supplementalData.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "supplementalData.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "supplementalData.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "supplementalData.code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Indicates a meaning for the supplemental data. This can be as simple as a unique identifier, or it can establish meaning in a broader context by drawing from a terminology, allowing supplemental data to be correlated across measures."
      },
      {
        "Data Fields": "supplementalData.usage",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "An indicator of the intended usage for the supplemental data element. Supplemental data indicates the data is additional information requested to augment the measure information. Risk adjustment factor indicates the data is additional information used to calculate risk adjustment factors when applying a risk model to the measure calculation."
      },
      {
        "Data Fields": "supplementalData.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The human readable description of this supplemental data."
      },
      {
        "Data Fields": "supplementalData.criteria",
        "Type": "Expression",
        
        
        "Required": "False",
        "Description": "The criteria for the supplemental data. This is typically the name of a valid expression defined within a referenced library, but it may also be a path to a specific data element. The criteria defines the data to be returned for this element."
      }
    ],
    "MeasureReport": [
      {
        "Data Fields": "resourceType",
        "Type": "MeasureReport",
        
        
        "Required": "True",
        "Description": "This is a MeasureReport resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A formal identifier that is used to identify this MeasureReport when it is represented in other formats or referenced in a specification, model, design or an instance."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The MeasureReport status. No data will be available until the MeasureReport status is complete."
      },
      {
        "Data Fields": "type",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The type of measure report. This may be an individual report, which provides the score for the measure for an individual member of the population; a subject-listing, which returns the list of members that meet the various criteria in the measure; a summary report, which returns a population count for each of the criteria in the measure; or a data-collection, which enables the MeasureReport to be used to exchange the data-of-interest for a quality measure."
      },
      {
        "Data Fields": "measure",
        "Type": "canonical",
        
        
        "Required": "True",
        "Description": "A reference to the Measure that was calculated to produce this report."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Optional subject identifying the individual or individuals the report is for."
      },
      {
        "Data Fields": "date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date this measure report was generated."
      },
      {
        "Data Fields": "reporter",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The individual, location, or organization that is reporting the data."
      },
      {
        "Data Fields": "period",
        "Type": "Period",
        
        
        "Required": "True",
        "Description": "The reporting period for which the report was calculated."
      },
      {
        "Data Fields": "improvementNotation",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Whether improvement in the measure is noted by an increase or decrease in the measure score."
      },
      {
        "Data Fields": "group",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The results of the calculation, one for each population group in the measure."
      },
      {
        "Data Fields": "group.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "group.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "group.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "group.code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The meaning of the population group as defined in the measure definition."
      },
      {
        "Data Fields": "group.population",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The populations that make up the population group, one for each type of population appropriate for the measure."
      },
      {
        "Data Fields": "group.population.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "group.population.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "group.population.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "group.population.code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The type of the population."
      },
      {
        "Data Fields": "group.population.count",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "The number of members of the population."
      },
      {
        "Data Fields": "group.population.subjectResults",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "This element refers to a List of subject level MeasureReport resources, one for each subject in this population."
      },
      {
        "Data Fields": "group.measureScore",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The measure score for this population group, calculated as appropriate for the measure type and scoring method, and based on the contents of the populations defined in the group."
      },
      {
        "Data Fields": "group.stratifier",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "When a measure includes multiple stratifiers, there will be a stratifier group for each stratifier defined by the measure."
      },
      {
        "Data Fields": "group.stratifier.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "group.stratifier.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "group.stratifier.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "group.stratifier.code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The meaning of this stratifier, as defined in the measure definition."
      },
      {
        "Data Fields": "group.stratifier.stratum",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "This element contains the results for a single stratum within the stratifier. For example, when stratifying on administrative gender, there will be four strata, one for each possible gender value."
      },
      {
        "Data Fields": "group.stratifier.stratum.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "group.stratifier.stratum.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "group.stratifier.stratum.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "group.stratifier.stratum.value",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The value for this stratum, expressed as a CodeableConcept. When defining stratifiers on complex values, the value must be rendered such that the value for each stratum within the stratifier is unique."
      },
      {
        "Data Fields": "group.stratifier.stratum.component",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A stratifier component value."
      },
      {
        "Data Fields": "group.stratifier.stratum.component.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "group.stratifier.stratum.component.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "group.stratifier.stratum.component.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "group.stratifier.stratum.component.code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The code for the stratum component value."
      },
      {
        "Data Fields": "group.stratifier.stratum.component.value",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The stratum component value."
      },
      {
        "Data Fields": "group.stratifier.stratum.population",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The populations that make up the stratum, one for each type of population appropriate to the measure."
      },
      {
        "Data Fields": "group.stratifier.stratum.population.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "group.stratifier.stratum.population.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "group.stratifier.stratum.population.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "group.stratifier.stratum.population.code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The type of the population."
      },
      {
        "Data Fields": "group.stratifier.stratum.population.count",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "The number of members of the population in this stratum."
      },
      {
        "Data Fields": "group.stratifier.stratum.population.subjectResults",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "This element refers to a List of subject level MeasureReport resources, one for each subject in this population in this stratum."
      },
      {
        "Data Fields": "group.stratifier.stratum.measureScore",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The measure score for this stratum, calculated as appropriate for the measure type and scoring method, and based on only the members of this stratum."
      },
      {
        "Data Fields": "evaluatedResource",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A reference to a Bundle containing the Resources that were used in the calculation of this measure."
      }
    ],
    "Media": [
      {
        "Data Fields": "resourceType",
        "Type": "Media",
        
        
        "Required": "True",
        "Description": "This is a Media resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Identifiers associated with the image - these may include identifiers for the image itself, identifiers for the context of its collection (e.g. series ids) and context ids such as accession numbers or other workflow identifiers."
      },
      {
        "Data Fields": "basedOn",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A procedure that is fulfilled in whole or in part by the creation of this media."
      },
      {
        "Data Fields": "partOf",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A larger event of which this particular event is a component or step."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The current state of the {{title}}."
      },
      {
        "Data Fields": "type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code that classifies whether the media is an image, video or audio recording or some other media category."
      },
      {
        "Data Fields": "modality",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Details of the type of the media - usually, how it was acquired (what type of device). If images sourced from a DICOM system, are wrapped in a Media resource, then this is the modality."
      },
      {
        "Data Fields": "view",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The name of the imaging view e.g. Lateral or Antero-posterior (AP)."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Who/What this Media is a record of."
      },
      {
        "Data Fields": "encounter",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The encounter that establishes the context for this media."
      },
      {
        "Data Fields": "createdDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The date and time(s) at which the media was collected."
      },
      {
        "Data Fields": "createdPeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The date and time(s) at which the media was collected."
      },
      {
        "Data Fields": "issued",
        "Type": "instant",
        
        
        "Required": "False",
        "Description": "The date and time this version of the media was made available to providers, typically after having been reviewed."
      },
      {
        "Data Fields": "operator",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The person who administered the collection of the image."
      },
      {
        "Data Fields": "reasonCode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Describes why the event occurred in coded or textual form."
      },
      {
        "Data Fields": "bodySite",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Indicates the site on the subject's body where the observation was made (i.e. the target site)."
      },
      {
        "Data Fields": "deviceName",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the device / manufacturer of the device  that was used to make the recording."
      },
      {
        "Data Fields": "device",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The device used to collect the media."
      },
      {
        "Data Fields": "height",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "Height of the image in pixels (photo/video)."
      },
      {
        "Data Fields": "width",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "Width of the image in pixels (photo/video)."
      },
      {
        "Data Fields": "frames",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "The number of frames in a photo. This is used with a multi-page fax, or an imaging acquisition context that takes multiple slices in a single image, or an animated gif. If there is more than one frame, this SHALL have a value in order to alert interface software that a multi-frame capable rendering widget is required."
      },
      {
        "Data Fields": "duration",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "The duration of the recording in seconds - for audio and video."
      },
      {
        "Data Fields": "content",
        "Type": "Attachment",
        
        
        "Required": "True",
        "Description": "The actual content of the media - inline or by direct reference to the media source file."
      },
      {
        "Data Fields": "note",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "Comments made about the media by the performer, subject or other participants."
      }
    ],
    "Medication": [
      {
        "Data Fields": "resourceType",
        "Type": "Medication",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "This is a Medication resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Business identifier for this medication."
      },
      {
        "Data Fields": "code",
        "Type": "CodeableConcept",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A code (or set of codes) that specify this medication, or a textual description if no code is available. Usage note: This could be a standard medication code such as a code from RxNorm, SNOMED CT, IDMP etc. It could also be a national or local formulary code, optionally with translations to other code systems."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A code to indicate if the medication is in active use."
      },
      {
        "Data Fields": "manufacturer",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Describes the details of the manufacturer of the medication product.  This is not intended to represent the distributor of a medication product."
      },
      {
        "Data Fields": "form",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Describes the form of the item.  Powder; tablets; capsule."
      },
      {
        "Data Fields": "amount",
        "Type": "Ratio",
        
        
        "Required": "False",
        "Description": "Specific amount of the drug in the packaged product.  For example, when specifying a product that has the same strength (For example, Insulin glargine 100 unit per mL solution for injection), this attribute provides additional clarification of the package amount (For example, 3 mL, 10mL, etc.)."
      },
      {
        "Data Fields": "ingredient",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Identifies a particular constituent of interest in the product."
      },
      {
        "Data Fields": "ingredient.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "ingredient.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "ingredient.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "ingredient.itemCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The actual ingredient - either a substance (simple ingredient) or another medication of a medication."
      },
      {
        "Data Fields": "ingredient.itemReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The actual ingredient - either a substance (simple ingredient) or another medication of a medication."
      },
      {
        "Data Fields": "ingredient.isActive",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Indication of whether this ingredient affects the therapeutic action of the drug."
      },
      {
        "Data Fields": "ingredient.strength",
        "Type": "Ratio",
        
        
        "Required": "False",
        "Description": "Specifies how many (or how much) of the items there are in this Medication.  For example, 250 mg per tablet.  This is expressed as a ratio where the numerator is 250mg and the denominator is 1 tablet."
      },
      {
        "Data Fields": "batch",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Information that only applies to packages (not products)."
      },
      {
        "Data Fields": "batch.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "batch.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "batch.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "batch.lotNumber",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The assigned lot number of a batch of the specified product."
      },
      {
        "Data Fields": "batch.expirationDate",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "When this specific batch of product will expire."
      }
    ],
    "MedicationAdministration": [
      {
        "Data Fields": "resourceType",
        "Type": "MedicationAdministration",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "This is a MedicationAdministration resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Identifiers associated with this Medication Administration that are defined by business processes and/or used to refer to it when a direct URL reference to the resource itself is not appropriate. They are business identifiers assigned to this resource by the performer or other systems and remain constant as the resource is updated and propagates from server to server."
      },
      {
        "Data Fields": "instantiates",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A protocol, guideline, orderset, or other definition that was adhered to in whole or in part by this event."
      },
      {
        "Data Fields": "partOf",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A larger event of which this particular event is a component or step."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Will generally be set to show that the administration has been completed.  For some long running administrations such as infusions, it is possible for an administration to be started but not completed or it may be paused while some other process is under way."
      },
      {
        "Data Fields": "statusReason",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A code indicating why the administration was not performed."
      },
      {
        "Data Fields": "category",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Indicates where the medication is expected to be consumed or administered."
      },
      {
        "Data Fields": "medicationCodeableConcept",
        "Type": "CodeableConcept",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Identifies the medication that was administered. This is either a link to a resource representing the details of the medication or a simple attribute carrying a code that identifies the medication from a known list of medications."
      },
      {
        "Data Fields": "medicationReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Identifies the medication that was administered. This is either a link to a resource representing the details of the medication or a simple attribute carrying a code that identifies the medication from a known list of medications."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "The person or animal or group receiving the medication."
      },
      {
        "Data Fields": "context",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The visit, admission, or other contact between patient and health care provider during which the medication administration was performed."
      },
      {
        "Data Fields": "supportingInformation",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Additional information (for example, patient height and weight) that supports the administration of the medication."
      },
      {
        "Data Fields": "effectiveDateTime",
        "Type": "string",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A specific date/time or interval of time during which the administration took place (or did not take place, when the 'notGiven' attribute is true). For many administrations, such as swallowing a tablet the use of dateTime is more appropriate."
      },
      {
        "Data Fields": "effectivePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "A specific date/time or interval of time during which the administration took place (or did not take place, when the 'notGiven' attribute is true). For many administrations, such as swallowing a tablet the use of dateTime is more appropriate."
      },
      {
        "Data Fields": "performer",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Indicates who or what performed the medication administration and how they were involved."
      },
      {
        "Data Fields": "performer.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "performer.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "performer.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "performer.function",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Distinguishes the type of involvement of the performer in the medication administration."
      },
      {
        "Data Fields": "performer.actor",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Indicates who or what performed the medication administration."
      },
      {
        "Data Fields": "reasonCode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code indicating why the medication was given."
      },
      {
        "Data Fields": "reasonReference",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Condition or observation that supports why the medication was administered."
      },
      {
        "Data Fields": "request",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The original request, instruction or authority to perform the administration."
      },
      {
        "Data Fields": "device",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The device used in administering the medication to the patient.  For example, a particular infusion pump."
      },
      {
        "Data Fields": "note",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "Extra information about the medication administration that is not conveyed by the other attributes."
      },
      {
        "Data Fields": "dosage",
        "Type": "BackboneElement",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Describes the medication dosage information details e.g. dose, rate, site, route, etc."
      },
      {
        "Data Fields": "dosage.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "dosage.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "dosage.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "dosage.text",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Free text dosage can be used for cases where the dosage administered is too complex to code. When coded dosage is present, the free text dosage may still be present for display to humans.\r\rThe dosage instructions should reflect the dosage of the medication that was administered."
      },
      {
        "Data Fields": "dosage.site",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A coded specification of the anatomic site where the medication first entered the body.  For example, \"left arm\"."
      },
      {
        "Data Fields": "dosage.route",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A code specifying the route or physiological path of administration of a therapeutic agent into or onto the patient.  For example, topical, intravenous, etc."
      },
      {
        "Data Fields": "dosage.method",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A coded value indicating the method by which the medication is intended to be or was introduced into or on the body.  This attribute will most often NOT be populated.  It is most commonly used for injections.  For example, Slow Push, Deep IV."
      },
      {
        "Data Fields": "dosage.dose",
        "Type": "Quantity",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The amount of the medication given at one administration event.   Use this value when the administration is essentially an instantaneous event such as a swallowing a tablet or giving an injection."
      },
      {
        "Data Fields": "dosage.rateRatio",
        "Type": "Ratio",
        
        
        "Required": "False",
        "Description": "Identifies the speed with which the medication was or will be introduced into the patient.  Typically, the rate for an infusion e.g. 100 ml per 1 hour or 100 ml/hr.  May also be expressed as a rate per unit of time, e.g. 500 ml per 2 hours.  Other examples:  200 mcg/min or 200 mcg/1 minute; 1 liter/8 hours."
      },
      {
        "Data Fields": "dosage.rateQuantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "Identifies the speed with which the medication was or will be introduced into the patient.  Typically, the rate for an infusion e.g. 100 ml per 1 hour or 100 ml/hr.  May also be expressed as a rate per unit of time, e.g. 500 ml per 2 hours.  Other examples:  200 mcg/min or 200 mcg/1 minute; 1 liter/8 hours."
      },
      {
        "Data Fields": "eventHistory",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A summary of the events of interest that have occurred, such as when the administration was verified."
      }
    ],
    "MedicationDispense": [
      {
        "Data Fields": "resourceType",
        "Type": "MedicationDispense",
        
        
        "Required": "True",
        "Description": "This is a MedicationDispense resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Identifiers associated with this Medication Dispense that are defined by business processes and/or used to refer to it when a direct URL reference to the resource itself is not appropriate. They are business identifiers assigned to this resource by the performer or other systems and remain constant as the resource is updated and propagates from server to server."
      },
      {
        "Data Fields": "partOf",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The procedure that trigger the dispense."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A code specifying the state of the set of dispense events."
      },
      {
        "Data Fields": "statusReasonCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Indicates the reason why a dispense was not performed."
      },
      {
        "Data Fields": "statusReasonReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Indicates the reason why a dispense was not performed."
      },
      {
        "Data Fields": "category",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Indicates the type of medication dispense (for example, where the medication is expected to be consumed or administered (i.e. inpatient or outpatient))."
      },
      {
        "Data Fields": "medicationCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Identifies the medication being administered. This is either a link to a resource representing the details of the medication or a simple attribute carrying a code that identifies the medication from a known list of medications."
      },
      {
        "Data Fields": "medicationReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Identifies the medication being administered. This is either a link to a resource representing the details of the medication or a simple attribute carrying a code that identifies the medication from a known list of medications."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A link to a resource representing the person or the group to whom the medication will be given."
      },
      {
        "Data Fields": "context",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The encounter or episode of care that establishes the context for this event."
      },
      {
        "Data Fields": "supportingInformation",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Additional information that supports the medication being dispensed."
      },
      {
        "Data Fields": "performer",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Indicates who or what performed the event."
      },
      {
        "Data Fields": "performer.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "performer.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "performer.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "performer.function",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Distinguishes the type of performer in the dispense.  For example, date enterer, packager, final checker."
      },
      {
        "Data Fields": "performer.actor",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The device, practitioner, etc. who performed the action.  It should be assumed that the actor is the dispenser of the medication."
      },
      {
        "Data Fields": "location",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The principal physical location where the dispense was performed."
      },
      {
        "Data Fields": "authorizingPrescription",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Indicates the medication order that is being dispensed against."
      },
      {
        "Data Fields": "type",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Indicates the type of dispensing event that is performed. For example, Trial Fill, Completion of Trial, Partial Fill, Emergency Fill, Samples, etc."
      },
      {
        "Data Fields": "quantity",
        "Type": "Quantity",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The amount of medication that has been dispensed. Includes unit of measure."
      },
      {
        "Data Fields": "daysSupply",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The amount of medication expressed as a timing amount."
      },
      {
        "Data Fields": "whenPrepared",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The time when the dispensed product was packaged and reviewed."
      },
      {
        "Data Fields": "whenHandedOver",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The time the dispensed product was provided to the patient or their representative."
      },
      {
        "Data Fields": "destination",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Identification of the facility/location where the medication was shipped to, as part of the dispense event."
      },
      {
        "Data Fields": "receiver",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Identifies the person who picked up the medication.  This will usually be a patient or their caregiver, but some cases exist where it can be a healthcare professional."
      },
      {
        "Data Fields": "note",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "Extra information about the dispense that could not be conveyed in the other attributes."
      },
      {
        "Data Fields": "dosageInstruction",
        "Type": "Dosage",
        
        
        "Required": "False",
        "Description": "Indicates how the medication is to be used by the patient."
      },
      {
        "Data Fields": "substitution",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Indicates whether or not substitution was made as part of the dispense.  In some cases, substitution will be expected but does not happen, in other cases substitution is not expected but does happen.  This block explains what substitution did or did not happen and why.  If nothing is specified, substitution was not done."
      },
      {
        "Data Fields": "substitution.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "substitution.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "substitution.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "substitution.wasSubstituted",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "True if the dispenser dispensed a different drug or product from what was prescribed."
      },
      {
        "Data Fields": "substitution.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code signifying whether a different drug was dispensed from what was prescribed."
      },
      {
        "Data Fields": "substitution.reason",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Indicates the reason for the substitution (or lack of substitution) from what was prescribed."
      },
      {
        "Data Fields": "substitution.responsibleParty",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The person or organization that has primary responsibility for the substitution."
      },
      {
        "Data Fields": "detectedIssue",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Indicates an actual or potential clinical issue with or between one or more active or proposed clinical actions for a patient; e.g. drug-drug interaction, duplicate therapy, dosage alert etc."
      },
      {
        "Data Fields": "eventHistory",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A summary of the events of interest that have occurred, such as when the dispense was verified."
      }
    ],
    "MedicationKnowledge": [
      {
        "Data Fields": "resourceType",
        "Type": "MedicationKnowledge",
        
        
        "Required": "True",
        "Description": "This is a MedicationKnowledge resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code that specifies this medication, or a textual description if no code is available. Usage note: This could be a standard medication code such as a code from RxNorm, SNOMED CT, IDMP etc. It could also be a national or local formulary code, optionally with translations to other code systems."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "A code to indicate if the medication is in active use.  The status refers to the validity about the information of the medication and not to its medicinal properties."
      },
      {
        "Data Fields": "manufacturer",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Describes the details of the manufacturer of the medication product.  This is not intended to represent the distributor of a medication product."
      },
      {
        "Data Fields": "doseForm",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Describes the form of the item.  Powder; tablets; capsule."
      },
      {
        "Data Fields": "amount",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "Specific amount of the drug in the packaged product.  For example, when specifying a product that has the same strength (For example, Insulin glargine 100 unit per mL solution for injection), this attribute provides additional clarification of the package amount (For example, 3 mL, 10mL, etc.)."
      },
      {
        "Data Fields": "synonym",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Additional names for a medication, for example, the name(s) given to a medication in different countries.  For example, acetaminophen and paracetamol or salbutamol and albuterol."
      },
      {
        "Data Fields": "relatedMedicationKnowledge",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Associated or related knowledge about a medication."
      },
      {
        "Data Fields": "relatedMedicationKnowledge.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "relatedMedicationKnowledge.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "relatedMedicationKnowledge.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "relatedMedicationKnowledge.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The category of the associated medication knowledge reference."
      },
      {
        "Data Fields": "relatedMedicationKnowledge.reference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Associated documentation about the associated medication knowledge."
      },
      {
        "Data Fields": "associatedMedication",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Associated or related medications.  For example, if the medication is a branded product (e.g. Crestor), this is the Therapeutic Moeity (e.g. Rosuvastatin) or if this is a generic medication (e.g. Rosuvastatin), this would link to a branded product (e.g. Crestor)."
      },
      {
        "Data Fields": "productType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Category of the medication or product (e.g. branded product, therapeutic moeity, generic product, innovator product, etc.)."
      },
      {
        "Data Fields": "monograph",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Associated documentation about the medication."
      },
      {
        "Data Fields": "monograph.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "monograph.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "monograph.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "monograph.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The category of documentation about the medication. (e.g. professional monograph, patient education monograph)."
      },
      {
        "Data Fields": "monograph.source",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Associated documentation about the medication."
      },
      {
        "Data Fields": "ingredient",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Identifies a particular constituent of interest in the product."
      },
      {
        "Data Fields": "ingredient.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "ingredient.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "ingredient.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "ingredient.itemCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The actual ingredient - either a substance (simple ingredient) or another medication."
      },
      {
        "Data Fields": "ingredient.itemReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The actual ingredient - either a substance (simple ingredient) or another medication."
      },
      {
        "Data Fields": "ingredient.isActive",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Indication of whether this ingredient affects the therapeutic action of the drug."
      },
      {
        "Data Fields": "ingredient.strength",
        "Type": "Ratio",
        
        
        "Required": "False",
        "Description": "Specifies how many (or how much) of the items there are in this Medication.  For example, 250 mg per tablet.  This is expressed as a ratio where the numerator is 250mg and the denominator is 1 tablet."
      },
      {
        "Data Fields": "preparationInstruction",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "The instructions for preparing the medication."
      },
      {
        "Data Fields": "intendedRoute",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The intended or approved route of administration."
      },
      {
        "Data Fields": "cost",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The price of the medication."
      },
      {
        "Data Fields": "cost.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "cost.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "cost.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "cost.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The category of the cost information.  For example, manufacturers' cost, patient cost, claim reimbursement cost, actual acquisition cost."
      },
      {
        "Data Fields": "cost.source",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The source or owner that assigns the price to the medication."
      },
      {
        "Data Fields": "monitoringProgram",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The program under which the medication is reviewed."
      },
      {
        "Data Fields": "monitoringProgram.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "monitoringProgram.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "monitoringProgram.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "monitoringProgram.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Type of program under which the medication is monitored."
      },
      {
        "Data Fields": "monitoringProgram.name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Name of the reviewing program."
      },
      {
        "Data Fields": "administrationGuidelines",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Guidelines for the administration of the medication."
      },
      {
        "Data Fields": "administrationGuidelines.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "administrationGuidelines.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "administrationGuidelines.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "administrationGuidelines.dosage",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Dosage for the medication for the specific guidelines."
      },
      {
        "Data Fields": "administrationGuidelines.dosage.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "administrationGuidelines.dosage.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "administrationGuidelines.dosage.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "administrationGuidelines.dosage.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The type of dosage (for example, prophylaxis, maintenance, therapeutic, etc.)."
      },
      {
        "Data Fields": "administrationGuidelines.indicationCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Indication for use that apply to the specific administration guidelines."
      },
      {
        "Data Fields": "administrationGuidelines.indicationReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Indication for use that apply to the specific administration guidelines."
      },
      {
        "Data Fields": "administrationGuidelines.patientCharacteristics",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Characteristics of the patient that are relevant to the administration guidelines (for example, height, weight, gender, etc.)."
      },
      {
        "Data Fields": "administrationGuidelines.patientCharacteristics.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "administrationGuidelines.patientCharacteristics.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "administrationGuidelines.patientCharacteristics.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "administrationGuidelines.patientCharacteristics.characteristicCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Specific characteristic that is relevant to the administration guideline (e.g. height, weight, gender)."
      },
      {
        "Data Fields": "administrationGuidelines.patientCharacteristics.characteristicQuantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "Specific characteristic that is relevant to the administration guideline (e.g. height, weight, gender)."
      },
      {
        "Data Fields": "administrationGuidelines.patientCharacteristics.value",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The specific characteristic (e.g. height, weight, gender, etc.)."
      },
      {
        "Data Fields": "medicineClassification",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Categorization of the medication within a formulary or classification system."
      },
      {
        "Data Fields": "medicineClassification.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "medicineClassification.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "medicineClassification.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "medicineClassification.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The type of category for the medication (for example, therapeutic classification, therapeutic sub-classification)."
      },
      {
        "Data Fields": "medicineClassification.classification",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Specific category assigned to the medication (e.g. anti-infective, anti-hypertensive, antibiotic, etc.)."
      },
      {
        "Data Fields": "packaging",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Information that only applies to packages (not products)."
      },
      {
        "Data Fields": "packaging.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "packaging.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "packaging.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "packaging.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code that defines the specific type of packaging that the medication can be found in (e.g. blister sleeve, tube, bottle)."
      },
      {
        "Data Fields": "packaging.quantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The number of product units the package would contain if fully loaded."
      },
      {
        "Data Fields": "drugCharacteristic",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Specifies descriptive properties of the medicine, such as color, shape, imprints, etc."
      },
      {
        "Data Fields": "drugCharacteristic.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "drugCharacteristic.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "drugCharacteristic.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "drugCharacteristic.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code specifying which characteristic of the medicine is being described (for example, colour, shape, imprint)."
      },
      {
        "Data Fields": "drugCharacteristic.valueCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Description of the characteristic."
      },
      {
        "Data Fields": "drugCharacteristic.valueString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Description of the characteristic."
      },
      {
        "Data Fields": "drugCharacteristic.valueQuantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "Description of the characteristic."
      },
      {
        "Data Fields": "drugCharacteristic.valueBase64Binary",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Description of the characteristic."
      },
      {
        "Data Fields": "contraindication",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Potential clinical issue with or between medication(s) (for example, drug-drug interaction, drug-disease contraindication, drug-allergy interaction, etc.)."
      },
      {
        "Data Fields": "regulatory",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Regulatory information about a medication."
      },
      {
        "Data Fields": "regulatory.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "regulatory.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "regulatory.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "regulatory.regulatoryAuthority",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The authority that is specifying the regulations."
      },
      {
        "Data Fields": "regulatory.substitution",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Specifies if changes are allowed when dispensing a medication from a regulatory perspective."
      },
      {
        "Data Fields": "regulatory.substitution.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "regulatory.substitution.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "regulatory.substitution.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "regulatory.substitution.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Specifies the type of substitution allowed."
      },
      {
        "Data Fields": "regulatory.substitution.allowed",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Specifies if regulation allows for changes in the medication when dispensing."
      },
      {
        "Data Fields": "regulatory.schedule",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Specifies the schedule of a medication in jurisdiction."
      },
      {
        "Data Fields": "regulatory.schedule.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "regulatory.schedule.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "regulatory.schedule.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "regulatory.maxDispense",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The maximum number of units of the medication that can be dispensed in a period."
      },
      {
        "Data Fields": "regulatory.maxDispense.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "regulatory.maxDispense.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "regulatory.maxDispense.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "regulatory.maxDispense.quantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The maximum number of units of the medication that can be dispensed."
      },
      {
        "Data Fields": "regulatory.maxDispense.period",
        "Type": "Duration",
        
        
        "Required": "False",
        "Description": "The period that applies to the maximum number of units."
      },
      {
        "Data Fields": "kinetics",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The time course of drug absorption, distribution, metabolism and excretion of a medication from the body."
      },
      {
        "Data Fields": "kinetics.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "kinetics.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "kinetics.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "kinetics.areaUnderCurve",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The drug concentration measured at certain discrete points in time."
      },
      {
        "Data Fields": "kinetics.lethalDose50",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The median lethal dose of a drug."
      },
      {
        "Data Fields": "kinetics.halfLifePeriod",
        "Type": "Duration",
        
        
        "Required": "False",
        "Description": "The time required for any specified property (e.g., the concentration of a substance in the body) to decrease by half."
      }
    ],
    "MedicationRequest": [
      {
        "Data Fields": "resourceType",
        "Type": "MedicationRequest",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "This is a MedicationRequest resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Identifiers associated with this medication request that are defined by business processes and/or used to refer to it when a direct URL reference to the resource itself is not appropriate. They are business identifiers assigned to this resource by the performer or other systems and remain constant as the resource is updated and propagates from server to server."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A code specifying the current state of the order.  Generally, this will be active or completed state."
      },
      {
        "Data Fields": "statusReason",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Captures the reason for the current state of the MedicationRequest."
      },
      {
        "Data Fields": "intent",
        "Type": "code",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Whether the request is a proposal, plan, or an original order."
      },
      {
        "Data Fields": "category",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Indicates the type of medication request (for example, where the medication is expected to be consumed or administered (i.e. inpatient or outpatient))."
      },
      {
        "Data Fields": "priority",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Indicates how quickly the Medication Request should be addressed with respect to other requests."
      },
      {
        "Data Fields": "doNotPerform",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "If true indicates that the provider is asking for the medication request not to occur."
      },
      {
        "Data Fields": "reportedBoolean",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Indicates if this record was captured as a secondary 'reported' record rather than as an original primary source-of-truth record.  It may also indicate the source of the report."
      },
      {
        "Data Fields": "reportedReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Indicates if this record was captured as a secondary 'reported' record rather than as an original primary source-of-truth record.  It may also indicate the source of the report."
      },
      {
        "Data Fields": "medicationCodeableConcept",
        "Type": "CodeableConcept",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Identifies the medication being requested. This is a link to a resource that represents the medication which may be the details of the medication or simply an attribute carrying a code that identifies the medication from a known list of medications."
      },
      {
        "Data Fields": "medicationReference",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Identifies the medication being requested. This is a link to a resource that represents the medication which may be the details of the medication or simply an attribute carrying a code that identifies the medication from a known list of medications."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "A link to a resource representing the person or set of individuals to whom the medication will be given."
      },
      {
        "Data Fields": "encounter",
        "Type": "Reference",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The Encounter during which this [x] was created or to which the creation of this record is tightly associated."
      },
      {
        "Data Fields": "supportingInformation",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Include additional information (for example, patient height and weight) that supports the ordering of the medication."
      },
      {
        "Data Fields": "authoredOn",
        "Type": "dateTime",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The date (and perhaps time) when the prescription was initially written or authored on."
      },
      {
        "Data Fields": "requester",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The individual, organization, or device that initiated the request and has responsibility for its activation."
      },
      {
        "Data Fields": "performer",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The specified desired performer of the medication treatment (e.g. the performer of the medication administration)."
      },
      {
        "Data Fields": "performerType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Indicates the type of performer of the administration of the medication."
      },
      {
        "Data Fields": "recorder",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The person who entered the order on behalf of another individual for example in the case of a verbal or a telephone order."
      },
      {
        "Data Fields": "reasonCode",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The reason or the indication for ordering or not ordering the medication."
      },
      {
        "Data Fields": "reasonReference",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Condition or observation that supports why the medication was ordered."
      },
      {
        "Data Fields": "instantiatesCanonical",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "The URL pointing to a protocol, guideline, orderset, or other definition that is adhered to in whole or in part by this MedicationRequest."
      },
      {
        "Data Fields": "instantiatesUri",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "The URL pointing to an externally maintained protocol, guideline, orderset or other definition that is adhered to in whole or in part by this MedicationRequest."
      },
      {
        "Data Fields": "basedOn",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A plan or request that is fulfilled in whole or in part by this medication request."
      },
      {
        "Data Fields": "groupIdentifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A shared identifier common to all requests that were authorized more or less simultaneously by a single author, representing the identifier of the requisition or prescription."
      },
      {
        "Data Fields": "courseOfTherapyType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The description of the overall patte3rn of the administration of the medication to the patient."
      },
      {
        "Data Fields": "insurance",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Insurance plans, coverage extensions, pre-authorizations and/or pre-determinations that may be required for delivering the requested service."
      },
      {
        "Data Fields": "note",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "Extra information about the prescription that could not be conveyed by the other attributes."
      },
      {
        "Data Fields": "dosageInstruction",
        "Type": "Dosage",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Indicates how the medication is to be used by the patient."
      },
      {
        "Data Fields": "dispenseRequest",
        "Type": "BackboneElement",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Indicates the specific details for the dispense or medication supply part of a medication request (also known as a Medication Prescription or Medication Order).  Note that this information is not always sent with the order.  There may be in some settings (e.g. hospitals) institutional or system support for completing the dispense details in the pharmacy department."
      },
      {
        "Data Fields": "dispenseRequest.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "dispenseRequest.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "dispenseRequest.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "dispenseRequest.initialFill",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Indicates the quantity or duration for the first dispense of the medication."
      },
      {
        "Data Fields": "dispenseRequest.initialFill.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "dispenseRequest.initialFill.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "dispenseRequest.initialFill.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "dispenseRequest.initialFill.quantity",
        "Type": "Quantity",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The amount or quantity to provide as part of the first dispense."
      },
      {
        "Data Fields": "dispenseRequest.initialFill.duration",
        "Type": "Duration",
        
        
        "Required": "False",
        "Description": "The length of time that the first dispense is expected to last."
      },
      {
        "Data Fields": "dispenseRequest.dispenseInterval",
        "Type": "Duration",
        
        
        "Required": "False",
        "Description": "The minimum period of time that must occur between dispenses of the medication."
      },
      {
        "Data Fields": "dispenseRequest.validityPeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "This indicates the validity period of a prescription (stale dating the Prescription)."
      },
      {
        "Data Fields": "dispenseRequest.numberOfRepeatsAllowed",
        "Type": "unsignedInt",
        
        
        "Required": "False",
        "Description": "An integer indicating the number of times, in addition to the original dispense, (aka refills or repeats) that the patient can receive the prescribed medication. Usage Notes: This integer does not include the original order dispense. This means that if an order indicates dispense 30 tablets plus \"3 repeats\", then the order can be dispensed a total of 4 times and the patient can receive a total of 120 tablets.  A prescriber may explicitly say that zero refills are permitted after the initial dispense."
      },
      {
        "Data Fields": "dispenseRequest.quantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The amount that is to be dispensed for one fill."
      },
      {
        "Data Fields": "dispenseRequest.expectedSupplyDuration",
        "Type": "Duration",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Identifies the period time over which the supplied product is expected to be used, or the length of time the dispense is expected to last."
      },
      {
        "Data Fields": "dispenseRequest.performer",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Indicates the intended dispensing Organization specified by the prescriber."
      },
      {
        "Data Fields": "substitution",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Indicates whether or not substitution can or should be part of the dispense. In some cases, substitution must happen, in other cases substitution must not happen. This block explains the prescriber's intent. If nothing is specified substitution may be done."
      },
      {
        "Data Fields": "substitution.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "substitution.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "substitution.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "substitution.allowedBoolean",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "True if the prescriber allows a different drug to be dispensed from what was prescribed."
      },
      {
        "Data Fields": "substitution.allowedCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "True if the prescriber allows a different drug to be dispensed from what was prescribed."
      },
      {
        "Data Fields": "substitution.reason",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Indicates the reason for the substitution, or why substitution must or must not be performed."
      },
      {
        "Data Fields": "priorPrescription",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A link to a resource representing an earlier order related order or prescription."
      },
      {
        "Data Fields": "detectedIssue",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Indicates an actual or potential clinical issue with or between one or more active or proposed clinical actions for a patient; e.g. Drug-drug interaction, duplicate therapy, dosage alert etc."
      },
      {
        "Data Fields": "eventHistory",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Links to Provenance records for past versions of this resource or fulfilling request or event resources that identify key state transitions or updates that are likely to be relevant to a user looking at the current version of the resource."
      }
    ],
    "MedicationStatement": [
      {
        "Data Fields": "resourceType",
        "Type": "MedicationStatement",
        
        
        "Required": "True",
        "Description": "This is a MedicationStatement resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Identifiers associated with this Medication Statement that are defined by business processes and/or used to refer to it when a direct URL reference to the resource itself is not appropriate. They are business identifiers assigned to this resource by the performer or other systems and remain constant as the resource is updated and propagates from server to server."
      },
      {
        "Data Fields": "basedOn",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A plan, proposal or order that is fulfilled in whole or in part by this event."
      },
      {
        "Data Fields": "partOf",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A larger event of which this particular event is a component or step."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "A code representing the patient or other source's judgment about the state of the medication used that this statement is about.  Generally, this will be active or completed."
      },
      {
        "Data Fields": "statusReason",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Captures the reason for the current state of the MedicationStatement."
      },
      {
        "Data Fields": "category",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Indicates where the medication is expected to be consumed or administered."
      },
      {
        "Data Fields": "medicationCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Identifies the medication being administered. This is either a link to a resource representing the details of the medication or a simple attribute carrying a code that identifies the medication from a known list of medications."
      },
      {
        "Data Fields": "medicationReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Identifies the medication being administered. This is either a link to a resource representing the details of the medication or a simple attribute carrying a code that identifies the medication from a known list of medications."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "The person, animal or group who is/was taking the medication."
      },
      {
        "Data Fields": "context",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The encounter or episode of care that establishes the context for this MedicationStatement."
      },
      {
        "Data Fields": "effectiveDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The interval of time during which it is being asserted that the patient is/was/will be taking the medication (or was not taking, when the MedicationStatement.taken element is No)."
      },
      {
        "Data Fields": "effectivePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The interval of time during which it is being asserted that the patient is/was/will be taking the medication (or was not taking, when the MedicationStatement.taken element is No)."
      },
      {
        "Data Fields": "dateAsserted",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date when the medication statement was asserted by the information source."
      },
      {
        "Data Fields": "informationSource",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The person or organization that provided the information about the taking of this medication. Note: Use derivedFrom when a MedicationStatement is derived from other resources, e.g. Claim or MedicationRequest."
      },
      {
        "Data Fields": "derivedFrom",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Allows linking the MedicationStatement to the underlying MedicationRequest, or to other information that supports or is used to derive the MedicationStatement."
      },
      {
        "Data Fields": "reasonCode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A reason for why the medication is being/was taken."
      },
      {
        "Data Fields": "reasonReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Condition or observation that supports why the medication is being/was taken."
      },
      {
        "Data Fields": "note",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "Provides extra information about the medication statement that is not conveyed by the other attributes."
      },
      {
        "Data Fields": "dosage",
        "Type": "Dosage",
        
        
        "Required": "False",
        "Description": "Indicates how the medication is/was or should be taken by the patient."
      }
    ],
    "MedicinalProduct": [
      {
        "Data Fields": "resourceType",
        "Type": "MedicinalProduct",
        
        
        "Required": "True",
        "Description": "This is a MedicinalProduct resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Business identifier for this product. Could be an MPID."
      },
      {
        "Data Fields": "type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Regulatory type, e.g. Investigational or Authorized."
      },
      {
        "Data Fields": "domain",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "If this medicine applies to human or veterinary uses."
      },
      {
        "Data Fields": "combinedPharmaceuticalDoseForm",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The dose form for a single part product, or combined form of a multiple part product."
      },
      {
        "Data Fields": "legalStatusOfSupply",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The legal status of supply of the medicinal product as classified by the regulator."
      },
      {
        "Data Fields": "additionalMonitoringIndicator",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Whether the Medicinal Product is subject to additional monitoring for regulatory reasons."
      },
      {
        "Data Fields": "specialMeasures",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Whether the Medicinal Product is subject to special measures for regulatory reasons."
      },
      {
        "Data Fields": "paediatricUseIndicator",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "If authorised for use in children."
      },
      {
        "Data Fields": "productClassification",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Allows the product to be classified by various systems."
      },
      {
        "Data Fields": "marketingStatus",
        "Type": "MarketingStatus",
        
        
        "Required": "False",
        "Description": "Marketing status of the medicinal product, in contrast to marketing authorizaton."
      },
      {
        "Data Fields": "pharmaceuticalProduct",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Pharmaceutical aspects of product."
      },
      {
        "Data Fields": "packagedMedicinalProduct",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Package representation for the product."
      },
      {
        "Data Fields": "attachedDocument",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Supporting documentation, typically for regulatory submission."
      },
      {
        "Data Fields": "masterFile",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A master file for to the medicinal product (e.g. Pharmacovigilance System Master File)."
      },
      {
        "Data Fields": "contact",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A product specific contact, person (in a role), or an organization."
      },
      {
        "Data Fields": "clinicalTrial",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Clinical trials or studies that this product is involved in."
      },
      {
        "Data Fields": "name",
        "Type": "BackboneElement",
        
        
        "Required": "True",
        "Description": "The product's name, including full name and possibly coded parts."
      },
      {
        "Data Fields": "name.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "name.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "name.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "name.productName",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The full product name."
      },
      {
        "Data Fields": "name.namePart",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Coding words or phrases of the name."
      },
      {
        "Data Fields": "name.namePart.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "name.namePart.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "name.namePart.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "name.namePart.part",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A fragment of a product name."
      },
      {
        "Data Fields": "name.namePart.type",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "Idenifying type for this part of the name (e.g. strength part)."
      },
      {
        "Data Fields": "name.countryLanguage",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Country where the name applies."
      },
      {
        "Data Fields": "name.countryLanguage.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "name.countryLanguage.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "name.countryLanguage.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "name.countryLanguage.country",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Country code for where this name applies."
      },
      {
        "Data Fields": "name.countryLanguage.jurisdiction",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Jurisdiction code for where this name applies."
      },
      {
        "Data Fields": "name.countryLanguage.language",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Language code for this name."
      },
      {
        "Data Fields": "crossReference",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Reference to another product, e.g. for linking authorised to investigational product."
      },
      {
        "Data Fields": "manufacturingBusinessOperation",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "An operation applied to the product, for manufacturing or adminsitrative purpose."
      },
      {
        "Data Fields": "manufacturingBusinessOperation.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "manufacturingBusinessOperation.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "manufacturingBusinessOperation.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "manufacturingBusinessOperation.operationType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The type of manufacturing operation."
      },
      {
        "Data Fields": "manufacturingBusinessOperation.authorisationReferenceNumber",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Regulatory authorization reference number."
      },
      {
        "Data Fields": "manufacturingBusinessOperation.effectiveDate",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "Regulatory authorization date."
      },
      {
        "Data Fields": "manufacturingBusinessOperation.confidentialityIndicator",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "To indicate if this proces is commercially confidential."
      },
      {
        "Data Fields": "manufacturingBusinessOperation.manufacturer",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The manufacturer or establishment associated with the process."
      },
      {
        "Data Fields": "manufacturingBusinessOperation.regulator",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A regulator which oversees the operation."
      },
      {
        "Data Fields": "specialDesignation",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Indicates if the medicinal product has an orphan designation for the treatment of a rare disease."
      },
      {
        "Data Fields": "specialDesignation.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "specialDesignation.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "specialDesignation.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "specialDesignation.identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Identifier for the designation, or procedure number."
      },
      {
        "Data Fields": "specialDesignation.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The type of special designation, e.g. orphan drug, minor use."
      },
      {
        "Data Fields": "specialDesignation.intendedUse",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The intended use of the product, e.g. prevention, treatment."
      },
      {
        "Data Fields": "specialDesignation.indicationCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Condition for which the medicinal use applies."
      },
      {
        "Data Fields": "specialDesignation.indicationReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Condition for which the medicinal use applies."
      },
      {
        "Data Fields": "specialDesignation.status",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "For example granted, pending, expired or withdrawn."
      },
      {
        "Data Fields": "specialDesignation.date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "Date when the designation was granted."
      },
      {
        "Data Fields": "specialDesignation.species",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Animal species for which this applies."
      }
    ],
    "MedicinalProductAuthorization": [
      {
        "Data Fields": "resourceType",
        "Type": "MedicinalProductAuthorization",
        
        
        "Required": "True",
        "Description": "This is a MedicinalProductAuthorization resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Business identifier for the marketing authorization, as assigned by a regulator."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The medicinal product that is being authorized."
      },
      {
        "Data Fields": "country",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The country in which the marketing authorization has been granted."
      },
      {
        "Data Fields": "jurisdiction",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Jurisdiction within a country."
      },
      {
        "Data Fields": "status",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The status of the marketing authorization."
      },
      {
        "Data Fields": "statusDate",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date at which the given status has become applicable."
      },
      {
        "Data Fields": "restoreDate",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date when a suspended the marketing or the marketing authorization of the product is anticipated to be restored."
      },
      {
        "Data Fields": "validityPeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The beginning of the time period in which the marketing authorization is in the specific status shall be specified A complete date consisting of day, month and year shall be specified using the ISO 8601 date format."
      },
      {
        "Data Fields": "dataExclusivityPeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "A period of time after authorization before generic product applicatiosn can be submitted."
      },
      {
        "Data Fields": "dateOfFirstAuthorization",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date when the first authorization was granted by a Medicines Regulatory Agency."
      },
      {
        "Data Fields": "internationalBirthDate",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "Date of first marketing authorization for a company's new medicinal product in any country in the World."
      },
      {
        "Data Fields": "legalBasis",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The legal framework against which this authorization is granted."
      },
      {
        "Data Fields": "jurisdictionalAuthorization",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Authorization in areas within a country."
      },
      {
        "Data Fields": "jurisdictionalAuthorization.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "jurisdictionalAuthorization.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "jurisdictionalAuthorization.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "jurisdictionalAuthorization.identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "The assigned number for the marketing authorization."
      },
      {
        "Data Fields": "jurisdictionalAuthorization.country",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Country of authorization."
      },
      {
        "Data Fields": "jurisdictionalAuthorization.jurisdiction",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Jurisdiction within a country."
      },
      {
        "Data Fields": "jurisdictionalAuthorization.legalStatusOfSupply",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The legal status of supply in a jurisdiction or region."
      },
      {
        "Data Fields": "jurisdictionalAuthorization.validityPeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The start and expected end date of the authorization."
      },
      {
        "Data Fields": "holder",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Marketing Authorization Holder."
      },
      {
        "Data Fields": "regulator",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Medicines Regulatory Agency."
      },
      {
        "Data Fields": "procedure",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The regulatory procedure for granting or amending a marketing authorization."
      },
      {
        "Data Fields": "procedure.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "procedure.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "procedure.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "procedure.identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Identifier for this procedure."
      },
      {
        "Data Fields": "procedure.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Type of procedure."
      },
      {
        "Data Fields": "procedure.datePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "Date of procedure."
      },
      {
        "Data Fields": "procedure.dateDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Date of procedure."
      },
      {
        "Data Fields": "procedure.application",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Applcations submitted to obtain a marketing authorization."
      },
      {
        "Data Fields": "procedure.application.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "procedure.application.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "procedure.application.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "procedure.application.identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Identifier for this procedure."
      },
      {
        "Data Fields": "procedure.application.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Type of procedure."
      },
      {
        "Data Fields": "procedure.application.datePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "Date of procedure."
      },
      {
        "Data Fields": "procedure.application.dateDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Date of procedure."
      }
    ],
    "MedicinalProductContraindicati": [
      {
        "Data Fields": "resourceType",
        "Type": "MedicinalProductContraindication",
        
        
        "Required": "True",
        "Description": "This is a MedicinalProductContraindication resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The medication for which this is an indication."
      },
      {
        "Data Fields": "disease",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The disease, symptom or procedure for the contraindication."
      },
      {
        "Data Fields": "diseaseStatus",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The status of the disease or symptom for the contraindication."
      },
      {
        "Data Fields": "comorbidity",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A comorbidity (concurrent condition) or coinfection."
      },
      {
        "Data Fields": "therapeuticIndication",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Information about the use of the medicinal product in relation to other therapies as part of the indication."
      },
      {
        "Data Fields": "otherTherapy",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Information about the use of the medicinal product in relation to other therapies described as part of the indication."
      },
      {
        "Data Fields": "otherTherapy.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "otherTherapy.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "otherTherapy.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "otherTherapy.therapyRelationshipType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The type of relationship between the medicinal product indication or contraindication and another therapy."
      },
      {
        "Data Fields": "otherTherapy.medicationCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Reference to a specific medication (active substance, medicinal product or class of products) as part of an indication or contraindication."
      },
      {
        "Data Fields": "otherTherapy.medicationReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Reference to a specific medication (active substance, medicinal product or class of products) as part of an indication or contraindication."
      },
      {
        "Data Fields": "population",
        "Type": "Population",
        
        
        "Required": "False",
        "Description": "The population group to which this applies."
      }
    ],
    "MedicinalProductIndication": [
      {
        "Data Fields": "resourceType",
        "Type": "MedicinalProductIndication",
        
        
        "Required": "True",
        "Description": "This is a MedicinalProductIndication resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The medication for which this is an indication."
      },
      {
        "Data Fields": "diseaseSymptomProcedure",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The disease, symptom or procedure that is the indication for treatment."
      },
      {
        "Data Fields": "diseaseStatus",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The status of the disease or symptom for which the indication applies."
      },
      {
        "Data Fields": "comorbidity",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Comorbidity (concurrent condition) or co-infection as part of the indication."
      },
      {
        "Data Fields": "intendedEffect",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The intended effect, aim or strategy to be achieved by the indication."
      },
      {
        "Data Fields": "duration",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "Timing or duration information as part of the indication."
      },
      {
        "Data Fields": "otherTherapy",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Information about the use of the medicinal product in relation to other therapies described as part of the indication."
      },
      {
        "Data Fields": "otherTherapy.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "otherTherapy.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "otherTherapy.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "otherTherapy.therapyRelationshipType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The type of relationship between the medicinal product indication or contraindication and another therapy."
      },
      {
        "Data Fields": "otherTherapy.medicationCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Reference to a specific medication (active substance, medicinal product or class of products) as part of an indication or contraindication."
      },
      {
        "Data Fields": "otherTherapy.medicationReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Reference to a specific medication (active substance, medicinal product or class of products) as part of an indication or contraindication."
      },
      {
        "Data Fields": "undesirableEffect",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Describe the undesirable effects of the medicinal product."
      },
      {
        "Data Fields": "population",
        "Type": "Population",
        
        
        "Required": "False",
        "Description": "The population group to which this applies."
      }
    ],
    "MedicinalProductIngredient": [
      {
        "Data Fields": "resourceType",
        "Type": "MedicinalProductIngredient",
        
        
        "Required": "True",
        "Description": "This is a MedicinalProductIngredient resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "The identifier(s) of this Ingredient that are assigned by business processes and/or used to refer to it when a direct URL reference to the resource itself is not appropriate."
      },
      {
        "Data Fields": "role",
        "Type": "CodeableConcept",
        
        
        "Required": "True",
        "Description": "Ingredient role e.g. Active ingredient, excipient."
      },
      {
        "Data Fields": "allergenicIndicator",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "If the ingredient is a known or suspected allergen."
      },
      {
        "Data Fields": "manufacturer",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Manufacturer of this Ingredient."
      },
      {
        "Data Fields": "specifiedSubstance",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A specified substance that comprises this ingredient."
      },
      {
        "Data Fields": "specifiedSubstance.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "specifiedSubstance.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "specifiedSubstance.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "specifiedSubstance.code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The specified substance."
      },
      {
        "Data Fields": "specifiedSubstance.group",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The group of specified substance, e.g. group 1 to 4."
      },
      {
        "Data Fields": "specifiedSubstance.confidentiality",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Confidentiality level of the specified substance as the ingredient."
      },
      {
        "Data Fields": "specifiedSubstance.strength",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Quantity of the substance or specified substance present in the manufactured item or pharmaceutical product."
      },
      {
        "Data Fields": "specifiedSubstance.strength.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "specifiedSubstance.strength.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "specifiedSubstance.strength.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "specifiedSubstance.strength.presentation",
        "Type": "Ratio",
        
        
        "Required": "False",
        "Description": "The quantity of substance in the unit of presentation, or in the volume (or mass) of the single pharmaceutical product or manufactured item."
      },
      {
        "Data Fields": "specifiedSubstance.strength.presentationLowLimit",
        "Type": "Ratio",
        
        
        "Required": "False",
        "Description": "A lower limit for the quantity of substance in the unit of presentation. For use when there is a range of strengths, this is the lower limit, with the presentation attribute becoming the upper limit."
      },
      {
        "Data Fields": "specifiedSubstance.strength.concentration",
        "Type": "Ratio",
        
        
        "Required": "False",
        "Description": "The strength per unitary volume (or mass)."
      },
      {
        "Data Fields": "specifiedSubstance.strength.concentrationLowLimit",
        "Type": "Ratio",
        
        
        "Required": "False",
        "Description": "A lower limit for the strength per unitary volume (or mass), for when there is a range. The concentration attribute then becomes the upper limit."
      },
      {
        "Data Fields": "specifiedSubstance.strength.measurementPoint",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "For when strength is measured at a particular point or distance."
      },
      {
        "Data Fields": "specifiedSubstance.strength.country",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The country or countries for which the strength range applies."
      },
      {
        "Data Fields": "specifiedSubstance.strength.referenceStrength",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Strength expressed in terms of a reference substance."
      },
      {
        "Data Fields": "specifiedSubstance.strength.referenceStrength.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "specifiedSubstance.strength.referenceStrength.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "specifiedSubstance.strength.referenceStrength.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "specifiedSubstance.strength.referenceStrength.substance",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Relevant reference substance."
      },
      {
        "Data Fields": "specifiedSubstance.strength.referenceStrength.strengthLowLimit",
        "Type": "Ratio",
        
        
        "Required": "False",
        "Description": "Strength expressed in terms of a reference substance."
      },
      {
        "Data Fields": "specifiedSubstance.strength.referenceStrength.measurementPoint",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "For when strength is measured at a particular point or distance."
      },
      {
        "Data Fields": "specifiedSubstance.strength.referenceStrength.country",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The country or countries for which the strength range applies."
      },
      {
        "Data Fields": "substance",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The ingredient substance."
      },
      {
        "Data Fields": "substance.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "substance.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "substance.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "substance.code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The ingredient substance."
      },
      {
        "Data Fields": "substance.strength",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Quantity of the substance or specified substance present in the manufactured item or pharmaceutical product."
      },
      {
        "Data Fields": "substance.strength.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "substance.strength.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "substance.strength.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "substance.strength.presentation",
        "Type": "Ratio",
        
        
        "Required": "False",
        "Description": "The quantity of substance in the unit of presentation, or in the volume (or mass) of the single pharmaceutical product or manufactured item."
      },
      {
        "Data Fields": "substance.strength.presentationLowLimit",
        "Type": "Ratio",
        
        
        "Required": "False",
        "Description": "A lower limit for the quantity of substance in the unit of presentation. For use when there is a range of strengths, this is the lower limit, with the presentation attribute becoming the upper limit."
      },
      {
        "Data Fields": "substance.strength.concentration",
        "Type": "Ratio",
        
        
        "Required": "False",
        "Description": "The strength per unitary volume (or mass)."
      },
      {
        "Data Fields": "substance.strength.concentrationLowLimit",
        "Type": "Ratio",
        
        
        "Required": "False",
        "Description": "A lower limit for the strength per unitary volume (or mass), for when there is a range. The concentration attribute then becomes the upper limit."
      },
      {
        "Data Fields": "substance.strength.measurementPoint",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "For when strength is measured at a particular point or distance."
      },
      {
        "Data Fields": "substance.strength.country",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The country or countries for which the strength range applies."
      },
      {
        "Data Fields": "substance.strength.referenceStrength",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Strength expressed in terms of a reference substance."
      },
      {
        "Data Fields": "substance.strength.referenceStrength.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "substance.strength.referenceStrength.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "substance.strength.referenceStrength.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "substance.strength.referenceStrength.strengthLowLimit",
        "Type": "Ratio",
        
        
        "Required": "False",
        "Description": "Strength expressed in terms of a reference substance."
      },
      {
        "Data Fields": "substance.strength.referenceStrength.measurementPoint",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "For when strength is measured at a particular point or distance."
      },
      {
        "Data Fields": "substance.strength.referenceStrength.country",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The country or countries for which the strength range applies."
      }
    ],
    "MedicinalProductInteraction": [
      {
        "Data Fields": "resourceType",
        "Type": "MedicinalProductInteraction",
        
        
        "Required": "True",
        "Description": "This is a MedicinalProductInteraction resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The medication for which this is a described interaction."
      },
      {
        "Data Fields": "description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The interaction described."
      },
      {
        "Data Fields": "interactant",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The specific medication, food or laboratory test that interacts."
      },
      {
        "Data Fields": "interactant.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "interactant.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "interactant.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "interactant.itemReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The specific medication, food or laboratory test that interacts."
      },
      {
        "Data Fields": "interactant.itemCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The specific medication, food or laboratory test that interacts."
      },
      {
        "Data Fields": "type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The type of the interaction e.g. drug-drug interaction, drug-food interaction, drug-lab test interaction."
      },
      {
        "Data Fields": "effect",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The effect of the interaction, for example \"reduced gastric absorption of primary medication\"."
      },
      {
        "Data Fields": "incidence",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The incidence of the interaction, e.g. theoretical, observed."
      },
      {
        "Data Fields": "management",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Actions for managing the interaction."
      }
    ],
    "MedicinalProductManufactured": [
      {
        "Data Fields": "resourceType",
        "Type": "MedicinalProductManufactured",
        
        
        "Required": "True",
        "Description": "This is a MedicinalProductManufactured resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "manufacturedDoseForm",
        "Type": "CodeableConcept",
        
        
        "Required": "True",
        "Description": "Dose form as manufactured and before any transformation into the pharmaceutical product."
      },
      {
        "Data Fields": "unitOfPresentation",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The “real world” units in which the quantity of the manufactured item is described."
      },
      {
        "Data Fields": "quantity",
        "Type": "Quantity",
        
        
        "Required": "True",
        "Description": "The quantity or \"count number\" of the manufactured item."
      },
      {
        "Data Fields": "manufacturer",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Manufacturer of the item (Note that this should be named \"manufacturer\" but it currently causes technical issues)."
      },
      {
        "Data Fields": "ingredient",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Ingredient."
      },
      {
        "Data Fields": "physicalCharacteristics",
        "Type": "ProdCharacteristic",
        
        
        "Required": "False",
        "Description": "Dimensions, color etc."
      },
      {
        "Data Fields": "otherCharacteristics",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Other codeable characteristics."
      }
    ],
    "MedicinalProductPackaged": [
      {
        "Data Fields": "resourceType",
        "Type": "MedicinalProductPackaged",
        
        
        "Required": "True",
        "Description": "This is a MedicinalProductPackaged resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Unique identifier."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The product with this is a pack for."
      },
      {
        "Data Fields": "description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Textual description."
      },
      {
        "Data Fields": "legalStatusOfSupply",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The legal status of supply of the medicinal product as classified by the regulator."
      },
      {
        "Data Fields": "marketingStatus",
        "Type": "MarketingStatus",
        
        
        "Required": "False",
        "Description": "Marketing information."
      },
      {
        "Data Fields": "marketingAuthorization",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Manufacturer of this Package Item."
      },
      {
        "Data Fields": "manufacturer",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Manufacturer of this Package Item."
      },
      {
        "Data Fields": "batchIdentifier",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Batch numbering."
      },
      {
        "Data Fields": "batchIdentifier.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "batchIdentifier.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "batchIdentifier.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "batchIdentifier.outerPackaging",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A number appearing on the outer packaging of a specific batch."
      },
      {
        "Data Fields": "batchIdentifier.immediatePackaging",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A number appearing on the immediate packaging (and not the outer packaging)."
      },
      {
        "Data Fields": "packageItem",
        "Type": "BackboneElement",
        
        
        "Required": "True",
        "Description": "A packaging item, as a contained for medicine, possibly with other packaging items within."
      },
      {
        "Data Fields": "packageItem.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "packageItem.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "packageItem.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "packageItem.identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Including possibly Data Carrier Identifier."
      },
      {
        "Data Fields": "packageItem.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The physical type of the container of the medicine."
      },
      {
        "Data Fields": "packageItem.quantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The quantity of this package in the medicinal product, at the current level of packaging. The outermost is always 1."
      },
      {
        "Data Fields": "packageItem.material",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Material type of the package item."
      },
      {
        "Data Fields": "packageItem.alternateMaterial",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A possible alternate material for the packaging."
      },
      {
        "Data Fields": "packageItem.device",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A device accompanying a medicinal product."
      },
      {
        "Data Fields": "packageItem.manufacturedItem",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The manufactured item as contained in the packaged medicinal product."
      },
      {
        "Data Fields": "packageItem.physicalCharacteristics",
        "Type": "ProdCharacteristic",
        
        
        "Required": "False",
        "Description": "Dimensions, color etc."
      },
      {
        "Data Fields": "packageItem.otherCharacteristics",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Other codeable characteristics."
      },
      {
        "Data Fields": "packageItem.shelfLifeStorage",
        "Type": "ProductShelfLife",
        
        
        "Required": "False",
        "Description": "Shelf Life and storage information."
      },
      {
        "Data Fields": "packageItem.manufacturer",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Manufacturer of this Package Item."
      }
    ],
    "MedicinalProductPharmaceutical": [
      {
        "Data Fields": "resourceType",
        "Type": "MedicinalProductPharmaceutical",
        
        
        "Required": "True",
        "Description": "This is a MedicinalProductPharmaceutical resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "An identifier for the pharmaceutical medicinal product."
      },
      {
        "Data Fields": "administrableDoseForm",
        "Type": "CodeableConcept",
        
        
        "Required": "True",
        "Description": "The administrable dose form, after necessary reconstitution."
      },
      {
        "Data Fields": "unitOfPresentation",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "ingredient",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Ingredient."
      },
      {
        "Data Fields": "device",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Accompanying device."
      },
      {
        "Data Fields": "characteristics",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Characteristics e.g. a products onset of action."
      },
      {
        "Data Fields": "characteristics.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "characteristics.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "characteristics.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "characteristics.code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A coded characteristic."
      },
      {
        "Data Fields": "characteristics.status",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The status of characteristic e.g. assigned or pending."
      },
      {
        "Data Fields": "routeOfAdministration",
        "Type": "BackboneElement",
        
        
        "Required": "True",
        "Description": "The path by which the pharmaceutical product is taken into or makes contact with the body."
      },
      {
        "Data Fields": "routeOfAdministration.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "routeOfAdministration.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "routeOfAdministration.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "routeOfAdministration.code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Coded expression for the route."
      },
      {
        "Data Fields": "routeOfAdministration.firstDose",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The first dose (dose quantity) administered in humans can be specified, for a product under investigation, using a numerical value and its unit of measurement."
      },
      {
        "Data Fields": "routeOfAdministration.maxSingleDose",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The maximum single dose that can be administered as per the protocol of a clinical trial can be specified using a numerical value and its unit of measurement."
      },
      {
        "Data Fields": "routeOfAdministration.maxDosePerDay",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The maximum dose per day (maximum dose quantity to be administered in any one 24-h period) that can be administered as per the protocol referenced in the clinical trial authorisation."
      },
      {
        "Data Fields": "routeOfAdministration.maxDosePerTreatmentPeriod",
        "Type": "Ratio",
        
        
        "Required": "False",
        "Description": "The maximum dose per treatment period that can be administered as per the protocol referenced in the clinical trial authorisation."
      },
      {
        "Data Fields": "routeOfAdministration.maxTreatmentPeriod",
        "Type": "Duration",
        
        
        "Required": "False",
        "Description": "The maximum treatment period during which an Investigational Medicinal Product can be administered as per the protocol referenced in the clinical trial authorisation."
      },
      {
        "Data Fields": "routeOfAdministration.targetSpecies",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A species for which this route applies."
      },
      {
        "Data Fields": "routeOfAdministration.targetSpecies.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "routeOfAdministration.targetSpecies.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "routeOfAdministration.targetSpecies.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "routeOfAdministration.targetSpecies.code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Coded expression for the species."
      },
      {
        "Data Fields": "routeOfAdministration.targetSpecies.withdrawalPeriod",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A species specific time during which consumption of animal product is not appropriate."
      },
      {
        "Data Fields": "routeOfAdministration.targetSpecies.withdrawalPeriod.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "routeOfAdministration.targetSpecies.withdrawalPeriod.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "routeOfAdministration.targetSpecies.withdrawalPeriod.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "routeOfAdministration.targetSpecies.withdrawalPeriod.tissue",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Coded expression for the type of tissue for which the withdrawal period applues, e.g. meat, milk."
      },
      {
        "Data Fields": "routeOfAdministration.targetSpecies.withdrawalPeriod.value",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "A value for the time."
      },
      {
        "Data Fields": "routeOfAdministration.targetSpecies.withdrawalPeriod.supportingInformation",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Extra information about the withdrawal period."
      }
    ],
    "MedicinalProductUndesirableEff": [
      {
        "Data Fields": "resourceType",
        "Type": "MedicinalProductUndesirableEffect",
        
        
        "Required": "True",
        "Description": "This is a MedicinalProductUndesirableEffect resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The medication for which this is an indication."
      },
      {
        "Data Fields": "symptomConditionEffect",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The symptom, condition or undesirable effect."
      },
      {
        "Data Fields": "classification",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Classification of the effect."
      },
      {
        "Data Fields": "frequencyOfOccurrence",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The frequency of occurrence of the effect."
      },
      {
        "Data Fields": "population",
        "Type": "Population",
        
        
        "Required": "False",
        "Description": "The population group to which this applies."
      }
    ],
    "MessageDefinition": [
      {
        "Data Fields": "resourceType",
        "Type": "MessageDefinition",
        
        
        "Required": "True",
        "Description": "This is a MessageDefinition resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "url",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "The business identifier that is used to reference the MessageDefinition and *is* expected to be consistent from server to server."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A formal identifier that is used to identify this message definition when it is represented in other formats, or referenced in a specification, model, design or an instance."
      },
      {
        "Data Fields": "version",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The identifier that is used to identify this version of the message definition when it is referenced in a specification, model, design or instance. This is an arbitrary value managed by the message definition author and is not expected to be globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if a managed version is not available. There is also no expectation that versions can be placed in a lexicographical sequence."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A natural language name identifying the message definition. This name should be usable as an identifier for the module by machine processing applications such as code generation."
      },
      {
        "Data Fields": "title",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A short, descriptive, user-friendly title for the message definition."
      },
      {
        "Data Fields": "replaces",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "A MessageDefinition that is superseded by this definition."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The status of this message definition. Enables tracking the life-cycle of the content."
      },
      {
        "Data Fields": "experimental",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "A Boolean value to indicate that this message definition is authored for testing purposes (or education/evaluation/marketing) and is not intended to be used for genuine usage."
      },
      {
        "Data Fields": "date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date  (and optionally time) when the message definition was published. The date must change when the business version changes and it must change if the status code changes. In addition, it should change when the substantive content of the message definition changes."
      },
      {
        "Data Fields": "publisher",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the organization or individual that published the message definition."
      },
      {
        "Data Fields": "contact",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "Contact details to assist a user in finding and communicating with the publisher."
      },
      {
        "Data Fields": "description",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A free text natural language description of the message definition from a consumer's perspective."
      },
      {
        "Data Fields": "useContext",
        "Type": "UsageContext",
        
        
        "Required": "False",
        "Description": "The content was developed with a focus and intent of supporting the contexts that are listed. These contexts may be general categories (gender, age, ...) or may be references to specific programs (insurance plans, studies, ...) and may be used to assist with indexing and searching for appropriate message definition instances."
      },
      {
        "Data Fields": "jurisdiction",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A legal or geographic region in which the message definition is intended to be used."
      },
      {
        "Data Fields": "purpose",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Explanation of why this message definition is needed and why it has been designed as it has."
      },
      {
        "Data Fields": "copyright",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A copyright statement relating to the message definition and/or its contents. Copyright statements are generally legal restrictions on the use and publishing of the message definition."
      },
      {
        "Data Fields": "base",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "The MessageDefinition that is the basis for the contents of this resource."
      },
      {
        "Data Fields": "parent",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "Identifies a protocol or workflow that this MessageDefinition represents a step in."
      },
      {
        "Data Fields": "eventCoding",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "Event code or link to the EventDefinition."
      },
      {
        "Data Fields": "eventUri",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Event code or link to the EventDefinition."
      },
      {
        "Data Fields": "category",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The impact of the content of the message."
      },
      {
        "Data Fields": "focus",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Identifies the resource (or resources) that are being addressed by the event.  For example, the Encounter for an admit message or two Account records for a merge."
      },
      {
        "Data Fields": "focus.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "focus.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "focus.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "focus.code",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The kind of resource that must be the focus for this message."
      },
      {
        "Data Fields": "focus.profile",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "A profile that reflects constraints for the focal resource (and potentially for related resources)."
      },
      {
        "Data Fields": "focus.min",
        "Type": "unsignedInt",
        
        
        "Required": "False",
        "Description": "Identifies the minimum number of resources of this type that must be pointed to by a message in order for it to be valid against this MessageDefinition."
      },
      {
        "Data Fields": "focus.max",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Identifies the maximum number of resources of this type that must be pointed to by a message in order for it to be valid against this MessageDefinition."
      },
      {
        "Data Fields": "responseRequired",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Declare at a message definition level whether a response is required or only upon error or success, or never."
      },
      {
        "Data Fields": "allowedResponse",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Indicates what types of messages may be sent as an application-level response to this message."
      },
      {
        "Data Fields": "allowedResponse.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "allowedResponse.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "allowedResponse.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "allowedResponse.message",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "A reference to the message definition that must be adhered to by this supported response."
      },
      {
        "Data Fields": "allowedResponse.situation",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Provides a description of the circumstances in which this response should be used (as opposed to one of the alternative responses)."
      },
      {
        "Data Fields": "graph",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "Canonical reference to a GraphDefinition. If a URL is provided, it is the canonical reference to a [[[GraphDefinition]]] that it controls what resources are to be added to the bundle when building the document. The GraphDefinition can also specify profiles that apply to the various resources."
      }
    ],
    "MessageHeader": [
      {
        "Data Fields": "resourceType",
        "Type": "MessageHeader",
        
        
        "Required": "True",
        "Description": "This is a MessageHeader resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "eventCoding",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "Code that identifies the event this message represents and connects it with its definition. Events defined as part of the FHIR specification have the system value \"http://terminology.hl7.org/CodeSystem/message-events\".  Alternatively uri to the EventDefinition."
      },
      {
        "Data Fields": "eventUri",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Code that identifies the event this message represents and connects it with its definition. Events defined as part of the FHIR specification have the system value \"http://terminology.hl7.org/CodeSystem/message-events\".  Alternatively uri to the EventDefinition."
      },
      {
        "Data Fields": "destination",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The destination application which the message is intended for."
      },
      {
        "Data Fields": "destination.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "destination.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "destination.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "destination.name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Human-readable name for the target system."
      },
      {
        "Data Fields": "destination.target",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Identifies the target end system in situations where the initial message transmission is to an intermediary system."
      },
      {
        "Data Fields": "destination.endpoint",
        "Type": "url",
        
        
        "Required": "False",
        "Description": "Indicates where the message should be routed to."
      },
      {
        "Data Fields": "destination.receiver",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Allows data conveyed by a message to be addressed to a particular person or department when routing to a specific application isn't sufficient."
      },
      {
        "Data Fields": "sender",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Identifies the sending system to allow the use of a trust relationship."
      },
      {
        "Data Fields": "enterer",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The person or device that performed the data entry leading to this message. When there is more than one candidate, pick the most proximal to the message. Can provide other enterers in extensions."
      },
      {
        "Data Fields": "author",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The logical author of the message - the person or device that decided the described event should happen. When there is more than one candidate, pick the most proximal to the MessageHeader. Can provide other authors in extensions."
      },
      {
        "Data Fields": "source",
        "Type": "BackboneElement",
        
        
        "Required": "True",
        "Description": "The source application from which this message originated."
      },
      {
        "Data Fields": "source.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "source.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "source.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "source.name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Human-readable name for the source system."
      },
      {
        "Data Fields": "source.software",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "May include configuration or other information useful in debugging."
      },
      {
        "Data Fields": "source.version",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Can convey versions of multiple systems in situations where a message passes through multiple hands."
      },
      {
        "Data Fields": "source.contact",
        "Type": "ContactPoint",
        
        
        "Required": "False",
        "Description": "An e-mail, phone, website or other contact point to use to resolve issues with message communications."
      },
      {
        "Data Fields": "source.endpoint",
        "Type": "url",
        
        
        "Required": "False",
        "Description": "Identifies the routing target to send acknowledgements to."
      },
      {
        "Data Fields": "responsible",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The person or organization that accepts overall responsibility for the contents of the message. The implication is that the message event happened under the policies of the responsible party."
      },
      {
        "Data Fields": "reason",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Coded indication of the cause for the event - indicates  a reason for the occurrence of the event that is a focus of this message."
      },
      {
        "Data Fields": "response",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Information about the message that this message is a response to.  Only present if this message is a response."
      },
      {
        "Data Fields": "response.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "response.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "response.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "response.identifier",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The MessageHeader.id of the message to which this message is a response."
      },
      {
        "Data Fields": "response.code",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Code that identifies the type of response to the message - whether it was successful or not, and whether it should be resent or not."
      },
      {
        "Data Fields": "response.details",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Full details of any issues found in the message."
      },
      {
        "Data Fields": "focus",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The actual data of the message - a reference to the root/focus class of the event."
      },
      {
        "Data Fields": "definition",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "Permanent link to the MessageDefinition for this message."
      }
    ],
    "MolecularSequence": [
      {
        "Data Fields": "resourceType",
        "Type": "MolecularSequence",
        
        
        "Required": "True",
        "Description": "This is a MolecularSequence resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A unique identifier for this particular sequence instance. This is a FHIR-defined id."
      },
      {
        "Data Fields": "type",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Amino Acid Sequence/ DNA Sequence / RNA Sequence."
      },
      {
        "Data Fields": "coordinateSystem",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "Whether the sequence is numbered starting at 0 (0-based numbering or coordinates, inclusive start, exclusive end) or starting at 1 (1-based numbering, inclusive start and inclusive end)."
      },
      {
        "Data Fields": "patient",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The patient whose sequencing results are described by this resource."
      },
      {
        "Data Fields": "specimen",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Specimen used for sequencing."
      },
      {
        "Data Fields": "device",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The method for sequencing, for example, chip information."
      },
      {
        "Data Fields": "performer",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The organization or lab that should be responsible for this result."
      },
      {
        "Data Fields": "quantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The number of copies of the sequence of interest. (RNASeq)."
      },
      {
        "Data Fields": "referenceSeq",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A sequence that is used as a reference to describe variants that are present in a sequence analyzed."
      },
      {
        "Data Fields": "referenceSeq.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "referenceSeq.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "referenceSeq.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "referenceSeq.chromosome",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Structural unit composed of a nucleic acid molecule which controls its own replication through the interaction of specific proteins at one or more origins of replication ([SO:0000340](http://www.sequenceontology.org/browser/current_svn/term/SO:0000340))."
      },
      {
        "Data Fields": "referenceSeq.genomeBuild",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The Genome Build used for reference, following GRCh build versions e.g. 'GRCh 37'.  Version number must be included if a versioned release of a primary build was used."
      },
      {
        "Data Fields": "referenceSeq.orientation",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "A relative reference to a DNA strand based on gene orientation. The strand that contains the open reading frame of the gene is the \"sense\" strand, and the opposite complementary strand is the \"antisense\" strand."
      },
      {
        "Data Fields": "referenceSeq.referenceSeqId",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Reference identifier of reference sequence submitted to NCBI. It must match the type in the MolecularSequence.type field. For example, the prefix, “NG_” identifies reference sequence for genes, “NM_” for messenger RNA transcripts, and “NP_” for amino acid sequences."
      },
      {
        "Data Fields": "referenceSeq.referenceSeqPointer",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A pointer to another MolecularSequence entity as reference sequence."
      },
      {
        "Data Fields": "referenceSeq.referenceSeqString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A string like \"ACGT\"."
      },
      {
        "Data Fields": "referenceSeq.strand",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "An absolute reference to a strand. The Watson strand is the strand whose 5'-end is on the short arm of the chromosome, and the Crick strand as the one whose 5'-end is on the long arm."
      },
      {
        "Data Fields": "referenceSeq.windowStart",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "Start position of the window on the reference sequence. If the coordinate system is either 0-based or 1-based, then start position is inclusive."
      },
      {
        "Data Fields": "referenceSeq.windowEnd",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "End position of the window on the reference sequence. If the coordinate system is 0-based then end is exclusive and does not include the last position. If the coordinate system is 1-base, then end is inclusive and includes the last position."
      },
      {
        "Data Fields": "variant",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The definition of variant here originates from Sequence ontology ([variant_of](http://www.sequenceontology.org/browser/current_svn/term/variant_of)). This element can represent amino acid or nucleic sequence change(including insertion,deletion,SNP,etc.)  It can represent some complex mutation or segment variation with the assist of CIGAR string."
      },
      {
        "Data Fields": "variant.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "variant.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "variant.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "variant.start",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "Start position of the variant on the  reference sequence. If the coordinate system is either 0-based or 1-based, then start position is inclusive."
      },
      {
        "Data Fields": "variant.end",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "End position of the variant on the reference sequence. If the coordinate system is 0-based then end is exclusive and does not include the last position. If the coordinate system is 1-base, then end is inclusive and includes the last position."
      },
      {
        "Data Fields": "variant.observedAllele",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "An allele is one of a set of coexisting sequence variants of a gene ([SO:0001023](http://www.sequenceontology.org/browser/current_svn/term/SO:0001023)).  Nucleotide(s)/amino acids from start position of sequence to stop position of sequence on the positive (+) strand of the observed  sequence. When the sequence  type is DNA, it should be the sequence on the positive (+) strand. This will lay in the range between variant.start and variant.end."
      },
      {
        "Data Fields": "variant.referenceAllele",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "An allele is one of a set of coexisting sequence variants of a gene ([SO:0001023](http://www.sequenceontology.org/browser/current_svn/term/SO:0001023)). Nucleotide(s)/amino acids from start position of sequence to stop position of sequence on the positive (+) strand of the reference sequence. When the sequence  type is DNA, it should be the sequence on the positive (+) strand. This will lay in the range between variant.start and variant.end."
      },
      {
        "Data Fields": "variant.cigar",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Extended CIGAR string for aligning the sequence with reference bases. See detailed documentation [here](http://support.illumina.com/help/SequencingAnalysisWorkflow/Content/Vault/Informatics/Sequencing_Analysis/CASAVA/swSEQ_mCA_ExtendedCIGARFormat.htm)."
      },
      {
        "Data Fields": "variant.variantPointer",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A pointer to an Observation containing variant information."
      },
      {
        "Data Fields": "observedSeq",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Sequence that was observed. It is the result marked by referenceSeq along with variant records on referenceSeq. This shall start from referenceSeq.windowStart and end by referenceSeq.windowEnd."
      },
      {
        "Data Fields": "quality",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "An experimental feature attribute that defines the quality of the feature in a quantitative way, such as a phred quality score ([SO:0001686](http://www.sequenceontology.org/browser/current_svn/term/SO:0001686))."
      },
      {
        "Data Fields": "quality.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "quality.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "quality.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "quality.type",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "INDEL / SNP / Undefined variant."
      },
      {
        "Data Fields": "quality.standardSequence",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Gold standard sequence used for comparing against."
      },
      {
        "Data Fields": "quality.start",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "Start position of the sequence. If the coordinate system is either 0-based or 1-based, then start position is inclusive."
      },
      {
        "Data Fields": "quality.end",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "End position of the sequence. If the coordinate system is 0-based then end is exclusive and does not include the last position. If the coordinate system is 1-base, then end is inclusive and includes the last position."
      },
      {
        "Data Fields": "quality.score",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The score of an experimentally derived feature such as a p-value ([SO:0001685](http://www.sequenceontology.org/browser/current_svn/term/SO:0001685))."
      },
      {
        "Data Fields": "quality.method",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Which method is used to get sequence quality."
      },
      {
        "Data Fields": "quality.truthTP",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "True positives, from the perspective of the truth data, i.e. the number of sites in the Truth Call Set for which there are paths through the Query Call Set that are consistent with all of the alleles at this site, and for which there is an accurate genotype call for the event."
      },
      {
        "Data Fields": "quality.queryTP",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "True positives, from the perspective of the query data, i.e. the number of sites in the Query Call Set for which there are paths through the Truth Call Set that are consistent with all of the alleles at this site, and for which there is an accurate genotype call for the event."
      },
      {
        "Data Fields": "quality.truthFN",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "False negatives, i.e. the number of sites in the Truth Call Set for which there is no path through the Query Call Set that is consistent with all of the alleles at this site, or sites for which there is an inaccurate genotype call for the event. Sites with correct variant but incorrect genotype are counted here."
      },
      {
        "Data Fields": "quality.queryFP",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "False positives, i.e. the number of sites in the Query Call Set for which there is no path through the Truth Call Set that is consistent with this site. Sites with correct variant but incorrect genotype are counted here."
      },
      {
        "Data Fields": "quality.gtFP",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "The number of false positives where the non-REF alleles in the Truth and Query Call Sets match (i.e. cases where the truth is 1/1 and the query is 0/1 or similar)."
      },
      {
        "Data Fields": "quality.precision",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "QUERY.TP / (QUERY.TP + QUERY.FP)."
      },
      {
        "Data Fields": "quality.recall",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "TRUTH.TP / (TRUTH.TP + TRUTH.FN)."
      },
      {
        "Data Fields": "quality.fScore",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "Harmonic mean of Recall and Precision, computed as: 2 * precision * recall / (precision + recall)."
      },
      {
        "Data Fields": "quality.roc",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Receiver Operator Characteristic (ROC) Curve  to give sensitivity/specificity tradeoff."
      },
      {
        "Data Fields": "quality.roc.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "quality.roc.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "quality.roc.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "quality.roc.score",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "Invidual data point representing the GQ (genotype quality) score threshold."
      },
      {
        "Data Fields": "quality.roc.numTP",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "The number of true positives if the GQ score threshold was set to \"score\" field value."
      },
      {
        "Data Fields": "quality.roc.numFP",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "The number of false positives if the GQ score threshold was set to \"score\" field value."
      },
      {
        "Data Fields": "quality.roc.numFN",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "The number of false negatives if the GQ score threshold was set to \"score\" field value."
      },
      {
        "Data Fields": "quality.roc.precision",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "Calculated precision if the GQ score threshold was set to \"score\" field value."
      },
      {
        "Data Fields": "quality.roc.sensitivity",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "Calculated sensitivity if the GQ score threshold was set to \"score\" field value."
      },
      {
        "Data Fields": "quality.roc.fMeasure",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "Calculated fScore if the GQ score threshold was set to \"score\" field value."
      },
      {
        "Data Fields": "readCoverage",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "Coverage (read depth or depth) is the average number of reads representing a given nucleotide in the reconstructed sequence."
      },
      {
        "Data Fields": "repository",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Configurations of the external repository. The repository shall store target's observedSeq or records related with target's observedSeq."
      },
      {
        "Data Fields": "repository.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "repository.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "repository.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "repository.type",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Click and see / RESTful API / Need login to see / RESTful API with authentication / Other ways to see resource."
      },
      {
        "Data Fields": "repository.url",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "URI of an external repository which contains further details about the genetics data."
      },
      {
        "Data Fields": "repository.name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "URI of an external repository which contains further details about the genetics data."
      },
      {
        "Data Fields": "repository.datasetId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Id of the variant in this external repository. The server will understand how to use this id to call for more info about datasets in external repository."
      },
      {
        "Data Fields": "repository.variantsetId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Id of the variantset in this external repository. The server will understand how to use this id to call for more info about variantsets in external repository."
      },
      {
        "Data Fields": "repository.readsetId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Id of the read in this external repository."
      },
      {
        "Data Fields": "pointer",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Pointer to next atomic sequence which at most contains one variant."
      },
      {
        "Data Fields": "structureVariant",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Information about chromosome structure variation."
      },
      {
        "Data Fields": "structureVariant.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "structureVariant.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "structureVariant.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "structureVariant.variantType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Information about chromosome structure variation DNA change type."
      },
      {
        "Data Fields": "structureVariant.exact",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Used to indicate if the outer and inner start-end values have the same meaning."
      },
      {
        "Data Fields": "structureVariant.length",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "Length of the variant chromosome."
      },
      {
        "Data Fields": "structureVariant.outer",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Structural variant outer."
      },
      {
        "Data Fields": "structureVariant.outer.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "structureVariant.outer.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "structureVariant.outer.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "structureVariant.outer.start",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "Structural variant outer start. If the coordinate system is either 0-based or 1-based, then start position is inclusive."
      },
      {
        "Data Fields": "structureVariant.outer.end",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "Structural variant outer end. If the coordinate system is 0-based then end is exclusive and does not include the last position. If the coordinate system is 1-base, then end is inclusive and includes the last position."
      },
      {
        "Data Fields": "structureVariant.inner",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Structural variant inner."
      },
      {
        "Data Fields": "structureVariant.inner.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "structureVariant.inner.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "structureVariant.inner.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "structureVariant.inner.start",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "Structural variant inner start. If the coordinate system is either 0-based or 1-based, then start position is inclusive."
      },
      {
        "Data Fields": "structureVariant.inner.end",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "Structural variant inner end. If the coordinate system is 0-based then end is exclusive and does not include the last position. If the coordinate system is 1-base, then end is inclusive and includes the last position."
      }
    ],
    "NamingSystem": [
      {
        "Data Fields": "resourceType",
        "Type": "NamingSystem",
        
        
        "Required": "True",
        "Description": "This is a NamingSystem resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A natural language name identifying the naming system. This name should be usable as an identifier for the module by machine processing applications such as code generation."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The status of this naming system. Enables tracking the life-cycle of the content."
      },
      {
        "Data Fields": "kind",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Indicates the purpose for the naming system - what kinds of things does it make unique?"
      },
      {
        "Data Fields": "date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date  (and optionally time) when the naming system was published. The date must change when the business version changes and it must change if the status code changes. In addition, it should change when the substantive content of the naming system changes."
      },
      {
        "Data Fields": "publisher",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the organization or individual that published the naming system."
      },
      {
        "Data Fields": "contact",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "Contact details to assist a user in finding and communicating with the publisher."
      },
      {
        "Data Fields": "responsible",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the organization that is responsible for issuing identifiers or codes for this namespace and ensuring their non-collision."
      },
      {
        "Data Fields": "type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Categorizes a naming system for easier search by grouping related naming systems."
      },
      {
        "Data Fields": "description",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A free text natural language description of the naming system from a consumer's perspective. Details about what the namespace identifies including scope, granularity, version labeling, etc."
      },
      {
        "Data Fields": "useContext",
        "Type": "UsageContext",
        
        
        "Required": "False",
        "Description": "The content was developed with a focus and intent of supporting the contexts that are listed. These contexts may be general categories (gender, age, ...) or may be references to specific programs (insurance plans, studies, ...) and may be used to assist with indexing and searching for appropriate naming system instances."
      },
      {
        "Data Fields": "jurisdiction",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A legal or geographic region in which the naming system is intended to be used."
      },
      {
        "Data Fields": "usage",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Provides guidance on the use of the namespace, including the handling of formatting characters, use of upper vs. lower case, etc."
      },
      {
        "Data Fields": "uniqueId",
        "Type": "BackboneElement",
        
        
        "Required": "True",
        "Description": "Indicates how the system may be identified when referenced in electronic exchange."
      },
      {
        "Data Fields": "uniqueId.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "uniqueId.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "uniqueId.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "uniqueId.type",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Identifies the unique identifier scheme used for this particular identifier."
      },
      {
        "Data Fields": "uniqueId.value",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The string that should be sent over the wire to identify the code system or identifier system."
      },
      {
        "Data Fields": "uniqueId.preferred",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Indicates whether this identifier is the \"preferred\" identifier of this type."
      },
      {
        "Data Fields": "uniqueId.comment",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Notes about the past or intended usage of this identifier."
      },
      {
        "Data Fields": "uniqueId.period",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "Identifies the period of time over which this identifier is considered appropriate to refer to the naming system.  Outside of this window, the identifier might be non-deterministic."
      }
    ],
    "NutritionOrder": [
      {
        "Data Fields": "resourceType",
        "Type": "NutritionOrder",
        
        
        "Required": "True",
        "Description": "This is a NutritionOrder resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Identifiers assigned to this order by the order sender or by the order receiver."
      },
      {
        "Data Fields": "instantiatesCanonical",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "The URL pointing to a FHIR-defined protocol, guideline, orderset or other definition that is adhered to in whole or in part by this NutritionOrder."
      },
      {
        "Data Fields": "instantiatesUri",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "The URL pointing to an externally maintained protocol, guideline, orderset or other definition that is adhered to in whole or in part by this NutritionOrder."
      },
      {
        "Data Fields": "instantiates",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "The URL pointing to a protocol, guideline, orderset or other definition that is adhered to in whole or in part by this NutritionOrder."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The workflow status of the nutrition order/request."
      },
      {
        "Data Fields": "intent",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Indicates the level of authority/intentionality associated with the NutrionOrder and where the request fits into the workflow chain."
      },
      {
        "Data Fields": "patient",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "The person (patient) who needs the nutrition order for an oral diet, nutritional supplement and/or enteral or formula feeding."
      },
      {
        "Data Fields": "encounter",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "An encounter that provides additional information about the healthcare context in which this request is made."
      },
      {
        "Data Fields": "dateTime",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date and time that this nutrition order was requested."
      },
      {
        "Data Fields": "orderer",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The practitioner that holds legal responsibility for ordering the diet, nutritional supplement, or formula feedings."
      },
      {
        "Data Fields": "allergyIntolerance",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A link to a record of allergies or intolerances  which should be included in the nutrition order."
      },
      {
        "Data Fields": "foodPreferenceModifier",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "This modifier is used to convey order-specific modifiers about the type of food that should be given. These can be derived from patient allergies, intolerances, or preferences such as Halal, Vegan or Kosher. This modifier applies to the entire nutrition order inclusive of the oral diet, nutritional supplements and enteral formula feedings."
      },
      {
        "Data Fields": "excludeFoodModifier",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "This modifier is used to convey Order-specific modifier about the type of oral food or oral fluids that should not be given. These can be derived from patient allergies, intolerances, or preferences such as No Red Meat, No Soy or No Wheat or  Gluten-Free.  While it should not be necessary to repeat allergy or intolerance information captured in the referenced AllergyIntolerance resource in the excludeFoodModifier, this element may be used to convey additional specificity related to foods that should be eliminated from the patient’s diet for any reason.  This modifier applies to the entire nutrition order inclusive of the oral diet, nutritional supplements and enteral formula feedings."
      },
      {
        "Data Fields": "oralDiet",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Diet given orally in contrast to enteral (tube) feeding."
      },
      {
        "Data Fields": "oralDiet.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "oralDiet.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "oralDiet.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "oralDiet.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The kind of diet or dietary restriction such as fiber restricted diet or diabetic diet."
      },
      {
        "Data Fields": "oralDiet.schedule",
        "Type": "Timing",
        
        
        "Required": "False",
        "Description": "The time period and frequency at which the diet should be given.  The diet should be given for the combination of all schedules if more than one schedule is present."
      },
      {
        "Data Fields": "oralDiet.nutrient",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Class that defines the quantity and type of nutrient modifications (for example carbohydrate, fiber or sodium) required for the oral diet."
      },
      {
        "Data Fields": "oralDiet.nutrient.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "oralDiet.nutrient.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "oralDiet.nutrient.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "oralDiet.nutrient.modifier",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The nutrient that is being modified such as carbohydrate or sodium."
      },
      {
        "Data Fields": "oralDiet.nutrient.amount",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The quantity of the specified nutrient to include in diet."
      },
      {
        "Data Fields": "oralDiet.texture",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Class that describes any texture modifications required for the patient to safely consume various types of solid foods."
      },
      {
        "Data Fields": "oralDiet.texture.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "oralDiet.texture.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "oralDiet.texture.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "oralDiet.texture.modifier",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Any texture modifications (for solid foods) that should be made, e.g. easy to chew, chopped, ground, and pureed."
      },
      {
        "Data Fields": "oralDiet.texture.foodType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The food type(s) (e.g. meats, all foods)  that the texture modification applies to.  This could be all foods types."
      },
      {
        "Data Fields": "oralDiet.fluidConsistencyType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The required consistency (e.g. honey-thick, nectar-thick, thin, thickened.) of liquids or fluids served to the patient."
      },
      {
        "Data Fields": "oralDiet.instruction",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Free text or additional instructions or information pertaining to the oral diet."
      },
      {
        "Data Fields": "supplement",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Oral nutritional products given in order to add further nutritional value to the patient's diet."
      },
      {
        "Data Fields": "supplement.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "supplement.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "supplement.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "supplement.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The kind of nutritional supplement product required such as a high protein or pediatric clear liquid supplement."
      },
      {
        "Data Fields": "supplement.productName",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The product or brand name of the nutritional supplement such as \"Acme Protein Shake\"."
      },
      {
        "Data Fields": "supplement.schedule",
        "Type": "Timing",
        
        
        "Required": "False",
        "Description": "The time period and frequency at which the supplement(s) should be given.  The supplement should be given for the combination of all schedules if more than one schedule is present."
      },
      {
        "Data Fields": "supplement.quantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The amount of the nutritional supplement to be given."
      },
      {
        "Data Fields": "supplement.instruction",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Free text or additional instructions or information pertaining to the oral supplement."
      },
      {
        "Data Fields": "enteralFormula",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Feeding provided through the gastrointestinal tract via a tube, catheter, or stoma that delivers nutrition distal to the oral cavity."
      },
      {
        "Data Fields": "enteralFormula.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "enteralFormula.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "enteralFormula.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "enteralFormula.baseFormulaType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The type of enteral or infant formula such as an adult standard formula with fiber or a soy-based infant formula."
      },
      {
        "Data Fields": "enteralFormula.baseFormulaProductName",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The product or brand name of the enteral or infant formula product such as \"ACME Adult Standard Formula\"."
      },
      {
        "Data Fields": "enteralFormula.additiveType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Indicates the type of modular component such as protein, carbohydrate, fat or fiber to be provided in addition to or mixed with the base formula."
      },
      {
        "Data Fields": "enteralFormula.additiveProductName",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The product or brand name of the type of modular component to be added to the formula."
      },
      {
        "Data Fields": "enteralFormula.caloricDensity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The amount of energy (calories) that the formula should provide per specified volume, typically per mL or fluid oz.  For example, an infant may require a formula that provides 24 calories per fluid ounce or an adult may require an enteral formula that provides 1.5 calorie/mL."
      },
      {
        "Data Fields": "enteralFormula.routeofAdministration",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The route or physiological path of administration into the patient's gastrointestinal  tract for purposes of providing the formula feeding, e.g. nasogastric tube."
      },
      {
        "Data Fields": "enteralFormula.administration",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Formula administration instructions as structured data.  This repeating structure allows for changing the administration rate or volume over time for both bolus and continuous feeding.  An example of this would be an instruction to increase the rate of continuous feeding every 2 hours."
      },
      {
        "Data Fields": "enteralFormula.administration.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "enteralFormula.administration.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "enteralFormula.administration.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "enteralFormula.administration.schedule",
        "Type": "Timing",
        
        
        "Required": "False",
        "Description": "The time period and frequency at which the enteral formula should be delivered to the patient."
      },
      {
        "Data Fields": "enteralFormula.administration.quantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The volume of formula to provide to the patient per the specified administration schedule."
      },
      {
        "Data Fields": "enteralFormula.administration.rateQuantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The rate of administration of formula via a feeding pump, e.g. 60 mL per hour, according to the specified schedule."
      },
      {
        "Data Fields": "enteralFormula.administration.rateRatio",
        "Type": "Ratio",
        
        
        "Required": "False",
        "Description": "The rate of administration of formula via a feeding pump, e.g. 60 mL per hour, according to the specified schedule."
      },
      {
        "Data Fields": "enteralFormula.maxVolumeToDeliver",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The maximum total quantity of formula that may be administered to a subject over the period of time, e.g. 1440 mL over 24 hours."
      },
      {
        "Data Fields": "enteralFormula.administrationInstruction",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Free text formula administration, feeding instructions or additional instructions or information."
      },
      {
        "Data Fields": "note",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "Comments made about the {{title}} by the requester, performer, subject or other participants."
      }
    ],
    "Observation": [
      {
        "Data Fields": "resourceType",
        "Type": "Observation",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "This is a Observation resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A unique identifier assigned to this observation."
      },
      {
        "Data Fields": "basedOn",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A plan, proposal or order that is fulfilled in whole or in part by this event.  For example, a MedicationRequest may require a patient to have laboratory test performed before  it is dispensed."
      },
      {
        "Data Fields": "partOf",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A larger event of which this particular Observation is a component or step.  For example,  an observation as part of a procedure."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The status of the result value."
      },
      {
        "Data Fields": "category",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A code that classifies the general type of observation being made."
      },
      {
        "Data Fields": "code",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "Describes what was observed. Sometimes this is called the observation \"name\"."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The patient, or group of patients, location, or device this observation is about and into whose record the observation is placed. If the actual focus of the observation is different from the subject (or a sample of, part, or region of the subject), the `focus` element or the `code` itself specifies the actual focus of the observation."
      },
      {
        "Data Fields": "focus",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The actual focus of an observation when it is not the patient of record representing something or someone associated with the patient such as a spouse, parent, fetus, or donor. For example, fetus observations in a mother's record.  The focus of an observation could also be an existing condition,  an intervention, the subject's diet,  another observation of the subject,  or a body structure such as tumor or implanted device.   An example use case would be using the Observation resource to capture whether the mother is trained to change her child's tracheostomy tube. In this example, the child is the patient of record and the mother is the focus."
      },
      {
        "Data Fields": "encounter",
        "Type": "Reference",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The healthcare event  (e.g. a patient and healthcare provider interaction) during which this observation is made."
      },
      {
        "Data Fields": "effectiveDateTime",
        "Type": "string",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The time or time-period the observed value is asserted as being true. For biological subjects - e.g. human patients - this is usually called the \"physiologically relevant time\". This is usually either the time of the procedure or of specimen collection, but very often the source of the date/time is not known, only the date/time itself."
      },
      {
        "Data Fields": "effectivePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The time or time-period the observed value is asserted as being true. For biological subjects - e.g. human patients - this is usually called the \"physiologically relevant time\". This is usually either the time of the procedure or of specimen collection, but very often the source of the date/time is not known, only the date/time itself."
      },
      {
        "Data Fields": "effectiveTiming",
        "Type": "Timing",
        
        
        "Required": "False",
        "Description": "The time or time-period the observed value is asserted as being true. For biological subjects - e.g. human patients - this is usually called the \"physiologically relevant time\". This is usually either the time of the procedure or of specimen collection, but very often the source of the date/time is not known, only the date/time itself."
      },
      {
        "Data Fields": "effectiveInstant",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The time or time-period the observed value is asserted as being true. For biological subjects - e.g. human patients - this is usually called the \"physiologically relevant time\". This is usually either the time of the procedure or of specimen collection, but very often the source of the date/time is not known, only the date/time itself."
      },
      {
        "Data Fields": "issued",
        "Type": "instant",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The date and time this version of the observation was made available to providers, typically after the results have been reviewed and verified."
      },
      {
        "Data Fields": "performer",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Who was responsible for asserting the observed value as \"true\"."
      },
      {
        "Data Fields": "valueQuantity",
        "Type": "Quantity",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The information determined as a result of making the observation, if the information has a simple value."
      },
      {
        "Data Fields": "valueCodeableConcept",
        "Type": "CodeableConcept",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The information determined as a result of making the observation, if the information has a simple value."
      },
      {
        "Data Fields": "valueString",
        "Type": "string",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The information determined as a result of making the observation, if the information has a simple value."
      },
      {
        "Data Fields": "valueBoolean",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "The information determined as a result of making the observation, if the information has a simple value."
      },
      {
        "Data Fields": "valueInteger",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "The information determined as a result of making the observation, if the information has a simple value."
      },
      {
        "Data Fields": "valueRange",
        "Type": "Range",
        
        
        "Required": "False",
        "Description": "The information determined as a result of making the observation, if the information has a simple value."
      },
      {
        "Data Fields": "valueRatio",
        "Type": "Ratio",
        
        
        "Required": "False",
        "Description": "The information determined as a result of making the observation, if the information has a simple value."
      },
      {
        "Data Fields": "valueSampledData",
        "Type": "SampledData",
        
        
        "Required": "False",
        "Description": "The information determined as a result of making the observation, if the information has a simple value."
      },
      {
        "Data Fields": "valueTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The information determined as a result of making the observation, if the information has a simple value."
      },
      {
        "Data Fields": "valueDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The information determined as a result of making the observation, if the information has a simple value."
      },
      {
        "Data Fields": "valuePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The information determined as a result of making the observation, if the information has a simple value."
      },
      {
        "Data Fields": "dataAbsentReason",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Provides a reason why the expected value in the element Observation.value[x] is missing."
      },
      {
        "Data Fields": "interpretation",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A categorical assessment of an observation value.  For example, high, low, normal."
      },
      {
        "Data Fields": "note",
        "Type": "Annotation",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Comments about the observation or the results."
      },
      {
        "Data Fields": "bodySite",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Indicates the site on the subject's body where the observation was made (i.e. the target site)."
      },
      {
        "Data Fields": "method",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Indicates the mechanism used to perform the observation."
      },
      {
        "Data Fields": "specimen",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The specimen that was used when this observation was made."
      },
      {
        "Data Fields": "device",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The device used to generate the observation data."
      },
      {
        "Data Fields": "referenceRange",
        "Type": "BackboneElement",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Guidance on how to interpret the value by comparison to a normal or recommended range.  Multiple reference ranges are interpreted as an \"OR\".   In other words, to represent two distinct target populations, two `referenceRange` elements would be used."
      },
      {
        "Data Fields": "referenceRange.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "referenceRange.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "referenceRange.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "referenceRange.low",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The value of the low bound of the reference range.  The low bound of the reference range endpoint is inclusive of the value (e.g.  reference range is >=5 - <=9). If the low bound is omitted,  it is assumed to be meaningless (e.g. reference range is <=2.3)."
      },
      {
        "Data Fields": "referenceRange.high",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The value of the high bound of the reference range.  The high bound of the reference range endpoint is inclusive of the value (e.g.  reference range is >=5 - <=9). If the high bound is omitted,  it is assumed to be meaningless (e.g. reference range is >= 2.3)."
      },
      {
        "Data Fields": "referenceRange.type",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Codes to indicate the what part of the targeted reference population it applies to. For example, the normal or therapeutic range."
      },
      {
        "Data Fields": "referenceRange.appliesTo",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Codes to indicate the target population this reference range applies to.  For example, a reference range may be based on the normal population or a particular sex or race.  Multiple `appliesTo`  are interpreted as an \"AND\" of the target populations.  For example, to represent a target population of African American females, both a code of female and a code for African American would be used."
      },
      {
        "Data Fields": "referenceRange.age",
        "Type": "Range",
        
        
        "Required": "False",
        "Description": "The age at which this reference range is applicable. This is a neonatal age (e.g. number of weeks at term) if the meaning says so."
      },
      {
        "Data Fields": "referenceRange.text",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Text based reference range in an observation which may be used when a quantitative range is not appropriate for an observation.  An example would be a reference value of \"Negative\" or a list or table of \"normals\"."
      },
      {
        "Data Fields": "hasMember",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "This observation is a group observation (e.g. a battery, a panel of tests, a set of vital sign measurements) that includes the target as a member of the group."
      },
      {
        "Data Fields": "derivedFrom",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The target resource that represents a measurement from which this observation value is derived. For example, a calculated anion gap or a fetal measurement based on an ultrasound image."
      },
      {
        "Data Fields": "component",
        "Type": "BackboneElement",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Some observations have multiple component observations.  These component observations are expressed as separate code value pairs that share the same attributes.  Examples include systolic and diastolic component observations for blood pressure measurement and multiple component observations for genetics observations."
      },
      {
        "Data Fields": "component.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "component.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "component.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "component.code",
        "Type": "CodeableConcept",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Describes what was observed. Sometimes this is called the observation \"code\"."
      },
      {
        "Data Fields": "component.valueQuantity",
        "Type": "Quantity",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The information determined as a result of making the observation, if the information has a simple value."
      },
      {
        "Data Fields": "component.valueCodeableConcept",
        "Type": "CodeableConcept",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The information determined as a result of making the observation, if the information has a simple value."
      },
      {
        "Data Fields": "component.valueString",
        "Type": "string",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The information determined as a result of making the observation, if the information has a simple value."
      },
      {
        "Data Fields": "component.valueBoolean",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "The information determined as a result of making the observation, if the information has a simple value."
      },
      {
        "Data Fields": "component.valueInteger",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "The information determined as a result of making the observation, if the information has a simple value."
      },
      {
        "Data Fields": "component.valueRange",
        "Type": "Range",
        
        
        "Required": "False",
        "Description": "The information determined as a result of making the observation, if the information has a simple value."
      },
      {
        "Data Fields": "component.valueRatio",
        "Type": "Ratio",
        
        
        "Required": "False",
        "Description": "The information determined as a result of making the observation, if the information has a simple value."
      },
      {
        "Data Fields": "component.valueSampledData",
        "Type": "SampledData",
        
        
        "Required": "False",
        "Description": "The information determined as a result of making the observation, if the information has a simple value."
      },
      {
        "Data Fields": "component.valueTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The information determined as a result of making the observation, if the information has a simple value."
      },
      {
        "Data Fields": "component.valueDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The information determined as a result of making the observation, if the information has a simple value."
      },
      {
        "Data Fields": "component.valuePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The information determined as a result of making the observation, if the information has a simple value."
      },
      {
        "Data Fields": "component.dataAbsentReason",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Provides a reason why the expected value in the element Observation.component.value[x] is missing."
      },
      {
        "Data Fields": "component.interpretation",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A categorical assessment of an observation value.  For example, high, low, normal."
      },
      {
        "Data Fields": "component.referenceRange",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Guidance on how to interpret the value by comparison to a normal or recommended range."
      },
      {
        "Data Fields": "component.referenceRange.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "component.referenceRange.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "component.referenceRange.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "component.referenceRange.low",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The value of the low bound of the reference range.  The low bound of the reference range endpoint is inclusive of the value (e.g.  reference range is >=5 - <=9). If the low bound is omitted,  it is assumed to be meaningless (e.g. reference range is <=2.3)."
      },
      {
        "Data Fields": "component.referenceRange.high",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The value of the high bound of the reference range.  The high bound of the reference range endpoint is inclusive of the value (e.g.  reference range is >=5 - <=9). If the high bound is omitted,  it is assumed to be meaningless (e.g. reference range is >= 2.3)."
      },
      {
        "Data Fields": "component.referenceRange.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Codes to indicate the what part of the targeted reference population it applies to. For example, the normal or therapeutic range."
      },
      {
        "Data Fields": "component.referenceRange.appliesTo",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Codes to indicate the target population this reference range applies to.  For example, a reference range may be based on the normal population or a particular sex or race.  Multiple `appliesTo`  are interpreted as an \"AND\" of the target populations.  For example, to represent a target population of African American females, both a code of female and a code for African American would be used."
      },
      {
        "Data Fields": "component.referenceRange.age",
        "Type": "Range",
        
        
        "Required": "False",
        "Description": "The age at which this reference range is applicable. This is a neonatal age (e.g. number of weeks at term) if the meaning says so."
      },
      {
        "Data Fields": "component.referenceRange.text",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Text based reference range in an observation which may be used when a quantitative range is not appropriate for an observation.  An example would be a reference value of \"Negative\" or a list or table of \"normals\"."
      }
    ],
    "ObservationDefinition": [
      {
        "Data Fields": "resourceType",
        "Type": "ObservationDefinition",
        
        
        "Required": "True",
        "Description": "This is a ObservationDefinition resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "category",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code that classifies the general type of observation."
      },
      {
        "Data Fields": "code",
        "Type": "CodeableConcept",
        
        
        "Required": "True",
        "Description": "Describes what will be observed. Sometimes this is called the observation \"name\"."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A unique identifier assigned to this ObservationDefinition artifact."
      },
      {
        "Data Fields": "permittedDataType",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The data types allowed for the value element of the instance observations conforming to this ObservationDefinition."
      },
      {
        "Data Fields": "multipleResultsAllowed",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Multiple results allowed for observations conforming to this ObservationDefinition."
      },
      {
        "Data Fields": "method",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The method or technique used to perform the observation."
      },
      {
        "Data Fields": "preferredReportName",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The preferred name to be used when reporting the results of observations conforming to this ObservationDefinition."
      },
      {
        "Data Fields": "quantitativeDetails",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Characteristics for quantitative results of this observation."
      },
      {
        "Data Fields": "quantitativeDetails.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "quantitativeDetails.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "quantitativeDetails.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "quantitativeDetails.customaryUnit",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Customary unit used to report quantitative results of observations conforming to this ObservationDefinition."
      },
      {
        "Data Fields": "quantitativeDetails.unit",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "SI unit used to report quantitative results of observations conforming to this ObservationDefinition."
      },
      {
        "Data Fields": "quantitativeDetails.conversionFactor",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "Factor for converting value expressed with SI unit to value expressed with customary unit."
      },
      {
        "Data Fields": "quantitativeDetails.decimalPrecision",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "Number of digits after decimal separator when the results of such observations are of type Quantity."
      },
      {
        "Data Fields": "qualifiedInterval",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Multiple  ranges of results qualified by different contexts for ordinal or continuous observations conforming to this ObservationDefinition."
      },
      {
        "Data Fields": "qualifiedInterval.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "qualifiedInterval.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "qualifiedInterval.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "qualifiedInterval.category",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The category of interval of values for continuous or ordinal observations conforming to this ObservationDefinition."
      },
      {
        "Data Fields": "qualifiedInterval.range",
        "Type": "Range",
        
        
        "Required": "False",
        "Description": "The low and high values determining the interval. There may be only one of the two."
      },
      {
        "Data Fields": "qualifiedInterval.context",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Codes to indicate the health context the range applies to. For example, the normal or therapeutic range."
      },
      {
        "Data Fields": "qualifiedInterval.appliesTo",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Codes to indicate the target population this reference range applies to."
      },
      {
        "Data Fields": "qualifiedInterval.gender",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Sex of the population the range applies to."
      },
      {
        "Data Fields": "qualifiedInterval.age",
        "Type": "Range",
        
        
        "Required": "False",
        "Description": "The age at which this reference range is applicable. This is a neonatal age (e.g. number of weeks at term) if the meaning says so."
      },
      {
        "Data Fields": "qualifiedInterval.gestationalAge",
        "Type": "Range",
        
        
        "Required": "False",
        "Description": "The gestational age to which this reference range is applicable, in the context of pregnancy."
      },
      {
        "Data Fields": "qualifiedInterval.condition",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Text based condition for which the reference range is valid."
      },
      {
        "Data Fields": "validCodedValueSet",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The set of valid coded results for the observations  conforming to this ObservationDefinition."
      },
      {
        "Data Fields": "normalCodedValueSet",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The set of normal coded results for the observations conforming to this ObservationDefinition."
      },
      {
        "Data Fields": "abnormalCodedValueSet",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The set of abnormal coded results for the observation conforming to this ObservationDefinition."
      },
      {
        "Data Fields": "criticalCodedValueSet",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The set of critical coded results for the observation conforming to this ObservationDefinition."
      }
    ],
    "OperationDefinition": [
      {
        "Data Fields": "resourceType",
        "Type": "OperationDefinition",
        
        
        "Required": "True",
        "Description": "This is a OperationDefinition resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "url",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "An absolute URI that is used to identify this operation definition when it is referenced in a specification, model, design or an instance; also called its canonical identifier. This SHOULD be globally unique and SHOULD be a literal address at which at which an authoritative instance of this operation definition is (or will be) published. This URL can be the target of a canonical reference. It SHALL remain the same when the operation definition is stored on different servers."
      },
      {
        "Data Fields": "version",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The identifier that is used to identify this version of the operation definition when it is referenced in a specification, model, design or instance. This is an arbitrary value managed by the operation definition author and is not expected to be globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if a managed version is not available. There is also no expectation that versions can be placed in a lexicographical sequence."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A natural language name identifying the operation definition. This name should be usable as an identifier for the module by machine processing applications such as code generation."
      },
      {
        "Data Fields": "title",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A short, descriptive, user-friendly title for the operation definition."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The status of this operation definition. Enables tracking the life-cycle of the content."
      },
      {
        "Data Fields": "kind",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Whether this is an operation or a named query."
      },
      {
        "Data Fields": "experimental",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "A Boolean value to indicate that this operation definition is authored for testing purposes (or education/evaluation/marketing) and is not intended to be used for genuine usage."
      },
      {
        "Data Fields": "date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date  (and optionally time) when the operation definition was published. The date must change when the business version changes and it must change if the status code changes. In addition, it should change when the substantive content of the operation definition changes."
      },
      {
        "Data Fields": "publisher",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the organization or individual that published the operation definition."
      },
      {
        "Data Fields": "contact",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "Contact details to assist a user in finding and communicating with the publisher."
      },
      {
        "Data Fields": "description",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A free text natural language description of the operation definition from a consumer's perspective."
      },
      {
        "Data Fields": "useContext",
        "Type": "UsageContext",
        
        
        "Required": "False",
        "Description": "The content was developed with a focus and intent of supporting the contexts that are listed. These contexts may be general categories (gender, age, ...) or may be references to specific programs (insurance plans, studies, ...) and may be used to assist with indexing and searching for appropriate operation definition instances."
      },
      {
        "Data Fields": "jurisdiction",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A legal or geographic region in which the operation definition is intended to be used."
      },
      {
        "Data Fields": "purpose",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Explanation of why this operation definition is needed and why it has been designed as it has."
      },
      {
        "Data Fields": "affectsState",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Whether the operation affects state. Side effects such as producing audit trail entries do not count as 'affecting  state'."
      },
      {
        "Data Fields": "code",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The name used to invoke the operation."
      },
      {
        "Data Fields": "comment",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Additional information about how to use this operation or named query."
      },
      {
        "Data Fields": "base",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "Indicates that this operation definition is a constraining profile on the base."
      },
      {
        "Data Fields": "resource",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The types on which this operation can be executed."
      },
      {
        "Data Fields": "system",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Indicates whether this operation or named query can be invoked at the system level (e.g. without needing to choose a resource type for the context)."
      },
      {
        "Data Fields": "type",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Indicates whether this operation or named query can be invoked at the resource type level for any given resource type level (e.g. without needing to choose a specific resource id for the context)."
      },
      {
        "Data Fields": "instance",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Indicates whether this operation can be invoked on a particular instance of one of the given types."
      },
      {
        "Data Fields": "inputProfile",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "Additional validation information for the in parameters - a single profile that covers all the parameters. The profile is a constraint on the parameters resource as a whole."
      },
      {
        "Data Fields": "outputProfile",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "Additional validation information for the out parameters - a single profile that covers all the parameters. The profile is a constraint on the parameters resource."
      },
      {
        "Data Fields": "parameter",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The parameters for the operation/query."
      },
      {
        "Data Fields": "parameter.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "parameter.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "parameter.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "parameter.name",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The name of used to identify the parameter."
      },
      {
        "Data Fields": "parameter.use",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Whether this is an input or an output parameter."
      },
      {
        "Data Fields": "parameter.min",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "The minimum number of times this parameter SHALL appear in the request or response."
      },
      {
        "Data Fields": "parameter.max",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The maximum number of times this element is permitted to appear in the request or response."
      },
      {
        "Data Fields": "parameter.documentation",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Describes the meaning or use of this parameter."
      },
      {
        "Data Fields": "parameter.type",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The type for this parameter."
      },
      {
        "Data Fields": "parameter.targetProfile",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "Used when the type is \"Reference\" or \"canonical\", and identifies a profile structure or implementation Guide that applies to the target of the reference this parameter refers to. If any profiles are specified, then the content must conform to at least one of them. The URL can be a local reference - to a contained StructureDefinition, or a reference to another StructureDefinition or Implementation Guide by a canonical URL. When an implementation guide is specified, the target resource SHALL conform to at least one profile defined in the implementation guide."
      },
      {
        "Data Fields": "parameter.searchType",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "How the parameter is understood as a search parameter. This is only used if the parameter type is 'string'."
      },
      {
        "Data Fields": "parameter.binding",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Binds to a value set if this parameter is coded (code, Coding, CodeableConcept)."
      },
      {
        "Data Fields": "parameter.binding.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "parameter.binding.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "parameter.binding.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "parameter.binding.strength",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Indicates the degree of conformance expectations associated with this binding - that is, the degree to which the provided value set must be adhered to in the instances."
      },
      {
        "Data Fields": "parameter.binding.valueSet",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "Points to the value set or external definition (e.g. implicit value set) that identifies the set of codes to be used."
      },
      {
        "Data Fields": "parameter.referencedFrom",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Identifies other resource parameters within the operation invocation that are expected to resolve to this resource."
      },
      {
        "Data Fields": "parameter.referencedFrom.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "parameter.referencedFrom.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "parameter.referencedFrom.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "parameter.referencedFrom.source",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the parameter or dot-separated path of parameter names pointing to the resource parameter that is expected to contain a reference to this resource."
      },
      {
        "Data Fields": "parameter.referencedFrom.sourceId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The id of the element in the referencing resource that is expected to resolve to this resource."
      },
      {
        "Data Fields": "parameter.part",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The parts of a nested Parameter."
      },
      {
        "Data Fields": "parameter.part.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "parameter.part.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "parameter.part.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "parameter.part.name",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The name of used to identify the parameter."
      },
      {
        "Data Fields": "parameter.part.use",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Whether this is an input or an output parameter."
      },
      {
        "Data Fields": "parameter.part.min",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "The minimum number of times this parameter SHALL appear in the request or response."
      },
      {
        "Data Fields": "parameter.part.max",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The maximum number of times this element is permitted to appear in the request or response."
      },
      {
        "Data Fields": "parameter.part.documentation",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Describes the meaning or use of this parameter."
      },
      {
        "Data Fields": "parameter.part.type",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The type for this parameter."
      },
      {
        "Data Fields": "parameter.part.targetProfile",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "Used when the type is \"Reference\" or \"canonical\", and identifies a profile structure or implementation Guide that applies to the target of the reference this parameter refers to. If any profiles are specified, then the content must conform to at least one of them. The URL can be a local reference - to a contained StructureDefinition, or a reference to another StructureDefinition or Implementation Guide by a canonical URL. When an implementation guide is specified, the target resource SHALL conform to at least one profile defined in the implementation guide."
      },
      {
        "Data Fields": "parameter.part.searchType",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "How the parameter is understood as a search parameter. This is only used if the parameter type is 'string'."
      },
      {
        "Data Fields": "parameter.part.binding",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Binds to a value set if this parameter is coded (code, Coding, CodeableConcept)."
      },
      {
        "Data Fields": "parameter.part.binding.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "parameter.part.binding.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "parameter.part.binding.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "parameter.part.binding.strength",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Indicates the degree of conformance expectations associated with this binding - that is, the degree to which the provided value set must be adhered to in the instances."
      },
      {
        "Data Fields": "parameter.part.binding.valueSet",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "Points to the value set or external definition (e.g. implicit value set) that identifies the set of codes to be used."
      },
      {
        "Data Fields": "parameter.part.referencedFrom",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Identifies other resource parameters within the operation invocation that are expected to resolve to this resource."
      },
      {
        "Data Fields": "parameter.part.referencedFrom.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "parameter.part.referencedFrom.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "parameter.part.referencedFrom.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "parameter.part.referencedFrom.source",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the parameter or dot-separated path of parameter names pointing to the resource parameter that is expected to contain a reference to this resource."
      },
      {
        "Data Fields": "parameter.part.referencedFrom.sourceId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The id of the element in the referencing resource that is expected to resolve to this resource."
      },
      {
        "Data Fields": "overload",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Defines an appropriate combination of parameters to use when invoking this operation, to help code generators when generating overloaded parameter sets for this operation."
      },
      {
        "Data Fields": "overload.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "overload.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "overload.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "overload.parameterName",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Name of parameter to include in overload."
      },
      {
        "Data Fields": "overload.comment",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Comments to go on overload."
      }
    ],
    "OperationOutcome": [
      {
        "Data Fields": "resourceType",
        "Type": "OperationOutcome",
        
        
        "Required": "True",
        "Description": "This is a OperationOutcome resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "issue",
        "Type": "BackboneElement",
        
        
        "Required": "True",
        "Description": "An error, warning, or information message that results from a system action."
      },
      {
        "Data Fields": "issue.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "issue.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "issue.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "issue.severity",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Indicates whether the issue indicates a variation from successful processing."
      },
      {
        "Data Fields": "issue.code",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Describes the type of the issue. The system that creates an OperationOutcome SHALL choose the most applicable code from the IssueType value set, and may additional provide its own code for the error in the details element."
      },
      {
        "Data Fields": "issue.details",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Additional details about the error. This may be a text description of the error or a system code that identifies the error."
      },
      {
        "Data Fields": "issue.diagnostics",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Additional diagnostic information about the issue."
      },
      {
        "Data Fields": "issue.location",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "This element is deprecated because it is XML specific. It is replaced by issue.expression, which is format independent, and simpler to parse.   For resource issues, this will be a simple XPath limited to element names, repetition indicators and the default child accessor that identifies one of the elements in the resource that caused this issue to be raised.  For HTTP errors, will be \"http.\" + the parameter name."
      },
      {
        "Data Fields": "issue.expression",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A [simple subset of FHIRPath](fhirpath.html#simple) limited to element names, repetition indicators and the default child accessor that identifies one of the elements in the resource that caused this issue to be raised."
      }
    ],
    "Organization": [
      {
        "Data Fields": "resourceType",
        "Type": "Organization",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "This is a Organization resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Identifier for the organization that is used to identify the organization across multiple disparate systems."
      },
      {
        "Data Fields": "active",
        "Type": "boolean",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Whether the organization's record is still in active use."
      },
      {
        "Data Fields": "type",
        "Type": "CodeableConcept",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The kind(s) of organization that this is."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A name associated with the organization."
      },
      {
        "Data Fields": "alias",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A list of alternate names that the organization is known as, or was known as in the past."
      },
      {
        "Data Fields": "telecom",
        "Type": "ContactPoint",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A contact detail for the organization."
      },
      {
        "Data Fields": "address",
        "Type": "Address",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "An address for the organization."
      },
      {
        "Data Fields": "partOf",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The organization of which this organization forms a part."
      },
      {
        "Data Fields": "contact",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Contact for the organization for a certain purpose."
      },
      {
        "Data Fields": "contact.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "contact.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "contact.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "contact.purpose",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Indicates a purpose for which the contact can be reached."
      },
      {
        "Data Fields": "contact.name",
        "Type": "HumanName",
        
        
        "Required": "False",
        "Description": "A name associated with the contact."
      },
      {
        "Data Fields": "contact.telecom",
        "Type": "ContactPoint",
        
        
        "Required": "False",
        "Description": "A contact detail (e.g. a telephone number or an email address) by which the party may be contacted."
      },
      {
        "Data Fields": "contact.address",
        "Type": "Address",
        
        
        "Required": "False",
        "Description": "Visiting or postal addresses for the contact."
      },
      {
        "Data Fields": "endpoint",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Technical endpoints providing access to services operated for the organization."
      }
    ],
    "OrganizationAffiliation": [
      {
        "Data Fields": "resourceType",
        "Type": "OrganizationAffiliation",
        
        
        "Required": "True",
        "Description": "This is a OrganizationAffiliation resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Business identifiers that are specific to this role."
      },
      {
        "Data Fields": "active",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Whether this organization affiliation record is in active use."
      },
      {
        "Data Fields": "period",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The period during which the participatingOrganization is affiliated with the primary organization."
      },
      {
        "Data Fields": "organization",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Organization where the role is available (primary organization/has members)."
      },
      {
        "Data Fields": "participatingOrganization",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The Participating Organization provides/performs the role(s) defined by the code to the Primary Organization (e.g. providing services or is a member of)."
      },
      {
        "Data Fields": "network",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Health insurance provider network in which the participatingOrganization provides the role's services (if defined) at the indicated locations (if defined)."
      },
      {
        "Data Fields": "code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Definition of the role the participatingOrganization plays in the association."
      },
      {
        "Data Fields": "specialty",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Specific specialty of the participatingOrganization in the context of the role."
      },
      {
        "Data Fields": "location",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The location(s) at which the role occurs."
      },
      {
        "Data Fields": "healthcareService",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Healthcare services provided through the role."
      },
      {
        "Data Fields": "telecom",
        "Type": "ContactPoint",
        
        
        "Required": "False",
        "Description": "Contact details at the participatingOrganization relevant to this Affiliation."
      },
      {
        "Data Fields": "endpoint",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Technical endpoints providing access to services operated for this role."
      }
    ],
    "Parameters": [
      {
        "Data Fields": "resourceType",
        "Type": "Parameters",
        
        
        "Required": "True",
        "Description": "This is a Parameters resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "parameter",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A parameter passed to or received from the operation."
      },
      {
        "Data Fields": "parameter.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "parameter.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "parameter.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "parameter.name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the parameter (reference to the operation definition)."
      },
      {
        "Data Fields": "parameter.valueBase64Binary",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueBoolean",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueCanonical",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueCode",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueDate",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueDecimal",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueInstant",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueInteger",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueMarkdown",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueOid",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valuePositiveInt",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueUnsignedInt",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueUri",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueUrl",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueUuid",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueAddress",
        "Type": "Address",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueAge",
        "Type": "Age",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueAnnotation",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueAttachment",
        "Type": "Attachment",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueCoding",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueContactPoint",
        "Type": "ContactPoint",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueCount",
        "Type": "Count",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueDistance",
        "Type": "Distance",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueDuration",
        "Type": "Duration",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueHumanName",
        "Type": "HumanName",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueIdentifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueMoney",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valuePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueQuantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueRange",
        "Type": "Range",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueRatio",
        "Type": "Ratio",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueSampledData",
        "Type": "SampledData",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueSignature",
        "Type": "Signature",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueTiming",
        "Type": "Timing",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueContactDetail",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueContributor",
        "Type": "Contributor",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueDataRequirement",
        "Type": "DataRequirement",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueExpression",
        "Type": "Expression",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueParameterDefinition",
        "Type": "ParameterDefinition",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueRelatedArtifact",
        "Type": "RelatedArtifact",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueTriggerDefinition",
        "Type": "TriggerDefinition",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueUsageContext",
        "Type": "UsageContext",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueDosage",
        "Type": "Dosage",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.valueMeta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.resource",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "If the parameter is a whole resource."
      },
      {
        "Data Fields": "parameter.part",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A named part of a multi-part parameter."
      },
      {
        "Data Fields": "parameter.part.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "parameter.part.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "parameter.part.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "parameter.part.name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the parameter (reference to the operation definition)."
      },
      {
        "Data Fields": "parameter.part.valueBase64Binary",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueBoolean",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueCanonical",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueCode",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueDate",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueDecimal",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueInstant",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueInteger",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueMarkdown",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueOid",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valuePositiveInt",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueUnsignedInt",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueUri",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueUrl",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueUuid",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueAddress",
        "Type": "Address",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueAge",
        "Type": "Age",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueAnnotation",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueAttachment",
        "Type": "Attachment",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueCoding",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueContactPoint",
        "Type": "ContactPoint",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueCount",
        "Type": "Count",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueDistance",
        "Type": "Distance",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueDuration",
        "Type": "Duration",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueHumanName",
        "Type": "HumanName",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueIdentifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueMoney",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valuePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueQuantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueRange",
        "Type": "Range",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueRatio",
        "Type": "Ratio",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueSampledData",
        "Type": "SampledData",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueSignature",
        "Type": "Signature",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueTiming",
        "Type": "Timing",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueContactDetail",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueContributor",
        "Type": "Contributor",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueDataRequirement",
        "Type": "DataRequirement",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueExpression",
        "Type": "Expression",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueParameterDefinition",
        "Type": "ParameterDefinition",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueRelatedArtifact",
        "Type": "RelatedArtifact",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueTriggerDefinition",
        "Type": "TriggerDefinition",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueUsageContext",
        "Type": "UsageContext",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueDosage",
        "Type": "Dosage",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.valueMeta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "If the parameter is a data type."
      },
      {
        "Data Fields": "parameter.part.resource",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "If the parameter is a whole resource."
      }
    ],
    "Patient": [
      {
        "Data Fields": "resourceType",
        "Type": "Patient",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "This is a Patient resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "An identifier for this patient."
      },
      {
        "Data Fields": "active",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Whether this patient record is in active use.  Many systems use this property to mark as non-current patients, such as those that have not been seen for a period of time based on an organization's business rules.  It is often used to filter patient lists to exclude inactive patients  Deceased patients may also be marked as inactive for the same reasons, but may be active for some time after death."
      },
      {
        "Data Fields": "name",
        "Type": "HumanName",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A name associated with the individual."
      },
      {
        "Data Fields": "telecom",
        "Type": "ContactPoint",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A contact detail (e.g. a telephone number or an email address) by which the individual may be contacted."
      },
      {
        "Data Fields": "gender",
        "Type": "code",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Administrative Gender - the gender that the patient is considered to have for administration and record keeping purposes."
      },
      {
        "Data Fields": "birthDate",
        "Type": "date",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The date of birth for the individual."
      },
      {
        "Data Fields": "deceasedBoolean",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Indicates if the individual is deceased or not."
      },
      {
        "Data Fields": "deceasedDateTime",
        "Type": "string",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Indicates if the individual is deceased or not."
      },
      {
        "Data Fields": "address",
        "Type": "Address",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "An address for the individual."
      },
      {
        "Data Fields": "maritalStatus",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "This field contains a patient's most recent marital (civil) status."
      },
      {
        "Data Fields": "multipleBirthBoolean",
        "Type": "boolean",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Indicates whether the patient is part of a multiple (boolean) or indicates the actual birth order (integer)."
      },
      {
        "Data Fields": "multipleBirthInteger",
        "Type": "number",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Indicates whether the patient is part of a multiple (boolean) or indicates the actual birth order (integer)."
      },
      {
        "Data Fields": "photo",
        "Type": "Attachment",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Image of the patient."
      },
      {
        "Data Fields": "contact",
        "Type": "BackboneElement",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A contact party (e.g. guardian, partner, friend) for the patient."
      },
      {
        "Data Fields": "contact.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "contact.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "contact.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "contact.relationship",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The nature of the relationship between the patient and the contact person."
      },
      {
        "Data Fields": "contact.name",
        "Type": "HumanName",
        
        
        "Required": "False",
        "Description": "A name associated with the contact person."
      },
      {
        "Data Fields": "contact.telecom",
        "Type": "ContactPoint",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A contact detail for the person, e.g. a telephone number or an email address."
      },
      {
        "Data Fields": "contact.address",
        "Type": "Address",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Address for the contact person."
      },
      {
        "Data Fields": "contact.gender",
        "Type": "code",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Administrative Gender - the gender that the contact person is considered to have for administration and record keeping purposes."
      },
      {
        "Data Fields": "contact.organization",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Organization on behalf of which the contact is acting or for which the contact is working."
      },
      {
        "Data Fields": "contact.period",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The period during which this contact person or organization is valid to be contacted relating to this patient."
      },
      {
        "Data Fields": "communication",
        "Type": "BackboneElement",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A language which may be used to communicate with the patient about his or her health."
      },
      {
        "Data Fields": "communication.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "communication.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "communication.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "communication.language",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The ISO-639-1 alpha 2 code in lower case for the language, optionally followed by a hyphen and the ISO-3166-1 alpha 2 code for the region in upper case; e.g. \"en\" for English, or \"en-US\" for American English versus \"en-EN\" for England English."
      },
      {
        "Data Fields": "communication.preferred",
        "Type": "boolean",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Indicates whether or not the patient prefers this language (over other languages he masters up a certain level)."
      },
      {
        "Data Fields": "generalPractitioner",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Patient's nominated care provider."
      },
      {
        "Data Fields": "managingOrganization",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Organization that is the custodian of the patient record."
      },
      {
        "Data Fields": "link",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Link to another patient resource that concerns the same actual patient."
      },
      {
        "Data Fields": "link.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "link.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "link.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "link.other",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The other patient resource that the link refers to."
      },
      {
        "Data Fields": "link.type",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The type of link between this patient resource and another patient resource."
      }
    ],
    "PaymentNotice": [
      {
        "Data Fields": "resourceType",
        "Type": "PaymentNotice",
        
        
        "Required": "True",
        "Description": "This is a PaymentNotice resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A unique identifier assigned to this payment notice."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The status of the resource instance."
      },
      {
        "Data Fields": "request",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Reference of resource for which payment is being made."
      },
      {
        "Data Fields": "response",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Reference of response to resource for which payment is being made."
      },
      {
        "Data Fields": "created",
        "Type": "dateTime",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The date when this resource was created."
      },
      {
        "Data Fields": "provider",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The practitioner who is responsible for the services rendered to the patient."
      },
      {
        "Data Fields": "payment",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "A reference to the payment which is the subject of this notice."
      },
      {
        "Data Fields": "paymentDate",
        "Type": "date",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The date when the above payment action occurred."
      },
      {
        "Data Fields": "payee",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The party who will receive or has received payment that is the subject of this notification."
      },
      {
        "Data Fields": "recipient",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "True",
        "Description": "The party who is notified of the payment status."
      },
      {
        "Data Fields": "amount",
        "Type": "Money",
        "Fold Availability": "True",
        
        "Required": "True",
        "Description": "The amount sent to the payee."
      },
      {
        "Data Fields": "paymentStatus",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A code indicating whether payment has been sent or cleared."
      }
    ],
    "PaymentReconciliation": [
      {
        "Data Fields": "resourceType",
        "Type": "PaymentReconciliation",
        
        
        "Required": "True",
        "Description": "This is a PaymentReconciliation resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A unique identifier assigned to this payment reconciliation."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The status of the resource instance."
      },
      {
        "Data Fields": "period",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The period of time for which payments have been gathered into this bulk payment for settlement."
      },
      {
        "Data Fields": "created",
        "Type": "dateTime",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The date when the resource was created."
      },
      {
        "Data Fields": "paymentIssuer",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The party who generated the payment."
      },
      {
        "Data Fields": "request",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Original request resource reference."
      },
      {
        "Data Fields": "requestor",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The practitioner who is responsible for the services rendered to the patient."
      },
      {
        "Data Fields": "outcome",
        "Type": "code",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The outcome of a request for a reconciliation."
      },
      {
        "Data Fields": "disposition",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A human readable description of the status of the request for the reconciliation."
      },
      {
        "Data Fields": "paymentDate",
        "Type": "date",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The date of payment as indicated on the financial instrument."
      },
      {
        "Data Fields": "paymentAmount",
        "Type": "Money",
        "Fold Availability": "True",
        
        "Required": "True",
        "Description": "Total payment amount as indicated on the financial instrument."
      },
      {
        "Data Fields": "paymentIdentifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Issuer's unique identifier for the payment instrument."
      },
      {
        "Data Fields": "detail",
        "Type": "BackboneElement",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Distribution of the payment amount for a previously acknowledged payable."
      },
      {
        "Data Fields": "detail.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "detail.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "detail.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "detail.identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Unique identifier for the current payment item for the referenced payable."
      },
      {
        "Data Fields": "detail.predecessor",
        "Type": "Identifier",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Unique identifier for the prior payment item for the referenced payable."
      },
      {
        "Data Fields": "detail.type",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Code to indicate the nature of the payment."
      },
      {
        "Data Fields": "detail.request",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A resource, such as a Claim, the evaluation of which could lead to payment."
      },
      {
        "Data Fields": "detail.submitter",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The party which submitted the claim or financial transaction."
      },
      {
        "Data Fields": "detail.response",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A resource, such as a ClaimResponse, which contains a commitment to payment."
      },
      {
        "Data Fields": "detail.date",
        "Type": "date",
        
        
        "Required": "False",
        "Description": "The date from the response resource containing a commitment to pay."
      },
      {
        "Data Fields": "detail.responsible",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A reference to the individual who is responsible for inquiries regarding the response and its payment."
      },
      {
        "Data Fields": "detail.payee",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The party which is receiving the payment."
      },
      {
        "Data Fields": "detail.amount",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "The monetary amount allocated from the total payment to the payable."
      },
      {
        "Data Fields": "formCode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code for the form to be used for printing the content."
      },
      {
        "Data Fields": "processNote",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A note that describes or explains the processing in a human readable form."
      },
      {
        "Data Fields": "processNote.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "processNote.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "processNote.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "processNote.type",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The business purpose of the note text."
      },
      {
        "Data Fields": "processNote.text",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The explanation or description associated with the processing."
      }
    ],
    "Person": [
      {
        "Data Fields": "resourceType",
        "Type": "Person",
        
        
        "Required": "True",
        "Description": "This is a Person resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Identifier for a person within a particular scope."
      },
      {
        "Data Fields": "name",
        "Type": "HumanName",
        
        
        "Required": "False",
        "Description": "A name associated with the person."
      },
      {
        "Data Fields": "telecom",
        "Type": "ContactPoint",
        
        
        "Required": "False",
        "Description": "A contact detail for the person, e.g. a telephone number or an email address."
      },
      {
        "Data Fields": "gender",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Administrative Gender."
      },
      {
        "Data Fields": "birthDate",
        "Type": "date",
        
        
        "Required": "False",
        "Description": "The birth date for the person."
      },
      {
        "Data Fields": "address",
        "Type": "Address",
        
        
        "Required": "False",
        "Description": "One or more addresses for the person."
      },
      {
        "Data Fields": "photo",
        "Type": "Attachment",
        
        
        "Required": "False",
        "Description": "An image that can be displayed as a thumbnail of the person to enhance the identification of the individual."
      },
      {
        "Data Fields": "managingOrganization",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The organization that is the custodian of the person record."
      },
      {
        "Data Fields": "active",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Whether this person's record is in active use."
      },
      {
        "Data Fields": "link",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Link to a resource that concerns the same actual person."
      },
      {
        "Data Fields": "link.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "link.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "link.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "link.target",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The resource to which this actual person is associated."
      },
      {
        "Data Fields": "link.assurance",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Level of assurance that this link is associated with the target resource."
      }
    ],
    "PlanDefinition": [
      {
        "Data Fields": "resourceType",
        "Type": "PlanDefinition",
        
        
        "Required": "True",
        "Description": "This is a PlanDefinition resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "url",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "An absolute URI that is used to identify this plan definition when it is referenced in a specification, model, design or an instance; also called its canonical identifier. This SHOULD be globally unique and SHOULD be a literal address at which at which an authoritative instance of this plan definition is (or will be) published. This URL can be the target of a canonical reference. It SHALL remain the same when the plan definition is stored on different servers."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A formal identifier that is used to identify this plan definition when it is represented in other formats, or referenced in a specification, model, design or an instance."
      },
      {
        "Data Fields": "version",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The identifier that is used to identify this version of the plan definition when it is referenced in a specification, model, design or instance. This is an arbitrary value managed by the plan definition author and is not expected to be globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if a managed version is not available. There is also no expectation that versions can be placed in a lexicographical sequence. To provide a version consistent with the Decision Support Service specification, use the format Major.Minor.Revision (e.g. 1.0.0). For more information on versioning knowledge assets, refer to the Decision Support Service specification. Note that a version is required for non-experimental active artifacts."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A natural language name identifying the plan definition. This name should be usable as an identifier for the module by machine processing applications such as code generation."
      },
      {
        "Data Fields": "title",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A short, descriptive, user-friendly title for the plan definition."
      },
      {
        "Data Fields": "subtitle",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "An explanatory or alternate title for the plan definition giving additional information about its content."
      },
      {
        "Data Fields": "type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A high-level category for the plan definition that distinguishes the kinds of systems that would be interested in the plan definition."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The status of this plan definition. Enables tracking the life-cycle of the content."
      },
      {
        "Data Fields": "experimental",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "A Boolean value to indicate that this plan definition is authored for testing purposes (or education/evaluation/marketing) and is not intended to be used for genuine usage."
      },
      {
        "Data Fields": "subjectCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code or group definition that describes the intended subject of the plan definition."
      },
      {
        "Data Fields": "subjectReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A code or group definition that describes the intended subject of the plan definition."
      },
      {
        "Data Fields": "date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date  (and optionally time) when the plan definition was published. The date must change when the business version changes and it must change if the status code changes. In addition, it should change when the substantive content of the plan definition changes."
      },
      {
        "Data Fields": "publisher",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the organization or individual that published the plan definition."
      },
      {
        "Data Fields": "contact",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "Contact details to assist a user in finding and communicating with the publisher."
      },
      {
        "Data Fields": "description",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A free text natural language description of the plan definition from a consumer's perspective."
      },
      {
        "Data Fields": "useContext",
        "Type": "UsageContext",
        
        
        "Required": "False",
        "Description": "The content was developed with a focus and intent of supporting the contexts that are listed. These contexts may be general categories (gender, age, ...) or may be references to specific programs (insurance plans, studies, ...) and may be used to assist with indexing and searching for appropriate plan definition instances."
      },
      {
        "Data Fields": "jurisdiction",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A legal or geographic region in which the plan definition is intended to be used."
      },
      {
        "Data Fields": "purpose",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Explanation of why this plan definition is needed and why it has been designed as it has."
      },
      {
        "Data Fields": "usage",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A detailed description of how the plan definition is used from a clinical perspective."
      },
      {
        "Data Fields": "copyright",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A copyright statement relating to the plan definition and/or its contents. Copyright statements are generally legal restrictions on the use and publishing of the plan definition."
      },
      {
        "Data Fields": "approvalDate",
        "Type": "date",
        
        
        "Required": "False",
        "Description": "The date on which the resource content was approved by the publisher. Approval happens once when the content is officially approved for usage."
      },
      {
        "Data Fields": "lastReviewDate",
        "Type": "date",
        
        
        "Required": "False",
        "Description": "The date on which the resource content was last reviewed. Review happens periodically after approval but does not change the original approval date."
      },
      {
        "Data Fields": "effectivePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The period during which the plan definition content was or is planned to be in active use."
      },
      {
        "Data Fields": "topic",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Descriptive topics related to the content of the plan definition. Topics provide a high-level categorization of the definition that can be useful for filtering and searching."
      },
      {
        "Data Fields": "author",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individiual or organization primarily involved in the creation and maintenance of the content."
      },
      {
        "Data Fields": "editor",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individual or organization primarily responsible for internal coherence of the content."
      },
      {
        "Data Fields": "reviewer",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individual or organization primarily responsible for review of some aspect of the content."
      },
      {
        "Data Fields": "endorser",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individual or organization responsible for officially endorsing the content for use in some setting."
      },
      {
        "Data Fields": "relatedArtifact",
        "Type": "RelatedArtifact",
        
        
        "Required": "False",
        "Description": "Related artifacts such as additional documentation, justification, or bibliographic references."
      },
      {
        "Data Fields": "library",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "A reference to a Library resource containing any formal logic used by the plan definition."
      },
      {
        "Data Fields": "goal",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Goals that describe what the activities within the plan are intended to achieve. For example, weight loss, restoring an activity of daily living, obtaining herd immunity via immunization, meeting a process improvement objective, etc."
      },
      {
        "Data Fields": "goal.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "goal.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "goal.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "goal.category",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Indicates a category the goal falls within."
      },
      {
        "Data Fields": "goal.description",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Human-readable and/or coded description of a specific desired objective of care, such as \"control blood pressure\" or \"negotiate an obstacle course\" or \"dance with child at wedding\"."
      },
      {
        "Data Fields": "goal.priority",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Identifies the expected level of importance associated with reaching/sustaining the defined goal."
      },
      {
        "Data Fields": "goal.start",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The event after which the goal should begin being pursued."
      },
      {
        "Data Fields": "goal.addresses",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Identifies problems, conditions, issues, or concerns the goal is intended to address."
      },
      {
        "Data Fields": "goal.documentation",
        "Type": "RelatedArtifact",
        
        
        "Required": "False",
        "Description": "Didactic or other informational resources associated with the goal that provide further supporting information about the goal. Information resources can include inline text commentary and links to web resources."
      },
      {
        "Data Fields": "goal.target",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Indicates what should be done and within what timeframe."
      },
      {
        "Data Fields": "goal.target.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "goal.target.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "goal.target.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "goal.target.measure",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The parameter whose value is to be tracked, e.g. body weight, blood pressure, or hemoglobin A1c level."
      },
      {
        "Data Fields": "goal.target.detailQuantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The target value of the measure to be achieved to signify fulfillment of the goal, e.g. 150 pounds or 7.0%. Either the high or low or both values of the range can be specified. When a low value is missing, it indicates that the goal is achieved at any value at or below the high value. Similarly, if the high value is missing, it indicates that the goal is achieved at any value at or above the low value."
      },
      {
        "Data Fields": "goal.target.detailRange",
        "Type": "Range",
        
        
        "Required": "False",
        "Description": "The target value of the measure to be achieved to signify fulfillment of the goal, e.g. 150 pounds or 7.0%. Either the high or low or both values of the range can be specified. When a low value is missing, it indicates that the goal is achieved at any value at or below the high value. Similarly, if the high value is missing, it indicates that the goal is achieved at any value at or above the low value."
      },
      {
        "Data Fields": "goal.target.detailCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The target value of the measure to be achieved to signify fulfillment of the goal, e.g. 150 pounds or 7.0%. Either the high or low or both values of the range can be specified. When a low value is missing, it indicates that the goal is achieved at any value at or below the high value. Similarly, if the high value is missing, it indicates that the goal is achieved at any value at or above the low value."
      },
      {
        "Data Fields": "goal.target.due",
        "Type": "Duration",
        
        
        "Required": "False",
        "Description": "Indicates the timeframe after the start of the goal in which the goal should be met."
      },
      {
        "Data Fields": "action",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "An action or group of actions to be taken as part of the plan."
      },
      {
        "Data Fields": "action.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "action.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "action.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "action.prefix",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A user-visible prefix for the action."
      },
      {
        "Data Fields": "action.title",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The title of the action displayed to a user."
      },
      {
        "Data Fields": "action.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A brief description of the action used to provide a summary to display to the user."
      },
      {
        "Data Fields": "action.textEquivalent",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A text equivalent of the action to be performed. This provides a human-interpretable description of the action when the definition is consumed by a system that might not be capable of interpreting it dynamically."
      },
      {
        "Data Fields": "action.priority",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Indicates how quickly the action should be addressed with respect to other actions."
      },
      {
        "Data Fields": "action.code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code that provides meaning for the action or action group. For example, a section may have a LOINC code for the section of a documentation template."
      },
      {
        "Data Fields": "action.reason",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A description of why this action is necessary or appropriate."
      },
      {
        "Data Fields": "action.documentation",
        "Type": "RelatedArtifact",
        
        
        "Required": "False",
        "Description": "Didactic or other informational resources associated with the action that can be provided to the CDS recipient. Information resources can include inline text commentary and links to web resources."
      },
      {
        "Data Fields": "action.goalId",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "Identifies goals that this action supports. The reference must be to a goal element defined within this plan definition."
      },
      {
        "Data Fields": "action.subjectCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code or group definition that describes the intended subject of the action and its children, if any."
      },
      {
        "Data Fields": "action.subjectReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A code or group definition that describes the intended subject of the action and its children, if any."
      },
      {
        "Data Fields": "action.trigger",
        "Type": "TriggerDefinition",
        
        
        "Required": "False",
        "Description": "A description of when the action should be triggered."
      },
      {
        "Data Fields": "action.condition",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "An expression that describes applicability criteria or start/stop conditions for the action."
      },
      {
        "Data Fields": "action.condition.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "action.condition.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "action.condition.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "action.condition.kind",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The kind of condition."
      },
      {
        "Data Fields": "action.condition.expression",
        "Type": "Expression",
        
        
        "Required": "False",
        "Description": "An expression that returns true or false, indicating whether the condition is satisfied."
      },
      {
        "Data Fields": "action.input",
        "Type": "DataRequirement",
        
        
        "Required": "False",
        "Description": "Defines input data requirements for the action."
      },
      {
        "Data Fields": "action.output",
        "Type": "DataRequirement",
        
        
        "Required": "False",
        "Description": "Defines the outputs of the action, if any."
      },
      {
        "Data Fields": "action.relatedAction",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A relationship to another action such as \"before\" or \"30-60 minutes after start of\"."
      },
      {
        "Data Fields": "action.relatedAction.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "action.relatedAction.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "action.relatedAction.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "action.relatedAction.actionId",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The element id of the related action."
      },
      {
        "Data Fields": "action.relatedAction.relationship",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The relationship of this action to the related action."
      },
      {
        "Data Fields": "action.relatedAction.offsetDuration",
        "Type": "Duration",
        
        
        "Required": "False",
        "Description": "A duration or range of durations to apply to the relationship. For example, 30-60 minutes before."
      },
      {
        "Data Fields": "action.relatedAction.offsetRange",
        "Type": "Range",
        
        
        "Required": "False",
        "Description": "A duration or range of durations to apply to the relationship. For example, 30-60 minutes before."
      },
      {
        "Data Fields": "action.timingDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "An optional value describing when the action should be performed."
      },
      {
        "Data Fields": "action.timingAge",
        "Type": "Age",
        
        
        "Required": "False",
        "Description": "An optional value describing when the action should be performed."
      },
      {
        "Data Fields": "action.timingPeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "An optional value describing when the action should be performed."
      },
      {
        "Data Fields": "action.timingDuration",
        "Type": "Duration",
        
        
        "Required": "False",
        "Description": "An optional value describing when the action should be performed."
      },
      {
        "Data Fields": "action.timingRange",
        "Type": "Range",
        
        
        "Required": "False",
        "Description": "An optional value describing when the action should be performed."
      },
      {
        "Data Fields": "action.timingTiming",
        "Type": "Timing",
        
        
        "Required": "False",
        "Description": "An optional value describing when the action should be performed."
      },
      {
        "Data Fields": "action.participant",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Indicates who should participate in performing the action described."
      },
      {
        "Data Fields": "action.participant.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "action.participant.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "action.participant.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "action.participant.type",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The type of participant in the action."
      },
      {
        "Data Fields": "action.participant.role",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The role the participant should play in performing the described action."
      },
      {
        "Data Fields": "action.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The type of action to perform (create, update, remove)."
      },
      {
        "Data Fields": "action.groupingBehavior",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Defines the grouping behavior for the action and its children."
      },
      {
        "Data Fields": "action.selectionBehavior",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Defines the selection behavior for the action and its children."
      },
      {
        "Data Fields": "action.requiredBehavior",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Defines the required behavior for the action."
      },
      {
        "Data Fields": "action.precheckBehavior",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Defines whether the action should usually be preselected."
      },
      {
        "Data Fields": "action.cardinalityBehavior",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Defines whether the action can be selected multiple times."
      },
      {
        "Data Fields": "action.definitionCanonical",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A reference to an ActivityDefinition that describes the action to be taken in detail, or a PlanDefinition that describes a series of actions to be taken."
      },
      {
        "Data Fields": "action.definitionUri",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A reference to an ActivityDefinition that describes the action to be taken in detail, or a PlanDefinition that describes a series of actions to be taken."
      },
      {
        "Data Fields": "action.transform",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "A reference to a StructureMap resource that defines a transform that can be executed to produce the intent resource using the ActivityDefinition instance as the input."
      },
      {
        "Data Fields": "action.dynamicValue",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Customizations that should be applied to the statically defined resource. For example, if the dosage of a medication must be computed based on the patient's weight, a customization would be used to specify an expression that calculated the weight, and the path on the resource that would contain the result."
      },
      {
        "Data Fields": "action.dynamicValue.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "action.dynamicValue.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "action.dynamicValue.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "action.dynamicValue.path",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The path to the element to be customized. This is the path on the resource that will hold the result of the calculation defined by the expression. The specified path SHALL be a FHIRPath resolveable on the specified target type of the ActivityDefinition, and SHALL consist only of identifiers, constant indexers, and a restricted subset of functions. The path is allowed to contain qualifiers (.) to traverse sub-elements, as well as indexers ([x]) to traverse multiple-cardinality sub-elements (see the [Simple FHIRPath Profile](fhirpath.html#simple) for full details)."
      },
      {
        "Data Fields": "action.dynamicValue.expression",
        "Type": "Expression",
        
        
        "Required": "False",
        "Description": "An expression specifying the value of the customized element."
      }
    ],
    "Practitioner": [
      {
        "Data Fields": "resourceType",
        "Type": "Practitioner",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "This is a Practitioner resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "An identifier that applies to this person in this role."
      },
      {
        "Data Fields": "active",
        "Type": "boolean",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Whether this practitioner's record is in active use."
      },
      {
        "Data Fields": "name",
        "Type": "HumanName",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The name(s) associated with the practitioner."
      },
      {
        "Data Fields": "telecom",
        "Type": "ContactPoint",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A contact detail for the practitioner, e.g. a telephone number or an email address."
      },
      {
        "Data Fields": "address",
        "Type": "Address",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Address(es) of the practitioner that are not role specific (typically home address). \rWork addresses are not typically entered in this property as they are usually role dependent."
      },
      {
        "Data Fields": "gender",
        "Type": "code",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Administrative Gender - the gender that the person is considered to have for administration and record keeping purposes."
      },
      {
        "Data Fields": "birthDate",
        "Type": "date",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The date of birth for the practitioner."
      },
      {
        "Data Fields": "photo",
        "Type": "Attachment",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Image of the person."
      },
      {
        "Data Fields": "qualification",
        "Type": "BackboneElement",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The official certifications, training, and licenses that authorize or otherwise pertain to the provision of care by the practitioner.  For example, a medical license issued by a medical board authorizing the practitioner to practice medicine within a certian locality."
      },
      {
        "Data Fields": "qualification.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "qualification.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "qualification.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "qualification.identifier",
        "Type": "Identifier",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "An identifier that applies to this person's qualification in this role."
      },
      {
        "Data Fields": "qualification.code",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Coded representation of the qualification."
      },
      {
        "Data Fields": "qualification.period",
        "Type": "Period",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Period during which the qualification is valid."
      },
      {
        "Data Fields": "qualification.issuer",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Organization that regulates and issues the qualification."
      },
      {
        "Data Fields": "communication",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A language the practitioner can use in patient communication."
      }
    ],
    "PractitionerRole": [
      {
        "Data Fields": "resourceType",
        "Type": "PractitionerRole",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "This is a PractitionerRole resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Business Identifiers that are specific to a role/location."
      },
      {
        "Data Fields": "active",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Whether this practitioner role record is in active use."
      },
      {
        "Data Fields": "period",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The period during which the person is authorized to act as a practitioner in these role(s) for the organization."
      },
      {
        "Data Fields": "practitioner",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Practitioner that is able to provide the defined services for the organization."
      },
      {
        "Data Fields": "organization",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The organization where the Practitioner performs the roles associated."
      },
      {
        "Data Fields": "code",
        "Type": "CodeableConcept",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Roles which this practitioner is authorized to perform for the organization."
      },
      {
        "Data Fields": "specialty",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Specific specialty of the practitioner."
      },
      {
        "Data Fields": "location",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The location(s) at which this practitioner provides care."
      },
      {
        "Data Fields": "healthcareService",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The list of healthcare services that this worker provides for this role's Organization/Location(s)."
      },
      {
        "Data Fields": "telecom",
        "Type": "ContactPoint",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Contact details that are specific to the role/location/service."
      },
      {
        "Data Fields": "availableTime",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A collection of times the practitioner is available or performing this role at the location and/or healthcareservice."
      },
      {
        "Data Fields": "availableTime.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "availableTime.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "availableTime.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "availableTime.daysOfWeek",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Indicates which days of the week are available between the start and end Times."
      },
      {
        "Data Fields": "availableTime.allDay",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Is this always available? (hence times are irrelevant) e.g. 24 hour service."
      },
      {
        "Data Fields": "availableTime.availableStartTime",
        "Type": "time",
        
        
        "Required": "False",
        "Description": "The opening time of day. Note: If the AllDay flag is set, then this time is ignored."
      },
      {
        "Data Fields": "availableTime.availableEndTime",
        "Type": "time",
        
        
        "Required": "False",
        "Description": "The closing time of day. Note: If the AllDay flag is set, then this time is ignored."
      },
      {
        "Data Fields": "notAvailable",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The practitioner is not available or performing this role during this period of time due to the provided reason."
      },
      {
        "Data Fields": "notAvailable.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "notAvailable.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "notAvailable.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "notAvailable.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The reason that can be presented to the user as to why this time is not available."
      },
      {
        "Data Fields": "notAvailable.during",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "Service is not available (seasonally or for a public holiday) from this date."
      },
      {
        "Data Fields": "availabilityExceptions",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A description of site availability exceptions, e.g. public holiday availability. Succinctly describing all possible exceptions to normal site availability as details in the available Times and not available Times."
      },
      {
        "Data Fields": "endpoint",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Technical endpoints providing access to services operated for the practitioner with this role."
      }
    ],
    "Procedure": [
      {
        "Data Fields": "resourceType",
        "Type": "Procedure",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "This is a Procedure resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Business identifiers assigned to this procedure by the performer or other systems which remain constant as the resource is updated and is propagated from server to server."
      },
      {
        "Data Fields": "instantiatesCanonical",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "The URL pointing to a FHIR-defined protocol, guideline, order set or other definition that is adhered to in whole or in part by this Procedure."
      },
      {
        "Data Fields": "instantiatesUri",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "The URL pointing to an externally maintained protocol, guideline, order set or other definition that is adhered to in whole or in part by this Procedure."
      },
      {
        "Data Fields": "basedOn",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A reference to a resource that contains details of the request for this procedure."
      },
      {
        "Data Fields": "partOf",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A larger event of which this particular procedure is a component or step."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A code specifying the state of the procedure. Generally, this will be the in-progress or completed state."
      },
      {
        "Data Fields": "statusReason",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Captures the reason for the current state of the procedure."
      },
      {
        "Data Fields": "category",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A code that classifies the procedure for searching, sorting and display purposes (e.g. \"Surgical Procedure\")."
      },
      {
        "Data Fields": "code",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The specific procedure that is performed. Use text if the exact nature of the procedure cannot be coded (e.g. \"Laparoscopic Appendectomy\")."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "The person, animal or group on which the procedure was performed."
      },
      {
        "Data Fields": "encounter",
        "Type": "Reference",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The Encounter during which this Procedure was created or performed or to which the creation of this record is tightly associated."
      },
      {
        "Data Fields": "performedDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Estimated or actual date, date-time, period, or age when the procedure was performed.  Allows a period to support complex procedures that span more than one date, and also allows for the length of the procedure to be captured."
      },
      {
        "Data Fields": "performedPeriod",
        "Type": "Period",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Estimated or actual date, date-time, period, or age when the procedure was performed.  Allows a period to support complex procedures that span more than one date, and also allows for the length of the procedure to be captured."
      },
      {
        "Data Fields": "performedString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Estimated or actual date, date-time, period, or age when the procedure was performed.  Allows a period to support complex procedures that span more than one date, and also allows for the length of the procedure to be captured."
      },
      {
        "Data Fields": "performedAge",
        "Type": "Age",
        
        
        "Required": "False",
        "Description": "Estimated or actual date, date-time, period, or age when the procedure was performed.  Allows a period to support complex procedures that span more than one date, and also allows for the length of the procedure to be captured."
      },
      {
        "Data Fields": "performedRange",
        "Type": "Range",
        
        
        "Required": "False",
        "Description": "Estimated or actual date, date-time, period, or age when the procedure was performed.  Allows a period to support complex procedures that span more than one date, and also allows for the length of the procedure to be captured."
      },
      {
        "Data Fields": "recorder",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Individual who recorded the record and takes responsibility for its content."
      },
      {
        "Data Fields": "asserter",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Individual who is making the procedure statement."
      },
      {
        "Data Fields": "performer",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Limited to \"real\" people rather than equipment."
      },
      {
        "Data Fields": "performer.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "performer.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "performer.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "performer.function",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Distinguishes the type of involvement of the performer in the procedure. For example, surgeon, anaesthetist, endoscopist."
      },
      {
        "Data Fields": "performer.actor",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The practitioner who was involved in the procedure."
      },
      {
        "Data Fields": "performer.onBehalfOf",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The organization the device or practitioner was acting on behalf of."
      },
      {
        "Data Fields": "location",
        "Type": "Reference",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The location where the procedure actually happened.  E.g. a newborn at home, a tracheostomy at a restaurant."
      },
      {
        "Data Fields": "reasonCode",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The coded reason why the procedure was performed. This may be a coded entity of some type, or may simply be present as text."
      },
      {
        "Data Fields": "reasonReference",
        "Type": "Reference",
        "Fold Availability": "True",
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The justification of why the procedure was performed."
      },
      {
        "Data Fields": "bodySite",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Detailed and structured anatomical location information. Multiple locations are allowed - e.g. multiple punch biopsies of a lesion."
      },
      {
        "Data Fields": "outcome",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The outcome of the procedure - did it resolve the reasons for the procedure being performed?"
      },
      {
        "Data Fields": "report",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "This could be a histology result, pathology report, surgical report, etc."
      },
      {
        "Data Fields": "complication",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Any complications that occurred during the procedure, or in the immediate post-performance period. These are generally tracked separately from the notes, which will typically describe the procedure itself rather than any 'post procedure' issues."
      },
      {
        "Data Fields": "complicationDetail",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Any complications that occurred during the procedure, or in the immediate post-performance period."
      },
      {
        "Data Fields": "followUp",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "If the procedure required specific follow up - e.g. removal of sutures. The follow up may be represented as a simple note or could potentially be more complex, in which case the CarePlan resource can be used."
      },
      {
        "Data Fields": "note",
        "Type": "Annotation",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Any other notes and comments about the procedure."
      },
      {
        "Data Fields": "focalDevice",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A device that is implanted, removed or otherwise manipulated (calibration, battery replacement, fitting a prosthesis, attaching a wound-vac, etc.) as a focal portion of the Procedure."
      },
      {
        "Data Fields": "focalDevice.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "focalDevice.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "focalDevice.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "focalDevice.action",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The kind of change that happened to the device during the procedure."
      },
      {
        "Data Fields": "focalDevice.manipulated",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The device that was manipulated (changed) during the procedure."
      },
      {
        "Data Fields": "usedReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Identifies medications, devices and any other substance used as part of the procedure."
      },
      {
        "Data Fields": "usedCode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Identifies coded items that were used as part of the procedure."
      }
    ],
    "Provenance": [
      {
        "Data Fields": "resourceType",
        "Type": "Provenance",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "This is a Provenance resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "target",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "The Reference(s) that were generated or updated by  the activity described in this resource. A provenance can point to more than one target if multiple resources were created/updated by the same activity."
      },
      {
        "Data Fields": "occurredPeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The period during which the activity occurred."
      },
      {
        "Data Fields": "occurredDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The period during which the activity occurred."
      },
      {
        "Data Fields": "recorded",
        "Type": "instant",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The instant of time at which the activity was recorded."
      },
      {
        "Data Fields": "policy",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "Policy or plan the activity was defined by. Typically, a single activity may have multiple applicable policy documents, such as patient consent, guarantor funding, etc."
      },
      {
        "Data Fields": "location",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Where the activity occurred, if relevant."
      },
      {
        "Data Fields": "reason",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The reason that the activity was taking place."
      },
      {
        "Data Fields": "activity",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "An activity is something that occurs over a period of time and acts upon or with entities; it may include consuming, processing, transforming, modifying, relocating, using, or generating entities."
      },
      {
        "Data Fields": "agent",
        "Type": "BackboneElement",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "An actor taking a role in an activity  for which it can be assigned some degree of responsibility for the activity taking place."
      },
      {
        "Data Fields": "agent.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "agent.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "agent.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "agent.type",
        "Type": "CodeableConcept",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The participation the agent had with respect to the activity."
      },
      {
        "Data Fields": "agent.role",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The function of the agent with respect to the activity. The security role enabling the agent with respect to the activity."
      },
      {
        "Data Fields": "agent.who",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The individual, device or organization that participated in the event."
      },
      {
        "Data Fields": "agent.onBehalfOf",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The individual, device, or organization for whom the change was made."
      },
      {
        "Data Fields": "entity",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "An entity used in this activity."
      },
      {
        "Data Fields": "entity.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "entity.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "entity.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "entity.role",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "How the entity was used during the activity."
      },
      {
        "Data Fields": "entity.what",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Identity of the  Entity used. May be a logical or physical uri and maybe absolute or relative."
      },
      {
        "Data Fields": "entity.agent",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The entity is attributed to an agent to express the agent's responsibility for that entity, possibly along with other agents. This description can be understood as shorthand for saying that the agent was responsible for the activity which generated the entity."
      },
      {
        "Data Fields": "entity.agent.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "entity.agent.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "entity.agent.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "entity.agent.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The participation the agent had with respect to the activity."
      },
      {
        "Data Fields": "entity.agent.role",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The function of the agent with respect to the activity. The security role enabling the agent with respect to the activity."
      },
      {
        "Data Fields": "entity.agent.who",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The individual, device or organization that participated in the event."
      },
      {
        "Data Fields": "entity.agent.onBehalfOf",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The individual, device, or organization for whom the change was made."
      },
      {
        "Data Fields": "signature",
        "Type": "Signature",
        
        
        "Required": "False",
        "Description": "A digital signature on the target Reference(s). The signer should match a Provenance.agent. The purpose of the signature is indicated."
      }
    ],
    "Questionnaire": [
      {
        "Data Fields": "resourceType",
        "Type": "Questionnaire",
        
        
        "Required": "True",
        "Description": "This is a Questionnaire resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "url",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "An absolute URI that is used to identify this questionnaire when it is referenced in a specification, model, design or an instance; also called its canonical identifier. This SHOULD be globally unique and SHOULD be a literal address at which at which an authoritative instance of this questionnaire is (or will be) published. This URL can be the target of a canonical reference. It SHALL remain the same when the questionnaire is stored on different servers."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A formal identifier that is used to identify this questionnaire when it is represented in other formats, or referenced in a specification, model, design or an instance."
      },
      {
        "Data Fields": "version",
        "Type": "string",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The identifier that is used to identify this version of the questionnaire when it is referenced in a specification, model, design or instance. This is an arbitrary value managed by the questionnaire author and is not expected to be globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if a managed version is not available. There is also no expectation that versions can be placed in a lexicographical sequence."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A natural language name identifying the questionnaire. This name should be usable as an identifier for the module by machine processing applications such as code generation."
      },
      {
        "Data Fields": "title",
        "Type": "string",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A short, descriptive, user-friendly title for the questionnaire."
      },
      {
        "Data Fields": "derivedFrom",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "The URL of a Questionnaire that this Questionnaire is based on."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The status of this questionnaire. Enables tracking the life-cycle of the content."
      },
      {
        "Data Fields": "experimental",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "A Boolean value to indicate that this questionnaire is authored for testing purposes (or education/evaluation/marketing) and is not intended to be used for genuine usage."
      },
      {
        "Data Fields": "subjectType",
        "Type": "code",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The types of subjects that can be the subject of responses created for the questionnaire."
      },
      {
        "Data Fields": "date",
        "Type": "dateTime",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The date  (and optionally time) when the questionnaire was published. The date must change when the business version changes and it must change if the status code changes. In addition, it should change when the substantive content of the questionnaire changes."
      },
      {
        "Data Fields": "publisher",
        "Type": "string",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The name of the organization or individual that published the questionnaire."
      },
      {
        "Data Fields": "contact",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "Contact details to assist a user in finding and communicating with the publisher."
      },
      {
        "Data Fields": "description",
        "Type": "markdown",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A free text natural language description of the questionnaire from a consumer's perspective."
      },
      {
        "Data Fields": "useContext",
        "Type": "UsageContext",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The content was developed with a focus and intent of supporting the contexts that are listed. These contexts may be general categories (gender, age, ...) or may be references to specific programs (insurance plans, studies, ...) and may be used to assist with indexing and searching for appropriate questionnaire instances."
      },
      {
        "Data Fields": "jurisdiction",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A legal or geographic region in which the questionnaire is intended to be used."
      },
      {
        "Data Fields": "purpose",
        "Type": "markdown",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Explanation of why this questionnaire is needed and why it has been designed as it has."
      },
      {
        "Data Fields": "copyright",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A copyright statement relating to the questionnaire and/or its contents. Copyright statements are generally legal restrictions on the use and publishing of the questionnaire."
      },
      {
        "Data Fields": "approvalDate",
        "Type": "date",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The date on which the resource content was approved by the publisher. Approval happens once when the content is officially approved for usage."
      },
      {
        "Data Fields": "lastReviewDate",
        "Type": "date",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The date on which the resource content was last reviewed. Review happens periodically after approval but does not change the original approval date."
      },
      {
        "Data Fields": "effectivePeriod",
        "Type": "Period",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The period during which the questionnaire content was or is planned to be in active use."
      },
      {
        "Data Fields": "code",
        "Type": "Coding",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "An identifier for this question or group of questions in a particular terminology such as LOINC."
      },
      {
        "Data Fields": "item",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A particular question, question grouping or display text that is part of the questionnaire."
      },
      {
        "Data Fields": "item.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "item.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "item.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "item.linkId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "An identifier that is unique within the Questionnaire allowing linkage to the equivalent item in a QuestionnaireResponse resource."
      },
      {
        "Data Fields": "item.definition",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "This element is a URI that refers to an [[[ElementDefinition]]] that provides information about this item, including information that might otherwise be included in the instance of the Questionnaire resource. A detailed description of the construction of the URI is shown in Comments, below. If this element is present then the following element values MAY be derived from the Element Definition if the corresponding elements of this Questionnaire resource instance have no value:  * code (ElementDefinition.code)  * type (ElementDefinition.type)  * required (ElementDefinition.min)  * repeats (ElementDefinition.max)  * maxLength (ElementDefinition.maxLength)  * answerValueSet (ElementDefinition.binding) * options (ElementDefinition.binding)."
      },
      {
        "Data Fields": "item.code",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "A terminology code that corresponds to this group or question (e.g. a code from LOINC, which defines many questions and answers)."
      },
      {
        "Data Fields": "item.prefix",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A short label for a particular group, question or set of display text within the questionnaire used for reference by the individual completing the questionnaire."
      },
      {
        "Data Fields": "item.text",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of a section, the text of a question or text content for a display item."
      },
      {
        "Data Fields": "item.type",
        "Type": "code",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The type of questionnaire item this is - whether text for display, a grouping of other items or a particular type of data to be captured (string, integer, coded choice, etc.)."
      },
      {
        "Data Fields": "item.enableWhen",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A constraint indicating that this item should only be enabled (displayed/allow answers to be captured) when the specified condition is true."
      },
      {
        "Data Fields": "item.enableWhen.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "item.enableWhen.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "item.enableWhen.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "item.enableWhen.question",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The linkId for the question whose answer (or lack of answer) governs whether this item is enabled."
      },
      {
        "Data Fields": "item.enableWhen.operator",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Specifies the criteria by which the question is enabled."
      },
      {
        "Data Fields": "item.enableWhen.answerBoolean",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "A value that the referenced question is tested using the specified operator in order for the item to be enabled."
      },
      {
        "Data Fields": "item.enableWhen.answerDecimal",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "A value that the referenced question is tested using the specified operator in order for the item to be enabled."
      },
      {
        "Data Fields": "item.enableWhen.answerInteger",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "A value that the referenced question is tested using the specified operator in order for the item to be enabled."
      },
      {
        "Data Fields": "item.enableWhen.answerDate",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A value that the referenced question is tested using the specified operator in order for the item to be enabled."
      },
      {
        "Data Fields": "item.enableWhen.answerDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A value that the referenced question is tested using the specified operator in order for the item to be enabled."
      },
      {
        "Data Fields": "item.enableWhen.answerTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A value that the referenced question is tested using the specified operator in order for the item to be enabled."
      },
      {
        "Data Fields": "item.enableWhen.answerString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A value that the referenced question is tested using the specified operator in order for the item to be enabled."
      },
      {
        "Data Fields": "item.enableWhen.answerCoding",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "A value that the referenced question is tested using the specified operator in order for the item to be enabled."
      },
      {
        "Data Fields": "item.enableWhen.answerQuantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "A value that the referenced question is tested using the specified operator in order for the item to be enabled."
      },
      {
        "Data Fields": "item.enableWhen.answerReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A value that the referenced question is tested using the specified operator in order for the item to be enabled."
      },
      {
        "Data Fields": "item.enableBehavior",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Controls how multiple enableWhen values are interpreted -  whether all or any must be true."
      },
      {
        "Data Fields": "item.required",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "An indication, if true, that the item must be present in a \"completed\" QuestionnaireResponse.  If false, the item may be skipped when answering the questionnaire."
      },
      {
        "Data Fields": "item.repeats",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "An indication, if true, that the item may occur multiple times in the response, collecting multiple answers for questions or multiple sets of answers for groups."
      },
      {
        "Data Fields": "item.readOnly",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "An indication, when true, that the value cannot be changed by a human respondent to the Questionnaire."
      },
      {
        "Data Fields": "item.maxLength",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "The maximum number of characters that are permitted in the answer to be considered a \"valid\" QuestionnaireResponse."
      },
      {
        "Data Fields": "item.answerValueSet",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "A reference to a value set containing a list of codes representing permitted answers for a \"choice\" or \"open-choice\" question."
      },
      {
        "Data Fields": "item.answerOption",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "One of the permitted answers for a \"choice\" or \"open-choice\" question."
      },
      {
        "Data Fields": "item.answerOption.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "item.answerOption.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "item.answerOption.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "item.answerOption.valueInteger",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "A potential answer that's allowed as the answer to this question."
      },
      {
        "Data Fields": "item.answerOption.valueDate",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A potential answer that's allowed as the answer to this question."
      },
      {
        "Data Fields": "item.answerOption.valueTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A potential answer that's allowed as the answer to this question."
      },
      {
        "Data Fields": "item.answerOption.valueString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A potential answer that's allowed as the answer to this question."
      },
      {
        "Data Fields": "item.answerOption.valueCoding",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "A potential answer that's allowed as the answer to this question."
      },
      {
        "Data Fields": "item.answerOption.valueReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A potential answer that's allowed as the answer to this question."
      },
      {
        "Data Fields": "item.answerOption.initialSelected",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Indicates whether the answer value is selected when the list of possible answers is initially shown."
      },
      {
        "Data Fields": "item.initial",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "One or more values that should be pre-populated in the answer when initially rendering the questionnaire for user input."
      },
      {
        "Data Fields": "item.initial.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "item.initial.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "item.initial.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "item.initial.valueBoolean",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "The actual value to for an initial answer."
      },
      {
        "Data Fields": "item.initial.valueDecimal",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "The actual value to for an initial answer."
      },
      {
        "Data Fields": "item.initial.valueInteger",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "The actual value to for an initial answer."
      },
      {
        "Data Fields": "item.initial.valueDate",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The actual value to for an initial answer."
      },
      {
        "Data Fields": "item.initial.valueDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The actual value to for an initial answer."
      },
      {
        "Data Fields": "item.initial.valueTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The actual value to for an initial answer."
      },
      {
        "Data Fields": "item.initial.valueString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The actual value to for an initial answer."
      },
      {
        "Data Fields": "item.initial.valueUri",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The actual value to for an initial answer."
      },
      {
        "Data Fields": "item.initial.valueAttachment",
        "Type": "Attachment",
        
        
        "Required": "False",
        "Description": "The actual value to for an initial answer."
      },
      {
        "Data Fields": "item.initial.valueCoding",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "The actual value to for an initial answer."
      },
      {
        "Data Fields": "item.initial.valueQuantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The actual value to for an initial answer."
      },
      {
        "Data Fields": "item.initial.valueReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The actual value to for an initial answer."
      }
    ],
    "QuestionnaireResponse": [
      {
        "Data Fields": "resourceType",
        "Type": "QuestionnaireResponse",
        
        
        "Required": "True",
        "Description": "This is a QuestionnaireResponse resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A business identifier assigned to a particular completed (or partially completed) questionnaire."
      },
      {
        "Data Fields": "basedOn",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The order, proposal or plan that is fulfilled in whole or in part by this QuestionnaireResponse.  For example, a ServiceRequest seeking an intake assessment or a decision support recommendation to assess for post-partum depression."
      },
      {
        "Data Fields": "partOf",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A procedure or observation that this questionnaire was performed as part of the execution of.  For example, the surgery a checklist was executed as part of."
      },
      {
        "Data Fields": "questionnaire",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "The Questionnaire that defines and organizes the questions for which answers are being provided."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The position of the questionnaire response within its overall lifecycle."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The subject of the questionnaire response.  This could be a patient, organization, practitioner, device, etc.  This is who/what the answers apply to, but is not necessarily the source of information."
      },
      {
        "Data Fields": "encounter",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The Encounter during which this questionnaire response was created or to which the creation of this record is tightly associated."
      },
      {
        "Data Fields": "authored",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date and/or time that this set of answers were last changed."
      },
      {
        "Data Fields": "author",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Person who received the answers to the questions in the QuestionnaireResponse and recorded them in the system."
      },
      {
        "Data Fields": "source",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The person who answered the questions about the subject."
      },
      {
        "Data Fields": "item",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A group or question item from the original questionnaire for which answers are provided."
      },
      {
        "Data Fields": "item.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "item.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "item.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "item.linkId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The item from the Questionnaire that corresponds to this item in the QuestionnaireResponse resource."
      },
      {
        "Data Fields": "item.definition",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to an [[[ElementDefinition]]] that provides the details for the item."
      },
      {
        "Data Fields": "item.text",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Text that is displayed above the contents of the group or as the text of the question being answered."
      },
      {
        "Data Fields": "item.answer",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The respondent's answer(s) to the question."
      },
      {
        "Data Fields": "item.answer.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "item.answer.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "item.answer.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "item.answer.valueBoolean",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "The answer (or one of the answers) provided by the respondent to the question."
      },
      {
        "Data Fields": "item.answer.valueDecimal",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "The answer (or one of the answers) provided by the respondent to the question."
      },
      {
        "Data Fields": "item.answer.valueInteger",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "The answer (or one of the answers) provided by the respondent to the question."
      },
      {
        "Data Fields": "item.answer.valueDate",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The answer (or one of the answers) provided by the respondent to the question."
      },
      {
        "Data Fields": "item.answer.valueDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The answer (or one of the answers) provided by the respondent to the question."
      },
      {
        "Data Fields": "item.answer.valueTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The answer (or one of the answers) provided by the respondent to the question."
      },
      {
        "Data Fields": "item.answer.valueString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The answer (or one of the answers) provided by the respondent to the question."
      },
      {
        "Data Fields": "item.answer.valueUri",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The answer (or one of the answers) provided by the respondent to the question."
      },
      {
        "Data Fields": "item.answer.valueAttachment",
        "Type": "Attachment",
        
        
        "Required": "False",
        "Description": "The answer (or one of the answers) provided by the respondent to the question."
      },
      {
        "Data Fields": "item.answer.valueCoding",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "The answer (or one of the answers) provided by the respondent to the question."
      },
      {
        "Data Fields": "item.answer.valueQuantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The answer (or one of the answers) provided by the respondent to the question."
      },
      {
        "Data Fields": "item.answer.valueReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The answer (or one of the answers) provided by the respondent to the question."
      }
    ],
    "RelatedPerson": [
      {
        "Data Fields": "resourceType",
        "Type": "RelatedPerson",
        
        
        "Required": "True",
        "Description": "This is a RelatedPerson resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Identifier for a person within a particular scope."
      },
      {
        "Data Fields": "active",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Whether this related person record is in active use."
      },
      {
        "Data Fields": "patient",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "The patient this person is related to."
      },
      {
        "Data Fields": "relationship",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The nature of the relationship between a patient and the related person."
      },
      {
        "Data Fields": "name",
        "Type": "HumanName",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A name associated with the person."
      },
      {
        "Data Fields": "telecom",
        "Type": "ContactPoint",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A contact detail for the person, e.g. a telephone number or an email address."
      },
      {
        "Data Fields": "gender",
        "Type": "code",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Administrative Gender - the gender that the person is considered to have for administration and record keeping purposes."
      },
      {
        "Data Fields": "birthDate",
        "Type": "date",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The date on which the related person was born."
      },
      {
        "Data Fields": "address",
        "Type": "Address",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Address where the related person can be contacted or visited."
      },
      {
        "Data Fields": "photo",
        "Type": "Attachment",
        
        
        "Required": "False",
        "Description": "Image of the person."
      },
      {
        "Data Fields": "period",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The period of time during which this relationship is or was active. If there are no dates defined, then the interval is unknown."
      },
      {
        "Data Fields": "communication",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A language which may be used to communicate with about the patient's health."
      },
      {
        "Data Fields": "communication.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "communication.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "communication.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "communication.language",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The ISO-639-1 alpha 2 code in lower case for the language, optionally followed by a hyphen and the ISO-3166-1 alpha 2 code for the region in upper case; e.g. \"en\" for English, or \"en-US\" for American English versus \"en-EN\" for England English."
      },
      {
        "Data Fields": "communication.preferred",
        "Type": "boolean",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Indicates whether or not the patient prefers this language (over other languages he masters up a certain level)."
      }
    ],
    "RequestGroup": [
      {
        "Data Fields": "resourceType",
        "Type": "RequestGroup",
        
        
        "Required": "True",
        "Description": "This is a RequestGroup resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Allows a service to provide a unique, business identifier for the request."
      },
      {
        "Data Fields": "instantiatesCanonical",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "A canonical URL referencing a FHIR-defined protocol, guideline, orderset or other definition that is adhered to in whole or in part by this request."
      },
      {
        "Data Fields": "instantiatesUri",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A URL referencing an externally defined protocol, guideline, orderset or other definition that is adhered to in whole or in part by this request."
      },
      {
        "Data Fields": "basedOn",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A plan, proposal or order that is fulfilled in whole or in part by this request."
      },
      {
        "Data Fields": "replaces",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Completed or terminated request(s) whose function is taken by this new request."
      },
      {
        "Data Fields": "groupIdentifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A shared identifier common to all requests that were authorized more or less simultaneously by a single author, representing the identifier of the requisition, prescription or similar form."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The current state of the request. For request groups, the status reflects the status of all the requests in the group."
      },
      {
        "Data Fields": "intent",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Indicates the level of authority/intentionality associated with the request and where the request fits into the workflow chain."
      },
      {
        "Data Fields": "priority",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Indicates how quickly the request should be addressed with respect to other requests."
      },
      {
        "Data Fields": "code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code that identifies what the overall request group is."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The subject for which the request group was created."
      },
      {
        "Data Fields": "encounter",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Describes the context of the request group, if any."
      },
      {
        "Data Fields": "authoredOn",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "Indicates when the request group was created."
      },
      {
        "Data Fields": "author",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Provides a reference to the author of the request group."
      },
      {
        "Data Fields": "reasonCode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Describes the reason for the request group in coded or textual form."
      },
      {
        "Data Fields": "reasonReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Indicates another resource whose existence justifies this request group."
      },
      {
        "Data Fields": "note",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "Provides a mechanism to communicate additional information about the response."
      },
      {
        "Data Fields": "action",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The actions, if any, produced by the evaluation of the artifact."
      },
      {
        "Data Fields": "action.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "action.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "action.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "action.prefix",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A user-visible prefix for the action."
      },
      {
        "Data Fields": "action.title",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The title of the action displayed to a user."
      },
      {
        "Data Fields": "action.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A short description of the action used to provide a summary to display to the user."
      },
      {
        "Data Fields": "action.textEquivalent",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A text equivalent of the action to be performed. This provides a human-interpretable description of the action when the definition is consumed by a system that might not be capable of interpreting it dynamically."
      },
      {
        "Data Fields": "action.priority",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Indicates how quickly the action should be addressed with respect to other actions."
      },
      {
        "Data Fields": "action.code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code that provides meaning for the action or action group. For example, a section may have a LOINC code for a section of a documentation template."
      },
      {
        "Data Fields": "action.documentation",
        "Type": "RelatedArtifact",
        
        
        "Required": "False",
        "Description": "Didactic or other informational resources associated with the action that can be provided to the CDS recipient. Information resources can include inline text commentary and links to web resources."
      },
      {
        "Data Fields": "action.condition",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "An expression that describes applicability criteria, or start/stop conditions for the action."
      },
      {
        "Data Fields": "action.condition.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "action.condition.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "action.condition.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "action.condition.kind",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The kind of condition."
      },
      {
        "Data Fields": "action.condition.expression",
        "Type": "Expression",
        
        
        "Required": "False",
        "Description": "An expression that returns true or false, indicating whether or not the condition is satisfied."
      },
      {
        "Data Fields": "action.relatedAction",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A relationship to another action such as \"before\" or \"30-60 minutes after start of\"."
      },
      {
        "Data Fields": "action.relatedAction.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "action.relatedAction.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "action.relatedAction.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "action.relatedAction.actionId",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The element id of the action this is related to."
      },
      {
        "Data Fields": "action.relatedAction.relationship",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The relationship of this action to the related action."
      },
      {
        "Data Fields": "action.relatedAction.offsetDuration",
        "Type": "Duration",
        
        
        "Required": "False",
        "Description": "A duration or range of durations to apply to the relationship. For example, 30-60 minutes before."
      },
      {
        "Data Fields": "action.relatedAction.offsetRange",
        "Type": "Range",
        
        
        "Required": "False",
        "Description": "A duration or range of durations to apply to the relationship. For example, 30-60 minutes before."
      },
      {
        "Data Fields": "action.timingDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "An optional value describing when the action should be performed."
      },
      {
        "Data Fields": "action.timingAge",
        "Type": "Age",
        
        
        "Required": "False",
        "Description": "An optional value describing when the action should be performed."
      },
      {
        "Data Fields": "action.timingPeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "An optional value describing when the action should be performed."
      },
      {
        "Data Fields": "action.timingDuration",
        "Type": "Duration",
        
        
        "Required": "False",
        "Description": "An optional value describing when the action should be performed."
      },
      {
        "Data Fields": "action.timingRange",
        "Type": "Range",
        
        
        "Required": "False",
        "Description": "An optional value describing when the action should be performed."
      },
      {
        "Data Fields": "action.timingTiming",
        "Type": "Timing",
        
        
        "Required": "False",
        "Description": "An optional value describing when the action should be performed."
      },
      {
        "Data Fields": "action.participant",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The participant that should perform or be responsible for this action."
      },
      {
        "Data Fields": "action.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The type of action to perform (create, update, remove)."
      },
      {
        "Data Fields": "action.groupingBehavior",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Defines the grouping behavior for the action and its children."
      },
      {
        "Data Fields": "action.selectionBehavior",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Defines the selection behavior for the action and its children."
      },
      {
        "Data Fields": "action.requiredBehavior",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Defines expectations around whether an action is required."
      },
      {
        "Data Fields": "action.precheckBehavior",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Defines whether the action should usually be preselected."
      },
      {
        "Data Fields": "action.cardinalityBehavior",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Defines whether the action can be selected multiple times."
      },
      {
        "Data Fields": "action.resource",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The resource that is the target of the action (e.g. CommunicationRequest)."
      }
    ],
    "ResearchDefinition": [
      {
        "Data Fields": "resourceType",
        "Type": "ResearchDefinition",
        
        
        "Required": "True",
        "Description": "This is a ResearchDefinition resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "url",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "An absolute URI that is used to identify this research definition when it is referenced in a specification, model, design or an instance; also called its canonical identifier. This SHOULD be globally unique and SHOULD be a literal address at which at which an authoritative instance of this research definition is (or will be) published. This URL can be the target of a canonical reference. It SHALL remain the same when the research definition is stored on different servers."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A formal identifier that is used to identify this research definition when it is represented in other formats, or referenced in a specification, model, design or an instance."
      },
      {
        "Data Fields": "version",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The identifier that is used to identify this version of the research definition when it is referenced in a specification, model, design or instance. This is an arbitrary value managed by the research definition author and is not expected to be globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if a managed version is not available. There is also no expectation that versions can be placed in a lexicographical sequence. To provide a version consistent with the Decision Support Service specification, use the format Major.Minor.Revision (e.g. 1.0.0). For more information on versioning knowledge assets, refer to the Decision Support Service specification. Note that a version is required for non-experimental active artifacts."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A natural language name identifying the research definition. This name should be usable as an identifier for the module by machine processing applications such as code generation."
      },
      {
        "Data Fields": "title",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A short, descriptive, user-friendly title for the research definition."
      },
      {
        "Data Fields": "shortTitle",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The short title provides an alternate title for use in informal descriptive contexts where the full, formal title is not necessary."
      },
      {
        "Data Fields": "subtitle",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "An explanatory or alternate title for the ResearchDefinition giving additional information about its content."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The status of this research definition. Enables tracking the life-cycle of the content."
      },
      {
        "Data Fields": "experimental",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "A Boolean value to indicate that this research definition is authored for testing purposes (or education/evaluation/marketing) and is not intended to be used for genuine usage."
      },
      {
        "Data Fields": "subjectCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The intended subjects for the ResearchDefinition. If this element is not provided, a Patient subject is assumed, but the subject of the ResearchDefinition can be anything."
      },
      {
        "Data Fields": "subjectReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The intended subjects for the ResearchDefinition. If this element is not provided, a Patient subject is assumed, but the subject of the ResearchDefinition can be anything."
      },
      {
        "Data Fields": "date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date  (and optionally time) when the research definition was published. The date must change when the business version changes and it must change if the status code changes. In addition, it should change when the substantive content of the research definition changes."
      },
      {
        "Data Fields": "publisher",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the organization or individual that published the research definition."
      },
      {
        "Data Fields": "contact",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "Contact details to assist a user in finding and communicating with the publisher."
      },
      {
        "Data Fields": "description",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A free text natural language description of the research definition from a consumer's perspective."
      },
      {
        "Data Fields": "comment",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A human-readable string to clarify or explain concepts about the resource."
      },
      {
        "Data Fields": "useContext",
        "Type": "UsageContext",
        
        
        "Required": "False",
        "Description": "The content was developed with a focus and intent of supporting the contexts that are listed. These contexts may be general categories (gender, age, ...) or may be references to specific programs (insurance plans, studies, ...) and may be used to assist with indexing and searching for appropriate research definition instances."
      },
      {
        "Data Fields": "jurisdiction",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A legal or geographic region in which the research definition is intended to be used."
      },
      {
        "Data Fields": "purpose",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Explanation of why this research definition is needed and why it has been designed as it has."
      },
      {
        "Data Fields": "usage",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A detailed description, from a clinical perspective, of how the ResearchDefinition is used."
      },
      {
        "Data Fields": "copyright",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A copyright statement relating to the research definition and/or its contents. Copyright statements are generally legal restrictions on the use and publishing of the research definition."
      },
      {
        "Data Fields": "approvalDate",
        "Type": "date",
        
        
        "Required": "False",
        "Description": "The date on which the resource content was approved by the publisher. Approval happens once when the content is officially approved for usage."
      },
      {
        "Data Fields": "lastReviewDate",
        "Type": "date",
        
        
        "Required": "False",
        "Description": "The date on which the resource content was last reviewed. Review happens periodically after approval but does not change the original approval date."
      },
      {
        "Data Fields": "effectivePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The period during which the research definition content was or is planned to be in active use."
      },
      {
        "Data Fields": "topic",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Descriptive topics related to the content of the ResearchDefinition. Topics provide a high-level categorization grouping types of ResearchDefinitions that can be useful for filtering and searching."
      },
      {
        "Data Fields": "author",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individiual or organization primarily involved in the creation and maintenance of the content."
      },
      {
        "Data Fields": "editor",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individual or organization primarily responsible for internal coherence of the content."
      },
      {
        "Data Fields": "reviewer",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individual or organization primarily responsible for review of some aspect of the content."
      },
      {
        "Data Fields": "endorser",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individual or organization responsible for officially endorsing the content for use in some setting."
      },
      {
        "Data Fields": "relatedArtifact",
        "Type": "RelatedArtifact",
        
        
        "Required": "False",
        "Description": "Related artifacts such as additional documentation, justification, or bibliographic references."
      },
      {
        "Data Fields": "library",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "A reference to a Library resource containing the formal logic used by the ResearchDefinition."
      },
      {
        "Data Fields": "population",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "A reference to a ResearchElementDefinition resource that defines the population for the research."
      },
      {
        "Data Fields": "exposure",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A reference to a ResearchElementDefinition resource that defines the exposure for the research."
      },
      {
        "Data Fields": "exposureAlternative",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A reference to a ResearchElementDefinition resource that defines the exposureAlternative for the research."
      },
      {
        "Data Fields": "outcome",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A reference to a ResearchElementDefinition resomece that defines the outcome for the research."
      }
    ],
    "ResearchElementDefinition": [
      {
        "Data Fields": "resourceType",
        "Type": "ResearchElementDefinition",
        
        
        "Required": "True",
        "Description": "This is a ResearchElementDefinition resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "url",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "An absolute URI that is used to identify this research element definition when it is referenced in a specification, model, design or an instance; also called its canonical identifier. This SHOULD be globally unique and SHOULD be a literal address at which at which an authoritative instance of this research element definition is (or will be) published. This URL can be the target of a canonical reference. It SHALL remain the same when the research element definition is stored on different servers."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A formal identifier that is used to identify this research element definition when it is represented in other formats, or referenced in a specification, model, design or an instance."
      },
      {
        "Data Fields": "version",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The identifier that is used to identify this version of the research element definition when it is referenced in a specification, model, design or instance. This is an arbitrary value managed by the research element definition author and is not expected to be globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if a managed version is not available. There is also no expectation that versions can be placed in a lexicographical sequence. To provide a version consistent with the Decision Support Service specification, use the format Major.Minor.Revision (e.g. 1.0.0). For more information on versioning knowledge assets, refer to the Decision Support Service specification. Note that a version is required for non-experimental active artifacts."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A natural language name identifying the research element definition. This name should be usable as an identifier for the module by machine processing applications such as code generation."
      },
      {
        "Data Fields": "title",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A short, descriptive, user-friendly title for the research element definition."
      },
      {
        "Data Fields": "shortTitle",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The short title provides an alternate title for use in informal descriptive contexts where the full, formal title is not necessary."
      },
      {
        "Data Fields": "subtitle",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "An explanatory or alternate title for the ResearchElementDefinition giving additional information about its content."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The status of this research element definition. Enables tracking the life-cycle of the content."
      },
      {
        "Data Fields": "experimental",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "A Boolean value to indicate that this research element definition is authored for testing purposes (or education/evaluation/marketing) and is not intended to be used for genuine usage."
      },
      {
        "Data Fields": "subjectCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The intended subjects for the ResearchElementDefinition. If this element is not provided, a Patient subject is assumed, but the subject of the ResearchElementDefinition can be anything."
      },
      {
        "Data Fields": "subjectReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The intended subjects for the ResearchElementDefinition. If this element is not provided, a Patient subject is assumed, but the subject of the ResearchElementDefinition can be anything."
      },
      {
        "Data Fields": "date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date  (and optionally time) when the research element definition was published. The date must change when the business version changes and it must change if the status code changes. In addition, it should change when the substantive content of the research element definition changes."
      },
      {
        "Data Fields": "publisher",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the organization or individual that published the research element definition."
      },
      {
        "Data Fields": "contact",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "Contact details to assist a user in finding and communicating with the publisher."
      },
      {
        "Data Fields": "description",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A free text natural language description of the research element definition from a consumer's perspective."
      },
      {
        "Data Fields": "comment",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A human-readable string to clarify or explain concepts about the resource."
      },
      {
        "Data Fields": "useContext",
        "Type": "UsageContext",
        
        
        "Required": "False",
        "Description": "The content was developed with a focus and intent of supporting the contexts that are listed. These contexts may be general categories (gender, age, ...) or may be references to specific programs (insurance plans, studies, ...) and may be used to assist with indexing and searching for appropriate research element definition instances."
      },
      {
        "Data Fields": "jurisdiction",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A legal or geographic region in which the research element definition is intended to be used."
      },
      {
        "Data Fields": "purpose",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Explanation of why this research element definition is needed and why it has been designed as it has."
      },
      {
        "Data Fields": "usage",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A detailed description, from a clinical perspective, of how the ResearchElementDefinition is used."
      },
      {
        "Data Fields": "copyright",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A copyright statement relating to the research element definition and/or its contents. Copyright statements are generally legal restrictions on the use and publishing of the research element definition."
      },
      {
        "Data Fields": "approvalDate",
        "Type": "date",
        
        
        "Required": "False",
        "Description": "The date on which the resource content was approved by the publisher. Approval happens once when the content is officially approved for usage."
      },
      {
        "Data Fields": "lastReviewDate",
        "Type": "date",
        
        
        "Required": "False",
        "Description": "The date on which the resource content was last reviewed. Review happens periodically after approval but does not change the original approval date."
      },
      {
        "Data Fields": "effectivePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The period during which the research element definition content was or is planned to be in active use."
      },
      {
        "Data Fields": "topic",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Descriptive topics related to the content of the ResearchElementDefinition. Topics provide a high-level categorization grouping types of ResearchElementDefinitions that can be useful for filtering and searching."
      },
      {
        "Data Fields": "author",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individiual or organization primarily involved in the creation and maintenance of the content."
      },
      {
        "Data Fields": "editor",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individual or organization primarily responsible for internal coherence of the content."
      },
      {
        "Data Fields": "reviewer",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individual or organization primarily responsible for review of some aspect of the content."
      },
      {
        "Data Fields": "endorser",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individual or organization responsible for officially endorsing the content for use in some setting."
      },
      {
        "Data Fields": "relatedArtifact",
        "Type": "RelatedArtifact",
        
        
        "Required": "False",
        "Description": "Related artifacts such as additional documentation, justification, or bibliographic references."
      },
      {
        "Data Fields": "library",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "A reference to a Library resource containing the formal logic used by the ResearchElementDefinition."
      },
      {
        "Data Fields": "type",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The type of research element, a population, an exposure, or an outcome."
      },
      {
        "Data Fields": "variableType",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The type of the outcome (e.g. Dichotomous, Continuous, or Descriptive)."
      },
      {
        "Data Fields": "characteristic",
        "Type": "BackboneElement",
        
        
        "Required": "True",
        "Description": "A characteristic that defines the members of the research element. Multiple characteristics are applied with \"and\" semantics."
      },
      {
        "Data Fields": "characteristic.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "characteristic.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "characteristic.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "characteristic.definitionCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Define members of the research element using Codes (such as condition, medication, or observation), Expressions ( using an expression language such as FHIRPath or CQL) or DataRequirements (such as Diabetes diagnosis onset in the last year)."
      },
      {
        "Data Fields": "characteristic.definitionCanonical",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Define members of the research element using Codes (such as condition, medication, or observation), Expressions ( using an expression language such as FHIRPath or CQL) or DataRequirements (such as Diabetes diagnosis onset in the last year)."
      },
      {
        "Data Fields": "characteristic.definitionExpression",
        "Type": "Expression",
        
        
        "Required": "False",
        "Description": "Define members of the research element using Codes (such as condition, medication, or observation), Expressions ( using an expression language such as FHIRPath or CQL) or DataRequirements (such as Diabetes diagnosis onset in the last year)."
      },
      {
        "Data Fields": "characteristic.definitionDataRequirement",
        "Type": "DataRequirement",
        
        
        "Required": "False",
        "Description": "Define members of the research element using Codes (such as condition, medication, or observation), Expressions ( using an expression language such as FHIRPath or CQL) or DataRequirements (such as Diabetes diagnosis onset in the last year)."
      },
      {
        "Data Fields": "characteristic.usageContext",
        "Type": "UsageContext",
        
        
        "Required": "False",
        "Description": "Use UsageContext to define the members of the population, such as Age Ranges, Genders, Settings."
      },
      {
        "Data Fields": "characteristic.exclude",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "When true, members with this characteristic are excluded from the element."
      },
      {
        "Data Fields": "characteristic.unitOfMeasure",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Specifies the UCUM unit for the outcome."
      },
      {
        "Data Fields": "characteristic.studyEffectiveDescription",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A narrative description of the time period the study covers."
      },
      {
        "Data Fields": "characteristic.studyEffectiveDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Indicates what effective period the study covers."
      },
      {
        "Data Fields": "characteristic.studyEffectivePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "Indicates what effective period the study covers."
      },
      {
        "Data Fields": "characteristic.studyEffectiveDuration",
        "Type": "Duration",
        
        
        "Required": "False",
        "Description": "Indicates what effective period the study covers."
      },
      {
        "Data Fields": "characteristic.studyEffectiveTiming",
        "Type": "Timing",
        
        
        "Required": "False",
        "Description": "Indicates what effective period the study covers."
      },
      {
        "Data Fields": "characteristic.studyEffectiveTimeFromStart",
        "Type": "Duration",
        
        
        "Required": "False",
        "Description": "Indicates duration from the study initiation."
      },
      {
        "Data Fields": "characteristic.studyEffectiveGroupMeasure",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Indicates how elements are aggregated within the study effective period."
      },
      {
        "Data Fields": "characteristic.participantEffectiveDescription",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A narrative description of the time period the study covers."
      },
      {
        "Data Fields": "characteristic.participantEffectiveDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Indicates what effective period the study covers."
      },
      {
        "Data Fields": "characteristic.participantEffectivePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "Indicates what effective period the study covers."
      },
      {
        "Data Fields": "characteristic.participantEffectiveDuration",
        "Type": "Duration",
        
        
        "Required": "False",
        "Description": "Indicates what effective period the study covers."
      },
      {
        "Data Fields": "characteristic.participantEffectiveTiming",
        "Type": "Timing",
        
        
        "Required": "False",
        "Description": "Indicates what effective period the study covers."
      },
      {
        "Data Fields": "characteristic.participantEffectiveTimeFromStart",
        "Type": "Duration",
        
        
        "Required": "False",
        "Description": "Indicates duration from the participant's study entry."
      },
      {
        "Data Fields": "characteristic.participantEffectiveGroupMeasure",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Indicates how elements are aggregated within the study effective period."
      }
    ],
    "ResearchStudy": [
      {
        "Data Fields": "resourceType",
        "Type": "ResearchStudy",
        
        
        "Required": "True",
        "Description": "This is a ResearchStudy resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Identifiers assigned to this research study by the sponsor or other systems."
      },
      {
        "Data Fields": "title",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A short, descriptive user-friendly label for the study."
      },
      {
        "Data Fields": "protocol",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The set of steps expected to be performed as part of the execution of the study."
      },
      {
        "Data Fields": "partOf",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A larger research study of which this particular study is a component or step."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The current state of the study."
      },
      {
        "Data Fields": "primaryPurposeType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The type of study based upon the intent of the study's activities. A classification of the intent of the study."
      },
      {
        "Data Fields": "phase",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The stage in the progression of a therapy from initial experimental use in humans in clinical trials to post-market evaluation."
      },
      {
        "Data Fields": "category",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Codes categorizing the type of study such as investigational vs. observational, type of blinding, type of randomization, safety vs. efficacy, etc."
      },
      {
        "Data Fields": "focus",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The medication(s), food(s), therapy(ies), device(s) or other concerns or interventions that the study is seeking to gain more information about."
      },
      {
        "Data Fields": "condition",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The condition that is the focus of the study.  For example, In a study to examine risk factors for Lupus, might have as an inclusion criterion \"healthy volunteer\", but the target condition code would be a Lupus SNOMED code."
      },
      {
        "Data Fields": "contact",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "Contact details to assist a user in learning more about or engaging with the study."
      },
      {
        "Data Fields": "relatedArtifact",
        "Type": "RelatedArtifact",
        
        
        "Required": "False",
        "Description": "Citations, references and other related documents."
      },
      {
        "Data Fields": "keyword",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Key terms to aid in searching for or filtering the study."
      },
      {
        "Data Fields": "location",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Indicates a country, state or other region where the study is taking place."
      },
      {
        "Data Fields": "description",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A full description of how the study is being conducted."
      },
      {
        "Data Fields": "enrollment",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Reference to a Group that defines the criteria for and quantity of subjects participating in the study.  E.g. \" 200 female Europeans between the ages of 20 and 45 with early onset diabetes\"."
      },
      {
        "Data Fields": "period",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "Identifies the start date and the expected (or actual, depending on status) end date for the study."
      },
      {
        "Data Fields": "sponsor",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "An organization that initiates the investigation and is legally responsible for the study."
      },
      {
        "Data Fields": "principalInvestigator",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A researcher in a study who oversees multiple aspects of the study, such as concept development, protocol writing, protocol submission for IRB approval, participant recruitment, informed consent, data collection, analysis, interpretation and presentation."
      },
      {
        "Data Fields": "site",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A facility in which study activities are conducted."
      },
      {
        "Data Fields": "reasonStopped",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A description and/or code explaining the premature termination of the study."
      },
      {
        "Data Fields": "note",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "Comments made about the study by the performer, subject or other participants."
      },
      {
        "Data Fields": "arm",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Describes an expected sequence of events for one of the participants of a study.  E.g. Exposure to drug A, wash-out, exposure to drug B, wash-out, follow-up."
      },
      {
        "Data Fields": "arm.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "arm.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "arm.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "arm.name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique, human-readable label for this arm of the study."
      },
      {
        "Data Fields": "arm.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Categorization of study arm, e.g. experimental, active comparator, placebo comparater."
      },
      {
        "Data Fields": "arm.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A succinct description of the path through the study that would be followed by a subject adhering to this arm."
      },
      {
        "Data Fields": "objective",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A goal that the study is aiming to achieve in terms of a scientific question to be answered by the analysis of data collected during the study."
      },
      {
        "Data Fields": "objective.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "objective.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "objective.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "objective.name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique, human-readable label for this objective of the study."
      },
      {
        "Data Fields": "objective.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The kind of study objective."
      }
    ],
    "ResearchSubject": [
      {
        "Data Fields": "resourceType",
        "Type": "ResearchSubject",
        
        
        "Required": "True",
        "Description": "This is a ResearchSubject resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Identifiers assigned to this research subject for a study."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The current state of the subject."
      },
      {
        "Data Fields": "period",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The dates the subject began and ended their participation in the study."
      },
      {
        "Data Fields": "study",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "Reference to the study the subject is participating in."
      },
      {
        "Data Fields": "individual",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "The record of the person or animal who is involved in the study."
      },
      {
        "Data Fields": "assignedArm",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the arm in the study the subject is expected to follow as part of this study."
      },
      {
        "Data Fields": "actualArm",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the arm in the study the subject actually followed as part of this study."
      },
      {
        "Data Fields": "consent",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A record of the patient's informed agreement to participate in the study."
      }
    ],
    "RiskAssessment": [
      {
        "Data Fields": "resourceType",
        "Type": "RiskAssessment",
        
        
        "Required": "True",
        "Description": "This is a RiskAssessment resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Business identifier assigned to the risk assessment."
      },
      {
        "Data Fields": "basedOn",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A reference to the request that is fulfilled by this risk assessment."
      },
      {
        "Data Fields": "parent",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A reference to a resource that this risk assessment is part of, such as a Procedure."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The status of the RiskAssessment, using the same statuses as an Observation."
      },
      {
        "Data Fields": "method",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The algorithm, process or mechanism used to evaluate the risk."
      },
      {
        "Data Fields": "code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The type of the risk assessment performed."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "True",
        "Description": "The patient or group the risk assessment applies to."
      },
      {
        "Data Fields": "encounter",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The encounter where the assessment was performed."
      },
      {
        "Data Fields": "occurrenceDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The date (and possibly time) the risk assessment was performed."
      },
      {
        "Data Fields": "occurrencePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The date (and possibly time) the risk assessment was performed."
      },
      {
        "Data Fields": "condition",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "For assessments or prognosis specific to a particular condition, indicates the condition being assessed."
      },
      {
        "Data Fields": "performer",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The provider or software application that performed the assessment."
      },
      {
        "Data Fields": "reasonCode",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "The reason the risk assessment was performed."
      },
      {
        "Data Fields": "reasonReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Resources supporting the reason the risk assessment was performed."
      },
      {
        "Data Fields": "basis",
        "Type": "Reference",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Indicates the source data considered as part of the assessment (for example, FamilyHistory, Observations, Procedures, Conditions, etc.)."
      },
      {
        "Data Fields": "prediction",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Describes the expected outcome for the subject."
      },
      {
        "Data Fields": "prediction.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "prediction.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "prediction.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "prediction.outcome",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "One of the potential outcomes for the patient (e.g. remission, death,  a particular condition)."
      },
      {
        "Data Fields": "prediction.probabilityDecimal",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "Indicates how likely the outcome is (in the specified timeframe)."
      },
      {
        "Data Fields": "prediction.probabilityRange",
        "Type": "Range",
        
        
        "Required": "False",
        "Description": "Indicates how likely the outcome is (in the specified timeframe)."
      },
      {
        "Data Fields": "prediction.qualitativeRisk",
        "Type": "CodeableConcept",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Indicates how likely the outcome is (in the specified timeframe), expressed as a qualitative value (e.g. low, medium, or high)."
      },
      {
        "Data Fields": "prediction.relativeRisk",
        "Type": "decimal",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "Indicates the risk for this particular subject (with their specific characteristics) divided by the risk of the population in general.  (Numbers greater than 1 = higher risk than the population, numbers less than 1 = lower risk.)."
      },
      {
        "Data Fields": "prediction.whenPeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "Indicates the period of time or age range of the subject to which the specified probability applies."
      },
      {
        "Data Fields": "prediction.whenRange",
        "Type": "Range",
        
        
        "Required": "False",
        "Description": "Indicates the period of time or age range of the subject to which the specified probability applies."
      },
      {
        "Data Fields": "prediction.rationale",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Additional information explaining the basis for the prediction."
      },
      {
        "Data Fields": "mitigation",
        "Type": "string",
        "Fold Availability": "True",
        
        "Required": "False",
        "Description": "A description of the steps that might be taken to reduce the identified risk(s)."
      },
      {
        "Data Fields": "note",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "Additional comments about the risk assessment."
      }
    ],
    "RiskEvidenceSynthesis": [
      {
        "Data Fields": "resourceType",
        "Type": "RiskEvidenceSynthesis",
        
        
        "Required": "True",
        "Description": "This is a RiskEvidenceSynthesis resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "url",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "An absolute URI that is used to identify this risk evidence synthesis when it is referenced in a specification, model, design or an instance; also called its canonical identifier. This SHOULD be globally unique and SHOULD be a literal address at which at which an authoritative instance of this risk evidence synthesis is (or will be) published. This URL can be the target of a canonical reference. It SHALL remain the same when the risk evidence synthesis is stored on different servers."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A formal identifier that is used to identify this risk evidence synthesis when it is represented in other formats, or referenced in a specification, model, design or an instance."
      },
      {
        "Data Fields": "version",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The identifier that is used to identify this version of the risk evidence synthesis when it is referenced in a specification, model, design or instance. This is an arbitrary value managed by the risk evidence synthesis author and is not expected to be globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if a managed version is not available. There is also no expectation that versions can be placed in a lexicographical sequence."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A natural language name identifying the risk evidence synthesis. This name should be usable as an identifier for the module by machine processing applications such as code generation."
      },
      {
        "Data Fields": "title",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A short, descriptive, user-friendly title for the risk evidence synthesis."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The status of this risk evidence synthesis. Enables tracking the life-cycle of the content."
      },
      {
        "Data Fields": "date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date  (and optionally time) when the risk evidence synthesis was published. The date must change when the business version changes and it must change if the status code changes. In addition, it should change when the substantive content of the risk evidence synthesis changes."
      },
      {
        "Data Fields": "publisher",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the organization or individual that published the risk evidence synthesis."
      },
      {
        "Data Fields": "contact",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "Contact details to assist a user in finding and communicating with the publisher."
      },
      {
        "Data Fields": "description",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A free text natural language description of the risk evidence synthesis from a consumer's perspective."
      },
      {
        "Data Fields": "note",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "A human-readable string to clarify or explain concepts about the resource."
      },
      {
        "Data Fields": "useContext",
        "Type": "UsageContext",
        
        
        "Required": "False",
        "Description": "The content was developed with a focus and intent of supporting the contexts that are listed. These contexts may be general categories (gender, age, ...) or may be references to specific programs (insurance plans, studies, ...) and may be used to assist with indexing and searching for appropriate risk evidence synthesis instances."
      },
      {
        "Data Fields": "jurisdiction",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A legal or geographic region in which the risk evidence synthesis is intended to be used."
      },
      {
        "Data Fields": "copyright",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A copyright statement relating to the risk evidence synthesis and/or its contents. Copyright statements are generally legal restrictions on the use and publishing of the risk evidence synthesis."
      },
      {
        "Data Fields": "approvalDate",
        "Type": "date",
        
        
        "Required": "False",
        "Description": "The date on which the resource content was approved by the publisher. Approval happens once when the content is officially approved for usage."
      },
      {
        "Data Fields": "lastReviewDate",
        "Type": "date",
        
        
        "Required": "False",
        "Description": "The date on which the resource content was last reviewed. Review happens periodically after approval but does not change the original approval date."
      },
      {
        "Data Fields": "effectivePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The period during which the risk evidence synthesis content was or is planned to be in active use."
      },
      {
        "Data Fields": "topic",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Descriptive topics related to the content of the RiskEvidenceSynthesis. Topics provide a high-level categorization grouping types of EffectEvidenceSynthesiss that can be useful for filtering and searching."
      },
      {
        "Data Fields": "author",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individiual or organization primarily involved in the creation and maintenance of the content."
      },
      {
        "Data Fields": "editor",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individual or organization primarily responsible for internal coherence of the content."
      },
      {
        "Data Fields": "reviewer",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individual or organization primarily responsible for review of some aspect of the content."
      },
      {
        "Data Fields": "endorser",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "An individual or organization responsible for officially endorsing the content for use in some setting."
      },
      {
        "Data Fields": "relatedArtifact",
        "Type": "RelatedArtifact",
        
        
        "Required": "False",
        "Description": "Related artifacts such as additional documentation, justification, or bibliographic references."
      },
      {
        "Data Fields": "synthesisType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Type of synthesis eg meta-analysis."
      },
      {
        "Data Fields": "studyType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Type of study eg randomized trial."
      },
      {
        "Data Fields": "population",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "A reference to a EvidenceVariable resource that defines the population for the research."
      },
      {
        "Data Fields": "exposure",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A reference to a EvidenceVariable resource that defines the exposure for the research."
      },
      {
        "Data Fields": "outcome",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "A reference to a EvidenceVariable resomece that defines the outcome for the research."
      },
      {
        "Data Fields": "sampleSize",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A description of the size of the sample involved in the synthesis."
      },
      {
        "Data Fields": "sampleSize.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "sampleSize.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "sampleSize.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "sampleSize.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Human-readable summary of sample size."
      },
      {
        "Data Fields": "sampleSize.numberOfStudies",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "Number of studies included in this evidence synthesis."
      },
      {
        "Data Fields": "sampleSize.numberOfParticipants",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "Number of participants included in this evidence synthesis."
      },
      {
        "Data Fields": "riskEstimate",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The estimated risk of the outcome."
      },
      {
        "Data Fields": "riskEstimate.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "riskEstimate.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "riskEstimate.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "riskEstimate.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Human-readable summary of risk estimate."
      },
      {
        "Data Fields": "riskEstimate.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Examples include proportion and mean."
      },
      {
        "Data Fields": "riskEstimate.value",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "The point estimate of the risk estimate."
      },
      {
        "Data Fields": "riskEstimate.unitOfMeasure",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Specifies the UCUM unit for the outcome."
      },
      {
        "Data Fields": "riskEstimate.denominatorCount",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "The sample size for the group that was measured for this risk estimate."
      },
      {
        "Data Fields": "riskEstimate.numeratorCount",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "The number of group members with the outcome of interest."
      },
      {
        "Data Fields": "riskEstimate.precisionEstimate",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A description of the precision of the estimate for the effect."
      },
      {
        "Data Fields": "riskEstimate.precisionEstimate.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "riskEstimate.precisionEstimate.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "riskEstimate.precisionEstimate.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "riskEstimate.precisionEstimate.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Examples include confidence interval and interquartile range."
      },
      {
        "Data Fields": "riskEstimate.precisionEstimate.level",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "Use 95 for a 95% confidence interval."
      },
      {
        "Data Fields": "riskEstimate.precisionEstimate.from",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "Lower bound of confidence interval."
      },
      {
        "Data Fields": "riskEstimate.precisionEstimate.to",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "Upper bound of confidence interval."
      },
      {
        "Data Fields": "certainty",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A description of the certainty of the risk estimate."
      },
      {
        "Data Fields": "certainty.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "certainty.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "certainty.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "certainty.rating",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A rating of the certainty of the effect estimate."
      },
      {
        "Data Fields": "certainty.note",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "A human-readable string to clarify or explain concepts about the resource."
      },
      {
        "Data Fields": "certainty.certaintySubcomponent",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A description of a component of the overall certainty."
      },
      {
        "Data Fields": "certainty.certaintySubcomponent.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "certainty.certaintySubcomponent.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "certainty.certaintySubcomponent.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "certainty.certaintySubcomponent.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Type of subcomponent of certainty rating."
      },
      {
        "Data Fields": "certainty.certaintySubcomponent.rating",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A rating of a subcomponent of rating certainty."
      },
      {
        "Data Fields": "certainty.certaintySubcomponent.note",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "A human-readable string to clarify or explain concepts about the resource."
      }
    ],
    "Schedule": [
      {
        "Data Fields": "resourceType",
        "Type": "Schedule",
        
        
        "Required": "True",
        "Description": "This is a Schedule resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "External Ids for this item."
      },
      {
        "Data Fields": "active",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Whether this schedule record is in active use or should not be used (such as was entered in error)."
      },
      {
        "Data Fields": "serviceCategory",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A broad categorization of the service that is to be performed during this appointment."
      },
      {
        "Data Fields": "serviceType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The specific service that is to be performed during this appointment."
      },
      {
        "Data Fields": "specialty",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The specialty of a practitioner that would be required to perform the service requested in this appointment."
      },
      {
        "Data Fields": "actor",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "Slots that reference this schedule resource provide the availability details to these referenced resource(s)."
      },
      {
        "Data Fields": "planningHorizon",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The period of time that the slots that reference this Schedule resource cover (even if none exist). These  cover the amount of time that an organization's planning horizon; the interval for which they are currently accepting appointments. This does not define a \"template\" for planning outside these dates."
      },
      {
        "Data Fields": "comment",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Comments on the availability to describe any extended information. Such as custom constraints on the slots that may be associated."
      }
    ],
    "SearchParameter": [
      {
        "Data Fields": "resourceType",
        "Type": "SearchParameter",
        
        
        "Required": "True",
        "Description": "This is a SearchParameter resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "url",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "An absolute URI that is used to identify this search parameter when it is referenced in a specification, model, design or an instance; also called its canonical identifier. This SHOULD be globally unique and SHOULD be a literal address at which at which an authoritative instance of this search parameter is (or will be) published. This URL can be the target of a canonical reference. It SHALL remain the same when the search parameter is stored on different servers."
      },
      {
        "Data Fields": "version",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The identifier that is used to identify this version of the search parameter when it is referenced in a specification, model, design or instance. This is an arbitrary value managed by the search parameter author and is not expected to be globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if a managed version is not available. There is also no expectation that versions can be placed in a lexicographical sequence."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A natural language name identifying the search parameter. This name should be usable as an identifier for the module by machine processing applications such as code generation."
      },
      {
        "Data Fields": "derivedFrom",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "Where this search parameter is originally defined. If a derivedFrom is provided, then the details in the search parameter must be consistent with the definition from which it is defined. i.e. the parameter should have the same meaning, and (usually) the functionality should be a proper subset of the underlying search parameter."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The status of this search parameter. Enables tracking the life-cycle of the content."
      },
      {
        "Data Fields": "experimental",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "A Boolean value to indicate that this search parameter is authored for testing purposes (or education/evaluation/marketing) and is not intended to be used for genuine usage."
      },
      {
        "Data Fields": "date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date  (and optionally time) when the search parameter was published. The date must change when the business version changes and it must change if the status code changes. In addition, it should change when the substantive content of the search parameter changes."
      },
      {
        "Data Fields": "publisher",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the organization or individual that published the search parameter."
      },
      {
        "Data Fields": "contact",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "Contact details to assist a user in finding and communicating with the publisher."
      },
      {
        "Data Fields": "description",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "And how it used."
      },
      {
        "Data Fields": "useContext",
        "Type": "UsageContext",
        
        
        "Required": "False",
        "Description": "The content was developed with a focus and intent of supporting the contexts that are listed. These contexts may be general categories (gender, age, ...) or may be references to specific programs (insurance plans, studies, ...) and may be used to assist with indexing and searching for appropriate search parameter instances."
      },
      {
        "Data Fields": "jurisdiction",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A legal or geographic region in which the search parameter is intended to be used."
      },
      {
        "Data Fields": "purpose",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Explanation of why this search parameter is needed and why it has been designed as it has."
      },
      {
        "Data Fields": "code",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The code used in the URL or the parameter name in a parameters resource for this search parameter."
      },
      {
        "Data Fields": "base",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base resource type(s) that this search parameter can be used against."
      },
      {
        "Data Fields": "type",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The type of value that a search parameter may contain, and how the content is interpreted."
      },
      {
        "Data Fields": "expression",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A FHIRPath expression that returns a set of elements for the search parameter."
      },
      {
        "Data Fields": "xpath",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "An XPath expression that returns a set of elements for the search parameter."
      },
      {
        "Data Fields": "xpathUsage",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "How the search parameter relates to the set of elements returned by evaluating the xpath query."
      },
      {
        "Data Fields": "target",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Types of resource (if a resource is referenced)."
      },
      {
        "Data Fields": "multipleOr",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Whether multiple values are allowed for each time the parameter exists. Values are separated by commas, and the parameter matches if any of the values match."
      },
      {
        "Data Fields": "multipleAnd",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Whether multiple parameters are allowed - e.g. more than one parameter with the same name. The search matches if all the parameters match."
      },
      {
        "Data Fields": "comparator",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Comparators supported for the search parameter."
      },
      {
        "Data Fields": "modifier",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "A modifier supported for the search parameter."
      },
      {
        "Data Fields": "chain",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Contains the names of any search parameters which may be chained to the containing search parameter. Chained parameters may be added to search parameters of type reference and specify that resources will only be returned if they contain a reference to a resource which matches the chained parameter value. Values for this field should be drawn from SearchParameter.code for a parameter on the target resource type."
      },
      {
        "Data Fields": "component",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Used to define the parts of a composite search parameter."
      },
      {
        "Data Fields": "component.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "component.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "component.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "component.definition",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "The definition of the search parameter that describes this part."
      },
      {
        "Data Fields": "component.expression",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A sub-expression that defines how to extract values for this component from the output of the main SearchParameter.expression."
      }
    ],
    "ServiceRequest": [
      {
        "Data Fields": "resourceType",
        "Type": "ServiceRequest",
        
        
        "Required": "True",
        "Description": "This is a ServiceRequest resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Identifiers assigned to this order instance by the orderer and/or the receiver and/or order fulfiller."
      },
      {
        "Data Fields": "instantiatesCanonical",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "The URL pointing to a FHIR-defined protocol, guideline, orderset or other definition that is adhered to in whole or in part by this ServiceRequest."
      },
      {
        "Data Fields": "instantiatesUri",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "The URL pointing to an externally maintained protocol, guideline, orderset or other definition that is adhered to in whole or in part by this ServiceRequest."
      },
      {
        "Data Fields": "basedOn",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Plan/proposal/order fulfilled by this request."
      },
      {
        "Data Fields": "replaces",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The request takes the place of the referenced completed or terminated request(s)."
      },
      {
        "Data Fields": "requisition",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A shared identifier common to all service requests that were authorized more or less simultaneously by a single author, representing the composite or group identifier."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The status of the order."
      },
      {
        "Data Fields": "intent",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Whether the request is a proposal, plan, an original order or a reflex order."
      },
      {
        "Data Fields": "category",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code that classifies the service for searching, sorting and display purposes (e.g. \"Surgical Procedure\")."
      },
      {
        "Data Fields": "priority",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Indicates how quickly the ServiceRequest should be addressed with respect to other requests."
      },
      {
        "Data Fields": "doNotPerform",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Set this to true if the record is saying that the service/procedure should NOT be performed."
      },
      {
        "Data Fields": "code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code that identifies a particular service (i.e., procedure, diagnostic investigation, or panel of investigations) that have been requested."
      },
      {
        "Data Fields": "orderDetail",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Additional details and instructions about the how the services are to be delivered.   For example, and order for a urinary catheter may have an order detail for an external or indwelling catheter, or an order for a bandage may require additional instructions specifying how the bandage should be applied."
      },
      {
        "Data Fields": "quantityQuantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "An amount of service being requested which can be a quantity ( for example $1,500 home modification), a ratio ( for example, 20 half day visits per month), or a range (2.0 to 1.8 Gy per fraction)."
      },
      {
        "Data Fields": "quantityRatio",
        "Type": "Ratio",
        
        
        "Required": "False",
        "Description": "An amount of service being requested which can be a quantity ( for example $1,500 home modification), a ratio ( for example, 20 half day visits per month), or a range (2.0 to 1.8 Gy per fraction)."
      },
      {
        "Data Fields": "quantityRange",
        "Type": "Range",
        
        
        "Required": "False",
        "Description": "An amount of service being requested which can be a quantity ( for example $1,500 home modification), a ratio ( for example, 20 half day visits per month), or a range (2.0 to 1.8 Gy per fraction)."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "On whom or what the service is to be performed. This is usually a human patient, but can also be requested on animals, groups of humans or animals, devices such as dialysis machines, or even locations (typically for environmental scans)."
      },
      {
        "Data Fields": "encounter",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "An encounter that provides additional information about the healthcare context in which this request is made."
      },
      {
        "Data Fields": "occurrenceDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The date/time at which the requested service should occur."
      },
      {
        "Data Fields": "occurrencePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The date/time at which the requested service should occur."
      },
      {
        "Data Fields": "occurrenceTiming",
        "Type": "Timing",
        
        
        "Required": "False",
        "Description": "The date/time at which the requested service should occur."
      },
      {
        "Data Fields": "asNeededBoolean",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "If a CodeableConcept is present, it indicates the pre-condition for performing the service.  For example \"pain\", \"on flare-up\", etc."
      },
      {
        "Data Fields": "asNeededCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "If a CodeableConcept is present, it indicates the pre-condition for performing the service.  For example \"pain\", \"on flare-up\", etc."
      },
      {
        "Data Fields": "authoredOn",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "When the request transitioned to being actionable."
      },
      {
        "Data Fields": "requester",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The individual who initiated the request and has responsibility for its activation."
      },
      {
        "Data Fields": "performerType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Desired type of performer for doing the requested service."
      },
      {
        "Data Fields": "performer",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The desired performer for doing the requested service.  For example, the surgeon, dermatopathologist, endoscopist, etc."
      },
      {
        "Data Fields": "locationCode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The preferred location(s) where the procedure should actually happen in coded or free text form. E.g. at home or nursing day care center."
      },
      {
        "Data Fields": "locationReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A reference to the the preferred location(s) where the procedure should actually happen. E.g. at home or nursing day care center."
      },
      {
        "Data Fields": "reasonCode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "An explanation or justification for why this service is being requested in coded or textual form.   This is often for billing purposes.  May relate to the resources referred to in `supportingInfo`."
      },
      {
        "Data Fields": "reasonReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Indicates another resource that provides a justification for why this service is being requested.   May relate to the resources referred to in `supportingInfo`."
      },
      {
        "Data Fields": "insurance",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Insurance plans, coverage extensions, pre-authorizations and/or pre-determinations that may be needed for delivering the requested service."
      },
      {
        "Data Fields": "supportingInfo",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Additional clinical information about the patient or specimen that may influence the services or their interpretations.     This information includes diagnosis, clinical findings and other observations.  In laboratory ordering these are typically referred to as \"ask at order entry questions (AOEs)\".  This includes observations explicitly requested by the producer (filler) to provide context or supporting information needed to complete the order. For example,  reporting the amount of inspired oxygen for blood gas measurements."
      },
      {
        "Data Fields": "specimen",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "One or more specimens that the laboratory procedure will use."
      },
      {
        "Data Fields": "bodySite",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Anatomic location where the procedure should be performed. This is the target site."
      },
      {
        "Data Fields": "note",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "Any other notes and comments made about the service request. For example, internal billing notes."
      },
      {
        "Data Fields": "patientInstruction",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Instructions in terms that are understood by the patient or consumer."
      },
      {
        "Data Fields": "relevantHistory",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Key events in the history of the request."
      }
    ],
    "Slot": [
      {
        "Data Fields": "resourceType",
        "Type": "Slot",
        
        
        "Required": "True",
        "Description": "This is a Slot resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "External Ids for this item."
      },
      {
        "Data Fields": "serviceCategory",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A broad categorization of the service that is to be performed during this appointment."
      },
      {
        "Data Fields": "serviceType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The type of appointments that can be booked into this slot (ideally this would be an identifiable service - which is at a location, rather than the location itself). If provided then this overrides the value provided on the availability resource."
      },
      {
        "Data Fields": "specialty",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The specialty of a practitioner that would be required to perform the service requested in this appointment."
      },
      {
        "Data Fields": "appointmentType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The style of appointment or patient that may be booked in the slot (not service type)."
      },
      {
        "Data Fields": "schedule",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "The schedule resource that this slot defines an interval of status information."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "busy | free | busy-unavailable | busy-tentative | entered-in-error."
      },
      {
        "Data Fields": "start",
        "Type": "instant",
        
        
        "Required": "False",
        "Description": "Date/Time that the slot is to begin."
      },
      {
        "Data Fields": "end",
        "Type": "instant",
        
        
        "Required": "False",
        "Description": "Date/Time that the slot is to conclude."
      },
      {
        "Data Fields": "overbooked",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "This slot has already been overbooked, appointments are unlikely to be accepted for this time."
      },
      {
        "Data Fields": "comment",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Comments on the slot to describe any extended information. Such as custom constraints on the slot."
      }
    ],
    "Specimen": [
      {
        "Data Fields": "resourceType",
        "Type": "Specimen",
        
        
        "Required": "True",
        "Description": "This is a Specimen resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Id for specimen."
      },
      {
        "Data Fields": "accessionIdentifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "The identifier assigned by the lab when accessioning specimen(s). This is not necessarily the same as the specimen identifier, depending on local lab procedures."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The availability of the specimen."
      },
      {
        "Data Fields": "type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The kind of material that forms the specimen."
      },
      {
        "Data Fields": "subject",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Where the specimen came from. This may be from patient(s), from a location (e.g., the source of an environmental sample), or a sampling of a substance or a device."
      },
      {
        "Data Fields": "receivedTime",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "Time when specimen was received for processing or testing."
      },
      {
        "Data Fields": "parent",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Reference to the parent (source) specimen which is used when the specimen was either derived from or a component of another specimen."
      },
      {
        "Data Fields": "request",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Details concerning a service request that required a specimen to be collected."
      },
      {
        "Data Fields": "collection",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Details concerning the specimen collection."
      },
      {
        "Data Fields": "collection.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "collection.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "collection.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "collection.collector",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Person who collected the specimen."
      },
      {
        "Data Fields": "collection.collectedDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Time when specimen was collected from subject - the physiologically relevant time."
      },
      {
        "Data Fields": "collection.collectedPeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "Time when specimen was collected from subject - the physiologically relevant time."
      },
      {
        "Data Fields": "collection.duration",
        "Type": "Duration",
        
        
        "Required": "False",
        "Description": "The span of time over which the collection of a specimen occurred."
      },
      {
        "Data Fields": "collection.quantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The quantity of specimen collected; for instance the volume of a blood sample, or the physical measurement of an anatomic pathology sample."
      },
      {
        "Data Fields": "collection.method",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A coded value specifying the technique that is used to perform the procedure."
      },
      {
        "Data Fields": "collection.bodySite",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Anatomical location from which the specimen was collected (if subject is a patient). This is the target site.  This element is not used for environmental specimens."
      },
      {
        "Data Fields": "collection.fastingStatusCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Abstinence or reduction from some or all food, drink, or both, for a period of time prior to sample collection."
      },
      {
        "Data Fields": "collection.fastingStatusDuration",
        "Type": "Duration",
        
        
        "Required": "False",
        "Description": "Abstinence or reduction from some or all food, drink, or both, for a period of time prior to sample collection."
      },
      {
        "Data Fields": "processing",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Details concerning processing and processing steps for the specimen."
      },
      {
        "Data Fields": "processing.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "processing.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "processing.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "processing.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Textual description of procedure."
      },
      {
        "Data Fields": "processing.procedure",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A coded value specifying the procedure used to process the specimen."
      },
      {
        "Data Fields": "processing.additive",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Material used in the processing step."
      },
      {
        "Data Fields": "processing.timeDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A record of the time or period when the specimen processing occurred.  For example the time of sample fixation or the period of time the sample was in formalin."
      },
      {
        "Data Fields": "processing.timePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "A record of the time or period when the specimen processing occurred.  For example the time of sample fixation or the period of time the sample was in formalin."
      },
      {
        "Data Fields": "container",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The container holding the specimen.  The recursive nature of containers; i.e. blood in tube in tray in rack is not addressed here."
      },
      {
        "Data Fields": "container.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "container.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "container.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "container.identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Id for container. There may be multiple; a manufacturer's bar code, lab assigned identifier, etc. The container ID may differ from the specimen id in some circumstances."
      },
      {
        "Data Fields": "container.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Textual description of the container."
      },
      {
        "Data Fields": "container.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The type of container associated with the specimen (e.g. slide, aliquot, etc.)."
      },
      {
        "Data Fields": "container.capacity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The capacity (volume or other measure) the container may contain."
      },
      {
        "Data Fields": "container.specimenQuantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The quantity of specimen in the container; may be volume, dimensions, or other appropriate measurements, depending on the specimen type."
      },
      {
        "Data Fields": "container.additiveCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Introduced substance to preserve, maintain or enhance the specimen. Examples: Formalin, Citrate, EDTA."
      },
      {
        "Data Fields": "container.additiveReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Introduced substance to preserve, maintain or enhance the specimen. Examples: Formalin, Citrate, EDTA."
      },
      {
        "Data Fields": "condition",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A mode or state of being that describes the nature of the specimen."
      },
      {
        "Data Fields": "note",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "To communicate any details or issues about the specimen or during the specimen collection. (for example: broken vial, sent with patient, frozen)."
      }
    ],
    "SpecimenDefinition": [
      {
        "Data Fields": "resourceType",
        "Type": "SpecimenDefinition",
        
        
        "Required": "True",
        "Description": "This is a SpecimenDefinition resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A business identifier associated with the kind of specimen."
      },
      {
        "Data Fields": "typeCollected",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The kind of material to be collected."
      },
      {
        "Data Fields": "patientPreparation",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Preparation of the patient for specimen collection."
      },
      {
        "Data Fields": "timeAspect",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Time aspect of specimen collection (duration or offset)."
      },
      {
        "Data Fields": "collection",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The action to be performed for collecting the specimen."
      },
      {
        "Data Fields": "typeTested",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Specimen conditioned in a container as expected by the testing laboratory."
      },
      {
        "Data Fields": "typeTested.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "typeTested.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "typeTested.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "typeTested.isDerived",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Primary of secondary specimen."
      },
      {
        "Data Fields": "typeTested.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The kind of specimen conditioned for testing expected by lab."
      },
      {
        "Data Fields": "typeTested.preference",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The preference for this type of conditioned specimen."
      },
      {
        "Data Fields": "typeTested.container",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The specimen's container."
      },
      {
        "Data Fields": "typeTested.container.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "typeTested.container.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "typeTested.container.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "typeTested.container.material",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The type of material of the container."
      },
      {
        "Data Fields": "typeTested.container.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The type of container used to contain this kind of specimen."
      },
      {
        "Data Fields": "typeTested.container.cap",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Color of container cap."
      },
      {
        "Data Fields": "typeTested.container.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The textual description of the kind of container."
      },
      {
        "Data Fields": "typeTested.container.capacity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The capacity (volume or other measure) of this kind of container."
      },
      {
        "Data Fields": "typeTested.container.minimumVolumeQuantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The minimum volume to be conditioned in the container."
      },
      {
        "Data Fields": "typeTested.container.minimumVolumeString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The minimum volume to be conditioned in the container."
      },
      {
        "Data Fields": "typeTested.container.additive",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Substance introduced in the kind of container to preserve, maintain or enhance the specimen. Examples: Formalin, Citrate, EDTA."
      },
      {
        "Data Fields": "typeTested.container.additive.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "typeTested.container.additive.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "typeTested.container.additive.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "typeTested.container.additive.additiveCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Substance introduced in the kind of container to preserve, maintain or enhance the specimen. Examples: Formalin, Citrate, EDTA."
      },
      {
        "Data Fields": "typeTested.container.additive.additiveReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Substance introduced in the kind of container to preserve, maintain or enhance the specimen. Examples: Formalin, Citrate, EDTA."
      },
      {
        "Data Fields": "typeTested.container.preparation",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Special processing that should be applied to the container for this kind of specimen."
      },
      {
        "Data Fields": "typeTested.requirement",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Requirements for delivery and special handling of this kind of conditioned specimen."
      },
      {
        "Data Fields": "typeTested.retentionTime",
        "Type": "Duration",
        
        
        "Required": "False",
        "Description": "The usual time that a specimen of this kind is retained after the ordered tests are completed, for the purpose of additional testing."
      },
      {
        "Data Fields": "typeTested.rejectionCriterion",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Criterion for rejection of the specimen in its container by the laboratory."
      },
      {
        "Data Fields": "typeTested.handling",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Set of instructions for preservation/transport of the specimen at a defined temperature interval, prior the testing process."
      },
      {
        "Data Fields": "typeTested.handling.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "typeTested.handling.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "typeTested.handling.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "typeTested.handling.temperatureQualifier",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "It qualifies the interval of temperature, which characterizes an occurrence of handling. Conditions that are not related to temperature may be handled in the instruction element."
      },
      {
        "Data Fields": "typeTested.handling.temperatureRange",
        "Type": "Range",
        
        
        "Required": "False",
        "Description": "The temperature interval for this set of handling instructions."
      },
      {
        "Data Fields": "typeTested.handling.maxDuration",
        "Type": "Duration",
        
        
        "Required": "False",
        "Description": "The maximum time interval of preservation of the specimen with these conditions."
      },
      {
        "Data Fields": "typeTested.handling.instruction",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Additional textual instructions for the preservation or transport of the specimen. For instance, 'Protect from light exposure'."
      }
    ],
    "StructureDefinition": [
      {
        "Data Fields": "resourceType",
        "Type": "StructureDefinition",
        
        
        "Required": "True",
        "Description": "This is a StructureDefinition resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "url",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "An absolute URI that is used to identify this structure definition when it is referenced in a specification, model, design or an instance; also called its canonical identifier. This SHOULD be globally unique and SHOULD be a literal address at which at which an authoritative instance of this structure definition is (or will be) published. This URL can be the target of a canonical reference. It SHALL remain the same when the structure definition is stored on different servers."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A formal identifier that is used to identify this structure definition when it is represented in other formats, or referenced in a specification, model, design or an instance."
      },
      {
        "Data Fields": "version",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The identifier that is used to identify this version of the structure definition when it is referenced in a specification, model, design or instance. This is an arbitrary value managed by the structure definition author and is not expected to be globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if a managed version is not available. There is also no expectation that versions can be placed in a lexicographical sequence."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A natural language name identifying the structure definition. This name should be usable as an identifier for the module by machine processing applications such as code generation."
      },
      {
        "Data Fields": "title",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A short, descriptive, user-friendly title for the structure definition."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The status of this structure definition. Enables tracking the life-cycle of the content."
      },
      {
        "Data Fields": "experimental",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "A Boolean value to indicate that this structure definition is authored for testing purposes (or education/evaluation/marketing) and is not intended to be used for genuine usage."
      },
      {
        "Data Fields": "date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date  (and optionally time) when the structure definition was published. The date must change when the business version changes and it must change if the status code changes. In addition, it should change when the substantive content of the structure definition changes."
      },
      {
        "Data Fields": "publisher",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the organization or individual that published the structure definition."
      },
      {
        "Data Fields": "contact",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "Contact details to assist a user in finding and communicating with the publisher."
      },
      {
        "Data Fields": "description",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A free text natural language description of the structure definition from a consumer's perspective."
      },
      {
        "Data Fields": "useContext",
        "Type": "UsageContext",
        
        
        "Required": "False",
        "Description": "The content was developed with a focus and intent of supporting the contexts that are listed. These contexts may be general categories (gender, age, ...) or may be references to specific programs (insurance plans, studies, ...) and may be used to assist with indexing and searching for appropriate structure definition instances."
      },
      {
        "Data Fields": "jurisdiction",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A legal or geographic region in which the structure definition is intended to be used."
      },
      {
        "Data Fields": "purpose",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Explanation of why this structure definition is needed and why it has been designed as it has."
      },
      {
        "Data Fields": "copyright",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A copyright statement relating to the structure definition and/or its contents. Copyright statements are generally legal restrictions on the use and publishing of the structure definition."
      },
      {
        "Data Fields": "keyword",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "A set of key words or terms from external terminologies that may be used to assist with indexing and searching of templates nby describing the use of this structure definition, or the content it describes."
      },
      {
        "Data Fields": "fhirVersion",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The version of the FHIR specification on which this StructureDefinition is based - this is the formal version of the specification, without the revision number, e.g. [publication].[major].[minor], which is 4.0.1. for this version."
      },
      {
        "Data Fields": "mapping",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "An external specification that the content is mapped to."
      },
      {
        "Data Fields": "mapping.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "mapping.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "mapping.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "mapping.identity",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "An Internal id that is used to identify this mapping set when specific mappings are made."
      },
      {
        "Data Fields": "mapping.uri",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "An absolute URI that identifies the specification that this mapping is expressed to."
      },
      {
        "Data Fields": "mapping.name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A name for the specification that is being mapped to."
      },
      {
        "Data Fields": "mapping.comment",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Comments about this mapping, including version notes, issues, scope limitations, and other important notes for usage."
      },
      {
        "Data Fields": "kind",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Defines the kind of structure that this definition is describing."
      },
      {
        "Data Fields": "abstract",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Whether structure this definition describes is abstract or not  - that is, whether the structure is not intended to be instantiated. For Resources and Data types, abstract types will never be exchanged  between systems."
      },
      {
        "Data Fields": "context",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Identifies the types of resource or data type elements to which the extension can be applied."
      },
      {
        "Data Fields": "context.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "context.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "context.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "context.type",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Defines how to interpret the expression that defines what the context of the extension is."
      },
      {
        "Data Fields": "context.expression",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "An expression that defines where an extension can be used in resources."
      },
      {
        "Data Fields": "contextInvariant",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A set of rules as FHIRPath Invariants about when the extension can be used (e.g. co-occurrence variants for the extension). All the rules must be true."
      },
      {
        "Data Fields": "type",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "The type this structure describes. If the derivation kind is 'specialization' then this is the master definition for a type, and there is always one of these (a data type, an extension, a resource, including abstract ones). Otherwise the structure definition is a constraint on the stated type (and in this case, the type cannot be an abstract type).  References are URLs that are relative to http://hl7.org/fhir/StructureDefinition e.g. \"string\" is a reference to http://hl7.org/fhir/StructureDefinition/string. Absolute URLs are only allowed in logical models."
      },
      {
        "Data Fields": "baseDefinition",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "An absolute URI that is the base structure from which this type is derived, either by specialization or constraint."
      },
      {
        "Data Fields": "derivation",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "How the type relates to the baseDefinition."
      },
      {
        "Data Fields": "snapshot",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A snapshot view is expressed in a standalone form that can be used and interpreted without considering the base StructureDefinition."
      },
      {
        "Data Fields": "snapshot.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "snapshot.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "snapshot.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "snapshot.element",
        "Type": "ElementDefinition",
        
        
        "Required": "False",
        "Description": "Captures constraints on each element within the resource."
      },
      {
        "Data Fields": "differential",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A differential view is expressed relative to the base StructureDefinition - a statement of differences that it applies."
      },
      {
        "Data Fields": "differential.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "differential.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "differential.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "differential.element",
        "Type": "ElementDefinition",
        
        
        "Required": "False",
        "Description": "Captures constraints on each element within the resource."
      }
    ],
    "StructureMap": [
      {
        "Data Fields": "resourceType",
        "Type": "StructureMap",
        
        
        "Required": "True",
        "Description": "This is a StructureMap resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "url",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "An absolute URI that is used to identify this structure map when it is referenced in a specification, model, design or an instance; also called its canonical identifier. This SHOULD be globally unique and SHOULD be a literal address at which at which an authoritative instance of this structure map is (or will be) published. This URL can be the target of a canonical reference. It SHALL remain the same when the structure map is stored on different servers."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A formal identifier that is used to identify this structure map when it is represented in other formats, or referenced in a specification, model, design or an instance."
      },
      {
        "Data Fields": "version",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The identifier that is used to identify this version of the structure map when it is referenced in a specification, model, design or instance. This is an arbitrary value managed by the structure map author and is not expected to be globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if a managed version is not available. There is also no expectation that versions can be placed in a lexicographical sequence."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A natural language name identifying the structure map. This name should be usable as an identifier for the module by machine processing applications such as code generation."
      },
      {
        "Data Fields": "title",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A short, descriptive, user-friendly title for the structure map."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The status of this structure map. Enables tracking the life-cycle of the content."
      },
      {
        "Data Fields": "experimental",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "A Boolean value to indicate that this structure map is authored for testing purposes (or education/evaluation/marketing) and is not intended to be used for genuine usage."
      },
      {
        "Data Fields": "date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date  (and optionally time) when the structure map was published. The date must change when the business version changes and it must change if the status code changes. In addition, it should change when the substantive content of the structure map changes."
      },
      {
        "Data Fields": "publisher",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the organization or individual that published the structure map."
      },
      {
        "Data Fields": "contact",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "Contact details to assist a user in finding and communicating with the publisher."
      },
      {
        "Data Fields": "description",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A free text natural language description of the structure map from a consumer's perspective."
      },
      {
        "Data Fields": "useContext",
        "Type": "UsageContext",
        
        
        "Required": "False",
        "Description": "The content was developed with a focus and intent of supporting the contexts that are listed. These contexts may be general categories (gender, age, ...) or may be references to specific programs (insurance plans, studies, ...) and may be used to assist with indexing and searching for appropriate structure map instances."
      },
      {
        "Data Fields": "jurisdiction",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A legal or geographic region in which the structure map is intended to be used."
      },
      {
        "Data Fields": "purpose",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Explanation of why this structure map is needed and why it has been designed as it has."
      },
      {
        "Data Fields": "copyright",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A copyright statement relating to the structure map and/or its contents. Copyright statements are generally legal restrictions on the use and publishing of the structure map."
      },
      {
        "Data Fields": "structure",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A structure definition used by this map. The structure definition may describe instances that are converted, or the instances that are produced."
      },
      {
        "Data Fields": "structure.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "structure.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "structure.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "structure.url",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "The canonical reference to the structure."
      },
      {
        "Data Fields": "structure.mode",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "How the referenced structure is used in this mapping."
      },
      {
        "Data Fields": "structure.alias",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name used for this type in the map."
      },
      {
        "Data Fields": "structure.documentation",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Documentation that describes how the structure is used in the mapping."
      },
      {
        "Data Fields": "import",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "Other maps used by this map (canonical URLs)."
      },
      {
        "Data Fields": "group",
        "Type": "BackboneElement",
        
        
        "Required": "True",
        "Description": "Organizes the mapping into manageable chunks for human review/ease of maintenance."
      },
      {
        "Data Fields": "group.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "group.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "group.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "group.name",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "A unique name for the group for the convenience of human readers."
      },
      {
        "Data Fields": "group.extends",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "Another group that this group adds rules to."
      },
      {
        "Data Fields": "group.typeMode",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "If this is the default rule set to apply for the source type or this combination of types."
      },
      {
        "Data Fields": "group.documentation",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Additional supporting documentation that explains the purpose of the group and the types of mappings within it."
      },
      {
        "Data Fields": "group.input",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A name assigned to an instance of data. The instance must be provided when the mapping is invoked."
      },
      {
        "Data Fields": "group.input.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "group.input.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "group.input.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "group.input.name",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "Name for this instance of data."
      },
      {
        "Data Fields": "group.input.type",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Type for this instance of data."
      },
      {
        "Data Fields": "group.input.mode",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Mode for this instance of data."
      },
      {
        "Data Fields": "group.input.documentation",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Documentation for this instance of data."
      },
      {
        "Data Fields": "group.rule",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Transform Rule from source to target."
      },
      {
        "Data Fields": "group.rule.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "group.rule.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "group.rule.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "group.rule.name",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "Name of the rule for internal references."
      },
      {
        "Data Fields": "group.rule.source",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Source inputs to the mapping."
      },
      {
        "Data Fields": "group.rule.source.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "group.rule.source.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "group.rule.source.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "group.rule.source.context",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "Type or variable this rule applies to."
      },
      {
        "Data Fields": "group.rule.source.min",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "Specified minimum cardinality for the element. This is optional; if present, it acts an implicit check on the input content."
      },
      {
        "Data Fields": "group.rule.source.max",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Specified maximum cardinality for the element - a number or a \"*\". This is optional; if present, it acts an implicit check on the input content (* just serves as documentation; it's the default value)."
      },
      {
        "Data Fields": "group.rule.source.type",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Specified type for the element. This works as a condition on the mapping - use for polymorphic elements."
      },
      {
        "Data Fields": "group.rule.source.defaultValueBase64Binary",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueBoolean",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueCanonical",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueCode",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueDate",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueDecimal",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueInstant",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueInteger",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueMarkdown",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueOid",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValuePositiveInt",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueUnsignedInt",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueUri",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueUrl",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueUuid",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueAddress",
        "Type": "Address",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueAge",
        "Type": "Age",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueAnnotation",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueAttachment",
        "Type": "Attachment",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueCoding",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueContactPoint",
        "Type": "ContactPoint",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueCount",
        "Type": "Count",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueDistance",
        "Type": "Distance",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueDuration",
        "Type": "Duration",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueHumanName",
        "Type": "HumanName",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueIdentifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueMoney",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValuePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueQuantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueRange",
        "Type": "Range",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueRatio",
        "Type": "Ratio",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueSampledData",
        "Type": "SampledData",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueSignature",
        "Type": "Signature",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueTiming",
        "Type": "Timing",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueContactDetail",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueContributor",
        "Type": "Contributor",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueDataRequirement",
        "Type": "DataRequirement",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueExpression",
        "Type": "Expression",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueParameterDefinition",
        "Type": "ParameterDefinition",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueRelatedArtifact",
        "Type": "RelatedArtifact",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueTriggerDefinition",
        "Type": "TriggerDefinition",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueUsageContext",
        "Type": "UsageContext",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueDosage",
        "Type": "Dosage",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.defaultValueMeta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "A value to use if there is no existing value in the source object."
      },
      {
        "Data Fields": "group.rule.source.element",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Optional field for this source."
      },
      {
        "Data Fields": "group.rule.source.listMode",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "How to handle the list mode for this element."
      },
      {
        "Data Fields": "group.rule.source.variable",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "Named context for field, if a field is specified."
      },
      {
        "Data Fields": "group.rule.source.condition",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "FHIRPath expression  - must be true or the rule does not apply."
      },
      {
        "Data Fields": "group.rule.source.check",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "FHIRPath expression  - must be true or the mapping engine throws an error instead of completing."
      },
      {
        "Data Fields": "group.rule.source.logMessage",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A FHIRPath expression which specifies a message to put in the transform log when content matching the source rule is found."
      },
      {
        "Data Fields": "group.rule.target",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Content to create because of this mapping rule."
      },
      {
        "Data Fields": "group.rule.target.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "group.rule.target.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "group.rule.target.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "group.rule.target.context",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "Type or variable this rule applies to."
      },
      {
        "Data Fields": "group.rule.target.contextType",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "How to interpret the context."
      },
      {
        "Data Fields": "group.rule.target.element",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Field to create in the context."
      },
      {
        "Data Fields": "group.rule.target.variable",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "Named context for field, if desired, and a field is specified."
      },
      {
        "Data Fields": "group.rule.target.listMode",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "If field is a list, how to manage the list."
      },
      {
        "Data Fields": "group.rule.target.listRuleId",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "Internal rule reference for shared list items."
      },
      {
        "Data Fields": "group.rule.target.transform",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "How the data is copied / created."
      },
      {
        "Data Fields": "group.rule.target.parameter",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Parameters to the transform."
      },
      {
        "Data Fields": "group.rule.target.parameter.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "group.rule.target.parameter.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "group.rule.target.parameter.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "group.rule.target.parameter.valueId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Parameter value - variable or literal."
      },
      {
        "Data Fields": "group.rule.target.parameter.valueString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Parameter value - variable or literal."
      },
      {
        "Data Fields": "group.rule.target.parameter.valueBoolean",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Parameter value - variable or literal."
      },
      {
        "Data Fields": "group.rule.target.parameter.valueInteger",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "Parameter value - variable or literal."
      },
      {
        "Data Fields": "group.rule.target.parameter.valueDecimal",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "Parameter value - variable or literal."
      },
      {
        "Data Fields": "group.rule.dependent",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Which other rules to apply in the context of this rule."
      },
      {
        "Data Fields": "group.rule.dependent.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "group.rule.dependent.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "group.rule.dependent.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "group.rule.dependent.name",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "Name of a rule or group to apply."
      },
      {
        "Data Fields": "group.rule.dependent.variable",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Variable to pass to the rule or group."
      },
      {
        "Data Fields": "group.rule.documentation",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Documentation for this instance of data."
      }
    ],
    "Subscription": [
      {
        "Data Fields": "resourceType",
        "Type": "Subscription",
        
        
        "Required": "True",
        "Description": "This is a Subscription resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The status of the subscription, which marks the server state for managing the subscription."
      },
      {
        "Data Fields": "contact",
        "Type": "ContactPoint",
        
        
        "Required": "False",
        "Description": "Contact details for a human to contact about the subscription. The primary use of this for system administrator troubleshooting."
      },
      {
        "Data Fields": "end",
        "Type": "instant",
        
        
        "Required": "False",
        "Description": "The time for the server to turn the subscription off."
      },
      {
        "Data Fields": "reason",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A description of why this subscription is defined."
      },
      {
        "Data Fields": "criteria",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The rules that the server should use to determine when to generate notifications for this subscription."
      },
      {
        "Data Fields": "error",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A record of the last error that occurred when the server processed a notification."
      },
      {
        "Data Fields": "channel",
        "Type": "BackboneElement",
        
        
        "Required": "True",
        "Description": "Details where to send notifications when resources are received that meet the criteria."
      },
      {
        "Data Fields": "channel.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "channel.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "channel.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "channel.type",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The type of channel to send notifications on."
      },
      {
        "Data Fields": "channel.endpoint",
        "Type": "url",
        
        
        "Required": "False",
        "Description": "The url that describes the actual end-point to send messages to."
      },
      {
        "Data Fields": "channel.payload",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The mime type to send the payload in - either application/fhir+xml, or application/fhir+json. If the payload is not present, then there is no payload in the notification, just a notification. The mime type \"text/plain\" may also be used for Email and SMS subscriptions."
      },
      {
        "Data Fields": "channel.header",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Additional headers / information to send as part of the notification."
      }
    ],
    "Substance": [
      {
        "Data Fields": "resourceType",
        "Type": "Substance",
        
        
        "Required": "True",
        "Description": "This is a Substance resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Unique identifier for the substance."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "A code to indicate if the substance is actively used."
      },
      {
        "Data Fields": "category",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code that classifies the general type of substance.  This is used  for searching, sorting and display purposes."
      },
      {
        "Data Fields": "code",
        "Type": "CodeableConcept",
        
        
        "Required": "True",
        "Description": "A code (or set of codes) that identify this substance."
      },
      {
        "Data Fields": "description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A description of the substance - its appearance, handling requirements, and other usage notes."
      },
      {
        "Data Fields": "instance",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Substance may be used to describe a kind of substance, or a specific package/container of the substance: an instance."
      },
      {
        "Data Fields": "instance.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "instance.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "instance.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "instance.identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Identifier associated with the package/container (usually a label affixed directly)."
      },
      {
        "Data Fields": "instance.expiry",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "When the substance is no longer valid to use. For some substances, a single arbitrary date is used for expiry."
      },
      {
        "Data Fields": "instance.quantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The amount of the substance."
      },
      {
        "Data Fields": "ingredient",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A substance can be composed of other substances."
      },
      {
        "Data Fields": "ingredient.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "ingredient.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "ingredient.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "ingredient.quantity",
        "Type": "Ratio",
        
        
        "Required": "False",
        "Description": "The amount of the ingredient in the substance - a concentration ratio."
      },
      {
        "Data Fields": "ingredient.substanceCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Another substance that is a component of this substance."
      },
      {
        "Data Fields": "ingredient.substanceReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Another substance that is a component of this substance."
      }
    ],
    "SubstanceNucleicAcid": [
      {
        "Data Fields": "resourceType",
        "Type": "SubstanceNucleicAcid",
        
        
        "Required": "True",
        "Description": "This is a SubstanceNucleicAcid resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "sequenceType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The type of the sequence shall be specified based on a controlled vocabulary."
      },
      {
        "Data Fields": "numberOfSubunits",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "The number of linear sequences of nucleotides linked through phosphodiester bonds shall be described. Subunits would be strands of nucleic acids that are tightly associated typically through Watson-Crick base pairing. NOTE: If not specified in the reference source, the assumption is that there is 1 subunit."
      },
      {
        "Data Fields": "areaOfHybridisation",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The area of hybridisation shall be described if applicable for double stranded RNA or DNA. The number associated with the subunit followed by the number associated to the residue shall be specified in increasing order. The underscore “” shall be used as separator as follows: “Subunitnumber Residue”."
      },
      {
        "Data Fields": "oligoNucleotideType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "(TBC)."
      },
      {
        "Data Fields": "subunit",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Subunits are listed in order of decreasing length; sequences of the same length will be ordered by molecular weight; subunits that have identical sequences will be repeated multiple times."
      },
      {
        "Data Fields": "subunit.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "subunit.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "subunit.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "subunit.sequence",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Actual nucleotide sequence notation from 5' to 3' end using standard single letter codes. In addition to the base sequence, sugar and type of phosphate or non-phosphate linkage should also be captured."
      },
      {
        "Data Fields": "subunit.length",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "The length of the sequence shall be captured."
      },
      {
        "Data Fields": "subunit.sequenceAttachment",
        "Type": "Attachment",
        
        
        "Required": "False",
        "Description": "(TBC)."
      },
      {
        "Data Fields": "subunit.fivePrime",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The nucleotide present at the 5’ terminal shall be specified based on a controlled vocabulary. Since the sequence is represented from the 5' to the 3' end, the 5’ prime nucleotide is the letter at the first position in the sequence. A separate representation would be redundant."
      },
      {
        "Data Fields": "subunit.threePrime",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The nucleotide present at the 3’ terminal shall be specified based on a controlled vocabulary. Since the sequence is represented from the 5' to the 3' end, the 5’ prime nucleotide is the letter at the last position in the sequence. A separate representation would be redundant."
      },
      {
        "Data Fields": "subunit.linkage",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The linkages between sugar residues will also be captured."
      },
      {
        "Data Fields": "subunit.linkage.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "subunit.linkage.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "subunit.linkage.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "subunit.linkage.connectivity",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The entity that links the sugar residues together should also be captured for nearly all naturally occurring nucleic acid the linkage is a phosphate group. For many synthetic oligonucleotides phosphorothioate linkages are often seen. Linkage connectivity is assumed to be 3’-5’. If the linkage is either 3’-3’ or 5’-5’ this should be specified."
      },
      {
        "Data Fields": "subunit.linkage.identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Each linkage will be registered as a fragment and have an ID."
      },
      {
        "Data Fields": "subunit.linkage.name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Each linkage will be registered as a fragment and have at least one name. A single name shall be assigned to each linkage."
      },
      {
        "Data Fields": "subunit.linkage.residueSite",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Residues shall be captured as described in 5.3.6.8.3."
      },
      {
        "Data Fields": "subunit.sugar",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "5.3.6.8.1 Sugar ID (Mandatory)."
      },
      {
        "Data Fields": "subunit.sugar.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "subunit.sugar.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "subunit.sugar.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "subunit.sugar.identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "The Substance ID of the sugar or sugar-like component that make up the nucleotide."
      },
      {
        "Data Fields": "subunit.sugar.name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the sugar or sugar-like component that make up the nucleotide."
      },
      {
        "Data Fields": "subunit.sugar.residueSite",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The residues that contain a given sugar will be captured. The order of given residues will be captured in the 5‘-3‘direction consistent with the base sequences listed above."
      }
    ],
    "SubstancePolymer": [
      {
        "Data Fields": "resourceType",
        "Type": "SubstancePolymer",
        
        
        "Required": "True",
        "Description": "This is a SubstancePolymer resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "class",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "geometry",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "copolymerConnectivity",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "modification",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "monomerSet",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "monomerSet.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "monomerSet.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "monomerSet.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "monomerSet.ratioType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "monomerSet.startingMaterial",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "monomerSet.startingMaterial.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "monomerSet.startingMaterial.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "monomerSet.startingMaterial.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "monomerSet.startingMaterial.material",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "monomerSet.startingMaterial.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "monomerSet.startingMaterial.isDefining",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "monomerSet.startingMaterial.amount",
        "Type": "SubstanceAmount",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "repeat",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "repeat.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "repeat.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "repeat.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "repeat.numberOfUnits",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "repeat.averageMolecularFormula",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "repeat.repeatUnitAmountType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "repeat.repeatUnit",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "repeat.repeatUnit.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "repeat.repeatUnit.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "repeat.repeatUnit.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "repeat.repeatUnit.orientationOfPolymerisation",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "repeat.repeatUnit.amount",
        "Type": "SubstanceAmount",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "repeat.repeatUnit.degreeOfPolymerisation",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "repeat.repeatUnit.degreeOfPolymerisation.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "repeat.repeatUnit.degreeOfPolymerisation.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "repeat.repeatUnit.degreeOfPolymerisation.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "repeat.repeatUnit.degreeOfPolymerisation.degree",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "repeat.repeatUnit.degreeOfPolymerisation.amount",
        "Type": "SubstanceAmount",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "repeat.repeatUnit.structuralRepresentation",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "repeat.repeatUnit.structuralRepresentation.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "repeat.repeatUnit.structuralRepresentation.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "repeat.repeatUnit.structuralRepresentation.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "repeat.repeatUnit.structuralRepresentation.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "repeat.repeatUnit.structuralRepresentation.representation",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "repeat.repeatUnit.structuralRepresentation.attachment",
        "Type": "Attachment",
        
        
        "Required": "False",
        "Description": "Todo."
      }
    ],
    "SubstanceProtein": [
      {
        "Data Fields": "resourceType",
        "Type": "SubstanceProtein",
        
        
        "Required": "True",
        "Description": "This is a SubstanceProtein resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "sequenceType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The SubstanceProtein descriptive elements will only be used when a complete or partial amino acid sequence is available or derivable from a nucleic acid sequence."
      },
      {
        "Data Fields": "numberOfSubunits",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "Number of linear sequences of amino acids linked through peptide bonds. The number of subunits constituting the SubstanceProtein shall be described. It is possible that the number of subunits can be variable."
      },
      {
        "Data Fields": "disulfideLinkage",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The disulphide bond between two cysteine residues either on the same subunit or on two different subunits shall be described. The position of the disulfide bonds in the SubstanceProtein shall be listed in increasing order of subunit number and position within subunit followed by the abbreviation of the amino acids involved. The disulfide linkage positions shall actually contain the amino acid Cysteine at the respective positions."
      },
      {
        "Data Fields": "subunit",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "This subclause refers to the description of each subunit constituting the SubstanceProtein. A subunit is a linear sequence of amino acids linked through peptide bonds. The Subunit information shall be provided when the finished SubstanceProtein is a complex of multiple sequences; subunits are not used to delineate domains within a single sequence. Subunits are listed in order of decreasing length; sequences of the same length will be ordered by decreasing molecular weight; subunits that have identical sequences will be repeated multiple times."
      },
      {
        "Data Fields": "subunit.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "subunit.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "subunit.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "subunit.sequence",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The sequence information shall be provided enumerating the amino acids from N- to C-terminal end using standard single-letter amino acid codes. Uppercase shall be used for L-amino acids and lowercase for D-amino acids. Transcribed SubstanceProteins will always be described using the translated sequence; for synthetic peptide containing amino acids that are not represented with a single letter code an X should be used within the sequence. The modified amino acids will be distinguished by their position in the sequence."
      },
      {
        "Data Fields": "subunit.length",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "Length of linear sequences of amino acids contained in the subunit."
      },
      {
        "Data Fields": "subunit.sequenceAttachment",
        "Type": "Attachment",
        
        
        "Required": "False",
        "Description": "The sequence information shall be provided enumerating the amino acids from N- to C-terminal end using standard single-letter amino acid codes. Uppercase shall be used for L-amino acids and lowercase for D-amino acids. Transcribed SubstanceProteins will always be described using the translated sequence; for synthetic peptide containing amino acids that are not represented with a single letter code an X should be used within the sequence. The modified amino acids will be distinguished by their position in the sequence."
      },
      {
        "Data Fields": "subunit.nTerminalModificationId",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Unique identifier for molecular fragment modification based on the ISO 11238 Substance ID."
      },
      {
        "Data Fields": "subunit.nTerminalModification",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the fragment modified at the N-terminal of the SubstanceProtein shall be specified."
      },
      {
        "Data Fields": "subunit.cTerminalModificationId",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Unique identifier for molecular fragment modification based on the ISO 11238 Substance ID."
      },
      {
        "Data Fields": "subunit.cTerminalModification",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The modification at the C-terminal shall be specified."
      }
    ],
    "SubstanceReferenceInformation": [
      {
        "Data Fields": "resourceType",
        "Type": "SubstanceReferenceInformation",
        
        
        "Required": "True",
        "Description": "This is a SubstanceReferenceInformation resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "comment",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "gene",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "gene.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "gene.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "gene.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "gene.geneSequenceOrigin",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "gene.source",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "geneElement",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "geneElement.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "geneElement.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "geneElement.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "geneElement.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "geneElement.element",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "geneElement.source",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "classification",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "classification.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "classification.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "classification.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "classification.domain",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "classification.subtype",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "classification.source",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "target",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "target.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "target.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "target.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "target.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "target.interaction",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "target.organism",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "target.organismType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "target.amountQuantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "target.amountRange",
        "Type": "Range",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "target.amountString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "target.amountType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Todo."
      },
      {
        "Data Fields": "target.source",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Todo."
      }
    ],
    "SubstanceSourceMaterial": [
      {
        "Data Fields": "resourceType",
        "Type": "SubstanceSourceMaterial",
        
        
        "Required": "True",
        "Description": "This is a SubstanceSourceMaterial resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "sourceMaterialClass",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "General high level classification of the source material specific to the origin of the material."
      },
      {
        "Data Fields": "sourceMaterialType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The type of the source material shall be specified based on a controlled vocabulary. For vaccines, this subclause refers to the class of infectious agent."
      },
      {
        "Data Fields": "sourceMaterialState",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The state of the source material when extracted."
      },
      {
        "Data Fields": "organismId",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "The unique identifier associated with the source material parent organism shall be specified."
      },
      {
        "Data Fields": "organismName",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The organism accepted Scientific name shall be provided based on the organism taxonomy."
      },
      {
        "Data Fields": "parentSubstanceId",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "The parent of the herbal drug Ginkgo biloba, Leaf is the substance ID of the substance (fresh) of Ginkgo biloba L. or Ginkgo biloba L. (Whole plant)."
      },
      {
        "Data Fields": "parentSubstanceName",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The parent substance of the Herbal Drug, or Herbal preparation."
      },
      {
        "Data Fields": "countryOfOrigin",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The country where the plant material is harvested or the countries where the plasma is sourced from as laid down in accordance with the Plasma Master File. For “Plasma-derived substances” the attribute country of origin provides information about the countries used for the manufacturing of the Cryopoor plama or Crioprecipitate."
      },
      {
        "Data Fields": "geographicalLocation",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The place/region where the plant is harvested or the places/regions where the animal source material has its habitat."
      },
      {
        "Data Fields": "developmentStage",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Stage of life for animals, plants, insects and microorganisms. This information shall be provided only when the substance is significantly different in these stages (e.g. foetal bovine serum)."
      },
      {
        "Data Fields": "fractionDescription",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Many complex materials are fractions of parts of plants, animals, or minerals. Fraction elements are often necessary to define both Substances and Specified Group 1 Substances. For substances derived from Plants, fraction information will be captured at the Substance information level ( . Oils, Juices and Exudates). Additional information for Extracts, such as extraction solvent composition, will be captured at the Specified Substance Group 1 information level. For plasma-derived products fraction information will be captured at the Substance and the Specified Substance Group 1 levels."
      },
      {
        "Data Fields": "fractionDescription.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "fractionDescription.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "fractionDescription.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "fractionDescription.fraction",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "This element is capturing information about the fraction of a plant part, or human plasma for fractionation."
      },
      {
        "Data Fields": "fractionDescription.materialType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The specific type of the material constituting the component. For Herbal preparations the particulars of the extracts (liquid/dry) is described in Specified Substance Group 1."
      },
      {
        "Data Fields": "organism",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "This subclause describes the organism which the substance is derived from. For vaccines, the parent organism shall be specified based on these subclause elements. As an example, full taxonomy will be described for the Substance Name: ., Leaf."
      },
      {
        "Data Fields": "organism.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "organism.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "organism.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "organism.family",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The family of an organism shall be specified."
      },
      {
        "Data Fields": "organism.genus",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The genus of an organism shall be specified; refers to the Latin epithet of the genus element of the plant/animal scientific name; it is present in names for genera, species and infraspecies."
      },
      {
        "Data Fields": "organism.species",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The species of an organism shall be specified; refers to the Latin epithet of the species of the plant/animal; it is present in names for species and infraspecies."
      },
      {
        "Data Fields": "organism.intraspecificType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The Intraspecific type of an organism shall be specified."
      },
      {
        "Data Fields": "organism.intraspecificDescription",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The intraspecific description of an organism shall be specified based on a controlled vocabulary. For Influenza Vaccine, the intraspecific description shall contain the syntax of the antigen in line with the WHO convention."
      },
      {
        "Data Fields": "organism.author",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "4.9.13.6.1 Author type (Conditional)."
      },
      {
        "Data Fields": "organism.author.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "organism.author.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "organism.author.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "organism.author.authorType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The type of author of an organism species shall be specified. The parenthetical author of an organism species refers to the first author who published the plant/animal name (of any rank). The primary author of an organism species refers to the first author(s), who validly published the plant/animal name."
      },
      {
        "Data Fields": "organism.author.authorDescription",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The author of an organism species shall be specified. The author year of an organism shall also be specified when applicable; refers to the year in which the first author(s) published the infraspecific plant/animal name (of any rank)."
      },
      {
        "Data Fields": "organism.hybrid",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "4.9.13.8.1 Hybrid species maternal organism ID (Optional)."
      },
      {
        "Data Fields": "organism.hybrid.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "organism.hybrid.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "organism.hybrid.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "organism.hybrid.maternalOrganismId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The identifier of the maternal species constituting the hybrid organism shall be specified based on a controlled vocabulary. For plants, the parents aren’t always known, and it is unlikely that it will be known which is maternal and which is paternal."
      },
      {
        "Data Fields": "organism.hybrid.maternalOrganismName",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the maternal species constituting the hybrid organism shall be specified. For plants, the parents aren’t always known, and it is unlikely that it will be known which is maternal and which is paternal."
      },
      {
        "Data Fields": "organism.hybrid.paternalOrganismId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The identifier of the paternal species constituting the hybrid organism shall be specified based on a controlled vocabulary."
      },
      {
        "Data Fields": "organism.hybrid.paternalOrganismName",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the paternal species constituting the hybrid organism shall be specified."
      },
      {
        "Data Fields": "organism.hybrid.hybridType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The hybrid type of an organism shall be specified."
      },
      {
        "Data Fields": "organism.organismGeneral",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "4.9.13.7.1 Kingdom (Conditional)."
      },
      {
        "Data Fields": "organism.organismGeneral.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "organism.organismGeneral.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "organism.organismGeneral.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "organism.organismGeneral.kingdom",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The kingdom of an organism shall be specified."
      },
      {
        "Data Fields": "organism.organismGeneral.phylum",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The phylum of an organism shall be specified."
      },
      {
        "Data Fields": "organism.organismGeneral.class",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The class of an organism shall be specified."
      },
      {
        "Data Fields": "organism.organismGeneral.order",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The order of an organism shall be specified,."
      },
      {
        "Data Fields": "partDescription",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "To do."
      },
      {
        "Data Fields": "partDescription.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "partDescription.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "partDescription.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "partDescription.part",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Entity of anatomical origin of source material within an organism."
      },
      {
        "Data Fields": "partDescription.partLocation",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The detailed anatomic location when the part can be extracted from different anatomical locations of the organism. Multiple alternative locations may apply."
      }
    ],
    "SubstanceSpecification": [
      {
        "Data Fields": "resourceType",
        "Type": "SubstanceSpecification",
        
        
        "Required": "True",
        "Description": "This is a SubstanceSpecification resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Identifier by which this substance is known."
      },
      {
        "Data Fields": "type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "High level categorization, e.g. polymer or nucleic acid."
      },
      {
        "Data Fields": "status",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Status of substance within the catalogue e.g. approved."
      },
      {
        "Data Fields": "domain",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "If the substance applies to only human or veterinary use."
      },
      {
        "Data Fields": "description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Textual description of the substance."
      },
      {
        "Data Fields": "source",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Supporting literature."
      },
      {
        "Data Fields": "comment",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Textual comment about this record of a substance."
      },
      {
        "Data Fields": "moiety",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Moiety, for structural modifications."
      },
      {
        "Data Fields": "moiety.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "moiety.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "moiety.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "moiety.role",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Role that the moiety is playing."
      },
      {
        "Data Fields": "moiety.identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Identifier by which this moiety substance is known."
      },
      {
        "Data Fields": "moiety.name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Textual name for this moiety substance."
      },
      {
        "Data Fields": "moiety.stereochemistry",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Stereochemistry type."
      },
      {
        "Data Fields": "moiety.opticalActivity",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Optical activity type."
      },
      {
        "Data Fields": "moiety.molecularFormula",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Molecular formula."
      },
      {
        "Data Fields": "moiety.amountQuantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "Quantitative value for this moiety."
      },
      {
        "Data Fields": "moiety.amountString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Quantitative value for this moiety."
      },
      {
        "Data Fields": "property",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "General specifications for this substance, including how it is related to other substances."
      },
      {
        "Data Fields": "property.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "property.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "property.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "property.category",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A category for this property, e.g. Physical, Chemical, Enzymatic."
      },
      {
        "Data Fields": "property.code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Property type e.g. viscosity, pH, isoelectric point."
      },
      {
        "Data Fields": "property.parameters",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Parameters that were used in the measurement of a property (e.g. for viscosity: measured at 20C with a pH of 7.1)."
      },
      {
        "Data Fields": "property.definingSubstanceReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A substance upon which a defining property depends (e.g. for solubility: in water, in alcohol)."
      },
      {
        "Data Fields": "property.definingSubstanceCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A substance upon which a defining property depends (e.g. for solubility: in water, in alcohol)."
      },
      {
        "Data Fields": "property.amountQuantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "Quantitative value for this property."
      },
      {
        "Data Fields": "property.amountString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Quantitative value for this property."
      },
      {
        "Data Fields": "referenceInformation",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "General information detailing this substance."
      },
      {
        "Data Fields": "structure",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Structural information."
      },
      {
        "Data Fields": "structure.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "structure.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "structure.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "structure.stereochemistry",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Stereochemistry type."
      },
      {
        "Data Fields": "structure.opticalActivity",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Optical activity type."
      },
      {
        "Data Fields": "structure.molecularFormula",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Molecular formula."
      },
      {
        "Data Fields": "structure.molecularFormulaByMoiety",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Specified per moiety according to the Hill system, i.e. first C, then H, then alphabetical, each moiety separated by a dot."
      },
      {
        "Data Fields": "structure.isotope",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Applicable for single substances that contain a radionuclide or a non-natural isotopic ratio."
      },
      {
        "Data Fields": "structure.isotope.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "structure.isotope.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "structure.isotope.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "structure.isotope.identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Substance identifier for each non-natural or radioisotope."
      },
      {
        "Data Fields": "structure.isotope.name",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Substance name for each non-natural or radioisotope."
      },
      {
        "Data Fields": "structure.isotope.substitution",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The type of isotopic substitution present in a single substance."
      },
      {
        "Data Fields": "structure.isotope.halfLife",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "Half life - for a non-natural nuclide."
      },
      {
        "Data Fields": "structure.isotope.molecularWeight",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The molecular weight or weight range (for proteins, polymers or nucleic acids)."
      },
      {
        "Data Fields": "structure.isotope.molecularWeight.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "structure.isotope.molecularWeight.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "structure.isotope.molecularWeight.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "structure.isotope.molecularWeight.method",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The method by which the molecular weight was determined."
      },
      {
        "Data Fields": "structure.isotope.molecularWeight.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Type of molecular weight such as exact, average (also known as. number average), weight average."
      },
      {
        "Data Fields": "structure.isotope.molecularWeight.amount",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "Used to capture quantitative values for a variety of elements. If only limits are given, the arithmetic mean would be the average. If only a single definite value for a given element is given, it would be captured in this field."
      },
      {
        "Data Fields": "structure.molecularWeight",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The molecular weight or weight range (for proteins, polymers or nucleic acids)."
      },
      {
        "Data Fields": "structure.molecularWeight.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "structure.molecularWeight.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "structure.molecularWeight.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "structure.molecularWeight.method",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The method by which the molecular weight was determined."
      },
      {
        "Data Fields": "structure.molecularWeight.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Type of molecular weight such as exact, average (also known as. number average), weight average."
      },
      {
        "Data Fields": "structure.molecularWeight.amount",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "Used to capture quantitative values for a variety of elements. If only limits are given, the arithmetic mean would be the average. If only a single definite value for a given element is given, it would be captured in this field."
      },
      {
        "Data Fields": "structure.source",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Supporting literature."
      },
      {
        "Data Fields": "structure.representation",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Molecular structural representation."
      },
      {
        "Data Fields": "structure.representation.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "structure.representation.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "structure.representation.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "structure.representation.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The type of structure (e.g. Full, Partial, Representative)."
      },
      {
        "Data Fields": "structure.representation.attachment",
        "Type": "Attachment",
        
        
        "Required": "False",
        "Description": "An attached file with the structural representation."
      },
      {
        "Data Fields": "code",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Codes associated with the substance."
      },
      {
        "Data Fields": "code.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "code.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "code.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "code.status",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Status of the code assignment."
      },
      {
        "Data Fields": "code.statusDate",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date at which the code status is changed as part of the terminology maintenance."
      },
      {
        "Data Fields": "code.comment",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Any comment can be provided in this field, if necessary."
      },
      {
        "Data Fields": "code.source",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Supporting literature."
      },
      {
        "Data Fields": "name",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Names applicable to this substance."
      },
      {
        "Data Fields": "name.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "name.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "name.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "name.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Name type."
      },
      {
        "Data Fields": "name.status",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The status of the name."
      },
      {
        "Data Fields": "name.preferred",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "If this is the preferred name for this substance."
      },
      {
        "Data Fields": "name.language",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Language of the name."
      },
      {
        "Data Fields": "name.domain",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The use context of this name for example if there is a different name a drug active ingredient as opposed to a food colour additive."
      },
      {
        "Data Fields": "name.jurisdiction",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The jurisdiction where this name applies."
      },
      {
        "Data Fields": "name.synonym",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A synonym of this name."
      },
      {
        "Data Fields": "name.synonym.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "name.synonym.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "name.synonym.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "name.synonym.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Name type."
      },
      {
        "Data Fields": "name.synonym.status",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The status of the name."
      },
      {
        "Data Fields": "name.synonym.preferred",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "If this is the preferred name for this substance."
      },
      {
        "Data Fields": "name.synonym.language",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Language of the name."
      },
      {
        "Data Fields": "name.synonym.domain",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The use context of this name for example if there is a different name a drug active ingredient as opposed to a food colour additive."
      },
      {
        "Data Fields": "name.synonym.jurisdiction",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The jurisdiction where this name applies."
      },
      {
        "Data Fields": "name.synonym.translation",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A translation for this name."
      },
      {
        "Data Fields": "name.synonym.translation.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "name.synonym.translation.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "name.synonym.translation.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "name.synonym.translation.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Name type."
      },
      {
        "Data Fields": "name.synonym.translation.status",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The status of the name."
      },
      {
        "Data Fields": "name.synonym.translation.preferred",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "If this is the preferred name for this substance."
      },
      {
        "Data Fields": "name.synonym.translation.language",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Language of the name."
      },
      {
        "Data Fields": "name.synonym.translation.domain",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The use context of this name for example if there is a different name a drug active ingredient as opposed to a food colour additive."
      },
      {
        "Data Fields": "name.synonym.translation.jurisdiction",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The jurisdiction where this name applies."
      },
      {
        "Data Fields": "name.synonym.translation.official",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Details of the official nature of this name."
      },
      {
        "Data Fields": "name.synonym.translation.official.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "name.synonym.translation.official.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "name.synonym.translation.official.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "name.synonym.translation.official.authority",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Which authority uses this official name."
      },
      {
        "Data Fields": "name.synonym.translation.official.status",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The status of the official name."
      },
      {
        "Data Fields": "name.synonym.translation.official.date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "Date of official name change."
      },
      {
        "Data Fields": "name.synonym.translation.source",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Supporting literature."
      },
      {
        "Data Fields": "name.synonym.official",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Details of the official nature of this name."
      },
      {
        "Data Fields": "name.synonym.official.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "name.synonym.official.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "name.synonym.official.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "name.synonym.official.authority",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Which authority uses this official name."
      },
      {
        "Data Fields": "name.synonym.official.status",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The status of the official name."
      },
      {
        "Data Fields": "name.synonym.official.date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "Date of official name change."
      },
      {
        "Data Fields": "name.synonym.source",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Supporting literature."
      },
      {
        "Data Fields": "name.translation",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A translation for this name."
      },
      {
        "Data Fields": "name.translation.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "name.translation.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "name.translation.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "name.translation.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Name type."
      },
      {
        "Data Fields": "name.translation.status",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The status of the name."
      },
      {
        "Data Fields": "name.translation.preferred",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "If this is the preferred name for this substance."
      },
      {
        "Data Fields": "name.translation.language",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Language of the name."
      },
      {
        "Data Fields": "name.translation.domain",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The use context of this name for example if there is a different name a drug active ingredient as opposed to a food colour additive."
      },
      {
        "Data Fields": "name.translation.jurisdiction",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The jurisdiction where this name applies."
      },
      {
        "Data Fields": "name.translation.synonym",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A synonym of this name."
      },
      {
        "Data Fields": "name.translation.synonym.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "name.translation.synonym.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "name.translation.synonym.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "name.translation.synonym.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Name type."
      },
      {
        "Data Fields": "name.translation.synonym.status",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The status of the name."
      },
      {
        "Data Fields": "name.translation.synonym.preferred",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "If this is the preferred name for this substance."
      },
      {
        "Data Fields": "name.translation.synonym.language",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Language of the name."
      },
      {
        "Data Fields": "name.translation.synonym.domain",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The use context of this name for example if there is a different name a drug active ingredient as opposed to a food colour additive."
      },
      {
        "Data Fields": "name.translation.synonym.jurisdiction",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The jurisdiction where this name applies."
      },
      {
        "Data Fields": "name.translation.synonym.official",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Details of the official nature of this name."
      },
      {
        "Data Fields": "name.translation.synonym.official.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "name.translation.synonym.official.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "name.translation.synonym.official.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "name.translation.synonym.official.authority",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Which authority uses this official name."
      },
      {
        "Data Fields": "name.translation.synonym.official.status",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The status of the official name."
      },
      {
        "Data Fields": "name.translation.synonym.official.date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "Date of official name change."
      },
      {
        "Data Fields": "name.translation.synonym.source",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Supporting literature."
      },
      {
        "Data Fields": "name.translation.official",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Details of the official nature of this name."
      },
      {
        "Data Fields": "name.translation.official.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "name.translation.official.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "name.translation.official.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "name.translation.official.authority",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Which authority uses this official name."
      },
      {
        "Data Fields": "name.translation.official.status",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The status of the official name."
      },
      {
        "Data Fields": "name.translation.official.date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "Date of official name change."
      },
      {
        "Data Fields": "name.translation.source",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Supporting literature."
      },
      {
        "Data Fields": "name.official",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Details of the official nature of this name."
      },
      {
        "Data Fields": "name.official.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "name.official.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "name.official.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "name.official.authority",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Which authority uses this official name."
      },
      {
        "Data Fields": "name.official.status",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The status of the official name."
      },
      {
        "Data Fields": "name.official.date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "Date of official name change."
      },
      {
        "Data Fields": "name.source",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Supporting literature."
      },
      {
        "Data Fields": "molecularWeight",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The molecular weight or weight range (for proteins, polymers or nucleic acids)."
      },
      {
        "Data Fields": "molecularWeight.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "molecularWeight.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "molecularWeight.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "molecularWeight.method",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The method by which the molecular weight was determined."
      },
      {
        "Data Fields": "molecularWeight.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Type of molecular weight such as exact, average (also known as. number average), weight average."
      },
      {
        "Data Fields": "molecularWeight.amount",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "Used to capture quantitative values for a variety of elements. If only limits are given, the arithmetic mean would be the average. If only a single definite value for a given element is given, it would be captured in this field."
      },
      {
        "Data Fields": "relationship",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A link between this substance and another, with details of the relationship."
      },
      {
        "Data Fields": "relationship.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "relationship.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "relationship.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "relationship.substanceReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A pointer to another substance, as a resource or just a representational code."
      },
      {
        "Data Fields": "relationship.substanceCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A pointer to another substance, as a resource or just a representational code."
      },
      {
        "Data Fields": "relationship.isDefining",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "For example where an enzyme strongly bonds with a particular substance, this is a defining relationship for that enzyme, out of several possible substance relationships."
      },
      {
        "Data Fields": "relationship.amountQuantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "A numeric factor for the relationship, for instance to express that the salt of a substance has some percentage of the active substance in relation to some other."
      },
      {
        "Data Fields": "relationship.amountRange",
        "Type": "Range",
        
        
        "Required": "False",
        "Description": "A numeric factor for the relationship, for instance to express that the salt of a substance has some percentage of the active substance in relation to some other."
      },
      {
        "Data Fields": "relationship.amountRatio",
        "Type": "Ratio",
        
        
        "Required": "False",
        "Description": "A numeric factor for the relationship, for instance to express that the salt of a substance has some percentage of the active substance in relation to some other."
      },
      {
        "Data Fields": "relationship.amountString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A numeric factor for the relationship, for instance to express that the salt of a substance has some percentage of the active substance in relation to some other."
      },
      {
        "Data Fields": "relationship.amountRatioLowLimit",
        "Type": "Ratio",
        
        
        "Required": "False",
        "Description": "For use when the numeric."
      },
      {
        "Data Fields": "relationship.amountType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "An operator for the amount, for example \"average\", \"approximately\", \"less than\"."
      },
      {
        "Data Fields": "relationship.source",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Supporting literature."
      },
      {
        "Data Fields": "nucleicAcid",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Data items specific to nucleic acids."
      },
      {
        "Data Fields": "polymer",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Data items specific to polymers."
      },
      {
        "Data Fields": "protein",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Data items specific to proteins."
      },
      {
        "Data Fields": "sourceMaterial",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Material or taxonomic/anatomical source for the substance."
      }
    ],
    "SupplyDelivery": [
      {
        "Data Fields": "resourceType",
        "Type": "SupplyDelivery",
        
        "Synthea Availability": "True",
        "Required": "True",
        "Description": "This is a SupplyDelivery resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Identifier for the supply delivery event that is used to identify it across multiple disparate systems."
      },
      {
        "Data Fields": "basedOn",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A plan, proposal or order that is fulfilled in whole or in part by this event."
      },
      {
        "Data Fields": "partOf",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A larger event of which this particular event is a component or step."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A code specifying the state of the dispense event."
      },
      {
        "Data Fields": "patient",
        "Type": "Reference",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "A link to a resource representing the person whom the delivered item is for."
      },
      {
        "Data Fields": "type",
        "Type": "CodeableConcept",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Indicates the type of dispensing event that is performed. Examples include: Trial Fill, Completion of Trial, Partial Fill, Emergency Fill, Samples, etc."
      },
      {
        "Data Fields": "suppliedItem",
        "Type": "BackboneElement",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The item that is being delivered or has been supplied."
      },
      {
        "Data Fields": "suppliedItem.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "suppliedItem.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "suppliedItem.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "suppliedItem.quantity",
        "Type": "Quantity",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The amount of supply that has been dispensed. Includes unit of measure."
      },
      {
        "Data Fields": "suppliedItem.itemCodeableConcept",
        "Type": "CodeableConcept",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "Identifies the medication, substance or device being dispensed. This is either a link to a resource representing the details of the item or a code that identifies the item from a known list."
      },
      {
        "Data Fields": "suppliedItem.itemReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Identifies the medication, substance or device being dispensed. This is either a link to a resource representing the details of the item or a code that identifies the item from a known list."
      },
      {
        "Data Fields": "occurrenceDateTime",
        "Type": "string",
        
        "Synthea Availability": "True",
        "Required": "False",
        "Description": "The date or time(s) the activity occurred."
      },
      {
        "Data Fields": "occurrencePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The date or time(s) the activity occurred."
      },
      {
        "Data Fields": "occurrenceTiming",
        "Type": "Timing",
        
        
        "Required": "False",
        "Description": "The date or time(s) the activity occurred."
      },
      {
        "Data Fields": "supplier",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The individual responsible for dispensing the medication, supplier or device."
      },
      {
        "Data Fields": "destination",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Identification of the facility/location where the Supply was shipped to, as part of the dispense event."
      },
      {
        "Data Fields": "receiver",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Identifies the person who picked up the Supply."
      }
    ],
    "SupplyRequest": [
      {
        "Data Fields": "resourceType",
        "Type": "SupplyRequest",
        
        
        "Required": "True",
        "Description": "This is a SupplyRequest resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Business identifiers assigned to this SupplyRequest by the author and/or other systems. These identifiers remain constant as the resource is updated and propagates from server to server."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Status of the supply request."
      },
      {
        "Data Fields": "category",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Category of supply, e.g.  central, non-stock, etc. This is used to support work flows associated with the supply process."
      },
      {
        "Data Fields": "priority",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Indicates how quickly this SupplyRequest should be addressed with respect to other requests."
      },
      {
        "Data Fields": "itemCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The item that is requested to be supplied. This is either a link to a resource representing the details of the item or a code that identifies the item from a known list."
      },
      {
        "Data Fields": "itemReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The item that is requested to be supplied. This is either a link to a resource representing the details of the item or a code that identifies the item from a known list."
      },
      {
        "Data Fields": "quantity",
        "Type": "Quantity",
        
        
        "Required": "True",
        "Description": "The amount that is being ordered of the indicated item."
      },
      {
        "Data Fields": "parameter",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Specific parameters for the ordered item.  For example, the size of the indicated item."
      },
      {
        "Data Fields": "parameter.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "parameter.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "parameter.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "parameter.code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code or string that identifies the device detail being asserted."
      },
      {
        "Data Fields": "parameter.valueCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The value of the device detail."
      },
      {
        "Data Fields": "parameter.valueQuantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The value of the device detail."
      },
      {
        "Data Fields": "parameter.valueRange",
        "Type": "Range",
        
        
        "Required": "False",
        "Description": "The value of the device detail."
      },
      {
        "Data Fields": "parameter.valueBoolean",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "The value of the device detail."
      },
      {
        "Data Fields": "occurrenceDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "When the request should be fulfilled."
      },
      {
        "Data Fields": "occurrencePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "When the request should be fulfilled."
      },
      {
        "Data Fields": "occurrenceTiming",
        "Type": "Timing",
        
        
        "Required": "False",
        "Description": "When the request should be fulfilled."
      },
      {
        "Data Fields": "authoredOn",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "When the request was made."
      },
      {
        "Data Fields": "requester",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The device, practitioner, etc. who initiated the request."
      },
      {
        "Data Fields": "supplier",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Who is intended to fulfill the request."
      },
      {
        "Data Fields": "reasonCode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The reason why the supply item was requested."
      },
      {
        "Data Fields": "reasonReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The reason why the supply item was requested."
      },
      {
        "Data Fields": "deliverFrom",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Where the supply is expected to come from."
      },
      {
        "Data Fields": "deliverTo",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Where the supply is destined to go."
      }
    ],
    "Task": [
      {
        "Data Fields": "resourceType",
        "Type": "Task",
        
        
        "Required": "True",
        "Description": "This is a Task resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "The business identifier for this task."
      },
      {
        "Data Fields": "instantiatesCanonical",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "The URL pointing to a *FHIR*-defined protocol, guideline, orderset or other definition that is adhered to in whole or in part by this Task."
      },
      {
        "Data Fields": "instantiatesUri",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "The URL pointing to an *externally* maintained  protocol, guideline, orderset or other definition that is adhered to in whole or in part by this Task."
      },
      {
        "Data Fields": "basedOn",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "BasedOn refers to a higher-level authorization that triggered the creation of the task.  It references a \"request\" resource such as a ServiceRequest, MedicationRequest, ServiceRequest, CarePlan, etc. which is distinct from the \"request\" resource the task is seeking to fulfill.  This latter resource is referenced by FocusOn.  For example, based on a ServiceRequest (= BasedOn), a task is created to fulfill a procedureRequest ( = FocusOn ) to collect a specimen from a patient."
      },
      {
        "Data Fields": "groupIdentifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "An identifier that links together multiple tasks and other requests that were created in the same context."
      },
      {
        "Data Fields": "partOf",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Task that this particular task is part of."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The current status of the task."
      },
      {
        "Data Fields": "statusReason",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "An explanation as to why this task is held, failed, was refused, etc."
      },
      {
        "Data Fields": "businessStatus",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Contains business-specific nuances of the business state."
      },
      {
        "Data Fields": "intent",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Indicates the \"level\" of actionability associated with the Task, i.e. i+R[9]Cs this a proposed task, a planned task, an actionable task, etc."
      },
      {
        "Data Fields": "priority",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Indicates how quickly the Task should be addressed with respect to other requests."
      },
      {
        "Data Fields": "code",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A name or code (or both) briefly describing what the task involves."
      },
      {
        "Data Fields": "description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A free-text description of what is to be performed."
      },
      {
        "Data Fields": "focus",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The request being actioned or the resource being manipulated by this task."
      },
      {
        "Data Fields": "for",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The entity who benefits from the performance of the service specified in the task (e.g., the patient)."
      },
      {
        "Data Fields": "encounter",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The healthcare event  (e.g. a patient and healthcare provider interaction) during which this task was created."
      },
      {
        "Data Fields": "executionPeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "Identifies the time action was first taken against the task (start) and/or the time final action was taken against the task prior to marking it as completed (end)."
      },
      {
        "Data Fields": "authoredOn",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date and time this task was created."
      },
      {
        "Data Fields": "lastModified",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date and time of last modification to this task."
      },
      {
        "Data Fields": "requester",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The creator of the task."
      },
      {
        "Data Fields": "performerType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The kind of participant that should perform the task."
      },
      {
        "Data Fields": "owner",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Individual organization or Device currently responsible for task execution."
      },
      {
        "Data Fields": "location",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Principal physical location where the this task is performed."
      },
      {
        "Data Fields": "reasonCode",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A description or code indicating why this task needs to be performed."
      },
      {
        "Data Fields": "reasonReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A resource reference indicating why this task needs to be performed."
      },
      {
        "Data Fields": "insurance",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Insurance plans, coverage extensions, pre-authorizations and/or pre-determinations that may be relevant to the Task."
      },
      {
        "Data Fields": "note",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "Free-text information captured about the task as it progresses."
      },
      {
        "Data Fields": "relevantHistory",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Links to Provenance records for past versions of this Task that identify key state transitions or updates that are likely to be relevant to a user looking at the current version of the task."
      },
      {
        "Data Fields": "restriction",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "If the Task.focus is a request resource and the task is seeking fulfillment (i.e. is asking for the request to be actioned), this element identifies any limitations on what parts of the referenced request should be actioned."
      },
      {
        "Data Fields": "restriction.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "restriction.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "restriction.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "restriction.repetitions",
        "Type": "positiveInt",
        
        
        "Required": "False",
        "Description": "Indicates the number of times the requested action should occur."
      },
      {
        "Data Fields": "restriction.period",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "Over what time-period is fulfillment sought."
      },
      {
        "Data Fields": "restriction.recipient",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "For requests that are targeted to more than on potential recipient/target, for whom is fulfillment sought?"
      },
      {
        "Data Fields": "input",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Additional information that may be needed in the execution of the task."
      },
      {
        "Data Fields": "input.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "input.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "input.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "input.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A code or description indicating how the input is intended to be used as part of the task execution."
      },
      {
        "Data Fields": "input.valueBase64Binary",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueBoolean",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueCanonical",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueCode",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueDate",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueDecimal",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueInstant",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueInteger",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueMarkdown",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueOid",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valuePositiveInt",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueUnsignedInt",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueUri",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueUrl",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueUuid",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueAddress",
        "Type": "Address",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueAge",
        "Type": "Age",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueAnnotation",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueAttachment",
        "Type": "Attachment",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueCoding",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueContactPoint",
        "Type": "ContactPoint",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueCount",
        "Type": "Count",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueDistance",
        "Type": "Distance",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueDuration",
        "Type": "Duration",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueHumanName",
        "Type": "HumanName",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueIdentifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueMoney",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valuePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueQuantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueRange",
        "Type": "Range",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueRatio",
        "Type": "Ratio",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueSampledData",
        "Type": "SampledData",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueSignature",
        "Type": "Signature",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueTiming",
        "Type": "Timing",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueContactDetail",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueContributor",
        "Type": "Contributor",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueDataRequirement",
        "Type": "DataRequirement",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueExpression",
        "Type": "Expression",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueParameterDefinition",
        "Type": "ParameterDefinition",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueRelatedArtifact",
        "Type": "RelatedArtifact",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueTriggerDefinition",
        "Type": "TriggerDefinition",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueUsageContext",
        "Type": "UsageContext",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueDosage",
        "Type": "Dosage",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "input.valueMeta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The value of the input parameter as a basic type."
      },
      {
        "Data Fields": "output",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Outputs produced by the Task."
      },
      {
        "Data Fields": "output.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "output.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "output.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "output.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The name of the Output parameter."
      },
      {
        "Data Fields": "output.valueBase64Binary",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueBoolean",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueCanonical",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueCode",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueDate",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueDecimal",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueInstant",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueInteger",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueMarkdown",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueOid",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valuePositiveInt",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueUnsignedInt",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueUri",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueUrl",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueUuid",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueAddress",
        "Type": "Address",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueAge",
        "Type": "Age",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueAnnotation",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueAttachment",
        "Type": "Attachment",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueCodeableConcept",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueCoding",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueContactPoint",
        "Type": "ContactPoint",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueCount",
        "Type": "Count",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueDistance",
        "Type": "Distance",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueDuration",
        "Type": "Duration",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueHumanName",
        "Type": "HumanName",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueIdentifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueMoney",
        "Type": "Money",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valuePeriod",
        "Type": "Period",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueQuantity",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueRange",
        "Type": "Range",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueRatio",
        "Type": "Ratio",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueReference",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueSampledData",
        "Type": "SampledData",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueSignature",
        "Type": "Signature",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueTiming",
        "Type": "Timing",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueContactDetail",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueContributor",
        "Type": "Contributor",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueDataRequirement",
        "Type": "DataRequirement",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueExpression",
        "Type": "Expression",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueParameterDefinition",
        "Type": "ParameterDefinition",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueRelatedArtifact",
        "Type": "RelatedArtifact",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueTriggerDefinition",
        "Type": "TriggerDefinition",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueUsageContext",
        "Type": "UsageContext",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueDosage",
        "Type": "Dosage",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      },
      {
        "Data Fields": "output.valueMeta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The value of the Output parameter as a basic type."
      }
    ],
    "TerminologyCapabilities": [
      {
        "Data Fields": "resourceType",
        "Type": "TerminologyCapabilities",
        
        
        "Required": "True",
        "Description": "This is a TerminologyCapabilities resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "url",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "An absolute URI that is used to identify this terminology capabilities when it is referenced in a specification, model, design or an instance; also called its canonical identifier. This SHOULD be globally unique and SHOULD be a literal address at which at which an authoritative instance of this terminology capabilities is (or will be) published. This URL can be the target of a canonical reference. It SHALL remain the same when the terminology capabilities is stored on different servers."
      },
      {
        "Data Fields": "version",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The identifier that is used to identify this version of the terminology capabilities when it is referenced in a specification, model, design or instance. This is an arbitrary value managed by the terminology capabilities author and is not expected to be globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if a managed version is not available. There is also no expectation that versions can be placed in a lexicographical sequence."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A natural language name identifying the terminology capabilities. This name should be usable as an identifier for the module by machine processing applications such as code generation."
      },
      {
        "Data Fields": "title",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A short, descriptive, user-friendly title for the terminology capabilities."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The status of this terminology capabilities. Enables tracking the life-cycle of the content."
      },
      {
        "Data Fields": "experimental",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "A Boolean value to indicate that this terminology capabilities is authored for testing purposes (or education/evaluation/marketing) and is not intended to be used for genuine usage."
      },
      {
        "Data Fields": "date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date  (and optionally time) when the terminology capabilities was published. The date must change when the business version changes and it must change if the status code changes. In addition, it should change when the substantive content of the terminology capabilities changes."
      },
      {
        "Data Fields": "publisher",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the organization or individual that published the terminology capabilities."
      },
      {
        "Data Fields": "contact",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "Contact details to assist a user in finding and communicating with the publisher."
      },
      {
        "Data Fields": "description",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A free text natural language description of the terminology capabilities from a consumer's perspective. Typically, this is used when the capability statement describes a desired rather than an actual solution, for example as a formal expression of requirements as part of an RFP."
      },
      {
        "Data Fields": "useContext",
        "Type": "UsageContext",
        
        
        "Required": "False",
        "Description": "The content was developed with a focus and intent of supporting the contexts that are listed. These contexts may be general categories (gender, age, ...) or may be references to specific programs (insurance plans, studies, ...) and may be used to assist with indexing and searching for appropriate terminology capabilities instances."
      },
      {
        "Data Fields": "jurisdiction",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A legal or geographic region in which the terminology capabilities is intended to be used."
      },
      {
        "Data Fields": "purpose",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Explanation of why this terminology capabilities is needed and why it has been designed as it has."
      },
      {
        "Data Fields": "copyright",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A copyright statement relating to the terminology capabilities and/or its contents. Copyright statements are generally legal restrictions on the use and publishing of the terminology capabilities."
      },
      {
        "Data Fields": "kind",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The way that this statement is intended to be used, to describe an actual running instance of software, a particular product (kind, not instance of software) or a class of implementation (e.g. a desired purchase)."
      },
      {
        "Data Fields": "software",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Software that is covered by this terminology capability statement.  It is used when the statement describes the capabilities of a particular software version, independent of an installation."
      },
      {
        "Data Fields": "software.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "software.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "software.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "software.name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Name the software is known by."
      },
      {
        "Data Fields": "software.version",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The version identifier for the software covered by this statement."
      },
      {
        "Data Fields": "implementation",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Identifies a specific implementation instance that is described by the terminology capability statement - i.e. a particular installation, rather than the capabilities of a software program."
      },
      {
        "Data Fields": "implementation.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "implementation.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "implementation.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "implementation.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Information about the specific installation that this terminology capability statement relates to."
      },
      {
        "Data Fields": "implementation.url",
        "Type": "url",
        
        
        "Required": "False",
        "Description": "An absolute base URL for the implementation."
      },
      {
        "Data Fields": "lockedDate",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Whether the server supports lockedDate."
      },
      {
        "Data Fields": "codeSystem",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Identifies a code system that is supported by the server. If there is a no code system URL, then this declares the general assumptions a client can make about support for any CodeSystem resource."
      },
      {
        "Data Fields": "codeSystem.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "codeSystem.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "codeSystem.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "codeSystem.uri",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "URI for the Code System."
      },
      {
        "Data Fields": "codeSystem.version",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "For the code system, a list of versions that are supported by the server."
      },
      {
        "Data Fields": "codeSystem.version.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "codeSystem.version.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "codeSystem.version.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "codeSystem.version.code",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "For version-less code systems, there should be a single version with no identifier."
      },
      {
        "Data Fields": "codeSystem.version.isDefault",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "If this is the default version for this code system."
      },
      {
        "Data Fields": "codeSystem.version.compositional",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "If the compositional grammar defined by the code system is supported."
      },
      {
        "Data Fields": "codeSystem.version.language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Language Displays supported."
      },
      {
        "Data Fields": "codeSystem.version.filter",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Filter Properties supported."
      },
      {
        "Data Fields": "codeSystem.version.filter.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "codeSystem.version.filter.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "codeSystem.version.filter.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "codeSystem.version.filter.code",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Code of the property supported."
      },
      {
        "Data Fields": "codeSystem.version.filter.op",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Operations supported for the property."
      },
      {
        "Data Fields": "codeSystem.version.property",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Properties supported for $lookup."
      },
      {
        "Data Fields": "codeSystem.subsumption",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "True if subsumption is supported for this version of the code system."
      },
      {
        "Data Fields": "expansion",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Information about the [ValueSet/$expand](valueset-operation-expand.html) operation."
      },
      {
        "Data Fields": "expansion.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "expansion.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "expansion.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "expansion.hierarchical",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Whether the server can return nested value sets."
      },
      {
        "Data Fields": "expansion.paging",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Whether the server supports paging on expansion."
      },
      {
        "Data Fields": "expansion.incomplete",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Allow request for incomplete expansions?"
      },
      {
        "Data Fields": "expansion.parameter",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Supported expansion parameter."
      },
      {
        "Data Fields": "expansion.parameter.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "expansion.parameter.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "expansion.parameter.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "expansion.parameter.name",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Expansion Parameter name."
      },
      {
        "Data Fields": "expansion.parameter.documentation",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Description of support for parameter."
      },
      {
        "Data Fields": "expansion.textFilter",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Documentation about text searching works."
      },
      {
        "Data Fields": "codeSearch",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The degree to which the server supports the code search parameter on ValueSet, if it is supported."
      },
      {
        "Data Fields": "validateCode",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Information about the [ValueSet/$validate-code](valueset-operation-validate-code.html) operation."
      },
      {
        "Data Fields": "validateCode.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "validateCode.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "validateCode.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "validateCode.translations",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Whether translations are validated."
      },
      {
        "Data Fields": "translation",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Information about the [ConceptMap/$translate](conceptmap-operation-translate.html) operation."
      },
      {
        "Data Fields": "translation.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "translation.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "translation.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "translation.needsMap",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Whether the client must identify the map."
      },
      {
        "Data Fields": "closure",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Whether the $closure operation is supported."
      },
      {
        "Data Fields": "closure.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "closure.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "closure.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "closure.translation",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "If cross-system closure is supported."
      }
    ],
    "TestReport": [
      {
        "Data Fields": "resourceType",
        "Type": "TestReport",
        
        
        "Required": "True",
        "Description": "This is a TestReport resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "Identifier for the TestScript assigned for external purposes outside the context of FHIR."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A free text natural language name identifying the executed TestScript."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The current state of this test report."
      },
      {
        "Data Fields": "testScript",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "Ideally this is an absolute URL that is used to identify the version-specific TestScript that was executed, matching the `TestScript.url`."
      },
      {
        "Data Fields": "result",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The overall result from the execution of the TestScript."
      },
      {
        "Data Fields": "score",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "The final score (percentage of tests passed) resulting from the execution of the TestScript."
      },
      {
        "Data Fields": "tester",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Name of the tester producing this report (Organization or individual)."
      },
      {
        "Data Fields": "issued",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "When the TestScript was executed and this TestReport was generated."
      },
      {
        "Data Fields": "participant",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A participant in the test execution, either the execution engine, a client, or a server."
      },
      {
        "Data Fields": "participant.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "participant.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "participant.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "participant.type",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The type of participant."
      },
      {
        "Data Fields": "participant.uri",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "The uri of the participant. An absolute URL is preferred."
      },
      {
        "Data Fields": "participant.display",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The display name of the participant."
      },
      {
        "Data Fields": "setup",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The results of the series of required setup operations before the tests were executed."
      },
      {
        "Data Fields": "setup.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "setup.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "setup.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "setup.action",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Action would contain either an operation or an assertion."
      },
      {
        "Data Fields": "setup.action.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "setup.action.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "setup.action.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "setup.action.operation",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The operation performed."
      },
      {
        "Data Fields": "setup.action.operation.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "setup.action.operation.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "setup.action.operation.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "setup.action.operation.result",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The result of this operation."
      },
      {
        "Data Fields": "setup.action.operation.message",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "An explanatory message associated with the result."
      },
      {
        "Data Fields": "setup.action.operation.detail",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A link to further details on the result."
      },
      {
        "Data Fields": "setup.action.assert",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The results of the assertion performed on the previous operations."
      },
      {
        "Data Fields": "setup.action.assert.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "setup.action.assert.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "setup.action.assert.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "setup.action.assert.result",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The result of this assertion."
      },
      {
        "Data Fields": "setup.action.assert.message",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "An explanatory message associated with the result."
      },
      {
        "Data Fields": "setup.action.assert.detail",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A link to further details on the result."
      },
      {
        "Data Fields": "test",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A test executed from the test script."
      },
      {
        "Data Fields": "test.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "test.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "test.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "test.name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of this test used for tracking/logging purposes by test engines."
      },
      {
        "Data Fields": "test.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A short description of the test used by test engines for tracking and reporting purposes."
      },
      {
        "Data Fields": "test.action",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Action would contain either an operation or an assertion."
      },
      {
        "Data Fields": "test.action.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "test.action.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "test.action.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "test.action.operation",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "An operation would involve a REST request to a server."
      },
      {
        "Data Fields": "test.action.operation.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "test.action.operation.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "test.action.operation.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "test.action.operation.result",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The result of this operation."
      },
      {
        "Data Fields": "test.action.operation.message",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "An explanatory message associated with the result."
      },
      {
        "Data Fields": "test.action.operation.detail",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A link to further details on the result."
      },
      {
        "Data Fields": "test.action.assert",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The results of the assertion performed on the previous operations."
      },
      {
        "Data Fields": "test.action.assert.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "test.action.assert.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "test.action.assert.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "test.action.assert.result",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The result of this assertion."
      },
      {
        "Data Fields": "test.action.assert.message",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "An explanatory message associated with the result."
      },
      {
        "Data Fields": "test.action.assert.detail",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A link to further details on the result."
      },
      {
        "Data Fields": "teardown",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The results of the series of operations required to clean up after all the tests were executed (successfully or otherwise)."
      },
      {
        "Data Fields": "teardown.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "teardown.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "teardown.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "teardown.action",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The teardown action will only contain an operation."
      },
      {
        "Data Fields": "teardown.action.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "teardown.action.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "teardown.action.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "teardown.action.operation",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "An operation would involve a REST request to a server."
      },
      {
        "Data Fields": "teardown.action.operation.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "teardown.action.operation.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "teardown.action.operation.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "teardown.action.operation.result",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The result of this operation."
      },
      {
        "Data Fields": "teardown.action.operation.message",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "An explanatory message associated with the result."
      },
      {
        "Data Fields": "teardown.action.operation.detail",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A link to further details on the result."
      }
    ],
    "TestScript": [
      {
        "Data Fields": "resourceType",
        "Type": "TestScript",
        
        
        "Required": "True",
        "Description": "This is a TestScript resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "url",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "An absolute URI that is used to identify this test script when it is referenced in a specification, model, design or an instance; also called its canonical identifier. This SHOULD be globally unique and SHOULD be a literal address at which at which an authoritative instance of this test script is (or will be) published. This URL can be the target of a canonical reference. It SHALL remain the same when the test script is stored on different servers."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A formal identifier that is used to identify this test script when it is represented in other formats, or referenced in a specification, model, design or an instance."
      },
      {
        "Data Fields": "version",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The identifier that is used to identify this version of the test script when it is referenced in a specification, model, design or instance. This is an arbitrary value managed by the test script author and is not expected to be globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if a managed version is not available. There is also no expectation that versions can be placed in a lexicographical sequence."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A natural language name identifying the test script. This name should be usable as an identifier for the module by machine processing applications such as code generation."
      },
      {
        "Data Fields": "title",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A short, descriptive, user-friendly title for the test script."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The status of this test script. Enables tracking the life-cycle of the content."
      },
      {
        "Data Fields": "experimental",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "A Boolean value to indicate that this test script is authored for testing purposes (or education/evaluation/marketing) and is not intended to be used for genuine usage."
      },
      {
        "Data Fields": "date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date  (and optionally time) when the test script was published. The date must change when the business version changes and it must change if the status code changes. In addition, it should change when the substantive content of the test script changes."
      },
      {
        "Data Fields": "publisher",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the organization or individual that published the test script."
      },
      {
        "Data Fields": "contact",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "Contact details to assist a user in finding and communicating with the publisher."
      },
      {
        "Data Fields": "description",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A free text natural language description of the test script from a consumer's perspective."
      },
      {
        "Data Fields": "useContext",
        "Type": "UsageContext",
        
        
        "Required": "False",
        "Description": "The content was developed with a focus and intent of supporting the contexts that are listed. These contexts may be general categories (gender, age, ...) or may be references to specific programs (insurance plans, studies, ...) and may be used to assist with indexing and searching for appropriate test script instances."
      },
      {
        "Data Fields": "jurisdiction",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A legal or geographic region in which the test script is intended to be used."
      },
      {
        "Data Fields": "purpose",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Explanation of why this test script is needed and why it has been designed as it has."
      },
      {
        "Data Fields": "copyright",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A copyright statement relating to the test script and/or its contents. Copyright statements are generally legal restrictions on the use and publishing of the test script."
      },
      {
        "Data Fields": "origin",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "An abstract server used in operations within this test script in the origin element."
      },
      {
        "Data Fields": "origin.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "origin.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "origin.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "origin.index",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "Abstract name given to an origin server in this test script.  The name is provided as a number starting at 1."
      },
      {
        "Data Fields": "origin.profile",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "The type of origin profile the test system supports."
      },
      {
        "Data Fields": "destination",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "An abstract server used in operations within this test script in the destination element."
      },
      {
        "Data Fields": "destination.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "destination.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "destination.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "destination.index",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "Abstract name given to a destination server in this test script.  The name is provided as a number starting at 1."
      },
      {
        "Data Fields": "destination.profile",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "The type of destination profile the test system supports."
      },
      {
        "Data Fields": "metadata",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The required capability must exist and are assumed to function correctly on the FHIR server being tested."
      },
      {
        "Data Fields": "metadata.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "metadata.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "metadata.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "metadata.link",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A link to the FHIR specification that this test is covering."
      },
      {
        "Data Fields": "metadata.link.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "metadata.link.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "metadata.link.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "metadata.link.url",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "URL to a particular requirement or feature within the FHIR specification."
      },
      {
        "Data Fields": "metadata.link.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Short description of the link."
      },
      {
        "Data Fields": "metadata.capability",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Capabilities that must exist and are assumed to function correctly on the FHIR server being tested."
      },
      {
        "Data Fields": "metadata.capability.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "metadata.capability.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "metadata.capability.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "metadata.capability.required",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Whether or not the test execution will require the given capabilities of the server in order for this test script to execute."
      },
      {
        "Data Fields": "metadata.capability.validated",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Whether or not the test execution will validate the given capabilities of the server in order for this test script to execute."
      },
      {
        "Data Fields": "metadata.capability.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Description of the capabilities that this test script is requiring the server to support."
      },
      {
        "Data Fields": "metadata.capability.origin",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "Which origin server these requirements apply to."
      },
      {
        "Data Fields": "metadata.capability.destination",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "Which server these requirements apply to."
      },
      {
        "Data Fields": "metadata.capability.link",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "Links to the FHIR specification that describes this interaction and the resources involved in more detail."
      },
      {
        "Data Fields": "metadata.capability.capabilities",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "Minimum capabilities required of server for test script to execute successfully.   If server does not meet at a minimum the referenced capability statement, then all tests in this script are skipped."
      },
      {
        "Data Fields": "fixture",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Fixture in the test script - by reference (uri). All fixtures are required for the test script to execute."
      },
      {
        "Data Fields": "fixture.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "fixture.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "fixture.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "fixture.autocreate",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Whether or not to implicitly create the fixture during setup. If true, the fixture is automatically created on each server being tested during setup, therefore no create operation is required for this fixture in the TestScript.setup section."
      },
      {
        "Data Fields": "fixture.autodelete",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Whether or not to implicitly delete the fixture during teardown. If true, the fixture is automatically deleted on each server being tested during teardown, therefore no delete operation is required for this fixture in the TestScript.teardown section."
      },
      {
        "Data Fields": "fixture.resource",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Reference to the resource (containing the contents of the resource needed for operations)."
      },
      {
        "Data Fields": "profile",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Reference to the profile to be used for validation."
      },
      {
        "Data Fields": "variable",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Variable is set based either on element value in response body or on header field value in the response headers."
      },
      {
        "Data Fields": "variable.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "variable.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "variable.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "variable.name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Descriptive name for this variable."
      },
      {
        "Data Fields": "variable.defaultValue",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A default, hard-coded, or user-defined value for this variable."
      },
      {
        "Data Fields": "variable.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A free text natural language description of the variable and its purpose."
      },
      {
        "Data Fields": "variable.expression",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The FHIRPath expression to evaluate against the fixture body. When variables are defined, only one of either expression, headerField or path must be specified."
      },
      {
        "Data Fields": "variable.headerField",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Will be used to grab the HTTP header field value from the headers that sourceId is pointing to."
      },
      {
        "Data Fields": "variable.hint",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Displayable text string with hint help information to the user when entering a default value."
      },
      {
        "Data Fields": "variable.path",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "XPath or JSONPath to evaluate against the fixture body.  When variables are defined, only one of either expression, headerField or path must be specified."
      },
      {
        "Data Fields": "variable.sourceId",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "Fixture to evaluate the XPath/JSONPath expression or the headerField  against within this variable."
      },
      {
        "Data Fields": "setup",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A series of required setup operations before tests are executed."
      },
      {
        "Data Fields": "setup.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "setup.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "setup.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "setup.action",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Action would contain either an operation or an assertion."
      },
      {
        "Data Fields": "setup.action.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "setup.action.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "setup.action.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "setup.action.operation",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The operation to perform."
      },
      {
        "Data Fields": "setup.action.operation.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "setup.action.operation.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "setup.action.operation.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "setup.action.operation.type",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "Server interaction or operation type."
      },
      {
        "Data Fields": "setup.action.operation.resource",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The type of the resource.  See http://build.fhir.org/resourcelist.html."
      },
      {
        "Data Fields": "setup.action.operation.label",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The label would be used for tracking/logging purposes by test engines."
      },
      {
        "Data Fields": "setup.action.operation.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The description would be used by test engines for tracking and reporting purposes."
      },
      {
        "Data Fields": "setup.action.operation.accept",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The mime-type to use for RESTful operation in the 'Accept' header."
      },
      {
        "Data Fields": "setup.action.operation.contentType",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The mime-type to use for RESTful operation in the 'Content-Type' header."
      },
      {
        "Data Fields": "setup.action.operation.destination",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "The server where the request message is destined for.  Must be one of the server numbers listed in TestScript.destination section."
      },
      {
        "Data Fields": "setup.action.operation.encodeRequestUrl",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Whether or not to implicitly send the request url in encoded format. The default is true to match the standard RESTful client behavior. Set to false when communicating with a server that does not support encoded url paths."
      },
      {
        "Data Fields": "setup.action.operation.method",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The HTTP method the test engine MUST use for this operation regardless of any other operation details."
      },
      {
        "Data Fields": "setup.action.operation.origin",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "The server where the request message originates from.  Must be one of the server numbers listed in TestScript.origin section."
      },
      {
        "Data Fields": "setup.action.operation.params",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Path plus parameters after [type].  Used to set parts of the request URL explicitly."
      },
      {
        "Data Fields": "setup.action.operation.requestHeader",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Header elements would be used to set HTTP headers."
      },
      {
        "Data Fields": "setup.action.operation.requestHeader.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "setup.action.operation.requestHeader.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "setup.action.operation.requestHeader.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "setup.action.operation.requestHeader.field",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The HTTP header field e.g. \"Accept\"."
      },
      {
        "Data Fields": "setup.action.operation.requestHeader.value",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of the header e.g. \"application/fhir+xml\"."
      },
      {
        "Data Fields": "setup.action.operation.requestId",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The fixture id (maybe new) to map to the request."
      },
      {
        "Data Fields": "setup.action.operation.responseId",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The fixture id (maybe new) to map to the response."
      },
      {
        "Data Fields": "setup.action.operation.sourceId",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The id of the fixture used as the body of a PUT or POST request."
      },
      {
        "Data Fields": "setup.action.operation.targetId",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "Id of fixture used for extracting the [id],  [type], and [vid] for GET requests."
      },
      {
        "Data Fields": "setup.action.operation.url",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Complete request URL."
      },
      {
        "Data Fields": "setup.action.assert",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Evaluates the results of previous operations to determine if the server under test behaves appropriately."
      },
      {
        "Data Fields": "setup.action.assert.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "setup.action.assert.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "setup.action.assert.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "setup.action.assert.label",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The label would be used for tracking/logging purposes by test engines."
      },
      {
        "Data Fields": "setup.action.assert.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The description would be used by test engines for tracking and reporting purposes."
      },
      {
        "Data Fields": "setup.action.assert.direction",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The direction to use for the assertion."
      },
      {
        "Data Fields": "setup.action.assert.compareToSourceId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Id of the source fixture used as the contents to be evaluated by either the \"source/expression\" or \"sourceId/path\" definition."
      },
      {
        "Data Fields": "setup.action.assert.compareToSourceExpression",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The FHIRPath expression to evaluate against the source fixture. When compareToSourceId is defined, either compareToSourceExpression or compareToSourcePath must be defined, but not both."
      },
      {
        "Data Fields": "setup.action.assert.compareToSourcePath",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "XPath or JSONPath expression to evaluate against the source fixture. When compareToSourceId is defined, either compareToSourceExpression or compareToSourcePath must be defined, but not both."
      },
      {
        "Data Fields": "setup.action.assert.contentType",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The mime-type contents to compare against the request or response message 'Content-Type' header."
      },
      {
        "Data Fields": "setup.action.assert.expression",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The FHIRPath expression to be evaluated against the request or response message contents - HTTP headers and payload."
      },
      {
        "Data Fields": "setup.action.assert.headerField",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The HTTP header field name e.g. 'Location'."
      },
      {
        "Data Fields": "setup.action.assert.minimumId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The ID of a fixture.  Asserts that the response contains at a minimum the fixture specified by minimumId."
      },
      {
        "Data Fields": "setup.action.assert.navigationLinks",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Whether or not the test execution performs validation on the bundle navigation links."
      },
      {
        "Data Fields": "setup.action.assert.operator",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The operator type defines the conditional behavior of the assert. If not defined, the default is equals."
      },
      {
        "Data Fields": "setup.action.assert.path",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The XPath or JSONPath expression to be evaluated against the fixture representing the response received from server."
      },
      {
        "Data Fields": "setup.action.assert.requestMethod",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The request method or HTTP operation code to compare against that used by the client system under test."
      },
      {
        "Data Fields": "setup.action.assert.requestURL",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value to use in a comparison against the request URL path string."
      },
      {
        "Data Fields": "setup.action.assert.resource",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The type of the resource.  See http://build.fhir.org/resourcelist.html."
      },
      {
        "Data Fields": "setup.action.assert.response",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "okay | created | noContent | notModified | bad | forbidden | notFound | methodNotAllowed | conflict | gone | preconditionFailed | unprocessable."
      },
      {
        "Data Fields": "setup.action.assert.responseCode",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of the HTTP response code to be tested."
      },
      {
        "Data Fields": "setup.action.assert.sourceId",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "Fixture to evaluate the XPath/JSONPath expression or the headerField  against."
      },
      {
        "Data Fields": "setup.action.assert.validateProfileId",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The ID of the Profile to validate against."
      },
      {
        "Data Fields": "setup.action.assert.value",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value to compare to."
      },
      {
        "Data Fields": "setup.action.assert.warningOnly",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Whether or not the test execution will produce a warning only on error for this assert."
      },
      {
        "Data Fields": "test",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A test in this script."
      },
      {
        "Data Fields": "test.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "test.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "test.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "test.name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of this test used for tracking/logging purposes by test engines."
      },
      {
        "Data Fields": "test.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A short description of the test used by test engines for tracking and reporting purposes."
      },
      {
        "Data Fields": "test.action",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Action would contain either an operation or an assertion."
      },
      {
        "Data Fields": "test.action.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "test.action.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "test.action.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "test.action.operation",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "An operation would involve a REST request to a server."
      },
      {
        "Data Fields": "test.action.operation.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "test.action.operation.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "test.action.operation.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "test.action.operation.type",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "Server interaction or operation type."
      },
      {
        "Data Fields": "test.action.operation.resource",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The type of the resource.  See http://build.fhir.org/resourcelist.html."
      },
      {
        "Data Fields": "test.action.operation.label",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The label would be used for tracking/logging purposes by test engines."
      },
      {
        "Data Fields": "test.action.operation.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The description would be used by test engines for tracking and reporting purposes."
      },
      {
        "Data Fields": "test.action.operation.accept",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The mime-type to use for RESTful operation in the 'Accept' header."
      },
      {
        "Data Fields": "test.action.operation.contentType",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The mime-type to use for RESTful operation in the 'Content-Type' header."
      },
      {
        "Data Fields": "test.action.operation.destination",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "The server where the request message is destined for.  Must be one of the server numbers listed in TestScript.destination section."
      },
      {
        "Data Fields": "test.action.operation.encodeRequestUrl",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Whether or not to implicitly send the request url in encoded format. The default is true to match the standard RESTful client behavior. Set to false when communicating with a server that does not support encoded url paths."
      },
      {
        "Data Fields": "test.action.operation.method",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The HTTP method the test engine MUST use for this operation regardless of any other operation details."
      },
      {
        "Data Fields": "test.action.operation.origin",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "The server where the request message originates from.  Must be one of the server numbers listed in TestScript.origin section."
      },
      {
        "Data Fields": "test.action.operation.params",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Path plus parameters after [type].  Used to set parts of the request URL explicitly."
      },
      {
        "Data Fields": "test.action.operation.requestHeader",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Header elements would be used to set HTTP headers."
      },
      {
        "Data Fields": "test.action.operation.requestHeader.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "test.action.operation.requestHeader.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "test.action.operation.requestHeader.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "test.action.operation.requestHeader.field",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The HTTP header field e.g. \"Accept\"."
      },
      {
        "Data Fields": "test.action.operation.requestHeader.value",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of the header e.g. \"application/fhir+xml\"."
      },
      {
        "Data Fields": "test.action.operation.requestId",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The fixture id (maybe new) to map to the request."
      },
      {
        "Data Fields": "test.action.operation.responseId",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The fixture id (maybe new) to map to the response."
      },
      {
        "Data Fields": "test.action.operation.sourceId",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The id of the fixture used as the body of a PUT or POST request."
      },
      {
        "Data Fields": "test.action.operation.targetId",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "Id of fixture used for extracting the [id],  [type], and [vid] for GET requests."
      },
      {
        "Data Fields": "test.action.operation.url",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Complete request URL."
      },
      {
        "Data Fields": "test.action.assert",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Evaluates the results of previous operations to determine if the server under test behaves appropriately."
      },
      {
        "Data Fields": "test.action.assert.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "test.action.assert.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "test.action.assert.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "test.action.assert.label",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The label would be used for tracking/logging purposes by test engines."
      },
      {
        "Data Fields": "test.action.assert.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The description would be used by test engines for tracking and reporting purposes."
      },
      {
        "Data Fields": "test.action.assert.direction",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The direction to use for the assertion."
      },
      {
        "Data Fields": "test.action.assert.compareToSourceId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Id of the source fixture used as the contents to be evaluated by either the \"source/expression\" or \"sourceId/path\" definition."
      },
      {
        "Data Fields": "test.action.assert.compareToSourceExpression",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The FHIRPath expression to evaluate against the source fixture. When compareToSourceId is defined, either compareToSourceExpression or compareToSourcePath must be defined, but not both."
      },
      {
        "Data Fields": "test.action.assert.compareToSourcePath",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "XPath or JSONPath expression to evaluate against the source fixture. When compareToSourceId is defined, either compareToSourceExpression or compareToSourcePath must be defined, but not both."
      },
      {
        "Data Fields": "test.action.assert.contentType",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The mime-type contents to compare against the request or response message 'Content-Type' header."
      },
      {
        "Data Fields": "test.action.assert.expression",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The FHIRPath expression to be evaluated against the request or response message contents - HTTP headers and payload."
      },
      {
        "Data Fields": "test.action.assert.headerField",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The HTTP header field name e.g. 'Location'."
      },
      {
        "Data Fields": "test.action.assert.minimumId",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The ID of a fixture.  Asserts that the response contains at a minimum the fixture specified by minimumId."
      },
      {
        "Data Fields": "test.action.assert.navigationLinks",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Whether or not the test execution performs validation on the bundle navigation links."
      },
      {
        "Data Fields": "test.action.assert.operator",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The operator type defines the conditional behavior of the assert. If not defined, the default is equals."
      },
      {
        "Data Fields": "test.action.assert.path",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The XPath or JSONPath expression to be evaluated against the fixture representing the response received from server."
      },
      {
        "Data Fields": "test.action.assert.requestMethod",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The request method or HTTP operation code to compare against that used by the client system under test."
      },
      {
        "Data Fields": "test.action.assert.requestURL",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value to use in a comparison against the request URL path string."
      },
      {
        "Data Fields": "test.action.assert.resource",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The type of the resource.  See http://build.fhir.org/resourcelist.html."
      },
      {
        "Data Fields": "test.action.assert.response",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "okay | created | noContent | notModified | bad | forbidden | notFound | methodNotAllowed | conflict | gone | preconditionFailed | unprocessable."
      },
      {
        "Data Fields": "test.action.assert.responseCode",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of the HTTP response code to be tested."
      },
      {
        "Data Fields": "test.action.assert.sourceId",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "Fixture to evaluate the XPath/JSONPath expression or the headerField  against."
      },
      {
        "Data Fields": "test.action.assert.validateProfileId",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The ID of the Profile to validate against."
      },
      {
        "Data Fields": "test.action.assert.value",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value to compare to."
      },
      {
        "Data Fields": "test.action.assert.warningOnly",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Whether or not the test execution will produce a warning only on error for this assert."
      },
      {
        "Data Fields": "teardown",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A series of operations required to clean up after all the tests are executed (successfully or otherwise)."
      },
      {
        "Data Fields": "teardown.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "teardown.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "teardown.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "teardown.action",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The teardown action will only contain an operation."
      },
      {
        "Data Fields": "teardown.action.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "teardown.action.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "teardown.action.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "teardown.action.operation",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "An operation would involve a REST request to a server."
      },
      {
        "Data Fields": "teardown.action.operation.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "teardown.action.operation.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "teardown.action.operation.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "teardown.action.operation.type",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "Server interaction or operation type."
      },
      {
        "Data Fields": "teardown.action.operation.resource",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The type of the resource.  See http://build.fhir.org/resourcelist.html."
      },
      {
        "Data Fields": "teardown.action.operation.label",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The label would be used for tracking/logging purposes by test engines."
      },
      {
        "Data Fields": "teardown.action.operation.description",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The description would be used by test engines for tracking and reporting purposes."
      },
      {
        "Data Fields": "teardown.action.operation.accept",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The mime-type to use for RESTful operation in the 'Accept' header."
      },
      {
        "Data Fields": "teardown.action.operation.contentType",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The mime-type to use for RESTful operation in the 'Content-Type' header."
      },
      {
        "Data Fields": "teardown.action.operation.destination",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "The server where the request message is destined for.  Must be one of the server numbers listed in TestScript.destination section."
      },
      {
        "Data Fields": "teardown.action.operation.encodeRequestUrl",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Whether or not to implicitly send the request url in encoded format. The default is true to match the standard RESTful client behavior. Set to false when communicating with a server that does not support encoded url paths."
      },
      {
        "Data Fields": "teardown.action.operation.method",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The HTTP method the test engine MUST use for this operation regardless of any other operation details."
      },
      {
        "Data Fields": "teardown.action.operation.origin",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "The server where the request message originates from.  Must be one of the server numbers listed in TestScript.origin section."
      },
      {
        "Data Fields": "teardown.action.operation.params",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Path plus parameters after [type].  Used to set parts of the request URL explicitly."
      },
      {
        "Data Fields": "teardown.action.operation.requestHeader",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Header elements would be used to set HTTP headers."
      },
      {
        "Data Fields": "teardown.action.operation.requestHeader.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "teardown.action.operation.requestHeader.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "teardown.action.operation.requestHeader.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "teardown.action.operation.requestHeader.field",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The HTTP header field e.g. \"Accept\"."
      },
      {
        "Data Fields": "teardown.action.operation.requestHeader.value",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of the header e.g. \"application/fhir+xml\"."
      },
      {
        "Data Fields": "teardown.action.operation.requestId",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The fixture id (maybe new) to map to the request."
      },
      {
        "Data Fields": "teardown.action.operation.responseId",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The fixture id (maybe new) to map to the response."
      },
      {
        "Data Fields": "teardown.action.operation.sourceId",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The id of the fixture used as the body of a PUT or POST request."
      },
      {
        "Data Fields": "teardown.action.operation.targetId",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "Id of fixture used for extracting the [id],  [type], and [vid] for GET requests."
      },
      {
        "Data Fields": "teardown.action.operation.url",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Complete request URL."
      }
    ],
    "ValueSet": [
      {
        "Data Fields": "resourceType",
        "Type": "ValueSet",
        
        
        "Required": "True",
        "Description": "This is a ValueSet resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "url",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "An absolute URI that is used to identify this value set when it is referenced in a specification, model, design or an instance; also called its canonical identifier. This SHOULD be globally unique and SHOULD be a literal address at which at which an authoritative instance of this value set is (or will be) published. This URL can be the target of a canonical reference. It SHALL remain the same when the value set is stored on different servers."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A formal identifier that is used to identify this value set when it is represented in other formats, or referenced in a specification, model, design or an instance."
      },
      {
        "Data Fields": "version",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The identifier that is used to identify this version of the value set when it is referenced in a specification, model, design or instance. This is an arbitrary value managed by the value set author and is not expected to be globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if a managed version is not available. There is also no expectation that versions can be placed in a lexicographical sequence."
      },
      {
        "Data Fields": "name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A natural language name identifying the value set. This name should be usable as an identifier for the module by machine processing applications such as code generation."
      },
      {
        "Data Fields": "title",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A short, descriptive, user-friendly title for the value set."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The status of this value set. Enables tracking the life-cycle of the content. The status of the value set applies to the value set definition (ValueSet.compose) and the associated ValueSet metadata. Expansions do not have a state."
      },
      {
        "Data Fields": "experimental",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "A Boolean value to indicate that this value set is authored for testing purposes (or education/evaluation/marketing) and is not intended to be used for genuine usage."
      },
      {
        "Data Fields": "date",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date (and optionally time) when the value set was created or revised (e.g. the 'content logical definition')."
      },
      {
        "Data Fields": "publisher",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The name of the organization or individual that published the value set."
      },
      {
        "Data Fields": "contact",
        "Type": "ContactDetail",
        
        
        "Required": "False",
        "Description": "Contact details to assist a user in finding and communicating with the publisher."
      },
      {
        "Data Fields": "description",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A free text natural language description of the value set from a consumer's perspective. The textual description specifies the span of meanings for concepts to be included within the Value Set Expansion, and also may specify the intended use and limitations of the Value Set."
      },
      {
        "Data Fields": "useContext",
        "Type": "UsageContext",
        
        
        "Required": "False",
        "Description": "The content was developed with a focus and intent of supporting the contexts that are listed. These contexts may be general categories (gender, age, ...) or may be references to specific programs (insurance plans, studies, ...) and may be used to assist with indexing and searching for appropriate value set instances."
      },
      {
        "Data Fields": "jurisdiction",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "A legal or geographic region in which the value set is intended to be used."
      },
      {
        "Data Fields": "immutable",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "If this is set to 'true', then no new versions of the content logical definition can be created.  Note: Other metadata might still change."
      },
      {
        "Data Fields": "purpose",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "Explanation of why this value set is needed and why it has been designed as it has."
      },
      {
        "Data Fields": "copyright",
        "Type": "markdown",
        
        
        "Required": "False",
        "Description": "A copyright statement relating to the value set and/or its contents. Copyright statements are generally legal restrictions on the use and publishing of the value set."
      },
      {
        "Data Fields": "compose",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A set of criteria that define the contents of the value set by including or excluding codes selected from the specified code system(s) that the value set draws from. This is also known as the Content Logical Definition (CLD)."
      },
      {
        "Data Fields": "compose.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "compose.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "compose.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "compose.lockedDate",
        "Type": "date",
        
        
        "Required": "False",
        "Description": "The Locked Date is  the effective date that is used to determine the version of all referenced Code Systems and Value Set Definitions included in the compose that are not already tied to a specific version."
      },
      {
        "Data Fields": "compose.inactive",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "Whether inactive codes - codes that are not approved for current use - are in the value set. If inactive = true, inactive codes are to be included in the expansion, if inactive = false, the inactive codes will not be included in the expansion. If absent, the behavior is determined by the implementation, or by the applicable $expand parameters (but generally, inactive codes would be expected to be included)."
      },
      {
        "Data Fields": "compose.include",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Include one or more codes from a code system or other value set(s)."
      },
      {
        "Data Fields": "compose.include.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "compose.include.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "compose.include.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "compose.include.system",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "An absolute URI which is the code system from which the selected codes come from."
      },
      {
        "Data Fields": "compose.include.version",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The version of the code system that the codes are selected from, or the special version '*' for all versions."
      },
      {
        "Data Fields": "compose.include.concept",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Specifies a concept to be included or excluded."
      },
      {
        "Data Fields": "compose.include.concept.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "compose.include.concept.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "compose.include.concept.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "compose.include.concept.code",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Specifies a code for the concept to be included or excluded."
      },
      {
        "Data Fields": "compose.include.concept.display",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The text to display to the user for this concept in the context of this valueset. If no display is provided, then applications using the value set use the display specified for the code by the system."
      },
      {
        "Data Fields": "compose.include.concept.designation",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Additional representations for this concept when used in this value set - other languages, aliases, specialized purposes, used for particular purposes, etc."
      },
      {
        "Data Fields": "compose.include.concept.designation.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "compose.include.concept.designation.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "compose.include.concept.designation.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "compose.include.concept.designation.language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The language this designation is defined for."
      },
      {
        "Data Fields": "compose.include.concept.designation.use",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "A code that represents types of uses of designations."
      },
      {
        "Data Fields": "compose.include.concept.designation.value",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The text value for this designation."
      },
      {
        "Data Fields": "compose.include.filter",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Select concepts by specify a matching criterion based on the properties (including relationships) defined by the system, or on filters defined by the system. If multiple filters are specified, they SHALL all be true."
      },
      {
        "Data Fields": "compose.include.filter.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "compose.include.filter.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "compose.include.filter.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "compose.include.filter.property",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "A code that identifies a property or a filter defined in the code system."
      },
      {
        "Data Fields": "compose.include.filter.op",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The kind of operation to perform as a part of the filter criteria."
      },
      {
        "Data Fields": "compose.include.filter.value",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The match value may be either a code defined by the system, or a string value, which is a regex match on the literal string of the property value  (if the filter represents a property defined in CodeSystem) or of the system filter value (if the filter represents a filter defined in CodeSystem) when the operation is 'regex', or one of the values (true and false), when the operation is 'exists'."
      },
      {
        "Data Fields": "compose.include.valueSet",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "Selects the concepts found in this value set (based on its value set definition). This is an absolute URI that is a reference to ValueSet.url.  If multiple value sets are specified this includes the union of the contents of all of the referenced value sets."
      },
      {
        "Data Fields": "compose.exclude",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Exclude one or more codes from the value set based on code system filters and/or other value sets."
      },
      {
        "Data Fields": "compose.exclude.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "compose.exclude.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "compose.exclude.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "compose.exclude.system",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "An absolute URI which is the code system from which the selected codes come from."
      },
      {
        "Data Fields": "compose.exclude.version",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The version of the code system that the codes are selected from, or the special version '*' for all versions."
      },
      {
        "Data Fields": "compose.exclude.concept",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Specifies a concept to be included or excluded."
      },
      {
        "Data Fields": "compose.exclude.concept.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "compose.exclude.concept.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "compose.exclude.concept.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "compose.exclude.concept.code",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "Specifies a code for the concept to be included or excluded."
      },
      {
        "Data Fields": "compose.exclude.concept.display",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The text to display to the user for this concept in the context of this valueset. If no display is provided, then applications using the value set use the display specified for the code by the system."
      },
      {
        "Data Fields": "compose.exclude.concept.designation",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Additional representations for this concept when used in this value set - other languages, aliases, specialized purposes, used for particular purposes, etc."
      },
      {
        "Data Fields": "compose.exclude.concept.designation.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "compose.exclude.concept.designation.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "compose.exclude.concept.designation.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "compose.exclude.concept.designation.language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The language this designation is defined for."
      },
      {
        "Data Fields": "compose.exclude.concept.designation.use",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "A code that represents types of uses of designations."
      },
      {
        "Data Fields": "compose.exclude.concept.designation.value",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The text value for this designation."
      },
      {
        "Data Fields": "compose.exclude.filter",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Select concepts by specify a matching criterion based on the properties (including relationships) defined by the system, or on filters defined by the system. If multiple filters are specified, they SHALL all be true."
      },
      {
        "Data Fields": "compose.exclude.filter.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "compose.exclude.filter.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "compose.exclude.filter.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "compose.exclude.filter.property",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "A code that identifies a property or a filter defined in the code system."
      },
      {
        "Data Fields": "compose.exclude.filter.op",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The kind of operation to perform as a part of the filter criteria."
      },
      {
        "Data Fields": "compose.exclude.filter.value",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The match value may be either a code defined by the system, or a string value, which is a regex match on the literal string of the property value  (if the filter represents a property defined in CodeSystem) or of the system filter value (if the filter represents a filter defined in CodeSystem) when the operation is 'regex', or one of the values (true and false), when the operation is 'exists'."
      },
      {
        "Data Fields": "compose.exclude.valueSet",
        "Type": "canonical",
        
        
        "Required": "False",
        "Description": "Selects the concepts found in this value set (based on its value set definition). This is an absolute URI that is a reference to ValueSet.url.  If multiple value sets are specified this includes the union of the contents of all of the referenced value sets."
      },
      {
        "Data Fields": "expansion",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A value set can also be \"expanded\", where the value set is turned into a simple collection of enumerated codes. This element holds the expansion, if it has been performed."
      },
      {
        "Data Fields": "expansion.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "expansion.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "expansion.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "expansion.identifier",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "An identifier that uniquely identifies this expansion of the valueset, based on a unique combination of the provided parameters, the system default parameters, and the underlying system code system versions etc. Systems may re-use the same identifier as long as those factors remain the same, and the expansion is the same, but are not required to do so. This is a business identifier."
      },
      {
        "Data Fields": "expansion.timestamp",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The time at which the expansion was produced by the expanding system."
      },
      {
        "Data Fields": "expansion.total",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "The total number of concepts in the expansion. If the number of concept nodes in this resource is less than the stated number, then the server can return more using the offset parameter."
      },
      {
        "Data Fields": "expansion.offset",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "If paging is being used, the offset at which this resource starts.  I.e. this resource is a partial view into the expansion. If paging is not being used, this element SHALL NOT be present."
      },
      {
        "Data Fields": "expansion.parameter",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "A parameter that controlled the expansion process. These parameters may be used by users of expanded value sets to check whether the expansion is suitable for a particular purpose, or to pick the correct expansion."
      },
      {
        "Data Fields": "expansion.parameter.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "expansion.parameter.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "expansion.parameter.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "expansion.parameter.name",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Name of the input parameter to the $expand operation; may be a server-assigned name for additional default or other server-supplied parameters used to control the expansion process."
      },
      {
        "Data Fields": "expansion.parameter.valueString",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of the parameter."
      },
      {
        "Data Fields": "expansion.parameter.valueBoolean",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "The value of the parameter."
      },
      {
        "Data Fields": "expansion.parameter.valueInteger",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "The value of the parameter."
      },
      {
        "Data Fields": "expansion.parameter.valueDecimal",
        "Type": "number",
        
        
        "Required": "False",
        "Description": "The value of the parameter."
      },
      {
        "Data Fields": "expansion.parameter.valueUri",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of the parameter."
      },
      {
        "Data Fields": "expansion.parameter.valueCode",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of the parameter."
      },
      {
        "Data Fields": "expansion.parameter.valueDateTime",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The value of the parameter."
      },
      {
        "Data Fields": "expansion.contains",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "The codes that are contained in the value set expansion."
      },
      {
        "Data Fields": "expansion.contains.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "expansion.contains.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "expansion.contains.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "expansion.contains.system",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "An absolute URI which is the code system in which the code for this item in the expansion is defined."
      },
      {
        "Data Fields": "expansion.contains.abstract",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "If true, this entry is included in the expansion for navigational purposes, and the user cannot select the code directly as a proper value."
      },
      {
        "Data Fields": "expansion.contains.inactive",
        "Type": "boolean",
        
        
        "Required": "False",
        "Description": "If the concept is inactive in the code system that defines it. Inactive codes are those that are no longer to be used, but are maintained by the code system for understanding legacy data. It might not be known or specified whether an concept is inactive (and it may depend on the context of use)."
      },
      {
        "Data Fields": "expansion.contains.version",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The version of the code system from this code was taken. Note that a well-maintained code system does not need the version reported, because the meaning of codes is consistent across versions. However this cannot consistently be assured, and when the meaning is not guaranteed to be consistent, the version SHOULD be exchanged."
      },
      {
        "Data Fields": "expansion.contains.code",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The code for this item in the expansion hierarchy. If this code is missing the entry in the hierarchy is a place holder (abstract) and does not represent a valid code in the value set."
      },
      {
        "Data Fields": "expansion.contains.display",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The recommended display for this item in the expansion."
      },
      {
        "Data Fields": "expansion.contains.designation",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Additional representations for this item - other languages, aliases, specialized purposes, used for particular purposes, etc. These are relevant when the conditions of the expansion do not fix to a single correct representation."
      },
      {
        "Data Fields": "expansion.contains.designation.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "expansion.contains.designation.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "expansion.contains.designation.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "expansion.contains.designation.language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The language this designation is defined for."
      },
      {
        "Data Fields": "expansion.contains.designation.use",
        "Type": "Coding",
        
        
        "Required": "False",
        "Description": "A code that represents types of uses of designations."
      },
      {
        "Data Fields": "expansion.contains.designation.value",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The text value for this designation."
      }
    ],
    "VerificationResult": [
      {
        "Data Fields": "resourceType",
        "Type": "VerificationResult",
        
        
        "Required": "True",
        "Description": "This is a VerificationResult resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "target",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A resource that was validated."
      },
      {
        "Data Fields": "targetLocation",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "The fhirpath location(s) within the resource that was validated."
      },
      {
        "Data Fields": "need",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The frequency with which the target must be validated (none; initial; periodic)."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The validation status of the target (attested; validated; in process; requires revalidation; validation failed; revalidation failed)."
      },
      {
        "Data Fields": "statusDate",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "When the validation status was updated."
      },
      {
        "Data Fields": "validationType",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "What the target is validated against (nothing; primary source; multiple sources)."
      },
      {
        "Data Fields": "validationProcess",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The primary process by which the target is validated (edit check; value set; primary source; multiple sources; standalone; in context)."
      },
      {
        "Data Fields": "frequency",
        "Type": "Timing",
        
        
        "Required": "False",
        "Description": "Frequency of revalidation."
      },
      {
        "Data Fields": "lastPerformed",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date/time validation was last completed (including failed validations)."
      },
      {
        "Data Fields": "nextScheduled",
        "Type": "date",
        
        
        "Required": "False",
        "Description": "The date when target is next validated, if appropriate."
      },
      {
        "Data Fields": "failureAction",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The result if validation fails (fatal; warning; record only; none)."
      },
      {
        "Data Fields": "primarySource",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Information about the primary source(s) involved in validation."
      },
      {
        "Data Fields": "primarySource.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "primarySource.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "primarySource.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "primarySource.who",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Reference to the primary source."
      },
      {
        "Data Fields": "primarySource.type",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Type of primary source (License Board; Primary Education; Continuing Education; Postal Service; Relationship owner; Registration Authority; legal source; issuing source; authoritative source)."
      },
      {
        "Data Fields": "primarySource.communicationMethod",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Method for communicating with the primary source (manual; API; Push)."
      },
      {
        "Data Fields": "primarySource.validationStatus",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Status of the validation of the target against the primary source (successful; failed; unknown)."
      },
      {
        "Data Fields": "primarySource.validationDate",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "When the target was validated against the primary source."
      },
      {
        "Data Fields": "primarySource.canPushUpdates",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Ability of the primary source to push updates/alerts (yes; no; undetermined)."
      },
      {
        "Data Fields": "primarySource.pushTypeAvailable",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Type of alerts/updates the primary source can send (specific requested changes; any changes; as defined by source)."
      },
      {
        "Data Fields": "attestation",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Information about the entity attesting to information."
      },
      {
        "Data Fields": "attestation.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "attestation.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "attestation.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "attestation.who",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "The individual or organization attesting to information."
      },
      {
        "Data Fields": "attestation.onBehalfOf",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "When the who is asserting on behalf of another (organization or individual)."
      },
      {
        "Data Fields": "attestation.communicationMethod",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "The method by which attested information was submitted/retrieved (manual; API; Push)."
      },
      {
        "Data Fields": "attestation.date",
        "Type": "date",
        
        
        "Required": "False",
        "Description": "The date the information was attested to."
      },
      {
        "Data Fields": "attestation.sourceIdentityCertificate",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A digital identity certificate associated with the attestation source."
      },
      {
        "Data Fields": "attestation.proxyIdentityCertificate",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A digital identity certificate associated with the proxy entity submitting attested information on behalf of the attestation source."
      },
      {
        "Data Fields": "attestation.proxySignature",
        "Type": "Signature",
        
        
        "Required": "False",
        "Description": "Signed assertion by the proxy entity indicating that they have the right to submit attested information on behalf of the attestation source."
      },
      {
        "Data Fields": "attestation.sourceSignature",
        "Type": "Signature",
        
        
        "Required": "False",
        "Description": "Signed assertion by the attestation source that they have attested to the information."
      },
      {
        "Data Fields": "validator",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Information about the entity validating information."
      },
      {
        "Data Fields": "validator.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "validator.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "validator.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "validator.organization",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "Reference to the organization validating information."
      },
      {
        "Data Fields": "validator.identityCertificate",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "A digital identity certificate associated with the validator."
      },
      {
        "Data Fields": "validator.attestationSignature",
        "Type": "Signature",
        
        
        "Required": "False",
        "Description": "Signed assertion by the validator that they have validated the information."
      }
    ],
    "VisionPrescription": [
      {
        "Data Fields": "resourceType",
        "Type": "VisionPrescription",
        
        
        "Required": "True",
        "Description": "This is a VisionPrescription resource"
      },
      {
        "Data Fields": "id",
        "Type": "id",
        
        
        "Required": "False",
        "Description": "The logical id of the resource, as used in the URL for the resource. Once assigned, this value never changes."
      },
      {
        "Data Fields": "meta",
        "Type": "Meta",
        
        
        "Required": "False",
        "Description": "The metadata about the resource. This is content that is maintained by the infrastructure. Changes to the content might not always be associated with version changes to the resource."
      },
      {
        "Data Fields": "implicitRules",
        "Type": "uri",
        
        
        "Required": "False",
        "Description": "A reference to a set of rules that were followed when the resource was constructed, and which must be understood when processing the content. Often, this is a reference to an implementation guide that defines the special rules along with other profiles etc."
      },
      {
        "Data Fields": "language",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The base language in which the resource is written."
      },
      {
        "Data Fields": "text",
        "Type": "Narrative",
        
        
        "Required": "False",
        "Description": "A human-readable narrative that contains a summary of the resource and can be used to represent the content of the resource to a human. The narrative need not encode all the structured data, but is required to contain sufficient detail to make it \"clinically safe\" for a human to just read the narrative. Resource definitions may define what content should be represented in the narrative to ensure clinical safety."
      },
      {
        "Data Fields": "contained",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "These resources do not have an independent existence apart from the resource that contains them - they cannot be identified independently, and nor can they have their own independent transaction scope."
      },
      {
        "Data Fields": "extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the resource and that modifies the understanding of the element that contains it and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer is allowed to define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "identifier",
        "Type": "Identifier",
        
        
        "Required": "False",
        "Description": "A unique identifier assigned to this vision prescription."
      },
      {
        "Data Fields": "status",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The status of the resource instance."
      },
      {
        "Data Fields": "created",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date this resource was created."
      },
      {
        "Data Fields": "patient",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "A resource reference to the person to whom the vision prescription applies."
      },
      {
        "Data Fields": "encounter",
        "Type": "Reference",
        
        
        "Required": "False",
        "Description": "A reference to a resource that identifies the particular occurrence of contact between patient and health care provider during which the prescription was issued."
      },
      {
        "Data Fields": "dateWritten",
        "Type": "dateTime",
        
        
        "Required": "False",
        "Description": "The date (and perhaps time) when the prescription was written."
      },
      {
        "Data Fields": "prescriber",
        "Type": "Reference",
        
        
        "Required": "True",
        "Description": "The healthcare professional responsible for authorizing the prescription."
      },
      {
        "Data Fields": "lensSpecification",
        "Type": "BackboneElement",
        
        
        "Required": "True",
        "Description": "Contain the details of  the individual lens specifications and serves as the authorization for the fullfillment by certified professionals."
      },
      {
        "Data Fields": "lensSpecification.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "lensSpecification.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "lensSpecification.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "lensSpecification.product",
        "Type": "CodeableConcept",
        
        
        "Required": "False",
        "Description": "Identifies the type of vision correction product which is required for the patient."
      },
      {
        "Data Fields": "lensSpecification.eye",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The eye for which the lens specification applies."
      },
      {
        "Data Fields": "lensSpecification.sphere",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "Lens power measured in dioptres (0.25 units)."
      },
      {
        "Data Fields": "lensSpecification.cylinder",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "Power adjustment for astigmatism measured in dioptres (0.25 units)."
      },
      {
        "Data Fields": "lensSpecification.axis",
        "Type": "integer",
        
        
        "Required": "False",
        "Description": "Adjustment for astigmatism measured in integer degrees."
      },
      {
        "Data Fields": "lensSpecification.prism",
        "Type": "BackboneElement",
        
        
        "Required": "False",
        "Description": "Allows for adjustment on two axis."
      },
      {
        "Data Fields": "lensSpecification.prism.id",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Unique id for the element within a resource (for internal references). This may be any string value that does not contain spaces."
      },
      {
        "Data Fields": "lensSpecification.prism.extension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element. To make the use of extensions safe and manageable, there is a strict set of governance  applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension."
      },
      {
        "Data Fields": "lensSpecification.prism.modifierExtension",
        "Type": "Extension",
        
        
        "Required": "False",
        "Description": "May be used to represent additional information that is not part of the basic definition of the element and that modifies the understanding of the element in which it is contained and/or the understanding of the containing element's descendants. Usually modifier elements provide negation or qualification. To make the use of extensions safe and manageable, there is a strict set of governance applied to the definition and use of extensions. Though any implementer can define an extension, there is a set of requirements that SHALL be met as part of the definition of the extension. Applications processing a resource are required to check for modifier extensions.  Modifier extensions SHALL NOT change the meaning of any elements on Resource or DomainResource (including cannot change the meaning of modifierExtension itself)."
      },
      {
        "Data Fields": "lensSpecification.prism.amount",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "Amount of prism to compensate for eye alignment in fractional units."
      },
      {
        "Data Fields": "lensSpecification.prism.base",
        "Type": "code",
        
        
        "Required": "False",
        "Description": "The relative base, or reference lens edge, for the prism."
      },
      {
        "Data Fields": "lensSpecification.add",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "Power adjustment for multifocal lenses measured in dioptres (0.25 units)."
      },
      {
        "Data Fields": "lensSpecification.power",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "Contact lens power measured in dioptres (0.25 units)."
      },
      {
        "Data Fields": "lensSpecification.backCurve",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "Back curvature measured in millimetres."
      },
      {
        "Data Fields": "lensSpecification.diameter",
        "Type": "decimal",
        
        
        "Required": "False",
        "Description": "Contact lens diameter measured in millimetres."
      },
      {
        "Data Fields": "lensSpecification.duration",
        "Type": "Quantity",
        
        
        "Required": "False",
        "Description": "The recommended maximum wear period for the lens."
      },
      {
        "Data Fields": "lensSpecification.color",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Special color or pattern."
      },
      {
        "Data Fields": "lensSpecification.brand",
        "Type": "string",
        
        
        "Required": "False",
        "Description": "Brand recommendations or restrictions."
      },
      {
        "Data Fields": "lensSpecification.note",
        "Type": "Annotation",
        
        
        "Required": "False",
        "Description": "Notes for special requirements such as coatings and lens materials."
      }
    ]
  }